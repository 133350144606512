import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaTrash } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import UserProfileImage from "../../user-profile-image/UserProfileImage"
import "./UserStory.css"
import { IoEyeOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { setIsUserStoryVisible } from '../../../app/feature/outletSlice';

const story = [
    "/img/cover/04.jpg",
    "/img/cover/05.jpg",
    "/img/cover/10.jpg",
    "/img/cover/02.jpg",
    "/img/cover/11.jpg",
  ]


const UserStory = () => {
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    const userStoryRef = useRef();
    const timerRef = useRef(null); // Use a ref to keep track of the timer

    const handlePrev = () => {
        setActiveIndex(prev => (prev - 1 + story.length) % story.length);
        setProgress(0);
    };

    const handleNext = () => {
        setActiveIndex(prev => (prev + 1) % story.length);
        setProgress(0)
    };

    const handleTimer = useCallback(() => {
        // Clear existing timer if any
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
            setProgress(prev => {
                if (prev < 100) {
                    return prev + 1;
                } else {
                    clearInterval(timerRef.current);
                    return prev;
                }
            });
        }, 50);

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isPaused) {
            handleTimer();
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [isPaused, handleTimer]);

    useEffect(() => {
        if (progress === 100) {
            if (activeIndex === story.length - 1) {
                dispatch(setIsUserStoryVisible({ value: false }));
                setActiveIndex(0);
            } else {
                handleNext();
            }
        }
    }, [progress]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (userStoryRef.current && !userStoryRef.current.contains(e.target)) {
                dispatch(setIsUserStoryVisible({ value: false }));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch]);

    const handleMouseEnter = () => setIsPaused(true);
    const handleMouseLeave = () => setIsPaused(false);

    return (
        <div className="user-story-container">
            <div
                className="user-story"
                ref={userStoryRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    className="popup-close-button popup-story-trigger"
                    onClick={() => dispatch(setIsUserStoryVisible({ value: false }))}
                >
                    <svg className="popup-close-button-icon icon-cross">
                        <use xlinkHref="#svg-cross"></use>
                    </svg>
                </div>
                <div className="story-progress-bar">
                    <span className="story-progress" style={{ width: `${progress}%` }}></span>
                </div>
                <div className="story-header">
                    <div className="user-status">
                        <Link className="user-status-avatar" to="/user-profile-page">
                            <UserProfileImage />
                        </Link>
                        <div className="ml-3">
                            <p className="user-status-title medium mb-1">
                                <a className="bold !text-white" href="/user-profile-page">
                                    Marina Valentine{" "}
                                </a>
                            </p>
                            <p className="user-status-text small !mt-[-4px] !text-white">2 days ago</p>
                        </div>
                    </div>
                    <div className="story-actions">
                        <div className="story-views">
                            <h3> <IoEyeOutline /> 5 Views</h3>
                            <ul className='viewers-list'>
                            <h3>5 Views</h3>
                                <li>
                                    <div className="user-status !pl-[60px] !pr-0">
                                        <Link className="user-status-avatar" to="/user-profile-page">
                                            <UserProfileImage />
                                        </Link>

                                        <p className="user-status-title">
                                            <span className="bold">Neko Bebop </span>
                                        </p>

                                        <p className="user-status-text small" style={{ color: "#adafca" }}>
                                            Marketing Manager - Brandmarks
                                        </p>
                                        <p className="user-status-timestamp floaty">54mins</p>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div className="user-status !pl-[60px] !pr-0">
                                        <Link className="user-status-avatar" to="/user-profile-page">
                                            <UserProfileImage />
                                        </Link>

                                        <p className="user-status-title">
                                            <span className="bold">Neko Bebop </span>
                                        </p>

                                        <p className="user-status-text small" style={{ color: "#adafca" }}>
                                            Marketing Manager - Brandmarks
                                        </p>
                                        <p className="user-status-timestamp floaty">54mins</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="user-status !pl-[60px] !pr-0">
                                        <Link className="user-status-avatar" to="/user-profile-page">
                                            <UserProfileImage />
                                        </Link>
                                        <p className="user-status-title">
                                            <span className="bold">Neko Bebop </span>
                                        </p>

                                        <p className="user-status-text small" style={{ color: "#adafca" }}>
                                            Marketing Manager - Brandmarks
                                        </p>
                                        <p className="user-status-timestamp floaty">54mins</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="user-status !pl-[60px] !pr-0">
                                        <Link className="user-status-avatar" to="/user-profile-page">
                                            <UserProfileImage />
                                        </Link>

                                        <p className="user-status-title">
                                            <span className="bold">Neko Bebop </span>
                                        </p>

                                        <p className="user-status-text small" style={{ color: "#adafca" }}>
                                            Marketing Manager - Brandmarks
                                        </p>
                                        <p className="user-status-timestamp floaty">54mins</p>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div className="user-status !pl-[60px] !pr-0">
                                        <Link className="user-status-avatar" to="/user-profile-page">
                                            <UserProfileImage />
                                        </Link>

                                        <p className="user-status-title">
                                            <span className="bold">Neko Bebop </span>
                                        </p>

                                        <p className="user-status-text small" style={{ color: "#adafca" }}>
                                            Marketing Manager - Brandmarks
                                        </p>
                                        <p className="user-status-timestamp floaty">54mins</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="user-status !pl-[60px] !pr-0">
                                        <Link className="user-status-avatar" to="/user-profile-page">
                                            <UserProfileImage />
                                        </Link>
                                        <p className="user-status-title">
                                            <span className="bold">Neko Bebop </span>
                                        </p>

                                        <p className="user-status-text small" style={{ color: "#adafca" }}>
                                            Marketing Manager - Brandmarks
                                        </p>
                                        <p className="user-status-timestamp floaty">54mins</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <p className="trash"><FaTrash size={16} /></p>
                    </div>
                </div>
                <div className="story-body">
                    <button className="story-prevBtn" onClick={handlePrev}>
                        <FaChevronLeft size={20} />
                    </button>
                    <button className="story-nextBtn" onClick={handleNext}>
                        <FaChevronRight size={20} />
                    </button>
                    <div className="story-img">
                        <div className="overlay" style={{ backgroundImage: `url(${story[activeIndex]})` }}></div>
                        <img src={story[activeIndex]} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
    };

export default UserStory