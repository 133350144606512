import React, { useState } from "react";
import "../Search.css";
import MemberPageOne from "./MemberPageOne";
import MemberPageTwo from "./MemberPageTwo";
import { MdAdminPanelSettings } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";

const MembersCard = ({ isFriend }) => {
	const [page, setPage] = useState(true);

	return (
		<div className="user-preview result">
			{page === false && <div className="image-border"></div>}
			{page === true && (
				<figure className="user-preview-cover liquid">
					<img
						src="/img/cover/04.jpg"
						alt="cover-04"
						className="user-cover-image-result"
					/>
				</figure>
			)}

			<div className="user-preview-info">
				{page === true && (
					<div className="user-short-description results">
						<div className="card-image-avatar">
							<img src="/img/avatar/03.jpg" alt="user-avatar" />
							<MdAdminPanelSettings
								color={"#d7006a"}
								size={24}
								className="admin-user-result-avatar"
							/>
							<RiVerifiedBadgeFill
								color={"#36e9f7"}
								size={24}
								className="verify-user-result-avatar"
							/>
							<div className="badge-container">
								<img src="/img/download.png" alt="Padge" />
								<p className="badge-number">12</p>
							</div>
						</div>

						<p className="user-short-description-title user-result-name">
							<a href="">NekoBebop</a>
						</p>

						<p className="user-short-description-text user-job-result">
							<a href="">Marketing Manager</a>
						</p>
						<p className="user-short-description-text user-job-result company">
							<a href="">Brandmarks</a>
						</p>
					</div>
				)}

				<div
					id="user-preview-stats-roster-01"
					className={`user-preview-stats-roster slider-roster swipe-pages ${
						page === false ? "swipe-pages-two" : ""
					}`}
					onClick={() => {
						setPage((prev) => !prev);
					}}
					style={{ cursor: "pointer" }}
				>
					<div
						className={`slider-roster-item ${
							page === true ? "active-dot" : ""
						}`}
					></div>

					<div
						className={`slider-roster-item ${
							page === false ? "active-dot" : ""
						}`}
					></div>
				</div>

				{page === false && <MemberPageOne />}
				{page === true && <MemberPageTwo />}

				<div className="user-preview-actions">
					{!isFriend && (
						<p className="button medium white loginButton !flex !items-center !justify-center !gap-[8px]">
							<FaPlus size={20} className="plus-sign" /> Connection
						</p>
					)}
					{isFriend && (
						<p className="button medium white loginButton view-btn">
							<span className="view-btn-connected">Connected</span>
							<div
								style={{
									marginTop: "6px",
								}}
							></div>
							<span>View Profile</span>
						</p>
					)}

					<p className="button secondary second-btn">Send Message</p>
				</div>
			</div>
		</div>
	);
};

export default MembersCard;
