import React, { useState } from "react";
import "./UserProfilePage.css";
import { FaPlus } from "react-icons/fa6";
import styles from "./StyleComponents.module.css";
import FormInput from "../ui/formInput/FormInput";
import Select from "../ui/select/Select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Countries = [
	{ value: "Egypt" },
	{ value: "Qatar" },
	{ value: "UAE" },
	{ value: "USA" },
	{ value: "Saudi" },
	{ value: "Oman" },
];

const AboutMe = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState("");
	const [isWriteIntro, setIsWriteIntro] = useState(false);
	const [isWriteResidence, setIsWriteResidence] = useState(false);
	const [isWriteNationality, setIsWriteNationality] = useState(false);
	const [isWriteAge, setIsWriteAge] = useState(false);
	const [isWriteStatus, setIsWriteStatus] = useState(false);
	const [showCalender, setShowCalender] = useState(false);
	const [date, setDate] = useState("");
	return (
		<div className="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p className="widget-box-title">About Me</p>

			<div className="widget-box-content">
				<div
					onClick={() => {
						setIsWriteIntro(true);
					}}
					className={`${styles.aboutMeArea} ${
						isWriteIntro
							? ""
							: "border-dashed border-[1px] border-[#00dff0] duration-200 hover:border-[#ff6728]"
					} h-[80px] flex items-center justify-center mb-[36px] rounded`}
				>
					{!isWriteIntro && (
						<div className="flex items-center justify-center gap-2">
							<FaPlus color="#3e3f5e" />{" "}
							<p className="font-medium">Add Your About Me Intro</p>
						</div>
					)}
					{isWriteIntro && (
						<textarea placeholder="Write Intro About You.."></textarea>
					)}
				</div>

				<div className="information-line-list">
					<div className="information-line">
						<p className="information-line-title">Joined</p>

						<p className="information-line-text">March 26th, 2017</p>
					</div>

					<div className="information-line">
						<p className="information-line-title">Affiliation</p>

						<p
							className={`information-line-text flex items-center justify-start gap-2 cursor-pointer ${styles.aboutMeFields}`}
						>
							<FaPlus color="#3e3f5e" classname="text-[0.875rem]" /> Add your
							institute
						</p>
					</div>

					<div
						className={`information-line ${
							isWriteResidence ? "flex items-center justify-start" : ""
						}`}
					>
						<p className="information-line-title">Residence</p>

						{!isWriteResidence && (
							<p
								onClick={() => {
									setIsWriteResidence(true);
								}}
								className={`information-line-text flex items-center justify-start gap-2 cursor-pointer ${styles.aboutMeFields}`}
							>
								<FaPlus color="#3e3f5e" classname="text-[0.875rem]" /> Add your
								residence
							</p>
						)}
						{isWriteResidence && (
							<div className="flex items-center justify-center flex-col flex-1">
								<Select defaultValue={"Country"} optionsArray={Countries} />
								<Select defaultValue={"City"} optionsArray={Countries} />
							</div>
						)}
					</div>

					<div
						className={`information-line ${
							isWriteNationality ? "flex items-center justify-between" : ""
						}`}
					>
						<p className="information-line-title">Nationality</p>

						{!isWriteNationality && (
							<p
								onClick={() => {
									setIsWriteNationality(true);
								}}
								className={`information-line-text flex items-center justify-start gap-2 cursor-pointer ${styles.aboutMeFields}`}
							>
								<FaPlus color="#3e3f5e" classname="text-[0.875rem]" /> Add your
								nationality
							</p>
						)}
						{isWriteNationality && (
							<Select defaultValue={"Nationality"} optionsArray={Countries} />
						)}
					</div>

					<div
						className={`information-line ${
							isWriteAge ? "flex items-center justify-start" : ""
						}`}
					>
						<p className="information-line-title">Age</p>
						{!isWriteAge && (
							<p
								onClick={() => {
									setIsWriteAge(true);
								}}
								className={`information-line-text flex items-center justify-start gap-2 cursor-pointer ${styles.aboutMeFields}`}
							>
								<FaPlus color="#3e3f5e" classname="text-[0.875rem]" /> Add your
								DDB
							</p>
						)}
						{isWriteAge && (
							<div className="relative flex items-center justify-start flex-1">
								<FormInput
									onClick={() => {
										setShowCalender((prev) => !prev);
									}}
									label="Date of Birth"
									type="text"
									id="date-of-birth"
									name="date_of_birth"
									icon={"date"}
									value={date.toLocaleString("en-us").split(", ")[0]}
								/>
								{showCalender && (
									<div
										onClick={() => {
											setShowCalender(false);
										}}
										className={`absolute top-20 left-0 w-fit z-50`}
									>
										<Calendar onChange={setDate} value={date} />
									</div>
								)}
							</div>
						)}
					</div>

					<div
						className={`information-line ${
							isWriteStatus ? "flex items-center justify-start" : ""
						}`}
					>
						<p className="information-line-title">Staus</p>
						{!isWriteStatus && (
							<p
								onClick={() => {
									setIsWriteStatus(true);
								}}
								className={`information-line-text flex items-center justify-start gap-2 cursor-pointer ${styles.aboutMeFields}`}
							>
								<FaPlus color="#3e3f5e" classname="text-[0.875rem]" /> Add your
								Status
							</p>
						)}
						{isWriteStatus && (
							<div className="relative flex items-center justify-start flex-1">
								<Select defaultValue={"Status"} optionsArray={[]} />
							</div>
						)}
					</div>
				</div>

				<div
					className={`transition-all duration-200 ${
						isWriteIntro ||
						isWriteResidence ||
						isWriteNationality ||
						isWriteAge ||
						isWriteStatus
							? "h-[80px] opacity-100 visible"
							: "h-0 opacity-0 invisible"
					}`}
				>
					<button className="button medium secondary mt-4">Save</button>
				</div>
			</div>
		</div>
	);
};

export default AboutMe;
