import React from "react";
import SuggestedBusiness from "../SuggestedBusiness/SuggestedBusiness";
import SuggestedCommunities from "../SuggestedCommunities/SuggestedCommunities";
import InviteFriends from "../InviteFriends/InviteFriends";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";



const slides = [
  [
    { imgSrc: "img/reaction/like.png", title: "12.642", text: "Likes" },
    { imgSrc: "img/reaction/love.png", title: "8.913", text: "Loves" },
    { imgSrc: "img/reaction/dislike.png", title: "945", text: "Dislikes" },
    { imgSrc: "img/reaction/happy.png", title: "7.034", text: "Happy" },
  ],
  [
    { imgSrc: "img/reaction/funny.png", title: "2.356", text: "Funny" },
    { imgSrc: "img/reaction/wow.png", title: "5.944", text: "Wow!" },
    { imgSrc: "img/reaction/angry.png", title: "1.706", text: "Angry" },
    { imgSrc: "img/reaction/sad.png", title: "801", text: "Sad" },
  ],
];

const badges = [
  {
    exp: 20,
    imgSrc: "img/badge/uexp-b.png",
    alt: "badge-uexp-b",
    title: "Universe Explorer",
    text: "Joined and posted on 20 different groups",
    id: "badge-uexp",
    barProgress: "17/20",
    progress: (17 / 20) * 100,
  },
  {
    exp: 40,
    imgSrc: "img/badge/verifieds-b.png",
    alt: "badge-verifieds-b",
    title: "Verified Streamer",
    text: "Has linked a stream that was verified by the staff",
    id: "badge-verifieds",
    barProgress: "UNLOCKED!",
    progress: 100,
  },
  {
    exp: 40,
    imgSrc: "img/badge/qconq-b.png",
    alt: "badge-qconq-b",
    title: "Quest Conqueror",
    text: "Successfully completed at least 10 quests at 100%",
    id: "badge-qconq",
    barProgress: "4/10",
    progress: (4 / 10) * 100,
  },
];


const HomeLeftAside = () => {
  return (
    <div className="grid-column min-[1367px]:sticky min-[1367px]:top-[-1450px]">
      <SuggestedBusiness />

      <div className="widget-box !px-[16px]">
        <div className="widget-box-controls">
          <div id="reaction-stat-slider-controls" className="slider-controls">
            <div className="slider-control left reaction-left">
              <svg className="slider-control-icon icon-small-arrow">
                <use xlinkHref="#svg-small-arrow"></use>
              </svg>
            </div>
            <div className="slider-control right reaction-right">
              <svg className="slider-control-icon icon-small-arrow">
                <use xlinkHref="#svg-small-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
        <p className="widget-box-title">Reactions Received</p>
        <div className="widget-box-content">
          <div
            id="reaction-stat-slider-items"
            className="widget-box-content-slider"
          >
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              navigation={{
                prevEl: ".slider-control.reaction-left",
                nextEl: ".slider-control.reaction-right",
              }}
              modules={[Navigation]}
              className={`reactions-swiper`}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className={`widget-box-content-slider-item`}>
                    <div className="reaction-stats">
                      {slide.map((reaction, idx) =>
                        idx < 2 ? (
                          <div className="reaction-stat" key={idx}>
                            <img
                              className="reaction-stat-image"
                              src={reaction.imgSrc}
                              alt={`reaction-${reaction.text.toLowerCase()}`}
                            />
                            <p className="reaction-stat-title">
                              {reaction.title}
                            </p>
                            <p className="reaction-stat-text">
                              {reaction.text}
                            </p>
                          </div>
                        ) : null
                      )}
                    </div>
                    <div className="reaction-stats">
                      {slide.map((reaction, idx) =>
                        idx >= 2 ? (
                          <div className="reaction-stat" key={idx}>
                            <img
                              className="reaction-stat-image"
                              src={reaction.imgSrc}
                              alt={`reaction-${reaction.text.toLowerCase()}`}
                            />
                            <p className="reaction-stat-title">
                              {reaction.title}
                            </p>
                            <p className="reaction-stat-text">
                              {reaction.text}
                            </p>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <SuggestedCommunities />

      <InviteFriends />

      <div className="widget-box !px-[16px]">
        <div className="widget-box-controls">
          <div id="badge-stat-slider-controls" className="slider-controls">
            <div className="slider-control left featured-left">
              <svg className="slider-control-icon icon-small-arrow">
                <use xlinkHref="#svg-small-arrow"></use>
              </svg>
            </div>
            <div className="slider-control right featured-right">
              <svg className="slider-control-icon icon-small-arrow">
                <use xlinkHref="#svg-small-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
        <p className="widget-box-title">Featured Badges</p>
        <div className="widget-box-content">
          <div
            id="badge-stat-slider-items"
            className="widget-box-content-slider"
          >
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              navigation={{
                prevEl: ".slider-control.featured-left",
                nextEl: ".slider-control.featured-right",
              }}
              modules={[Navigation]}
              className={`featuredBadges-swiper`}
            >
              {badges.map((badge, index) => (
                <SwiperSlide key={index}>
                  <div className={`widget-box-content-slider-item`}>
                    <div className="badge-item-stat void">
                      <p className="text-sticker flex items-center">
                        <svg className="text-sticker-icon icon-plus-small">
                          <use xlinkHref="#svg-plus-small"></use>
                        </svg>
                        {badge.exp} EXP
                      </p>
                      <img
                        className="badge-item-stat-image"
                        src={badge.imgSrc}
                        alt={badge.alt}
                      />
                      <p className="badge-item-stat-title">{badge.title}</p>
                      <p className="badge-item-stat-text">{badge.text}</p>
                      <div className="progress-stat medium">
                        <div
                          id={badge.id}
                          className="progress-stat-bar"
                          style={{
                            width: "100%",
                            height: "4px",
                            position: "relative",
                          }}
                        >
                          <div
                            className="bar"
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#ddd",
                            }}
                          ></div>
                          <div
                            className="colored-bar"
                            style={{
                              width: `${badge.progress}%`,
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              background:
                                "linear-gradient(90deg, #fd6729, orange)",
                            }}
                          ></div>
                        </div>
                        <div className="bar-progress-wrap">
                          <p className="bar-progress-info negative center">
                            <span className="bar-progress-text no-space">
                              {" "}
                              {badge.barProgress}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLeftAside;
