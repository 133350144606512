import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, } from "antd";
import { FaLinkedin, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { FaFacebookF, FaXTwitter } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi";
import { GrFlagFill } from "react-icons/gr";
import { TbTimelineEventText } from "react-icons/tb";
import { GoLink } from "react-icons/go";
import { IoFlagSharp } from "react-icons/io5";


const AdPost = () => {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
	const [isShareOpen , setIsShareOpen] = useState(false)
  const [selectShare , setSelectShare] = useState("")

  const postSettingsRef = useRef()

  useEffect( ()=>{
    document.addEventListener("mousedown" , function(event){
      if(postSettingsRef.current && !postSettingsRef.current.contains(event.target)){
        setIsWidgetOpen(false)
      }
    })

    return () =>{
      document.removeEventListener("mousedown" , function(event){
        if(postSettingsRef.current && !postSettingsRef.current.contains(event.target)){
          setIsWidgetOpen(false)
        }
      })
    }
  } ,[])


  return (
    <div className="widget-box no-padding border-[1px] border-solid border-[#fd6729]">

      <div className="widget-box-settings flex items-center gap-[8px]">
        <h3 className="text-[#fd6729]">Sponsored</h3>
        <div className="post-settings-wrap" ref={postSettingsRef}>
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>

          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link"
            onClick={ () => setIsShareOpen( prev => !prev ) }
            >Share</p>
          </div>
        </div>
      </div>

      <div className="widget-box-status">
        <div className="widget-box-status-content">
          <div className="user-status">
            <div className="user-status-avatar bg-[#ff3a5526] w-[45px] h-[45px] rounded-[50%] flex items-center justify-center text-[#fd6729]">
                <IoFlagSharp size={20} />
            </div>

            <div className="ml-1">
                <p className="user-status-title medium">
                    <a className="bold" href="profile-timeline.html">
                        Graphic & Design
                    </a>
                </p>

                <p className="user-status-text small !mt-[0]">2 days ago</p>

              
            </div>
          </div>

          <p class="widget-box-status-text">
            When you finally pass the quest that you've been trying for so long...
          </p>

        </div>

        <a href="#" >
            <div className="ad-contnet mt-[22px] rounded-b-[12px] overflow-hidden">
                <h3 className="ad-title p-[15px] bg-[#fd6729] text-[#fff]">
                    <GoLink className="inline" />{" "}
                    Indiepro Game Developement
                </h3>
                <img className="w-full h-[350px] object-cover" src="/img/cover/04.jpg" alt="" />
            </div>
        </a>

      </div>

      <Modal
        title={null}
        open={isShareOpen}
        onCancel={() => setIsShareOpen(!isShareOpen)}
        footer={null}
        width={800}
        zIndex={100000000000}
      >
        <div className="share-post-container">
          <h2 className="share-post-title">Share the post on</h2>

          <div className="share-post-socials">
            <ul className="grid !grid-cols-3 md:!grid-cols-5">
              <li>
                <a href="">
                  <FaXTwitter />
                  Twiiter
                </a>
              </li>
              <li>
                <a href="">
                  <FaFacebookF />
                  Facebook
                </a>
              </li>
              <li>
                <a href="">
                  <FaWhatsapp />
                  Whatsapp
                </a>
              </li>
              <li>
                <a href="">
                  <FaLinkedin />
                  Linkedin
                </a>
              </li>
              <li>
                <a href="">
                  <FaTelegramPlane />
                  Telegram
                </a>
              </li>
            </ul>
          </div>

          <form action="">
            <textarea
              id="share-post-text"
              name="share-post-text"
              placeholder="What's going on? #Hashtag.. @Mention.. Link.."
              rows={5}
            ></textarea>

            <div className="share-on">
              <h3 className="text-[25px] mb-[15px]">Share the post on</h3>

              <div className="share-select-wrapper">
                <div
                  className={`share-select-item ${
                    selectShare == "timeline" ? "active" : ""
                  }`}
                  onClick={() => setSelectShare("timeline")}
                >
                  <span>
                    <TbTimelineEventText size={25} />
                  </span>
                  <h5 className="text-[18px]">My Timeline</h5>
                </div>
                <div
                  className={`share-select-item ${
                    selectShare == "business" ? "active" : ""
                  }`}
                  onClick={() => setSelectShare("business")}
                >
                  <span>
                    <GrFlagFill size={25} />
                  </span>
                  <h5 className="text-[18px]">Bussiness Profile</h5>
                </div>
                <div
                  className={`share-select-item ${
                    selectShare == "community" ? "active" : ""
                  }`}
                  onClick={() => setSelectShare("community")}
                >
                  <span>
                    <HiUserGroup size={25} />
                  </span>
                  <h5 className="text-[18px]">Community</h5>
                </div>
              </div>

              <div className="relative overflow-hidden py-[30px] mb-[30px]">
                <input
                  type="text"
                  placeholder="Enter Business Name"
                  style={
                    selectShare == "business"
                      ? { left: "50%", bottom: "0" }
                      : { left: "50%", bottom: "-50px" }
                  }
                />
                <input
                  type="text"
                  placeholder="Enter Community Name"
                  style={
                    selectShare == "community"
                      ? { left: "50%", bottom: "0" }
                      : { left: "50%", bottom: "-50px" }
                  }
                />
              </div>

              <button className="post-btn button primary">Post</button>
            </div>
          </form>
        </div>
      </Modal>

    </div>
  );
};

export default AdPost;
