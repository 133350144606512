import React, { useState } from "react";

const GroupInfo = () => {
  const [isWidgetOpen, setIsWidgetOpen] = useState("");

  return (
    <div class="widget-box">
      <div className="widget-box-settings">
        <div className="post-settings-wrap">
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>

          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link">Widget Settings</p>
          </div>
        </div>
      </div>

      <p class="widget-box-title">
        Group info
        {/* <span class="highlighted">7</span> */}
      </p>


      <div className="widget-box-content">
    <p className="paragraph">Hello everyone! This is a group open to all for cosplayers around the world to talk about their passion, trade tips, ask for opinions and have a great time!</p>

    <div className="information-line-list">
      <div className="information-line">
        <p className="information-line-title">Created</p>
        <p className="information-line-text">April 9th, 2018</p>
      </div>

      {/* <div className="information-line">
        <p className="information-line-title">Type</p>
        <p className="information-line-text">Public</p>
      </div>

      <div className="information-line">
        <p className="information-line-title">Email</p>
        <p className="information-line-text"><a href="#">info@cosworld.com</a></p>
      </div>

      <div className="information-line">
        <p className="information-line-title">Web</p>
        <p className="information-line-text"><a href="#">www.cosplayworld.com</a></p>
      </div> */}
    </div>
    </div>
    </div>
  );
};

export default GroupInfo;

