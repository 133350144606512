import React from "react";
import Select from "../../../ui/select/Select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";


const TwoFactorAuth = [{ value: "Enable" }, { value: "Disable" }];

const TwoFactorAuthentication = () => {
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Account</p>
					<h2 className="section-title">Two-Factor Authentication</h2>
				</div>
			</div>
			<div className="widget-box">
				{/* <p className="widget-box-title">Two-Factor Authentication</p> */}

				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center justify-between flex-col gap-4">
							<Select
								defaultValue={"Two-Factor Authentication"}
								optionsArray={TwoFactorAuth}
							/>
							<p className="text-[14px] font-semibold -mt-6">
								Turn on 2-step login to level-up your account's security, Once
								turned on, you'll use both your password and a 6-digit security
								code sent to your phone or email to log in.
							</p>
						</div>
					</form>
				</div>
			</div>


			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default TwoFactorAuthentication;
