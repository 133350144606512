import React, { useState } from "react";

const Skills = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState(false);

	return (
		<div className="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p className="widget-box-title">Skills</p>

			<div className="widget-box-content">
				<div className="information-block-list">
					<div className="information-block">
						<p className="information-block-title">Favourite TV Shows</p>

						<p className="information-block-text">
							Breaking Good, RedDevil, People of Interest, The Running Dead,
							Found, American Guy, The Last Windbender, Game of Wars.
						</p>
					</div>

					<div className="information-block">
						<p className="information-block-title">
							Favourite Music Bands / Artists
						</p>

						<p className="information-block-text">
							Iron Maid, DC/AC, Megablow, Kung Fighters, System of a Revenge,
							Rammstown.
						</p>
					</div>

					<div className="information-block">
						<p className="information-block-title">Favourite Movies</p>

						<p className="information-block-text">
							The Revengers Saga, The Scarred Wizard and the Fire Crown, Crime
							Squad, Metal Man, The Dark Rider, Watchers, The Impossible Heist.
						</p>
					</div>

					<div className="information-block">
						<p className="information-block-title">Favourite Books</p>

						<p className="information-block-text">
							The Crime of the Century, Egiptian Mythology 101, The Scarred
							Wizard, Lord of the Wings, Amongst Gods, The Oracle, A Tale of Air
							and Water.
						</p>
					</div>

					<div className="information-block">
						<p className="information-block-title">Favourite Games</p>

						<p className="information-block-text">
							The First of Us, Assassin’s Squad, Dark Assylum, NMAK16, Last
							Cause 4, Grand Snatch Auto.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Skills;
