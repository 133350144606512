import "./register-screen.css";
import { useState } from "react";
import {
	StepOneSendSMSCode,
	RegisterUser,
} from "../../../components/registrationSteps/RegistrationSteps";

const RegisterScreen = ({
	toggleForm,
	isMobileView,
	stateStep,
	isProfile,
	isProfileDone,
}) => {
	const [currentStep, setCurrentStep] = useState(
		stateStep === 0 ? 1 : stateStep
	);
	const [isSmsModalHide, setIsSmsModalHide] = useState(false);
	const [otp, setOTP] = useState("");
	const [stepThreeIsChecked, setStepThreeIsChecked] = useState(false);
	const [date, setDate] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isPasswordAndConfirmMatch, setIsPasswordAndConfirmMatch] =
		useState(false);

	if (toggleForm && isMobileView && currentStep === 1) {
		window.window.scrollTo(0, 330);
	}

	return (
		<div
			className={`form-box login-register-form-element ${
				currentStep === 0 ? "decrease-padding-form" : ""
			}`}
			style={
				toggleForm === false && isMobileView === true
					? { display: "none" }
					: !toggleForm
					? {
							transform: "translate(50%)",
							opacity: "0",
							visibility: "hidden",
							zIndex: "1",
					  }
					: {
							transform: "translate(0px)",
							opacity: "1",
							visibility: "visible",
							zIndex: "2",
					  }
			}
		>
			<h2 className="form-box-title">Create your Account!</h2>

			<div className="register-steps">
				<span
					className={`step ${
						currentStep === 1 ? "active" : currentStep > 1 ? "complete" : ""
					}`}
				></span>
				<span
					className={`step ${
						currentStep === 2 ? "active" : currentStep > 2 ? "complete" : ""
					}`}
				></span>
			</div>

			{currentStep === 1 && (
				<StepOneSendSMSCode setCurrentStep={setCurrentStep} />
			)}
			{currentStep === 2 && (
				<RegisterUser
					username={username}
					setUsername={setUsername}
					confirmPassword={confirmPassword}
					password={password}
					setConfirmPassword={setConfirmPassword}
					setPassword={setPassword}
				/>
			)}
		</div>
	);
};

export default RegisterScreen;
