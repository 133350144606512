import React from "react";

const MemberSearchItems = () => {
	return (
		<>
			<p>
				Filters Keyword: <span>Ahmed</span>
			</p>
			<p>
				Residence: <span>Egypt</span>
			</p>
			<p>
				City: <span>Cairo</span>
			</p>
			<p>
				Nationality: <span>Egyptian</span>
			</p>
			<p>
				Affiliation: <span>The American University of Cairo</span>
			</p>
		</>
	);
};

export default MemberSearchItems;
