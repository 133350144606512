import React, { useEffect, useRef, useState } from "react";
import PostComments from "./PostComments/PostComments";
import UserProfileImage from "../user-profile-image/UserProfileImage";
import { Link } from "react-router-dom";
import { Modal, } from "antd";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { MdAddLocationAlt, MdLocationOn, MdOutlinePublic } from "react-icons/md";
import { FaLinkedin, FaStar, FaTelegramPlane, FaUser, FaUsers, FaWhatsapp } from "react-icons/fa";
import { FaFacebookF, FaTag, FaXTwitter } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi";
import { GrFlagFill, GrServices } from "react-icons/gr";
import { TbTimelineEventText } from "react-icons/tb";
import PostCommentForm from "./PostComments/PostCommentForm";
import { CiClock2, CiHeart, CiStar } from "react-icons/ci";
import { PiBagSimpleFill } from "react-icons/pi";
import { BsFillClockFill, BsTagFill } from "react-icons/bs";

import img from "../../assests/crea8/01-removebg-preview.png"
import { IoStar } from "react-icons/io5";

const postTypeOptions = [
  {
    value: "only-me",
    label: (
      <div className="flex items-center gap-[10px]">
        <RiGitRepositoryPrivateLine /> <span>Only Me</span>
      </div>
    ),
    svg: <RiGitRepositoryPrivateLine />
  },
  {
    value: "everyone",
    label: (
      <div className="flex items-center gap-[10px]">
        <MdOutlinePublic /> <span>Everyone</span>
      </div>
    ),
    svg: <MdOutlinePublic />
  },
  {
    value: "people-i-follow",
    label: (
      <div className="flex items-center gap-[10px]">
        <FaUser /> <span>People I Follow</span>
      </div>
    ),
    svg: <FaUser />
  },
  {
    value: "people-follow-me",
    label: (
      <div className="flex items-center gap-[10px]">
        <FaUsers /> <span>People Follow Me</span>
      </div>
    ),
    svg: <FaUsers />
  },
];


const VacancyPost = () => {
  const [isPostTypeOpen, setIsPostTypeOpen] = useState(false)
  const [postType, setPostType] = useState(postTypeOptions[1])
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [isWowListOpen, setIsWowListOpen] = useState(false);
  const [isHappyListOpen, setIsHappyListOpen] = useState(false);
  const [isLikeListOpen, setIsLikeListOpen] = useState(false);
  const [isReactListOpen, setIsReactListOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false)
  const [selectShare, setSelectShare] = useState("")
  const [isFav, setIsFav] = useState(false)

  const postSettingsRef = useRef()
  const postTypeRef = useRef()


  useEffect(() => {
    document.addEventListener("mousedown", function (event) {
      if (postSettingsRef.current && !postSettingsRef.current.contains(event.target)) {
        setIsWidgetOpen(false)
      }
      if (postTypeRef.current && !postTypeRef.current.contains(event.target)) {
        setIsPostTypeOpen(false)
      }
    })

    return () => {
      document.removeEventListener("mousedown", function (event) {
        if (postSettingsRef.current && !postSettingsRef.current.contains(event.target)) {
          setIsWidgetOpen(false)
        }
        if (postTypeRef.current && !postTypeRef.current.contains(event.target)) {
          setIsPostTypeOpen(false)
        }
      })
    }
  }, [])

  return (
    <div className="widget-box no-padding">
      <div className="widget-box-settings">
        <div className="post-settings-wrap" ref={postSettingsRef}>
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${isWidgetOpen ? "active" : ""
              }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>

          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
              }`}
          >
            <p className="simple-dropdown-link">Edit Post</p>

            <p className="simple-dropdown-link">Delete Post</p>

            <p className="simple-dropdown-link">Pin Post</p>

            <p className="simple-dropdown-link">Report Post</p>

            <p className="simple-dropdown-link">Report Author</p>

            <p className="simple-dropdown-link">Disable Comments</p>

            <a className="simple-dropdown-link" href="/post/5" target="_blank">Open in new tab</a>
          </div>
        </div>
      </div>

      <div className="widget-box-status">
        <div className="widget-box-status-content">
          <div className="user-status">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>

            <div className="ml-1">
              <p className="user-status-title medium">
                <a className="bold" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
                shared a <a href="#" className="bold">Vacancy</a> <a href="#" className="bold">@elnomadics</a>
              </p>

              <div className="flex items-center gap-[10px]">
                <div className="post-type-wrapper" ref={postTypeRef}>
                  <button
                    className="w-fit text-[#a6a8c7]"
                    onClick={() => {
                      setIsPostTypeOpen((prev) => !prev);
                    }}
                  >
                    {postType.svg}
                  </button>
                  <div
                    className={`simple-dropdown widget-box-post-types-dropdown ${isPostTypeOpen
                        ? "widget-types-open"
                        : "widget-types-close"
                      }`}
                  >
                    {postTypeOptions.map((option) => (
                      <p
                        className="simple-dropdown-link"
                        onClick={() => {
                          setPostType(option);
                          setIsPostTypeOpen(false);
                        }}
                      >
                        {" "}
                        {option.label}
                      </p>
                    ))}
                  </div>
                </div>
                <p className="user-status-text small !mt-[-4px]">2 days ago</p>
              </div>
            </div>

          </div>

          <div className="post-vacancy-content mt-[24px]">
            <div className="lisitng-tag flex items-start justify-end gap-[10px] mr-[10px]">
              <p className="special-post-tab bg-[#fd6729] text-white text-[18px] font-bold flex gap-[5px] pt-[10px] py-[8px] px-[20px] rounded-t-[12px] w-fit">
                <GrServices />
                Vacancy
              </p>
            </div>
            <div className="post-vacancy-container">

              <div className="img relative overflow-hidden rounded-t-[12px]">
                <div className={`post-vacancy-fav ${isFav ? "active" : ""}`} onClick={() => setIsFav(prev => !prev)}>
                  <IoStar />
                </div>
                <a href="/job-details"><img src="/img/cover/04.jpg" alt="" /></a>
                <a href="/job-details" className="post-vacancy-apply">Apply</a>
              </div>

              <div className="post-vacancy-body">

                <div className="post-vacancy-header relative">
                  <a href="/job-details"><img className="post-vacancy-logo" src="/img/businesses/02.jpeg" alt="" /></a>
                  <div className="pl-[130px] pb-[10px]">
                    <a className="flex items-center gap-1 mb-[10px] text-[1rem] text-[#fd6729]" href="https://main.flokky.app/directory ">
                      <span className="">
                        <svg className="!fill-[#fd6729] w-[15px] h-[15px]">
                          <use xlinkHref="#svg-public"></use>
                        </svg>
                      </span>{" "}
                      Restaurant
                    </a>
                    <h2 className="text-[20px]">
                      <a href="/job-details">
                        Exprienced Uniform Senior Graphic Designer and Web Desiner Needed ...
                      </a>
                    </h2>
                  </div>
                </div>

                <div className="post-vacancy-footer">
                  <ul className="flex items-center gap-[12px]">
                    <li className="flex items-center gap-[2px]">
                      <MdLocationOn color="#fd6729" /> Egypt
                    </li>
                    <li className="flex items-center gap-[2px]">
                      <BsFillClockFill fill="#4caf50" /> 20 w
                    </li>
                    <li className="flex items-center gap-[2px]">
                      <PiBagSimpleFill size={21} fill="#2196f3" /> Part Time
                    </li>
                    <li className="flex items-center gap-[2px]">
                      <BsTagFill color="#fd6729" /> 3
                    </li>
                  </ul>

                  <p className="text-[18px] font-bold text-[#fd6729]">14,000 - 15,000 EGP/Month</p>
                </div>

              </div>
            </div>
            <div className="tag-list">
              <p className="tag-item !bg-[#2196f3] !mt-0 !mr-auto"> Graphic Design </p>
              <p className="tag-item primary !mt-0">
                Skill 1
              </p>
              <p className="tag-item primary !mt-0">
                Skill 2
              </p>
              <p className="tag-item primary !mt-0">
                Skill 3
              </p>
              <p className="tag-item primary !mt-0">
                Skill 4
              </p>
              <p className="tag-item primary !mt-0">
                Skill 5
              </p>

            </div>
          </div>

          <div className="content-actions">
            <div className="content-action">
              <div className="meta-line">
                <div className="meta-line-list reaction-item-list">

                  <div className="reaction-item gap-[4px]"
                    onMouseLeave={() => {
                      setIsWowListOpen(false);
                    }}
                    onMouseEnter={() => {
                      setIsWowListOpen(true);
                    }}
                  >
                    <img
                      src="/img/reaction/wow.png"
                      alt="reaction-wow"
                      className={`reaction-image reaction-item-dropdown-trigger ${isWowListOpen ? "active" : ""
                        }`}

                    />
                    <p className="meta-line-text">4</p>

                    <div
                      className={`simple-dropdown padded reaction-item-dropdown !w-[270px] ${isWowListOpen ? "reacts-list-open" : "reacts-list-close"
                        }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="/img/reaction/wow.png"
                          alt="reaction-wow"
                        />
                        <p className="reactions-number">5</p>
                        <span className="bold">Wow</span>
                      </p>

                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="reaction-item gap-[4px]"
                    onMouseEnter={() => {
                      setIsHappyListOpen(true);
                    }}
                    onMouseLeave={() => {
                      setIsHappyListOpen(false);
                    }}
                  >
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${isHappyListOpen ? "active" : ""
                        }`}
                      src="/img/reaction/happy.png"
                      alt="reaction-happy"
                    />
                    <p className="meta-line-text">4</p>

                    <div
                      className={`simple-dropdown padded reaction-item-dropdown !w-[270px] ${isHappyListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                        }`}
                      onMouseEnter={() => {
                        setIsHappyListOpen(true);
                      }}
                      onMouseLeave={() => {
                        setIsHappyListOpen(false);
                      }}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="/img/reaction/happy.png"
                          alt="reaction-happy"
                        />
                        <p className="reactions-number">5</p>
                        <span className="bold">Happy</span>
                      </p>

                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="reaction-item gap-[4px]"
                    onMouseEnter={() => {
                      setIsLikeListOpen(true);
                    }}
                    onMouseLeave={() => {
                      setIsLikeListOpen(false);
                    }}
                  >
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${isLikeListOpen ? "active" : ""
                        }`}
                      src="/img/reaction/like.png"
                      alt="reaction-like"
                    />
                    <p className="meta-line-text">4</p>

                    <div
                      className={`simple-dropdown padded reaction-item-dropdown !w-[270px] ${isLikeListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                        }`}
                      onMouseEnter={() => {
                        setIsLikeListOpen(true);
                      }}
                      onMouseLeave={() => {
                        setIsLikeListOpen(false);
                      }}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="/img/reaction/like.png"
                          alt="reaction-like"
                        />
                        <p className="reactions-number">5</p>
                        <span className="bold">Like</span>
                      </p>

                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-action">
              <div className="meta-line">
                <p className="meta-line-link">5 Comments</p>
              </div>

              <div className="meta-line">
                <p className="meta-line-text">0 Shares</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="post-options">
        <div className="post-option-wrap">
          <div
            className={`post-option reaction-options-dropdown-trigger ${isReactListOpen ? "active" : ""
              }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <svg className="post-option-icon icon-thumbs-up">
              <use xlinkHref="#svg-thumbs-up"></use>
            </svg>

            <p className="post-option-text">React!</p>
          </div>

          <div
            className={`reaction-options reaction-options-dropdown ${isReactListOpen ? "emoji-list-open" : "emoji-list-close"
              }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <div className="reaction-option text-tooltip-tft" data-title="Like">
              <img
                className="reaction-option-image"
                src="/img/reaction/like.png"
                alt="reaction-like"
              />
            </div>

            <div className="reaction-option text-tooltip-tft" data-title="Love">
              <img
                className="reaction-option-image"
                src="/img/reaction/love.png"
                alt="reaction-love"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Dislike"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/dislike.png"
                alt="reaction-dislike"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Happy"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/happy.png"
                alt="reaction-happy"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Funny"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/funny.png"
                alt="reaction-funny"
              />
            </div>

            <div className="reaction-option text-tooltip-tft" data-title="Wow">
              <img
                className="reaction-option-image"
                src="/img/reaction/wow.png"
                alt="reaction-wow"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Angry"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/angry.png"
                alt="reaction-angry"
              />
            </div>

            <div className="reaction-option text-tooltip-tft" data-title="Sad">
              <img
                className="reaction-option-image"
                src="/img/reaction/sad.png"
                alt="reaction-sad"
              />
            </div>
          </div>
        </div>

        <div
          className="post-option"
          onClick={() => {
            setIsCommentsOpen((prev) => !prev);
          }}
        >
          <svg className="post-option-icon icon-comment">
            <use xlinkHref="#svg-comment"></use>
          </svg>

          <p className="post-option-text">Comment</p>
        </div>

        <div
          className="post-option"
          onClick={() => {
            setIsShareOpen((prev) => !prev);
          }}
        >
          <svg className="post-option-icon icon-share">
            <use xlinkHref="#svg-share"></use>
          </svg>

          <p className="post-option-text">Share</p>
        </div>
      </div>

      <Modal
        title={null}
        open={isShareOpen}
        onCancel={() => setIsShareOpen(!isShareOpen)}
        footer={null}
        width={800}
        zIndex={100000000000}
      >
        <div className="share-post-container">
          <h2 className="share-post-title">Share the post on</h2>

          <div className="share-post-socials">
            <ul className="grid !grid-cols-3 md:!grid-cols-5">
              <li>
                <a href="">
                  <FaXTwitter />
                  Twiiter
                </a>
              </li>
              <li>
                <a href="">
                  <FaFacebookF />
                  Facebook
                </a>
              </li>
              <li>
                <a href="">
                  <FaWhatsapp />
                  Whatsapp
                </a>
              </li>
              <li>
                <a href="">
                  <FaLinkedin />
                  Linkedin
                </a>
              </li>
              <li>
                <a href="">
                  <FaTelegramPlane />
                  Telegram
                </a>
              </li>
            </ul>
          </div>

          <form action="">
            <textarea
              id="share-post-text"
              name="share-post-text"
              placeholder="What's going on? #Hashtag.. @Mention.. Link.."
              rows={5}
            ></textarea>

            <div className="share-on">
              <h3 className="text-[25px] mb-[15px]">Share the post on</h3>

              <div className="share-select-wrapper">
                <div
                  className={`share-select-item ${selectShare == "timeline" ? "active" : ""
                    }`}
                  onClick={() => setSelectShare("timeline")}
                >
                  <span>
                    <TbTimelineEventText size={25} />
                  </span>
                  <h5 className="text-[18px]">My Timeline</h5>
                </div>
                <div
                  className={`share-select-item ${selectShare == "business" ? "active" : ""
                    }`}
                  onClick={() => setSelectShare("business")}
                >
                  <span>
                    <GrFlagFill size={25} />
                  </span>
                  <h5 className="text-[18px]">Bussiness Profile</h5>
                </div>
                <div
                  className={`share-select-item ${selectShare == "community" ? "active" : ""
                    }`}
                  onClick={() => setSelectShare("community")}
                >
                  <span>
                    <HiUserGroup size={25} />
                  </span>
                  <h5 className="text-[18px]">Community</h5>
                </div>
              </div>

              <div className="relative overflow-hidden py-[30px] mb-[30px]">
                <input
                  type="text"
                  placeholder="Enter Business Name"
                  style={
                    selectShare == "business"
                      ? { left: "50%", bottom: "0" }
                      : { left: "50%", bottom: "-50px" }
                  }
                />
                <input
                  type="text"
                  placeholder="Enter Community Name"
                  style={
                    selectShare == "community"
                      ? { left: "50%", bottom: "0" }
                      : { left: "50%", bottom: "-50px" }
                  }
                />
              </div>

              <button className="post-btn button primary">Post</button>
            </div>
          </form>
        </div>
      </Modal>

      <PostComments>{isCommentsOpen && <PostCommentForm />}</PostComments>
    </div>
  );
};

export default VacancyPost;
