import React from "react";
import SideMenuItem from "./SideMenuItem";
import { MdOutlineSecurity } from "react-icons/md";
import { PiPlugsConnectedFill } from "react-icons/pi";

const MenuItems = [
	{
		icon: (
			<svg className="sidebar-menu-header-icon icon-profile">
				<use xlinkHref="#svg-profile"></use>
			</svg>
		),
		name: "My Profile",
		desc: "Change your avatar & cover, accept friends, read messages and more!",
		items: [
			"Profile Info",
		],
	},
	{
		icon: (
			// <svg className="sidebar-menu-header-icon icon-profile">
			// 	<use xlinkHref="#svg-profile"></use>
			// </svg>
			<PiPlugsConnectedFill className="sidebar-menu-header-icon icon-profile" />
		),
		name: "Connectivity",
		desc: "Change your avatar & cover, accept friends, read messages and more!",
		items: [
			"Affiliations",
			"Interests",
			"Notifications",
			"Connection Requests",
			"Social Links",
			"Invitation Links",
		],
	},
	{
		icon: (
			<svg className="sidebar-menu-header-icon icon-profile">
				<use xlinkHref="#svg-profile"></use>
			</svg>
		),
		name: "Experience",
		desc: "Share with people your experience and also your journey in Work, College and School, and share your Skills.",
		items: ["Work Experience", "Industries", "Education", "Skills"],
	},
	{
		icon: (
			<svg className="sidebar-menu-header-icon icon-settings">
				<use xlinkHref="#svg-settings"></use>
			</svg>
		),
		name: "Account",
		desc: "Change settings, configure notifications, and review your privacy",
		items: [
			// "Account Info",
			// "General Settings",
			"My Information",
			"Notifications Settings",
			"Privacy",
			"Change Password",
			"Two Factor Authentication",
			"Delete Account",
			"Blocked Users",
		],
	},
	{
		icon: (
			<svg className="sidebar-menu-header-icon icon-group">
				<use xlinkHref="#svg-group"></use>
			</svg>
		),
		name: "Communities",
		desc: "Create new groups, manage the ones you created or accept invites!",
		items: ["Manage Communtities", "Joined Communtities", "Invitations"],
	},
	{
		icon: (
			<svg className="sidebar-menu-header-icon icon-store">
				<use xlinkHref="#svg-store"></use>
			</svg>
		),
		name: "Earnings",
		desc: "Review your account, manage products check stats and much more!",
		items: ["My Earnings", "My Points", "Wallet & Credit", "My Products","Purchased"],
	},
];

const AccountSettingSidebar = () => {
	return (
		<div className="account-hub-sidebar">
			<div className="sidebar-box no-padding">
				<div className="sidebar-menu">
					{MenuItems.map((item, index) => (
						<SideMenuItem
							key={index}
							icon={item.icon}
							name={item.name}
							desc={item.desc}
							items={item.items}
						/>
					))}
				</div>

				<div className="sidebar-box-footer hidden md:block">
					<p className="button primary">Save Changes!</p>

					<p className="button white small-space">Discard All</p>
				</div>
			</div>
		</div>
	);
};

export default AccountSettingSidebar;
