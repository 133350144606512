import React from 'react'
import { Link } from 'react-router-dom'
import WrapInCenter from '../../WrapInCenter/WrapInCenter'

const FindTalent = () => {
    return (
        <section className="bg-[#ffede8] mt-[25px] py-20 md:p-0">
            <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
                <div className="row align-items-center justify-content-between md:h-[450px]">
                    <div className="col-md-6 col-lg-8 wow fadeInLeft">
                        <div className="cta-style3">
                            <h2 className="text-[30px] mb-[15px]">
                                Find the talent needed to get your{" "}
                                <br className="d-none d-xl-block" />
                                business growing.
                            </h2>
                            <p className="mb-[20px]">
                                Advertise your jobs to millions of monthly users and search 15.8
                                million CVs
                            </p>
                            <Link
                                href="/job-1"
                                className="button primary w-[150px] md:w-[200px] mr-3"
                            >
                                Find Work <i className="fal fa-arrow-right-long"></i>
                            </Link>
                            <Link
                                href="/freelancer-1"
                                className="button white w-[150px] md:w-[200px]"
                            >
                                Find Talent <i className="fal fa-arrow-right-long"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn">
                        <img
                            width={433}
                            height={550}
                            style={{ height: "fit-content" }}
                            className="absolute bottom-0 z-10 d-none d-md-block"
                            src="/img/careers/talent/person.png"
                            alt=" image "
                        />
                        <img
                            width={270}
                            height={431}
                            style={{ height: "fit-content" }}
                            className="absolute -top-[100px] z-0 d-none d-md-block"
                            src="/img/careers/talent/line.png"
                            alt=" image "
                        />
                    </div>
                </div>
            </WrapInCenter>
        </section>
    )
}

export default FindTalent