import { useState } from "react";

import wowEmoji from "../../../assests/reaction/wow.png";
import happyEmoji from "../../../assests/reaction/happy.png";
import sadEmoji from "../../../assests/reaction/sad.png";
import angryEmoji from "../../../assests/reaction/angry.png";
import likeEmoji from "../../../assests/reaction/like.png";
import loveEmoji from "../../../assests/reaction/love.png";

const reactions = [
  {
    id: 1,
    name: "wow",
    img: wowEmoji,
    count: 10,
  },
  {
    id: 2,
    name: "happy",
    img: happyEmoji,
    count: 32,
  },
  {
    id: 3,
    name: "sad",
    img: sadEmoji,
    count: 8,
  },
  {
    id: 4,
    name: "angry",
    img: angryEmoji,
    count: 9,
  },
  {
    id: 5,
    name: "like",
    img: likeEmoji,
    count: 7,
  },
  {
    id: 6,
    name: "love",
    img: loveEmoji,
    count: 35,
  },
];

const LatestReviewsReaction = ({ small }) => {
  const [activeReaction, setActiveReaction] = useState(null);

  return (
    <div className="flex items-center gap-3">
      {reactions.map((reaction) => (
        <div className="flex flex-col items-center justify-center gap-1">
          <button
            className={small ? "w-7 h-7" : "w-8 h-8"}
            onClick={() => setActiveReaction(reaction.id)}
          >
            <img
              src={reaction.img}
              alt="like"
              loading="lazy"
              className={`w-full h-full object-contain ${
                activeReaction && activeReaction !== reaction.id
                  ? "filter brightness-50 grayscale opacity-55"
                  : ""
              }`}
            />
          </button>

          <span
            className={`text-gray-500 font-semibold ${
              small ? "text-sm" : "text-base"
            }`}
            title="Like"
          >
            {activeReaction === reaction.id
              ? reaction.count + 1
              : reaction.count}
          </span>
        </div>
      ))}
    </div>
  );
};

export default LatestReviewsReaction;
