import { Modal } from "antd";
import FormInput from "../../../ui/formInput/FormInput";
import Select from "../../../ui/select/Select";
import { BsCameraFill } from "react-icons/bs";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid'; // Import uuid library
import { FaTimes } from "react-icons/fa";

const countries = [
  { value: "Afghanistan" },
  { value: "Albania" },
  { value: "Algeria" },
  { value: "Andorra" },
  { value: "Angola" },
];

const cities = [
  { value: "Kabul" },
  { value: "Tirana" },
  { value: "Algiers" },
  { value: "Andorra la Vella" },
  { value: "Luanda" },
];

const categories = [
  { value: "Category 1" },
  { value: "Category 2" },
  { value: "Category 3" },
];

const conditions = [
  { value: "Condition 1" },
  { value: "Condition 2" },
  { value: "Condition 3" },
];

const currencies = [
  { value: "USD" },
  { value: "EUR" },
  { value: "EGP" },
  { value: "SAR" },
  { value: "AED" },
];

const QuickPostSellProduct = ({isOpened , setIsOpened}) => {
  // const [isOpened, setIsOpened] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map(file => ({
      id: uuidv4(), // Generate unique ID
      url: URL.createObjectURL(file)
    }));
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleRemoveImage = (id) => {
    setSelectedImages(prevImages => prevImages.filter(image => image.id !== id));
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(selectedImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedImages(items);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const newImages = files.map(file => ({
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }));
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
    setIsDragOver(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () =>{
    setIsDragOver(false);
  }

  return (
    <>
      <button className="more-btn" onClick={() => setIsOpened(!isOpened)}>
        <span className="more-btn-img">
          <img
            src="https://develop.abncw.com/themes/sunshine/img/icons/pub-product.svg"
            alt="Sell product"
          />
        </span>
        <span className="more-btn-text">Publish Listing</span>
      </button>

      <Modal
        title={null}
        open={isOpened}
        onCancel={() => setIsOpened(!isOpened)}
        footer={null}
        width={800}
        zIndex={100000000000}
      >
        <div className="sell-product-container">
          <h2 className="sell-product-title">Add a Listing to Classifieds</h2>

          <form action="">
            <div className="sell-product-form">
              <label
                className={`sell-upload-product-img ${isDragOver ? "scale-125 !text-[#fd6729] !border-[#fd6729]" : ""}`}
                htmlFor="upload-product-img"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <input
                  type="file"
                  id="upload-product-img"
                  style={{ display: "none" }}
                  multiple
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <BsCameraFill />
                <p>Product image</p>
              </label>

              { selectedImages.length > 0 && (
                <div
                className="progress-stat-bar"
                style={{
                  width: "100%",
                  height: "4px",
                  position: "relative",
                  marginBottom: 22,
                  paddingRight: 35,
                }}
              >
                <div
                  className="bar"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#ddd",
                  }}
                ></div>
                <div
                  className="colored-bar"
                  style={{
                    width: `50%`,
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    background: "linear-gradient(90deg, #fd6729, orange)",
                  }}
                ></div>

                <p className="absolute top-[-5px] right-0 text-[14px]">50%</p>
                </div>
              ) }

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-1" direction="horizontal">
                  {(provided) => (
                    <div
                      className="image-thumbnails flex flex-wrap gap-[10px]"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {selectedImages.map((image, index) => (
                        <Draggable
                          key={image.id}
                          draggableId={image.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="thumbnail relative w-[100px] h-[100px]"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <img
                                className="w-full h-full object-cover"
                                src={image.url}
                                alt={`Thumbnail ${index}`}
                              />
                              <button
                                className="absolute top-[5px] right-[5px] bg-[#00000040] text-white w-[25px] h-[25px] flex items-center justify-center rounded-[50%]"
                                onClick={() => handleRemoveImage(image.id)}
                              >
                                <FaTimes />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <FormInput label="Product name" name="product-name" type="text" />

              <div className="sell-product-form-item">
                <Select defaultValue={"Country"} optionsArray={countries} />
                <Select defaultValue={"City"} optionsArray={cities} />
              </div>

              <div className="sell-product-form-item">
                <Select defaultValue={"Category"} optionsArray={categories} />
                <Select defaultValue={"Condition"} optionsArray={conditions} />
              </div>

              <div className="sell-product-form-item">
                <Select defaultValue={"Currency"} optionsArray={currencies} />
                <FormInput label="Price" name="price" type="number" />
              </div>

              <FormInput
                label="Description"
                name="desc"
                type="text"
                className="sell-product-textarea"
              />

              <div className="sell-product-form-item">
                <button className="button white">Cancel</button>
                <button className="button primary">Sell product</button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default QuickPostSellProduct;
