import React from 'react'
import { RiVerifiedBadgeFill } from 'react-icons/ri';

const PostWithoutPhoto = () => {
    return (
        <div class="widget-box no-padding">
          <div class="widget-box-status">
            <div class="widget-box-status-content">
              <div class="user-status">
                <a class="user-status-avatar" href="profile-timeline.html">
                  <div class="user-avatar small no-border">
                    <a className="user-status-avatar" href="profile-timeline.html">
                      <div className="after:content-[''] border-solid border-[1px] border-[#ff6728] after:w-[9px] after:h-[9px] after:absolute after:top-0 after:-left-2 after:rounded-full relative w-[45px] h-[45px] rounded-full">
                        <div className="relative w-full h-full rounded-full p-[1px] bg-white">
                          <img
                            src="/img/avatar/01.jpg"
                            alt="Profile"
                            className="w-full h-full rounded-full"
                          />
                          <div className="relative w-[16px] h-[16px] -top-[10px] -left-3">
                            <img
                              src="/img/download.png"
                              alt="Padge"
                              className="absolute w-full h-full"
                            />
                            <p className="absolute text-[10px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10 font-bold">
                              12
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </a>
                <p class="user-status-title medium flex items-center">
                  <a
                    class="bold"
                    href="profile-timeline.html"
                    className="flex color-black"
                  >
                    <p className="text-[#3e3f5e]">Marina Valentinex</p>
                    <RiVerifiedBadgeFill
                      // className="absolute -bottom-1 -right-2"
                      color={"#36e9f7"}
                      size={16}
                    />
                    {/* <div class="tag-list"> */}
                    <a class="tag-item mt-[-2px] ml-1 mr-1" href="newsfeed.html">
                      Pro Member
                    </a>
                    {/* </div> */}
                    <p className="text-[#3e3f5e]">replied to the topic</p>{" "}
                    <span class="bold">Welcome to</span>
                  </a>{" "}
                </p>
                <p class="user-status-text small">4 years ago</p>
              </div>
              <p class="widget-box-status-text">
                Hello Every one
              </p>
              <div class="content-actions">
                <div class="content-action">
                  <div class="meta-line">
                    <div class="meta-line-list reaction-item-list">
                      <div class="reaction-item">
                        <img
                          class="reaction-image reaction-item-dropdown-trigger"
                          src="img/reaction/wow.png"
                          alt="reaction-wow"
                        />
                      </div>
                      <div class="reaction-item">
                        <img
                          class="reaction-image reaction-item-dropdown-trigger"
                          src="img/reaction/like.png"
                          alt="reaction-like"
                        />
                      </div>
                      <div class="reaction-item">
                        <img
                          class="reaction-image reaction-item-dropdown-trigger"
                          src="img/reaction/like.png"
                          alt="reaction-like"
                        />
                      </div>
                    </div>
                    <p class="meta-line-text">3</p>
                  </div>
                </div>
                <div class="content-action">
                  <div class="meta-line">
                    <p class="meta-line-link">3 Comments</p>
                  </div>
                  <div class="meta-line">
                    <p class="meta-line-text">0 Shares</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default PostWithoutPhoto
