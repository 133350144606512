import React, { useState } from "react";

const UserBadges = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState("");

	return (
		<div className="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p className="widget-box-title">
				Badges <span className="highlighted">13</span>
			</p>

			<div className="widget-box-content">
				<div className="badge-list">
					<div className="badge-item text-tooltip-tft" data-title="Gold User">
						<img src="img/badge/gold-s.png" alt="badge-gold-s" />
					</div>

					<div className="badge-item text-tooltip-tft" data-title="Profile Age">
						<img src="img/badge/age-s.png" alt="badge-age-s" />
					</div>

					<div
						className="badge-item text-tooltip-tft"
						data-title="Caffeinatted"
					>
						<img src="img/badge/caffeinated-s.png" alt="badge-caffeinated-s" />
					</div>

					<div className="badge-item text-tooltip-tft" data-title="The Warrior">
						<img src="img/badge/warrior-s.png" alt="badge-warrior-s" />
					</div>

					<div
						className="badge-item text-tooltip-tft"
						data-title="Forum Traveller"
					>
						<img src="img/badge/traveller-s.png" alt="badge-traveller-s" />
					</div>

					<div
						className="badge-item text-tooltip-tft"
						data-title="Crazy Scientist"
					>
						<img src="img/badge/scientist-s.png" alt="badge-scientist-s" />
					</div>

					<div
						className="badge-item text-tooltip-tft"
						data-title="Night Creature"
					>
						<img src="img/badge/ncreature-s.png" alt="badge-ncreature-s" />
					</div>

					<div
						className="badge-item text-tooltip-tft"
						data-title="Mightier Than Sword"
					>
						<img src="img/badge/mightiers-s.png" alt="badge-mightiers-s" />
					</div>

					<div className="badge-item text-tooltip-tft" data-title="The Phantom">
						<img src="img/badge/phantom-s.png" alt="badge-phantom-s" />
					</div>

					<div
						className="badge-item text-tooltip-tft"
						data-title="The Collector"
					>
						<img src="img/badge/collector-s.png" alt="badge-collector-s" />
					</div>

					<div className="badge-item text-tooltip-tft" data-title="Bronze Cup">
						<img src="img/badge/bronzec-s.png" alt="badge-bronzec-s" />
					</div>

					<div className="badge-item text-tooltip-tft" data-title="Silver Cup">
						<img src="img/badge/silverc-s.png" alt="badge-silverc-s" />
					</div>

					<div className="badge-item text-tooltip-tft" data-title="Gold Cup">
						<img src="img/badge/goldc-s.png" alt="badge-goldc-s" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserBadges;
