import { Link } from "react-router-dom";
import service1 from "../../../../assests/crea8/gigs-01.jpg";
import { CiStar } from "react-icons/ci";
import { FaMeteor } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import { useState } from "react";
import { FaStar } from "react-icons/fa6";
import loc from "../../../../assests/crea8/map-pin-check.svg";
import { MdOutlineWatchLater } from "react-icons/md";
import "./FreelanceCard.css";
import UserProfileImage from "../../../user-profile-image/UserProfileImage";

const FreelanceCard = (index) => {
	const [heart, setheart] = useState(false);

	const handleheart = (index) => {
		setheart((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	return (
		<div className="crea8-gigs-grid">
			<div className="crea8-gigs-img">
				<div className="crea8-img-slider crea8-owl-carousel">
					<div className="crea8-slide-images">
						<Link>
							<img src={service1} alt="" />
						</Link>
					</div>
					<div className="crea8-card-overlay-badge">
						<Link>
							<span className="badge bg-warning text-white bg-[#ffc107] font-bold text-[14px]">
								<CiStar /> Featured
							</span>
						</Link>
						<Link>
							<span className="badge bg-danger text-white bg-[#dc3545] font-bold text-[14px]">
								<FaMeteor />
								Hot
							</span>
						</Link>
					</div>
					<div className="crea8-fav-selection">
						<Link
							onClick={() => handleheart(index)}
							style={{
								cursor: "pointer",
								background: `${heart[index] ? "rgba(255, 105, 0, 1)" : ""}`,
								color: `${heart[index] ? "#fff" : ""}`,
							}}
						>
							<CiHeart />
						</Link>
					</div>
					<div className="crea8-user-thumb">
						<UserProfileImage />
					</div>
				</div>
			</div>
			<div className="crea8-gigs-content">
				<div className="crea8-gigs-info">
					<Link class="badge bg-primary-light duration-200">
						Programming & Tech
					</Link>
					<small>+1</small>
					<p>
						<img src={loc} alt="" /> NewYork
					</p>
				</div>
				<div className="crea8-gigs-title">
					<h3>
						<Link className="duration-200">
							Embedded Android & AOSP customizations
						</Link>
					</h3>
				</div>
				<div className="crea8-star-rate">
					<span className="!flex !items-center !justify-start">
						<FaStar /> <p>5.0 (28 Reviews)</p>
					</span>
				</div>
				<div className="crea8-gigs-card-footer">
					<div className="crea8-gigs-share">
						<Link>
							<IoShareSocialOutline />
						</Link>
						<span className="badge font-bold">
							<MdOutlineWatchLater /> Delivery in 1 day
						</span>
					</div>
					<h5>$780</h5>
				</div>
			</div>
		</div>
	);
};

export default FreelanceCard;
