import "./CareerSearch.css"
import React, { useState } from 'react'
import WrapInCenter from '../../../components/WrapInCenter/WrapInCenter'
import LatestJobCard from '../../../components/Careers/LatestJobs/LatestJobCard';
import FormInputwithIcon from '../../../components/ui/formInputWithSearchIcon/FormInputwithIcon';
import Select from '../../../components/ui/select/Select';
import { Slider } from 'antd';
import { IoGridSharp, IoLocationSharp } from 'react-icons/io5';
import { FaStar, FaThList } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { PiBagSimpleFill } from "react-icons/pi";
import UserProfileImage from "../../../components/user-profile-image/UserProfileImage"
import { IoIosPricetag } from "react-icons/io";
import AdsBanner from "../../../components/AdsBanner/AdsBanner";
import Footer from "../../../components/Careers/Footer/Footer";

const Categories = [
    { id: 1, value: "Category 1" },
    { id: 2, value: "Category 2" },
    { id: 3, value: "Category 3" },
    { id: 4, value: "Category 4" },
    { id: 5, value: "Category 5" },
];

const Countries = [
    { id: 1, value: "Egypt" },
    { id: 2, value: "France" },
    { id: 3, value: "Germany" },
    { id: 5, value: "Spain" },
];

const Cities = {
    Egypt: [
        { id: 1, value: "Cairo", count: 3447 },
        { id: 2, value: "Alexandria", count: 2477 },
        { id: 3, value: "Tanta", count: 1051 },
        { id: 4, value: "Giza", count: 1023 },
    ],
    France: [
        { id: 1, value: "Paris", count: 3447 },
        { id: 2, value: "Lyon", count: 2477 },
        { id: 3, value: "Marseille", count: 1051 },
        { id: 4, value: "Nice", count: 1023 },
    ],
    Germany: [
        { id: 1, value: "Berlin", count: 3447 },
        { id: 2, value: "Hamburg", count: 2477 },
        { id: 3, value: "Munich", count: 1051 },
        { id: 4, value: "Frankfurt", count: 1023 },
    ],
    Spain: [
        { id: 1, value: "Madrid", count: 3447 },
        { id: 2, value: "Barcelona", count: 2477 },
        { id: 3, value: "Seville", count: 1051 },
        { id: 4, value: "Valencia", count: 1023 },
    ],
};

const bannersImages = [
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
];

const marks = {
    20: {
        label: <>20 KM</>,
    },
    100000: {
        label: <>100,000 KM</>,
    },
};

const Universities = [
    { id: 2, value: "Alexandria University" },
    { id: 3, value: "Cairo University" },
    { id: 4, value: "Helwan University" },
    { id: 5, value: "Tanta University" },
    { id: 6, value: "Ain Shams University" },
    { id: 7, value: "Kafrelsheikh University" },
];


const jobTypes = [
    { value: 'All', name: 'all', count: 905 },
    { value: 'Full-Time', name: 'fulltime', count: 755 },
    { value: 'Part-Time', name: 'parttime', count: 506 },
    { value: 'Internship', name: 'internship', count: 634 },
    { value: 'Volunteer', name: 'volunteer', count: 483 },
    { value: 'Contract', name: 'contract', count: 283 },
];

const Workplaces = [
    { id: "all", value: "All", count: 905 },
    { id: "onSite", value: "On-site", count: 207 },
    { id: "hyprid", value: "Hypird", count: 30 },
    { id: "remote", value: "Remote", count: 10 },
]


const CareerSearch = () => {
    const [activeView, setActiveView] = useState({
        "list-item": false,
        "grid-item": true,
    });
    const [sort, setSort] = useState(null);
    const [country, setCountry] = useState(null);
    const [city, setCity] = useState(null);
    const [category, setCategory] = useState(null);
    const [university, setUniversity] = useState(null);
    const [workplace, setWorkplace] = useState(null);
    const [switchButtons, setSwitchButtons] = useState({
        all: true,
        fullTime: false,
        partTime: false,
        internship: false,
        volunteer: false,
        contract: false,
    })
    const [switchWorkplaceButtons, setSwitchWorkplaceButtons] = useState({
        all: true,
        onSite: false,
        hypird: false,
        remote: false,
    })

    const handleSwitchButton = (jobType) => {
        setSwitchButtons(prev => {
            // Create a new object with all keys set to false
            const updatedSwitchButtons = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});

            // Toggle the selected jobType
            updatedSwitchButtons[jobType] = !prev[jobType];

            return updatedSwitchButtons;
        });
    };

    const handleSwitchWorkplaceButton = (jobType) => {
        setSwitchWorkplaceButtons(prev => {
            // Create a new object with all keys set to false
            const updatedSwitchButtons = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});

            // Toggle the selected jobType
            updatedSwitchButtons[jobType] = !prev[jobType];

            return updatedSwitchButtons;
        });
    };

    const handleViewClick = (id) => {
        setActiveView(prev => {
            // Create a new object with all keys set to false
            const updatedActiveView = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});
            // Toggle the selected view
            updatedActiveView[id] = !prev[id];

            return updatedActiveView;
        })
    }

    function onChange(value) {
        console.log("onChange: ", value);
    }


    return (
        <div className='career-search-page'>
            <AdsBanner bannersImages={bannersImages} />
            <WrapInCenter noPaddingBottom={false} noPaddingTop={false}>
                <div class="section-header-info">
                    <p class="section-pretitle">JOB SEARCH</p>
                    <h2 class="section-title">Find Jobs in Egypt</h2>
                </div>
                <div className="grid lg:!grid-cols-12">
                    <aside className="search-sidebar lg:col-span-3 bg-white p-3 md:p-4 rounded-[12px] shadow-[0px_0px_40px_0px_#5e5c9a0f]">
                        {/* Advance Information widget */}
                        <div className="">
                            <h4 className="text-[1.2rem] mb-[15px]">Location Distance</h4>
                            <div className="location-distance-slider">
                                <Slider
                                    step={20}
                                    defaultValue={20}
                                    marks={marks}
                                    min={20}
                                    max={100000}
                                    onChange={onChange}
                                />
                            </div>
                            <hr className='my-[35px] text-[#8d8d8d]' />

                            <h4 className="text-[1.2rem] mb-[15px]">Career Locations</h4>
                            <ul>
                                <li>
                                    <Select
                                        optionsArray={Countries}
                                        defaultValue={"All"}
                                        selectName={country}
                                        setValue={setCountry}
                                    />
                                </li>
                                {country && (
                                    <>
                                        <li className='mt-3.5'>
                                            <h4 className='text-[1.2rem]'>{country}</h4>
                                            <div className="ml-[10px]">
                                                <Select
                                                    optionsArray={Cities[country]}
                                                    defaultValue={"All"}
                                                    selectName={city}
                                                    setValue={setCity}
                                                />
                                            </div>
                                        </li>
                                        <li className='mt-3.5'>
                                            <h4 className="text-[1.2rem]">University</h4>
                                            <ul className="ml-[10px]">
                                                <li className="flex items-center mb-[15px]">
                                                    <Select
                                                        optionsArray={Universities}
                                                        defaultValue={"All"}
                                                        selectName={university}
                                                        setValue={setUniversity}
                                                    />
                                                </li>
                                            </ul>
                                        </li>
                                    </>
                                )}
                            </ul>
                            <hr className='my-[35px] text-[#8d8d8d]' />

                            <h4 className="text-[1.2rem] mb-[15px]">Career Type</h4>
                            <ul>
                                {jobTypes.map(jobType => (
                                    <li className="flex items-center justify-between mb-[15px]" key={jobType.name}>
                                        <div className='flex items-center space-x-2'>
                                            <div className={`form-switch ${switchButtons[jobType.name] ? "active" : ""}`}
                                                onClick={() => {
                                                    handleSwitchButton(jobType.name);
                                                }}
                                            >
                                                <div className="form-switch-button"></div>
                                            </div>
                                            <p className='font-bold'> {jobType.value} </p>
                                        </div>
                                        <p className="categorey-no" href="#">
                                            ({jobType.count})
                                        </p>
                                    </li>
                                ))}

                            </ul>
                            <hr className='my-[35px] text-[#8d8d8d]' />

                            <h4 className="text-[1.2rem] mb-[15px]">Workplace</h4>
                            <ul>
                                {Workplaces.map(workplace => (
                                    <li className="flex items-center justify-between mb-[15px]" key={workplace.id}>
                                        <div className='flex items-center space-x-2'>
                                            <div className={`form-switch ${switchWorkplaceButtons[workplace.id] ? "active" : ""}`}
                                                onClick={() => {
                                                    handleSwitchWorkplaceButton(workplace.id);
                                                }}
                                            >
                                                <div className="form-switch-button"></div>
                                            </div>
                                            <p className='font-bold'> {workplace.value} </p>
                                        </div>
                                        <p className="categorey-no" href="#">
                                            ({workplace.count})
                                        </p>
                                    </li>
                                ))}
                            </ul>
                            <hr className='my-[35px] text-[#8d8d8d]' />

                            <h4 className="text-[1.2rem] mb-[15px]">Career Categories</h4>
                            <ul className="grid">
                                <li>
                                    <Select
                                        optionsArray={Categories}
                                        defaultValue={"Categories"}
                                        selectName={category}
                                        setValue={setCategory}
                                    />
                                </li>
                            </ul>

                        </div>
                    </aside>

                    <div className="lg:col-span-9">
                        <nav className="bg-white rounded-[12px] mb-[15px] p-[20px] shadow-[0px_0px_40px_0px_#5e5c9a0f]">
                            <ul class="flex items-center flex-wrap" role="tablist">
                                <li className="search-bar pt-[15px] w-full lg:w-1/2">
                                    <FormInputwithIcon
                                        label={"Search for..."}
                                        id={"product-search"}
                                    />
                                </li>
                                {/* <li
                                    class="sort-by relative w-full lg:w-[200px]"
                                >
                                    <Select
                                        optionsArray={[]}
                                        defaultValue={"Sort By"}
                                        selectName={sort} setValue={setSort}
                                    />
                                </li> */}
                                <li class="flex-1 view-item flex justify-end">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleViewClick("list-item")}
                                    >
                                        <FaThList
                                            className={`${activeView["list-item"] ? "active" : ""}`}
                                            id="list-item"
                                        />
                                    </span>
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleViewClick("grid-item")}
                                    >
                                        <IoGridSharp
                                            className={`${activeView["grid-item"] ? "active" : ""}`}
                                            id="grid-item"
                                        />
                                    </span>
                                </li>
                            </ul>
                        </nav>

                        {activeView["grid-item"] ? <GridView /> : <ListView />}

                    </div>
                </div>
            </WrapInCenter>

            <Footer />
        </div>
    )
}

export default CareerSearch




const GridView = () => {
    return (
        <div className="grid md:!grid-cols-2">
            <LatestJobCard />
            <LatestJobCard />
            <LatestJobCard />
            <LatestJobCard />
            <LatestJobCard />
            <LatestJobCard />
            <LatestJobCard />
            <LatestJobCard />
        </div>
    )
}


const ListView = () => {
    return (
        <div className="grid md:!grid-cols-1">
            <div className="job-card list">
                <div className="flex items-center justify-center flex-wrap gap-[20px] py-[25px] px-[40px] relative rounded-t-lg overflow-hidden">
                    <div className="bg-[#ddd6d6] text-[#646464] w-[100px] absolute -top-[5px] -left-[33px] pt-[13px] pb-[5px] -rotate-45 text-center cursor-pointer">
                        <FaStar className="inline" size={15} />
                    </div>
                    <div className="border-[1px] border-solid border-[#fd6728] rounded-full p-1.5">
                        <Link to={"/businesses/profile/digitechsolutions"}>
                            <img className="w-[50px] h-[50px] rounded-full" src="/img/careers/logo/client-1.webp" alt="" />
                        </Link>
                    </div>
                    <div className="md:flex-1">
                        <h3 className="mb-2.5">
                            <Link to="/career-details" className='text-[#fd6728] hover:text-[#00c7d9]'>
                                Website Designer Required For Directory Theme
                            </Link>
                        </h3>
                        <ul className="flex items-center gap-[15px] flex-wrap">
                            <li>
                                <h3>
                                    <Link to={"/businesses/profile/digitechsolutions"}> Brandmarks </Link>
                                </h3>
                            </li>
                            <li>
                                <Link to="/career-search" class="list-text text-[16px] font-semibold text-[#adafca] block">web & design</Link>
                            </li>
                            <li className="text-[16px] font-medium flex items-center gap-[2px] !leading-none py-[5px]">
                                <IoLocationSharp size={14} color="#3e3f5e" />
                                <Link to="/career-search">luxembourg</Link>
                                <Link to="/career-search">, Grevenmacher</Link>
                            </li>
                            <li className="text-[16px] font-medium flex items-center gap-[3px] !leading-none py-[5px]">
                                <PiBagSimpleFill size={14} fill="#2196f3" />
                                <Link to="/career-search">Part Time</Link>
                            </li>
                            <li>
                                <Link to="/job-details" class="job-apply">Apply</Link>
                            </li>
                        </ul>
                    </div>
                    <Link to="/career-search" className="bg-[#e3fbe4] text-[#65c18c] font-bold rounded-md py-1.5 px-[10px] absolute top-2 right-2">
                        Full Time
                    </Link>
                </div>
                <div className="bg-[#f8f8fb] py-2.5 pl-[40px] pr-[15px] rounded-b-lg flex items-center justify-between flex-wrap gap-y-1.5 relative">
                    <ul className="flex space-x-8">
                        <li>
                            posted: <span className="font-bold">20 W</span>
                        </li>
                        <li className="flex items-center gap-[5px]">
                            <IoIosPricetag color="brown" /> 3
                        </li>
                    </ul>
                    <div className="md:pr-[60px]">
                        <Link to={"/user-profile-page"} className="font-bold">Marine Valentine</Link>
                        <Link to={"/university"} className="font-medium ml-4">Nile University</Link>
                    </div>
                    <div className="absolute -top-7 right-3">
                        <Link to={"/user-profile-page"}>
                            <UserProfileImage />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
