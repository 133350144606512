import React from "react";

const BusinessesSearchItems = () => {
	return (
		<>
			<p>
				Search Title or Company: <span>Brandmarks</span>
			</p>
			<p>
				Business Country: <span>Egypt</span>
			</p>
			<p>
				Business City: <span>Cairo</span>
			</p>
			<p>
				Business Industry: <span>Brandmarks</span>
			</p>
			<p>
				Sub-Category: <span>Design</span>
			</p>
			<p>
				University: <span>The American University of Cairo</span>
			</p>
		</>
	);
};

export default BusinessesSearchItems;
