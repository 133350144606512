import React from "react";
import Select from "../../../ui/select/Select";
import { setIsAddingAffiliations } from "../../../../app/feature/userDashboard";
import { useDispatch } from "react-redux";

const ExpatCommunityForm = () => {
	const dispatch = useDispatch();
	return (
		<div className="mt-4">
			<div className="flex items-center justify-between !gap-8 max-[680px]:!flex-col max-[680px]:!gap-0">
				<Select defaultValue={"Nationality"} optionsArray={[]} />
				<Select defaultValue={"Country"} optionsArray={[]} />
			</div>
			<div className="flex items-center justify-between gap-8">
				<Select defaultValue={"City"} optionsArray={[]} />
			</div>
			<div className="upload-box shadow-sm my-4">
				<svg className="upload-box-icon icon-photos">
					<use xlinkHref="#svg-photos"></use>
				</svg>

				<p className="upload-box-title">Upload Your Passport</p>
			</div>
			<button
				className="secondary medium button"
				onClick={(e) => {
					e.preventDefault();
					dispatch(setIsAddingAffiliations({ value: false }));
				}}
			>
				Save
			</button>
		</div>
	);
};

export default ExpatCommunityForm;
