import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	setIsLargeHidden,
	setIsMiniMenuHidden,
	setIsFloatyHidden,
} from "../../../app/feature/outletSlice";

const FloatyBar = () => {
	const dispatch = useDispatch();
	const isFloatyHidden = useSelector((state) => state.outlet.isFloatyHidden);
	const breakPoint = 680;
	const [width, setWidth] = useState(window.innerWidth);
	const [prevScrollPosition, setPrevScrollPosition] = useState(0);

	const handleScroll = useCallback(() => {
		const scrollPosition = window.scrollY;
		// console.log(prevScrollPosition, scrollPosition);
		dispatch(
			setIsMiniMenuHidden({ value: scrollPosition > prevScrollPosition })
		);

		if (scrollPosition > prevScrollPosition) {
			dispatch(setIsFloatyHidden({ value: true }));
		} else {
			dispatch(setIsFloatyHidden({ value: false }));
		}

		setPrevScrollPosition(scrollPosition);
	}, [dispatch, prevScrollPosition]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	useEffect(() => {
		const handleResizeWindow = () => {
			setWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResizeWindow);
		return () => {
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	return (
		<aside
			className={`floaty-bar ${
				isFloatyHidden ? "floaty-display-none" : "floaty-display-show"
			}`}
		>
			<div className="bar-actions">
				<div className="progress-stat">
					<div className="bar-progress-wrap">
						<p className="bar-progress-info">
							Next:{" "}
							<span className="bar-progress-text">
								38<span className="bar-progress-unit">exp</span>
							</span>
						</p>
					</div>

					<div
						id="logged-user-level"
						className="progress-stat-bar"
						style={{ width: "110px", height: "4px", position: "relative" }}
					>
						<canvas
							style={{
								position: "absolute",
								top: "0px",
								left: "0px",
								width: "70px",
								height: "4px",
								backgroundColor: "#23d2e2",
								zIndex: "1",
							}}
						></canvas>
						<canvas
							style={{
								position: "absolute",
								top: "0px",
								left: "0px",
								width: "110px",
								height: "4px",
								backgroundColor: "#4e4ac8",
							}}
						></canvas>
					</div>
				</div>
			</div>

			<div className="bar-actions">
				<div className="action-list dark">

					<Link className="action-list-item" to={"/affiliations"}>
						<svg className="action-list-item-icon icon-group">
							<use xlinkHref="#svg-group"></use>
						</svg>
					</Link>

					<Link className="action-list-item" to={"/businesses"}>
						<svg
							className="action-list-item-icon icon-directory"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path
								// fill="#f79f58"
								d="M5,21 L5,1 L7,1 L7,2 L21.1247446,2 L18.1259893,7.99991055 L21.126034,14 L7,14 L7,21 L8,21 L8,23 L4,23 L4,21 L5,21 Z M7,12 L17.889966,12 L15.8900107,8.00008945 L17.8892554,4 L7,4 L7,12 Z"
							></path>
						</svg>
					</Link>

					<Link className="action-list-item" to={"/reviews"}>
						<svg className="action-list-item-icon icon-quests">
							<use xlinkHref="#svg-quests"></use>
						</svg>
					</Link>

					<Link className="action-list-item" to={"https://marketplace.flokky.app"}>
						<svg
							className="action-list-item-icon icon-directory"
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 30 30"
						>
							<path
								fill={"white"}
								d="M23 14c-.7 0-1.4-.2-2-.6-.6.3-1.3.6-2 .6s-1.4-.2-2-.6c-.6.3-1.3.6-2 .6s-1.4-.2-2-.6c-.6.3-1.3.6-2 .6s-1.4-.2-2-.6c-.6.4-1.3.6-2 .6s-1.4-.2-2-.5c-.6.3-1.3.5-2 .5v11c0 1.1.9 2 2 2h12V17h6v10h2c1.1 0 2-.9 2-2V14c-.7 0-1.4-.2-2-.5-.6.3-1.3.5-2 .5zm-10 9H7v-6h6v6zM26 6V5c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v1l-3 4c0 1.1.9 2 2 2s2-.9 2-2l2-4h3l-1 4c0 1.1.9 2 2 2s2-.9 2-2V6h4v4c0 1.1.9 2 2 2s2-.9 2-2l-1-4h3l2 4c0 1.1.9 2 2 2s2-.9 2-2l-3-4z"
							></path>
						</svg>
					</Link>

					
				</div>

				<a className="action-item-wrap" href="/user-dashboard">
					<div className="action-item dark">
						<svg className="action-item-icon icon-settings">
							<use xlinkHref="#svg-settings"></use>
						</svg>
					</div>
				</a>
			</div>
		</aside>
	);
};

export default FloatyBar;
