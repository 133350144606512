import { FaUserEdit } from "react-icons/fa";
import FormInputwithIcon from "../../components/ui/formInputWithSearchIcon/FormInputwithIcon";
import Select from "../../components/ui/select/Select";
import { Link } from "react-router-dom";
import logo from '../../assests/avatar/logo.svg';
import cover from '../../assests/avatar/cover.jpg'
const SectionffiationsAdmin = () => {
  const categories = [
    { id: 1, value: "Category 1" },
    { id: 2, value: "Category 2" },
    { id: 3, value: "Category 3" },
    { id: 4, value: "Category 4" },
  ];
  const Subcategory = [
    { id: 1, value: "Subcategory 1" },
    { id: 2, value: "Subcategory 2" },
    { id: 3, value: "Subcategory 3" },
    { id: 4, value: "Subcategory 4" },
  ];
  const Affiliations = [
    { id: 1, value: "Affiliations 1" },
    { id: 2, value: "Affiliations 2" },
    { id: 3, value: "Affiliations 3" },
    { id: 4, value: "Affiliations 4" },
  ];
  return (
    <>
      <div className="section-header mt-0">
        <div className="section-header-info">
          <p className="text-[#3e3f5e] text-xl font-extrabold">
            Manage Communities
          </p>
          <h2 className="section-title">Admin</h2>
        </div>
        <div className="create-AddAffiliations">
          <h2 className="section-title-sec-affiliations"><Link className="hover:text-[#ff6728] text-[#23d2e2]" to={'/new-group'}>
          + Create Group</Link></h2>
        </div>
      </div>
      <div className="sec-one-affiliations-header">
        <div className="section-Title-affiliations">
          <div className="result-view-affiations">
            <div className=" w-[100%] input-search-affiations">
            <FormInputwithIcon
              type={"text"}
              label={"Search"}
              name={"Search"}
              id={"Search"}
            />
            </div>
            <Select defaultValue={"Affiliations"} optionsArray={Affiliations} />
            <Select defaultValue={"Category"} optionsArray={categories} />
            <Select defaultValue={"Subcategory"} optionsArray={Subcategory}/>
            <div className="results-view">
              <div className={`number-of-results`}>
                <p  className={`numberActive`}>Reset</p>
                <p  className={`numberActive`}>32</p>
                <p>64</p>
                <p>128</p>
              </div>
              <div
                class="view-action text-tooltip-tft-medium active grid-view-color grid-view-active-color"
                data-title="Small Grid"
              >
                <svg class="view-action-icon icon-small-grid-view">
                  <use xlinkHref="#svg-small-grid-view"></use>
                </svg>
              </div>

              <div
                class="view-action text-tooltip-tft-medium grid-view-color"
                data-title="List"
              >
                <svg class="view-action-icon icon-list-grid-view">
                  <use xlinkHref="#svg-list-grid-view"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-4-4-4 centered">
        <div className="flex flex-col">
          <div className="user-preview">
            <figure className="user-preview-cover liquid">
              <img
                src={cover}
                alt="cover-29"
                className="rounded-t-[12px]"
              />
            </figure>
            <div className="user-preview-info">
              <div className="tag-sticker">
                <svg className="tag-sticker-icon icon-public">
                  <use xlinkHref="#svg-public"></use>
                </svg>
              </div>
              <div className="user-short-description">
                <a
                  className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                  href=""
                >
                  <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                    <img
                      src={logo}
                      alt="Community"
                      className="rounded-full"
                    />
                  </div>
                </a>
                <p className="user-short-description-title">
                  <a href="group-timeline.html">Cosplayers of the World</a>
                </p>
                <p className="user-short-description-text">
                  All cosplayers welcome!
                </p>
              </div>
              <div className="user-stats">
                <div className="user-stat">
                  <p className="user-stat-title">139</p>
                  <p className="user-stat-text">members</p>
                </div>
                <div className="user-stat">
                  <p className="user-stat-title">105</p>
                  <p className="user-stat-text">posts</p>
                </div>
                <div className="user-stat">
                  <p className="user-stat-title">7.3k</p>
                  <p className="user-stat-text">visits</p>
                </div>
              </div>
              <div className="communities-card-flokkers-list mt-4">
                <div className="fldkker">
                  <img
                    src="/img/avatar/01.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/02.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/03.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/04.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/05.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/06.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker plus">
                  <p>+19K</p>
                </div>
              </div>
              <div className="user-preview-actions">
                <p className="button secondary full !flex items-center justify-center gap-2">
                  <div className="button-icon icon-join-group">
                    <FaUserEdit className="text-xl" />
                  </div>
                  Edit
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="user-preview">
            <figure className="user-preview-cover liquid">
              <img
                src={cover}
                alt="cover-29"
                className="rounded-t-[12px]"
              />
            </figure>
            <div className="user-preview-info">
              <div className="tag-sticker">
                <svg className="tag-sticker-icon icon-public">
                  <use xlinkHref="#svg-public"></use>
                </svg>
              </div>
              <div className="user-short-description">
                <a
                  className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                  href=""
                >
                  <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                    <img
                      src={logo}
                      alt="Community"
                      className="rounded-full"
                    />
                  </div>
                </a>
                <p className="user-short-description-title">
                  <a href="group-timeline.html">Cosplayers of the World</a>
                </p>
                <p className="user-short-description-text">
                  All cosplayers welcome!
                </p>
              </div>
              <div className="user-stats">
                <div className="user-stat">
                  <p className="user-stat-title">139</p>
                  <p className="user-stat-text">members</p>
                </div>
                <div className="user-stat">
                  <p className="user-stat-title">105</p>
                  <p className="user-stat-text">posts</p>
                </div>
                <div className="user-stat">
                  <p className="user-stat-title">7.3k</p>
                  <p className="user-stat-text">visits</p>
                </div>
              </div>
              <div className="communities-card-flokkers-list mt-4">
                <div className="fldkker">
                  <img
                    src="/img/avatar/01.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/02.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/03.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/04.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/05.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/06.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker plus">
                  <p>+19K</p>
                </div>
              </div>
              <div className="user-preview-actions">
                <p className="button secondary full !flex items-center justify-center gap-2">
                  <div className="button-icon icon-join-group">
                    <FaUserEdit className="text-xl" />
                  </div>
                  Edit
                </p>
              </div>
            </div>
          </div>
        </div>        <div className="flex flex-col">
          <div className="user-preview">
            <figure className="user-preview-cover liquid">
              <img
                src={cover}
                alt="cover-29"
                className="rounded-t-[12px]"
              />
            </figure>
            <div className="user-preview-info">
              <div className="tag-sticker">
                <svg className="tag-sticker-icon icon-public">
                  <use xlinkHref="#svg-public"></use>
                </svg>
              </div>
              <div className="user-short-description">
                <a
                  className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                  href=""
                >
                  <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                    <img
                      src={logo}
                      alt="Community"
                      className="rounded-full"
                    />
                  </div>
                </a>
                <p className="user-short-description-title">
                  <a href="group-timeline.html">Cosplayers of the World</a>
                </p>
                <p className="user-short-description-text">
                  All cosplayers welcome!
                </p>
              </div>
              <div className="user-stats">
                <div className="user-stat">
                  <p className="user-stat-title">139</p>
                  <p className="user-stat-text">members</p>
                </div>
                <div className="user-stat">
                  <p className="user-stat-title">105</p>
                  <p className="user-stat-text">posts</p>
                </div>
                <div className="user-stat">
                  <p className="user-stat-title">7.3k</p>
                  <p className="user-stat-text">visits</p>
                </div>
              </div>
              <div className="communities-card-flokkers-list mt-4">
                <div className="fldkker">
                  <img
                    src="/img/avatar/01.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/02.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/03.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/04.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/05.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker">
                  <img
                    src="/img/avatar/06.jpg"
                    alt="fldkker-avatar"
                    loading="lazy"
                  />
                </div>
                <div className="fldkker plus">
                  <p>+19K</p>
                </div>
              </div>
              <div className="user-preview-actions">
                <p className="button secondary full !flex items-center justify-center gap-2">
                  <div className="button-icon icon-join-group">
                    <FaUserEdit className="text-xl" />
                  </div>
                  Edit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionffiationsAdmin;