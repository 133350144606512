import logo from '../../../assests/avatar/logo.svg';
import cover from '../../../assests/avatar/cover.jpg'
const PhotoCoverGroup = () => {
  return (
    <div className="grid grid-4-4-4 centered mb-[16px]">
      <div className="user-preview small fixed-height">
        <figure className="user-preview-cover liquid">
          <img
            src={cover}
            alt="cover-01"
            className="rounded-t-2xl object-cover"
          />
        </figure>

        <div className="user-preview-info">
          <div className="user-short-description small">
            <div className="user-short-description-avatar user-avatar !relative !top-[-58px] !left-[155px] !scale-[1.5]">
              <div className="sm:ml-[30px] ml-[10px]">
                <div className="after:content-[''] border-solid border-[1px] border-[#ff6728]  after:w-[9px] after:h-[9px] after:absolute after:top-[19px] after:left-[-12px] after:rounded-full relative w-[45px] h-[45px] rounded-full">
                  <div className="relative w-full h-full rounded-full p-[1px] bg-white">
                    <img
                      src={logo}
                      alt="Profile"
                      className="w-full h-full rounded-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="upload-box">
        <svg className="upload-box-icon icon-members">
          <use xlinkHref="#svg-members"></use>
        </svg>

        <p className="upload-box-title">Change Logo</p>

        <p className="upload-box-text">110x110px size minimum</p>
      </div>

      <div className="upload-box">
        <svg className="upload-box-icon icon-photos">
          <use xlinkHref="#svg-photos"></use>
        </svg>

        <p className="upload-box-title">Change Cover</p>

        <p className="upload-box-text">1184x300px size minimum</p>
      </div>
    </div>
  );
};

export default PhotoCoverGroup;
