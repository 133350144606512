import React from 'react'
import { Link } from 'react-router-dom'
const FlokkersBusinessCard = ({
    cover = "/img/cover/04.jpg",
    avatar = "/img/businesses/01.jpeg",
    title = "DigiTech Solutions",
    desc = "Information Technology",
    cardId = "digitechsolutions",
    stars = 4.5,
    icon = (
        <svg className="icon-public">
            <use xlinkHref="#svg-public"></use>
        </svg>
    ),
    vacancies = 7,
}) => {
    return (
        <div className="businesses-card w-full">
            <div className="businesses-card-header">
                <img src={cover} alt="cover-04" loading="lazy" />
            </div>
            <div className="businesses-card-avatar">
                <img src={avatar} alt="user-avatar" loading="lazy" />
            </div>
            <div className="businesses-card-info">
                <h2 className="businesses-card-title">{title}</h2>

                <p className="businesses-card-desc">
                    <span className="businesses-card-desc-icon">{icon}</span>
                    {desc}
                </p>
            </div>
            <div className="businesses-card-status">
                <div className="status-item" style={{ borderRight: "1px solid #eee" }}>
                    <p className="status-item-result">{vacancies}</p>

                    <p className="status-item-label">Vacancies</p>
                </div>

                <div className="status-item">
                    <p className="status-item-result">{stars}</p>

                    <p className="status-item-stars">
                        {new Array(5).fill(0).map((_, i) => (
                            <svg
                                key={i}
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill={i < parseInt(stars) ? "gold" : "#adafca"}
                                stroke={i < parseInt(stars) ? "gold" : "#adafca"}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-star"
                            >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                            </svg>
                        ))}
                    </p>
                </div>

            </div>
            <div className="businesses-card-flokkers">
                <h2 className="businesses-card-flokkers-title">flokkers</h2>

                <div className="businesses-card-flokkers-list">
                    <div className="fldkker">
                        <img src="/img/avatar/01.jpg" alt="fldkker-avatar" loading="lazy" />
                    </div>

                    <div className="fldkker">
                        <img src="/img/avatar/02.jpg" alt="fldkker-avatar" loading="lazy" />
                    </div>

                    <div className="fldkker">
                        <img src="/img/avatar/03.jpg" alt="fldkker-avatar" loading="lazy" />
                    </div>

                    <div className="fldkker">
                        <img src="/img/avatar/04.jpg" alt="fldkker-avatar" loading="lazy" />
                    </div>

                    <div className="fldkker">
                        <img src="/img/avatar/05.jpg" alt="fldkker-avatar" loading="lazy" />
                    </div>

                    <div className="fldkker">
                        <img src="/img/avatar/06.jpg" alt="fldkker-avatar" loading="lazy" />
                    </div>

                    <div className="fldkker plus">
                        <p>+19K</p>
                    </div>
                </div>
            </div>
            <div className="businesses-card-actions">
                <Link
                    to={`/businesses/profile/${cardId}`}
                    className="button medium white d-flex justify-content-center"
                >
                    View Jobs
                </Link>
            </div>
        </div>
    )
}

export default FlokkersBusinessCard