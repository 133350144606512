import React from 'react'
import Select from '../../../ui/select/Select'
import FormInput from '../../../ui/formInput/FormInput'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
import FormNumber from '../../../ui/formNumber/formNumber';

const MyEarnings = () => {
    const { showContent } = useSelector((state) => state.userDashboard)
    const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
                <svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
                    <use xlinkHref="#svg-back-arrow"></use>
                </svg>
            </span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Earnings</p>
                    <h2 className="section-title">My Earnings</h2>
                </div>
            </div>
            <div className="widget-box">
                {/* <p className="widget-box-title">My Earnings</p> */}
                <div className="widget-box-content">
                    <form className="form">
                        <div className="flex items-center justify-between !gap-4 max-[1000px]:!flex-col max-[1000px]:!gap-0">
                            <Select defaultValue={"Withdraw Method"} optionsArray={[]} />
                        </div>
                        <div className="flex items-center justify-between !gap-4 max-[1000px]:!flex-col max-[1000px]:!gap-0">
                            <FormInput
                                type={"text"}
                                label={"Paypal Account"}
                                name={"your-email"}
                                id={"your-email"} />
                            <FormNumber
                                label={"Amount"}
                                name={"amount"}
                                id={"amount"}
                                noMT={true} />
                        </div>
                        <button className='button primary !block !w-full md:!w-[200px] !ml-auto'> Request Withdraw </button>
                    </form>
                </div>
            </div>
            <div className="widget-box mt-4">
                <div className="widget-box-content">
                    <p className="widget-box-title">Payment History</p>
                    <div className="overflow-x-scroll md:!overflow-x-auto">
                        <div className="table table-cart split-rows">
                            <div className="table-header">
                                <div className="table-header-column">
                                    <p className="table-header-title">Account</p>
                                </div>
                                <div className="table-header-column padded-left">
                                    <p className="table-header-title">Requested</p>
                                </div>
                                <div className="table-header-column padded-left">
                                    <p className="table-header-title">Status</p>
                                </div>
                            </div>

                            <div className="table-body same-color-rows">
                                <div className="table-row medium">
                                    <div className="table-column">
                                        <div className="account w-[150px] md:w-1/3">
                                            <p className='text-[0.85rem] font-bold'>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="table-column padded-left w-1/3">
                                        <div className='w-[150px] md:w-1/3'>
                                            <p className='text-[0.85rem] font-bold'>
                                            </p>
                                        </div>
                                        
                                    </div>

                                    <div className="table-column padded-left">
                                        <div className="status w-[90px]">
                                            <span className='py-[7px] px-[15px] bg-[#d1e2fb] text-[#0d6efd] font-semibold rounded-md'>Recieved</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MyEarnings