import cover from "../../assests/avatar/cover.jpg";
import logo from "../../assests/avatar/logoGroup.jpg";
const GroupHeader = () => {
  return (
    <div className="profile-header v2">
    <figure className="profile-header-cover liquid">
      <img src={cover} alt="" />
    </figure>
    <div className="profile-header-info">
      <div className="user-short-description center-groupText big">
        <a className="user-short-description-avatar" href="/">
          <div className="user-avatar-border">
            <div className="hexagon-148-164"></div>
          </div>
          <div className="">
            <img
              className=" img-main-group-content"
              src={logo}
              alt=""
            ></img>
          </div>
        </a>
        <div className="flex flex-col items-start">
          <p className="user-short-description-title text-name-group-affiations">
            <a href="/">Marketing Gurus</a>
          </p>
          <p className="user-short-description-text text-desc-group-affiations">
            The American University in Cairo
          </p>
          <div className="flex items-center gap-1 mt-1  sm-screen-hashtag-texts">
            <div className="user-stat-icon">
              <svg className="icon-public">
                <use xlinkHref="#svg-public"></use>
              </svg>
            </div>
            <p className="text-lg font-semibold text-[#adafca] uppercase pr-1">
              public
            </p>
            <span className="text-lg font-semibold text-[#adafca] pl-1 border-left-hashtag-text">
              Business Networking
            </span>
          </div>
        </div>
      </div>

      <div className="user-stats">
        <div className="user-stat big">
          <div className="user-stat-icon">
            <svg className="icon-public icon_SubCategory-Group">
              <use xlinkHref="#svg-public"></use>
            </svg>
          </div>
          <p className="user-stat-text text-color-subcategory-group">
            Subcategory
          </p>
        </div>

        <div className="user-stat big">
        <div className="communities-card-flokkers-list mt-[-15px]">
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/01.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/02.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/02.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
          </div>
          <p className="user-stat-text">members</p>
        </div>

        <div className="user-stat big">
          <p className="user-stat-title">1.4M</p>
          <p className="user-stat-text">INTERACTIONS</p>
        </div>
      </div>

      <div className="tag-sticker">
        <svg className="tag-sticker-icon icon-public">
          <use xlinkHref="#svg-public"></use>
        </svg>
      </div>

      <div className="profile-header-info-actions">
        <p className="profile-header-info-action button secondary flex-main-group-icons">
          <svg className="icon-join-group fill-white">
            <use xlinkHref="#svg-join-group"></use>
          </svg>
        </p>
        <a
          className="profile-header-info-action button flex-main-group-icons"
          href="hub-group-management.html"
        >
          <svg className="icon-more-dots fill-white">
            <use xlinkHref="#svg-more-dots"></use>
          </svg>
        </a>
      </div>
    </div>
  </div>
  )
}

export default GroupHeader
