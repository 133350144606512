import React from 'react'
import FlokkersBusinessCard from './FlokkersBusinessCard'
import WrapInCenter from '../../WrapInCenter/WrapInCenter'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';



const UniversityHiring = () => {

    const renderJobCards = Array.from({ length: 5 }).map((_, idx) => (
        <SwiperSlide key={idx}>
            <FlokkersBusinessCard />
        </SwiperSlide>
    ))

    return (
        <div className='latest-careers-jobs'>
            <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
                <div className="header-career-section relative">
                    <h3>Which Flokkers from The American University of Cairo are Hiring</h3>
                </div>
                <div className="swiper-wrapper relative">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={16}
                        className="job-swiper mt-[30px] md:!overflow-visible"
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            991: {
                                slidesPerView: 3,
                                spaceBetween: 16
                            }
                        }}
                    >
                        {renderJobCards}
                    </Swiper>
                </div>

            </WrapInCenter>
        </div>
    )
}

export default UniversityHiring