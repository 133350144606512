import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { IoReload } from "react-icons/io5";
import BusinessesCard from "../../BusinessesComponents/BusinessesCard/BusinessesCard";


const SuggestedBusiness = () => {
  return (
    <div className="widget-box bussiness-card">
      <h3 className="widget-box-title">Business you may like</h3>
      <span className="widget-relod">
        <IoReload />
      </span>
      <div className="widget-box-content">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className={`mayLikeBusiness-swiper !pb-[2rem]`}
        >
          <SwiperSlide className="">
            <BusinessesCard
              avatar="/img/businesses/01.jpeg"
              cover="/img/cover/04.jpg"
              title="DigiTech Solutions"
              desc="Information Technology"
              cardId={"digitechsolutions"}
              stars={4.5}
              isHome={true}
            />
          </SwiperSlide>
          <SwiperSlide className="">
            <BusinessesCard
              avatar="/img/businesses/01.jpeg"
              cover="/img/cover/04.jpg"
              title="DigiTech Solutions"
              desc="Information Technology"
              cardId={"digitechsolutions"}
              stars={4.5}
              isHome={true}
            />
          </SwiperSlide>
          <SwiperSlide className="">
            <BusinessesCard
              avatar="/img/businesses/01.jpeg"
              cover="/img/cover/04.jpg"
              title="DigiTech Solutions"
              desc="Information Technology"
              cardId={"digitechsolutions"}
              stars={4.5}
              isHome={true}
            />
          </SwiperSlide>
          <SwiperSlide className="">
            <BusinessesCard
              avatar="/img/businesses/01.jpeg"
              cover="/img/cover/04.jpg"
              title="DigiTech Solutions"
              desc="Information Technology"
              cardId={"digitechsolutions"}
              stars={4.5}
              isHome={true}
            />
          </SwiperSlide>
          <SwiperSlide className="">
            <BusinessesCard
              avatar="/img/businesses/01.jpeg"
              cover="/img/cover/04.jpg"
              title="DigiTech Solutions"
              desc="Information Technology"
              cardId={"digitechsolutions"}
              stars={4.5}
              isHome={true}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default SuggestedBusiness;
