import "./BusinessesCard.css";

const CreateBusinessesCard = () => {
  return (
    <div className="create-businesses-card">
      <div className="create-businesses-card-header"></div>

      <div className="create-businesses-card-plus">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-plus"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </div>

      <div className="create-businesses-card-info">
        <h2 className="create-businesses-card-title">
          Create a businesses profile
        </h2>

        <p className="create-businesses-card-desc">
          Offer your product and services as a registered company or as a
          freelancer and start earning.
        </p>
      </div>

      <div className="create-businesses-card-actions">
        <button className="button medium secondary">Start Creating</button>
      </div>
    </div>
  );
};

export default CreateBusinessesCard;
