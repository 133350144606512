import { Navigate, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import HomeScreen from "./pages/homeScreen/HomeScreen";
import AuthLayout from "./pages/auth/authLayout/AuthLayout";
import AuthComplete from "./pages/authComplete/AuthComplete";
import AuthNext from "./pages/authNext/AuthNext";
import SearchPage from "./pages/Search/SearchPage";
import MainPage from "./pages/PagesOutlet/Pages";
import UserProfilePage from "./pages/UserProfilePage/UserProfilePage";
import BusinessesPage from "./pages/businesses/BusinessesPage";
import TechBusinessProfile from "./pages/tech-business-profile/TechBusinessProfile";
import "./styles/css/styles.css";
import "./App.css";
import UserDashboard from "./pages/user-dashboard/UserDashboard";
import NewGroup from "./pages/affiliations/NewGroup/NewGroup";
import Index from "./pages/affiliations/Index";
import MainGroup from "./pages/auth/group-detail-page/MainGroup";
import Crea8 from "./pages/Crea8/Crea8";
// import { useSelector } from "react-redux";
import { useState } from "react";
import SinglePost from "./pages/homeScreen/SinglePost";
import CreateStory from "./pages/homeScreen/CreateStory";
import AllStories from "./pages/homeScreen/AllStories";
import Careers from "./pages/careers/careers";
import CareerSearch from "./pages/careers/CareerSearch/CareerSearch";
import CareerDetails from "./pages/careers/CareerDetails/CareerDetails";

function App() {
	// const token = useSelector((state) => state.auth.token);
	// const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	const [isAuthenticated] = useState(true);

	return (
		<ConfigProvider
			theme={{
				components: {
					Slider: {
						handleActiveColor: "#fd6729",
						dotBorderColor: "#fd6729",
						handleColor: "#fd6729",
						trackBg: "#fd6729",
						trackHoverBg: "#fd6729",
					},
					Input: {
						hoverBorderColor: "#fd6729",
						activeBorderColor: "#fd6729",
						activeShadow: "none",
					},
				},
			}}
		>
			<div className="App">
				<Routes>
					<Route
						path="/"
						element={
							// token !== "" ? <MainPage /> : <Navigate to="/auth" replace />
							isAuthenticated ? <MainPage /> : <Navigate to="/auth" replace />
						}
					>
						<Route path="/" element={<HomeScreen />} />
						<Route path="/post/:id" element={<SinglePost />} />
						<Route path="/stories" element={<AllStories />} />

						<Route path="/crea8" element={<Crea8 />} />
						<Route path="/directory" element={<SearchPage />} />
						<Route path="/new-group" element={<NewGroup />} />
						<Route path="/affiliations" element={<Index />} />
						<Route path="/group-detail-page" element={<MainGroup />} />
						<Route path="/directory" element={<SearchPage />} />
						<Route path="/careers" element={<Careers />} />
						<Route path="/career-search" element={<CareerSearch />} />
						<Route path="/career-details" element={<CareerDetails />} />

						<Route path="/user-profile-page" element={<UserProfilePage />} />
						<Route path="/businesses" element={<BusinessesPage />} />
						<Route
							path="/businesses/profile/:id"
							element={<TechBusinessProfile />}
						/>
						<Route path="/user-dashboard" element={<UserDashboard />} />
					</Route>
					<Route path="/auth" element={<AuthLayout />} />
					<Route path="/completeAuth" element={<AuthComplete />} />
					<Route path="/nextAuth" element={<AuthNext />} />
				</Routes>
			</div>
		</ConfigProvider>
	);
}

export default App;
