import React, { useEffect, useState } from "react";
import FormInput from "../../../ui/formInput/FormInput";
import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const ourSkills = [
	{
		category: "Frontend",
		skills: ["Html", "Css", "JS", "TS", "React.js", "Angular"],
	},
	{
		category: "Backend",
		skills: ["PHP", "Node.js", "JS", "TS", "ASP .Net", "Java Spring"],
	},
];

const SkillsForm = () => {
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	const [width, setWidth] = useState(window.innerWidth);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [skillsInCategory, setSkillsInCategory] = useState(ourSkills[0].skills);
	const [selectedCategory, setSelectedCategory] = useState(
		ourSkills[0].category
	);
	const [allSkills, setAllSkills] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);
		

		return () => {
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	useEffect(() => {
		ourSkills.forEach((item) => {
			item.skills.forEach((item) => {
				setAllSkills((prev) => Array.from(new Set([...prev, item])));
			});
		});
	}, []);

	useEffect(() => {
		if (search !== "") {
			const filterdSkills = allSkills.filter((item) => item.includes(search));
			console.log(allSkills, filterdSkills);
			setSkillsInCategory(Array.from(new Set(filterdSkills)));
			setSelectedCategory("");
		} else {
			setSkillsInCategory(ourSkills[0].skills);
			setSelectedCategory(ourSkills[0].category);
		}
	}, [search, allSkills]);

	const addSkill = (industry) => {
		setSelectedSkills((prev) => {
			if (!prev.includes(industry)) {
				return [...selectedSkills, industry];
			} else {
				return prev;
			}
		});
	};

	const removeSkill = (industry) => {
		setSelectedSkills((prev) => {
			const filteredIndustries = prev.filter((item) => item !== industry);
			return filteredIndustries;
		});
	};

	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Experience</p>
					<h2 className="section-title">Skills</h2>
				</div>
			</div>
			<div className="widget-box">
				{/* <p className="widget-box-title">Skills</p> */}

				<div className="widget-box-content">
					<form className="form">
						<FormInput
							label={"Search Skills"}
							name={"search-skills"}
							id={"search_skills"}
							type={"text"}
							value={search}
							setValue={setSearch}
						/>
						<div className="mt-4">
							<p className="widget-box-title">Selected Skills</p>
							<div className="w-full flex items-center justify-start flex-wrap gap-4 mt-2">
								{selectedSkills.map((item, inedx) => (
									<p
										key={inedx}
										className="bg-[#ff6728] text-white px-[8px] py-[4px] rounded-lg font-medium text-[16px] flex items-center justify-between gap-2"
									>
										{item}{" "}
										<IoIosCloseCircle
											color="white"
											size={16}
											className="cursor-pointer"
											onClick={() => {
												removeSkill(item);
											}}
										/>
									</p>
								))}
							</div>
							{width >= 680 && (
								<div className="!grid !grid-cols-4 border-solid border-[1px] border-[#23d2e2] mt-4 rounded-lg">
									<div className="flex items-start justify-start flex-col p-4 border !border-r-[1px] !border-t-0 !border-l-0 !border-b-0 !border-[#23d2e2]">
										<p className="text-[18px] font-semibold mb-2">Category</p>
										{ourSkills.map((item, index) => (
											<p
												key={index}
												onClick={() => {
													setSelectedCategory(item.category);
													setSkillsInCategory(item.skills);
												}}
												className={`text-[16px] font-medium my-2 duration-200 hover:text-[#23d2e2] cursor-pointer ${
													selectedCategory === item.category
														? "!text-[#ff6728]"
														: ""
												}`}
											>
												{item.category}
											</p>
										))}
									</div>
									<div className="p-4 !col-span-3 flex items-start justify-start flex-wrap gap-3">
										{skillsInCategory.map((item, index) => (
											<p
												key={index}
												onClick={() => {
													addSkill(item);
												}}
												className="border-solid border-[1px] border-[#23d2e2] duration-200 hover:bg-[#23d2e2] hover:text-white cursor-pointer px-[8px] py-[4px] rounded-lg text-[16px] font-semibold flex items-center justify-between gap-2"
											>
												{item}
											</p>
										))}
									</div>
								</div>
							)}
							{width < 680 && (
								<div className="p-4 mt-4 border-solid border-[1px] border-[#23d2e2] rounded-lg flex items-start justify-start flex-wrap gap-3">
									{allSkills.map((item, index) => (
										<p
											key={index}
											onClick={() => {
												addSkill(item);
											}}
											className="border-solid border-[1px] border-[#23d2e2] duration-200 hover:bg-[#23d2e2] hover:text-white cursor-pointer px-[8px] py-[4px] rounded-lg text-[16px] font-semibold flex items-center justify-between gap-2"
										>
											{item}
										</p>
									))}
								</div>
							)}
						</div>
					</form>
				</div>
			</div>

			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default SkillsForm;
