import React, { useEffect, useRef, useState } from "react";

import "./WelcomeMessage.css"

const WelcomeMessage = () => {
    const [isWelcomeVisible, setIsWelcomeVisible] = useState(true);
    const [greeting, setGreeting] = useState("");
    const [timeOfDay, setTimeOfDay] = useState("");

    const welcomeRef = useRef()
  

    const handleWelcomeClose = () => {
      setIsWelcomeVisible(false);
      setTimeout(() => {
        if (welcomeRef.current) {
          welcomeRef.current.style.display = "none";
        }
      }, 15);
    };

      useEffect(() => {
        const hours = new Date().getHours();
        let greetingMessage = "Hello";
        let timePeriod = "day";
    
        if (hours < 12) {
          greetingMessage = "Good Morning";
          timePeriod = "morning";
        } else if (hours < 18) {
          greetingMessage = "Good Afternoon";
          timePeriod = "afternoon";
        } else {
          greetingMessage = "Good Evening";
          timePeriod = "evening";
        }
    
        setGreeting(greetingMessage);
        setTimeOfDay(timePeriod);
      }, []);

      
  return (
    <div className={`wellcome-message ${!isWelcomeVisible ? "notshow" : ""}`} ref={welcomeRef}>
      <h3 className="wellcome-title">{greeting}, Super Admin</h3>
      <p>
        May this {timeOfDay} be light, blessed, enlightened, productive and
        happy.
      </p>
      <span className="" onClick={handleWelcomeClose}>
        &times;
      </span>
      <img
        className="w-[50px] absolute top-[12px] right-[40px]"
        src="/icon/sunrise.png"
        alt=""
      />
    </div>
  );
};

export default WelcomeMessage;
