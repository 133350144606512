import React from 'react'

const FilterationMain = () => {
  return (
<div className="section-filters-bar v1">
  <div className="flex justify-between items-center w-[100%] flex-wrap">
    <div className="filter-tabs filter-tabs-main-group">
      <div className="filter-tab-main-group  active">
        <p className="filter-tab-text">All Updates</p>
      </div>
      <div className="filter-tab-main-group">
        <p className="filter-tab-text">Mentions</p>
      </div>
      <div className="filter-tab-main-group">
        <p className="filter-tab-text">Favorites</p>
      </div>
      <div className="filter-tab-main-group">
        <p className="filter-tab-text">Friends</p>
      </div>
    </div>
    <form className="form2 smm">
      <div className="form-select form-select-main-group">
        <label htmlFor="members-filter-category">Show</label>
        <select id="members-filter-category" name="members_filter_category">
          <option value="0">Everything</option>
          <option value="1">Only me</option>
          <option value="2">option 3</option>
        </select>
      </div>
    </form>
  </div>
</div>

  )
}

export default FilterationMain
