import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedForm: "Profile Info",
    isAddingAffiliations: false,
    showContent:false,
};

export const userDashboardSlice = createSlice({
    name: "userDashboard",
    initialState,
    reducers: {
        setSelectedForm(state, action) {
            state.selectedForm = action.payload.value;
        },
        setIsAddingAffiliations(state, action) {
            state.isAddingAffiliations = action.payload.value;
        },
        setShowContent (state,action) {
            state.showContent = action.payload
        }
    }
});

export const { setSelectedForm, setIsAddingAffiliations,setShowContent } = userDashboardSlice.actions;

export default userDashboardSlice.reducer;
