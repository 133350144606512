import { Modal } from "antd";
import { useState } from "react";
import { BsShareFill } from "react-icons/bs";
import FormInput from "../../../ui/formInput/FormInput";
import Select from "../../../ui/select/Select";
import { BsCameraFill } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid'; 

const QuickPostShareService = ({isOpened , setIsOpened}) => {
  const [service , setService] = useState("")
  const [selectedImages, setSelectedImages] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map(file => ({
      id: uuidv4(), // Generate unique ID
      url: URL.createObjectURL(file)
    }));
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleRemoveImage = (id) => {
    setSelectedImages(prevImages => prevImages.filter(image => image.id !== id));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const newImages = files.map(file => ({
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }));
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
    setIsDragOver(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () =>{
    setIsDragOver(false);
  }
    return (
      <>
        <button className="more-btn" onClick={() => setIsOpened(!isOpened)}>
            <span className="more-btn-img">
                <BsShareFill size={21} fill="#3f51b5" />
            </span>
    
            <span className="more-btn-text">Share Service</span>
        </button>
  
        <Modal
          title={null}
          open={isOpened}
          onCancel={() => setIsOpened(!isOpened)}
          footer={null}
          width={800}
          zIndex={100000000000}
        >
          {/* <div className="share-service-container">
            <h2 className="sell-product-title">Share Services</h2>
            <form action="">
            <div className="sell-product-form">
              <label
                className={`sell-upload-product-img ${isDragOver ? "scale-125 !text-[#fd6729] !border-[#fd6729]" : ""}`}
                htmlFor="upload-product-img"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <input
                  type="file"
                  id="upload-product-img"
                  style={{ display: "none" }}
                  multiple
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <BsCameraFill />
                <p>Service image</p>
              </label>

              { selectedImages.length > 0 && (
                <div
                className="progress-stat-bar"
                style={{
                  width: "100%",
                  height: "4px",
                  position: "relative",
                  marginBottom: 22,
                  paddingRight: 35,
                }}
              >
                <div
                  className="bar"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#ddd",
                  }}
                ></div>
                <div
                  className="colored-bar"
                  style={{
                    width: `50%`,
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    background: "linear-gradient(90deg, #fd6729, orange)",
                  }}
                ></div>

                <p className="absolute top-[-5px] right-0 text-[14px]">50%</p>
                </div>
              ) }


              {selectedImages.map((image, index) => (
                  <div
                  className="thumbnail relative w-[100px] h-[100px]"
                >
                  <img
                    className="w-full h-full object-cover"
                    src={image.url}
                    alt={`Thumbnail ${index}`}
                  />
                  <button
                    className="absolute top-[5px] right-[5px] bg-[#00000040] text-white w-[25px] h-[25px] flex items-center justify-center rounded-[50%]"
                    onClick={() => handleRemoveImage(image.id)}
                  >
                    <FaTimes />
                  </button>
                </div>
                ))}


              <FormInput label="Service Name" name="product-name" type="text" />

              <div className="sell-product-form-item">
                <Select defaultValue={"City"} optionsArray={cities} />
              </div>

              <div className="sell-product-form-item">
                <Select defaultValue={"Category"} optionsArray={categories} />
              </div>

              <div className="sell-product-form-item">
                <Select defaultValue={"Currency"} optionsArray={currencies} />
                <FormInput label="Price" name="price" type="number" />
              </div>

              <FormInput
                label="Description"
                name="desc"
                type="text"
                className="sell-product-textarea"
              />

              <div className="sell-product-form-item">
                <button className="button white">Cancel</button>
                <button className="button primary">Share Services</button>
              </div>
            </div>
          </form>
          </div> */}

          <div className="share-service-container">
            <h2 className="sell-product-title">Share Service</h2>
            <form action="">
              {service && 
                <h2 className="mb-[2rem] w-fit text-white text-[1rem] bg-[#fd6729] py-[8px] px-[20px] rounded-[30px] cursor-pointer"
                onClick={() => setService("")}>
                  {service} <FaTimes className="inline" />
                </h2>
              }
              <div className="grid md:!grid-cols-2 lg:!grid-cols-3">
                <div className="select-service-card" onClick={() => setService("Service 1")}>
                  <img className="rounded-t-[12px] w-full" src="/img/cover/05.jpg" alt="" />
                  <p className="text-[16px] p-[20px] text-center font-semibold">Service Name Here</p>
                </div>
                <div className="select-service-card" onClick={() => setService("Service 2")}>
                  <img className="rounded-t-[12px] w-full" src="/img/cover/05.jpg" alt="" />
                  <p className="text-[16px] p-[20px] text-center font-semibold">Service Name Here</p>
                </div>
                <div className="select-service-card" onClick={() => setService("Service 3")}>
                  <img className="rounded-t-[12px] w-full" src="/img/cover/05.jpg" alt="" />
                  <p className="text-[16px] p-[20px] text-center font-semibold">Service Name Here</p>
                </div>
              </div>
              <div className="flex gap-[15px] mt-[3rem]">
                <button className="button white">Cancel</button>
                <button className="button primary">Share Service</button>
              </div>
            </form>
          </div>
        </Modal>
  
        
      </>
    );
  };
  
  export default QuickPostShareService;