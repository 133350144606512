import React from 'react'
import { FaCommentAlt } from 'react-icons/fa'
import { FaMessage } from 'react-icons/fa6'
import { IoIosCreate, IoIosInformationCircle, IoMdHappy } from 'react-icons/io'
import { TbPointFilled } from 'react-icons/tb'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const MyPoints = () => {
    const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Earnings</p>
                    <h2 className="section-title">My Points</h2>
                </div>
            </div>
            <div className="widget-box">
                {/* <p className="widget-box-title">My Points</p> */}

                <div className="widget-box-content">
                    <ul className='grid !grid-cols-2 md:!grid-cols-3 lg:!grid-cols-4'>
                        <li className='flex flex-col items-center gap-3'>
                            <span className='flex items-center justify-center bg-[#4caf50] text-white w-[50px] h-[50px] rounded-full'>
                                <FaCommentAlt size={25} />
                            </span>
                            <p className='text-center font-semibold'>Earn 10 points by commenting any post</p>
                        </li>
                        <li className='flex flex-col items-center gap-3'>
                            <span className='flex items-center justify-center bg-[#2196F3] text-white w-[50px] h-[50px] rounded-full'>
                                <IoIosCreate className='-mt-[5px] -mr-[4px]' size={25} />
                            </span>
                            <p className='text-center font-semibold'>Earn 10 points by commenting any post</p>
                        </li>
                        <li className='flex flex-col items-center gap-3'>
                            <span className='flex items-center justify-center bg-[#f3b600] text-white w-[50px] h-[50px] rounded-full'>
                                <IoMdHappy size={25} />
                            </span>
                            <p className='text-center font-semibold'>Earn 5 points by reacting on any post</p>
                        </li>
                        <li className='flex flex-col items-center gap-3'>
                            <span className='flex items-center justify-center bg-[#7a7a7a] text-white w-[50px] h-[50px] rounded-full'>
                                <IoIosCreate className='-mt-[5px] -mr-[4px]' size={25} />
                            </span>
                            <p className='text-center font-semibold'>Earn 15 points by creating a new blog</p>
                        </li>
                    </ul>
                    <div className="grid !grid-cols-2 !mt-[40px]">
                        <div className='bg-[#fff] flex flex-col items-center gap-[8px] py-[30px] rounded-xl shadow-[0_0_40px_0_#5e5c9a0f]'>
                            <TbPointFilled className='text-[#3e3f5e]' />
                            <p className='font-semibold text-[2.5rem]'>0</p>
                            <p>Points</p>
                        </div>
                        <div className='flex flex-col items-center gap-[12px] p-[30px] border !border-dashed !border-[#3e3f5ed8] rounded-xl h-full'>
                            <IoIosInformationCircle className="fill-[#36e9f7]" size={30} />
                            <p className='font-semibold text-[18px] text-center'>Your earned points will automatically go to <a href="#">Wallet</a> </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MyPoints