import React from "react";

const Rejected = () => {
	return (
		<div className="bg-red-600 text-white font-semibold px-2 py-1 rounded">
			Rejected
		</div>
	);
};

export default Rejected;
