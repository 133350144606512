import React from "react";
import EducationalInstituteForm from "./EducationalInstituteForm";
import ExpatCommunityForm from "./ExpatCommunityForm";

const AddAffiliation = ({ form, setForm }) => {
	return (
		<div className="widget-box-content">
			<form className="form">
				{form === "Educational" ? (
					<EducationalInstituteForm />
				) : form === "Expat" ? (
					<ExpatCommunityForm />
				) : (
					<></>
				)}
			</form>
		</div>
	);
};

export default AddAffiliation;
