import React, { useState } from "react";

const Photos = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState("");

	return (
		<div class="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p class="widget-box-title">
				Photos <span class="highlighted">74</span>
			</p>

			<div class="widget-box-content">
				<div class="picture-item-list small">
					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/avatar/01.jpg"
								alt="avatar-01"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/10.jpg"
								alt="avatar-10"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/12.jpg"
								alt="avatar-12"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/02.jpg"
								alt="avatar-02"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/06.jpg"
								alt="avatar-06"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/13.jpg"
								alt="avatar-13"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/04.jpg"
								alt="avatar-04"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/15.jpg"
								alt="avatar-15"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/11.jpg"
								alt="avatar-11"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/08.jpg"
								alt="avatar-08"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<div class="picture-item">
						<figure class="picture round liquid">
							<img
								src="img/cover/16.jpg"
								alt="avatar-16"
								className="pic-item-image"
							/>
						</figure>
					</div>

					<a class="picture-item" href="profile-photos.html">
						<figure class="picture round liquid">
							<img
								src="img/cover/17.jpg"
								alt="avatar-17"
								className="pic-item-image"
							/>
						</figure>

						<div class="picture-item-overlay round">
							<p class="picture-item-overlay-text">+61</p>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Photos;
