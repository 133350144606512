import React, { useState } from "react";
import Select from "../../../ui/select/Select";
import FormInput from "../../../ui/formInput/FormInput";
import { setIsAddingAffiliations } from "../../../../app/feature/userDashboard";
import { useDispatch } from "react-redux";

const EducationalInstituteForm = () => {
	const [isHaveEmail, setIsHaveEmail] = useState(true);
	const dispatch = useDispatch();
	return (
		<div className="mt-4">
			<div className="flex items-center justify-between !gap-8 max-[680px]:!flex-col max-[680px]:!gap-0">
				<Select defaultValue={"Country"} optionsArray={[]} />
				<Select defaultValue={"City"} optionsArray={[]} />
			</div>
			<div className="flex items-center justify-between !gap-8 max-[680px]:!flex-col max-[680px]:!gap-0">
				<Select defaultValue={"Your University"} optionsArray={[]} />
				<Select defaultValue={"Your Faculty"} optionsArray={[]} />
			</div>
			<div className="mt-4">
				<p className="text-[18px] font-semibold">
					Do you have a university email to register with?
				</p>
				<div className="register-radio-box">
					<div class="form-row">
						<div class="form-item">
							<div class="checkbox-wrap">
								<input
									type="radio"
									id="yes"
									name="haveEmail"
									value={true}
									checked={isHaveEmail === true}
								/>
								<div class="checkbox-box round"></div>
								<label htmlFor="yes" onClick={() => setIsHaveEmail(true)}>
									Yes
								</label>
							</div>
						</div>
					</div>

					<div class="form-row delete-margin">
						<div class="form-item">
							<div class="checkbox-wrap">
								<input type="radio" id="no" name="haveEmail" value={false} />
								<div class="checkbox-box round"></div>
								<label htmlFor="no" onClick={() => setIsHaveEmail(false)}>
									No I don’t
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col">
				<div className="flex items-center justify-center gap-1 !mt-0">
					<FormInput
						label={isHaveEmail ? "Username" : "Email"}
						type="email"
						id="university-email"
						name="university_email"
						icon={isHaveEmail ? "username" : "email"}
					/>
					{isHaveEmail === true && (
						<p className="email-domain">@aucegypt.edu</p>
					)}
				</div>

				<div className="flex-1">
					<FormInput
						label="Student ID#"
						type="text"
						id="student-id-#"
						name="student_id_#"
					/>
				</div>
			</div>
			<div className="flex items-center justify-between mt-3 mb-4 !gap-4 max-[680px]:!flex-col max-[680px]:!gap-2">
				<div className="upload-box shadow-sm">
					<svg className="upload-box-icon icon-photos">
						<use xlinkHref="#svg-photos"></use>
					</svg>

					<p className="upload-box-title">
						Upload copy of your passport or ID card
					</p>
				</div>
				<div className="upload-box shadow-sm">
					<svg className="upload-box-icon icon-photos">
						<use xlinkHref="#svg-photos"></use>
					</svg>

					<p className="upload-box-title">Upload your Institution ID</p>
				</div>
			</div>
			<button
				className="button medium secondary"
				onClick={(e) => {
					e.preventDefault();
					dispatch(setIsAddingAffiliations({ value: false }));
				}}
			>
				Save
			</button>
		</div>
	);
};

export default EducationalInstituteForm;
