import React from "react";

const InputWithStaticData = ({ label, type, id, name, value }) => {
	return (
		<div className="form-item">
			<div className="form-input small active">
				<label htmlFor={id}>{label}</label>
				<input type={type} id={id} name={name} value={value} />
			</div>
		</div>
	);
};

export default InputWithStaticData;
