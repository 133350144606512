import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
import { FaUserEdit } from "react-icons/fa";
import FormInputwithIcon from "../../../ui/formInputWithSearchIcon/FormInputwithIcon";
import Select from "../../../ui/select/Select";
import logo from '../../../../assests/avatar/logo.svg';
import cover from '../../../../assests/avatar/cover.jpg';
import { Tooltip } from "antd";


const categories = [
	{ id: 1, value: "Category 1" },
	{ id: 2, value: "Category 2" },
	{ id: 3, value: "Category 3" },
	{ id: 4, value: "Category 4" },
];
const Subcategory = [
	{ id: 1, value: "Subcategory 1" },
	{ id: 2, value: "Subcategory 2" },
	{ id: 3, value: "Subcategory 3" },
	{ id: 4, value: "Subcategory 4" },
];
const Affiliations = [
	{ id: 1, value: "Affiliations 1" },
	{ id: 2, value: "Affiliations 2" },
	{ id: 3, value: "Affiliations 3" },
	{ id: 4, value: "Affiliations 4" },
];

const ManageCommunities = () => {
	const { showContent } = useSelector((state) => state.userDashboard)
	const [isgrid, setIsGrid] = useState(true)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					{/* <p className="text-[#3e3f5e] text-xl font-extrabold">
						Manage Communities
					</p> */}
					<p className="section-pretitle">Communities</p>
					<h2 className="section-title">Manage Communities</h2>
				</div>
				<div className="create-AddAffiliations w-1/2 md:w-auto">
					<h2 className="section-title-sec-affiliations">
						<Link className="hover:text-[#ff6728] text-[#23d2e2]" to={'/new-group'}>
						+ Create Group
						</Link>
					</h2>
				</div>
			</div>
			<div className="sec-one-affiliations-header">
				<div className="section-Title-affiliations p-0 md:!p-[25px]">
					<div className=" w-[100%] input-search-affiations">
						<FormInputwithIcon
							type={"text"}
							label={"Search"}
							name={"Search"}
							id={"Search"}
						/>
					</div>
					<div className="result-view-affiations">
						<Select defaultValue={"Affiliations"} optionsArray={Affiliations} />
						<Select defaultValue={"Category"} optionsArray={categories} />
						<Select defaultValue={"Subcategory"} optionsArray={Subcategory} />
						<div className="results-view">
							<div className={`number-of-results`}>
								<p className={`numberActive`}>Reset</p>
								<p className={`numberActive`}>32</p>
								<p>64</p>
								<p>128</p>
							</div>
							<div class={`view-action text-tooltip-tft-medium active grid-view-color ${isgrid ? "grid-view-active-color" : ""}`}
								data-title="Small Grid"
								onClick={() => setIsGrid(true)}
							>
								<svg class="view-action-icon icon-small-grid-view">
									<use xlinkHref="#svg-small-grid-view"></use>
								</svg>
							</div>

							<div class={`view-action text-tooltip-tft-medium active grid-view-color ${!isgrid ? "grid-view-active-color" : ""}`}
								data-title="List"
								onClick={() => setIsGrid(false)}
							>
								<svg class="view-action-icon icon-list-grid-view">
									<use xlinkHref="#svg-list-grid-view"></use>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isgrid ? <GridView cover={cover} logo={logo} /> : <ListView />}


		</div>
	);
};

export default ManageCommunities;






const GridView = ({ cover, logo }) => {
	return (
		<div className="grid grid-3-3-3-3 centered !mt-[32px]">
			<div className="flex flex-col">
				<div className="user-preview">
					<figure className="user-preview-cover liquid">
						<img
							src={cover}
							alt="cover-29"
							className="rounded-t-[12px]"
						/>
					</figure>
					<div className="user-preview-info">
						<div className="tag-sticker">
							<svg className="tag-sticker-icon icon-public">
								<use xlinkHref="#svg-public"></use>
							</svg>
						</div>
						<div className="user-short-description">
							<a
								className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
								href=""
							>
								<div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
									<img
										src={logo}
										alt="Community"
										className="rounded-full"
									/>
								</div>
							</a>
							<p className="user-short-description-title">
								<a href="group-timeline.html">Cosplayers of the World</a>
							</p>
							<p className="user-short-description-text">
								All cosplayers welcome!
							</p>
						</div>
						<div className="user-stats">
							<div className="user-stat">
								<p className="user-stat-title">139</p>
								<p className="user-stat-text">members</p>
							</div>
							<div className="user-stat">
								<p className="user-stat-title">105</p>
								<p className="user-stat-text">posts</p>
							</div>
							<div className="user-stat">
								<p className="user-stat-title">7.3k</p>
								<p className="user-stat-text">visits</p>
							</div>
						</div>
						<div className="communities-card-flokkers-list mt-4">
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/01.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/02.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/03.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/04.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/05.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/06.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker plus !w-[45px] !h-[45px]">
								<p>+19K</p>
							</div>
						</div>
						<div className="user-preview-actions">
							<p className="button secondary full !flex items-center justify-center gap-2">
								<div className="button-icon icon-join-group">
									<FaUserEdit className="text-xl" />
								</div>
								Edit
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col">
				<div className="user-preview">
					<figure className="user-preview-cover liquid">
						<img
							src={cover}
							alt="cover-29"
							className="rounded-t-[12px]"
						/>
					</figure>
					<div className="user-preview-info">
						<div className="tag-sticker">
							<svg className="tag-sticker-icon icon-public">
								<use xlinkHref="#svg-public"></use>
							</svg>
						</div>
						<div className="user-short-description">
							<a
								className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
								href=""
							>
								<div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
									<img
										src={logo}
										alt="Community"
										className="rounded-full"
									/>
								</div>
							</a>
							<p className="user-short-description-title">
								<a href="group-timeline.html">Cosplayers of the World</a>
							</p>
							<p className="user-short-description-text">
								All cosplayers welcome!
							</p>
						</div>
						<div className="user-stats">
							<div className="user-stat">
								<p className="user-stat-title">139</p>
								<p className="user-stat-text">members</p>
							</div>
							<div className="user-stat">
								<p className="user-stat-title">105</p>
								<p className="user-stat-text">posts</p>
							</div>
							<div className="user-stat">
								<p className="user-stat-title">7.3k</p>
								<p className="user-stat-text">visits</p>
							</div>
						</div>
						<div className="communities-card-flokkers-list mt-4">
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/01.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/02.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/03.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/04.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/05.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/06.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker plus !w-[45px] !h-[45px]">
								<p>+19K</p>
							</div>
						</div>
						<div className="user-preview-actions">
							<p className="button secondary full !flex items-center justify-center gap-2">
								<div className="button-icon icon-join-group">
									<FaUserEdit className="text-xl" />
								</div>
								Edit
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col">
				<div className="user-preview">
					<figure className="user-preview-cover liquid">
						<img
							src={cover}
							alt="cover-29"
							className="rounded-t-[12px]"
						/>
					</figure>
					<div className="user-preview-info">
						<div className="tag-sticker">
							<svg className="tag-sticker-icon icon-public">
								<use xlinkHref="#svg-public"></use>
							</svg>
						</div>
						<div className="user-short-description">
							<a
								className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
								href=""
							>
								<div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
									<img
										src={logo}
										alt="Community"
										className="rounded-full"
									/>
								</div>
							</a>
							<p className="user-short-description-title">
								<a href="group-timeline.html">Cosplayers of the World</a>
							</p>
							<p className="user-short-description-text">
								All cosplayers welcome!
							</p>
						</div>
						<div className="user-stats">
							<div className="user-stat">
								<p className="user-stat-title">139</p>
								<p className="user-stat-text">members</p>
							</div>
							<div className="user-stat">
								<p className="user-stat-title">105</p>
								<p className="user-stat-text">posts</p>
							</div>
							<div className="user-stat">
								<p className="user-stat-title">7.3k</p>
								<p className="user-stat-text">visits</p>
							</div>
						</div>
						<div className="communities-card-flokkers-list mt-4">
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/01.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/02.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/03.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/04.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/05.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker !w-[45px] !h-[45px]">
								<img
									src="/img/avatar/06.jpg"
									alt="fldkker-avatar"
									loading="lazy"
								/>
							</div>
							<div className="fldkker plus !w-[45px] !h-[45px]">
								<p>+19K</p>
							</div>
						</div>
						<div className="user-preview-actions">
							<p className="button secondary full !flex items-center justify-center gap-2">
								<div className="button-icon icon-join-group">
									<FaUserEdit className="text-xl" />
								</div>
								Edit
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


const ListView = () => {
	return (
		<div className="grid !mt-[32px]">
			<div className="user-preview landscape">
				<figure className="user-preview-cover liquid">
					<img
						src={cover}
						alt="cover-29"
						className="rounded-l-[12px]"
					/>
				</figure>
				<div className="user-preview-info">
					<div className="user-short-description landscape tiny">
						<a
							className="user-short-description-avatar user-avatar small no-stats rounded-full bg-white p-[6px] !w-[50px] !h-[50px]"
							href=""
						>
							<div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
								<img
									src={logo}
									alt="Community"
									className="rounded-full"
								/>
							</div>
						</a>
						<p className="user-short-description-title !text-[1rem]">
							<a href="group-timeline.html">Cosplayers of the World</a>
						</p>
						<p className="user-short-description-text !text-[0.75rem]">
							All cosplayers welcome!
						</p>
					</div>
					<div className="user-stats">
						<div className="user-stat">
							<p className="user-stat-title">139</p>
							<p className="user-stat-text">members</p>
						</div>
						<div className="user-stat">
							<p className="user-stat-title">105</p>
							<p className="user-stat-text">posts</p>
						</div>
						<div className="user-stat">
							<p className="user-stat-title">7.3k</p>
							<p className="user-stat-text">visits</p>
						</div>
					</div>
					<div className="communities-card-flokkers-list !hidden md:!flex">
						<div className="fldkker !w-[45px] !h-[45px]">
							<img
								src="/img/avatar/01.jpg"
								alt="fldkker-avatar"
								loading="lazy"
							/>
						</div>
						<div className="fldkker !w-[45px] !h-[45px]">
							<img
								src="/img/avatar/02.jpg"
								alt="fldkker-avatar"
								loading="lazy"
							/>
						</div>
						<div className="fldkker !w-[45px] !h-[45px]">
							<img
								src="/img/avatar/03.jpg"
								alt="fldkker-avatar"
								loading="lazy"
							/>
						</div>
						<div className="fldkker !w-[45px] !h-[45px]">
							<img
								src="/img/avatar/04.jpg"
								alt="fldkker-avatar"
								loading="lazy"
							/>
						</div>
						<div className="fldkker !w-[45px] !h-[45px]">
							<img
								src="/img/avatar/05.jpg"
								alt="fldkker-avatar"
								loading="lazy"
							/>
						</div>
						<div className="fldkker !w-[45px] !h-[45px]">
							<img
								src="/img/avatar/06.jpg"
								alt="fldkker-avatar"
								loading="lazy"
							/>
						</div>
						<div className="fldkker plus !w-[45px] !h-[45px]">
							<p>+19K</p>
						</div>
					</div>
					<div className="user-preview-actions">
						<div className="tag-sticker">
							<svg className="tag-sticker-icon icon-public">
								<use xlinkHref="#svg-public"></use>
							</svg>
						</div>
						<Tooltip placement="top" color="#fd6728" title="Edit Community">
							<p className="button secondary !flex items-center justify-center gap-2">
								<div className="button-icon icon-join-group">
									<FaUserEdit className="text-xl" />
								</div>
							</p>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	)
}