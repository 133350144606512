import React from 'react'
import FormInput from '../../ui/formInput/FormInput'
import { IoMdSend } from 'react-icons/io'

const InviteFriends = () => {
  return (
    <div className="widget-box invite-friend !px-[16px]">
        <h3 className="widget-box-title">Invite Your Friends</h3>
        <div className="widget-box-content relative">
            <form action="">
                <FormInput label={"Email"} name={"email"} type={"email"} />
                <button className="send-invite">
                    {" "}<IoMdSend size={20} />{" "}
                </button>
            </form>
        </div>
    </div>
  )
}

export default InviteFriends