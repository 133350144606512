import React, { useEffect, useState } from "react";
import { MdAdminPanelSettings, MdLocationOn } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const MemberPageOne = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const breakPoint = 680;
	const breakPointPersonalAffiliation = 400;

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);
		return () => {
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	return (
		<div className="page-one-member page-member-desc">
			<div className="pl-[10px]">
				<div className="user-status">
					<div className="user-chat-profile user-status-avatar user-small-avatar-results-dir">
						<MdAdminPanelSettings
							color={"#d7006a"}
							size={16}
							className="admin-icon-inside-chat"
						/>
						<img src="/img/avatar/03.jpg" alt="Profile" />
						<RiVerifiedBadgeFill
							color={"#36e9f7"}
							size={16}
							className="verify-icon-inside-chat"
						/>
						<div className="badge-container-chats">
							<img src="/img/download.png" alt="Padge" />
							<p className="badge-number-chats">12</p>
						</div>
					</div>

					<p className="user-status-title user-small-text-results-dir page-two-member-name">
						<span className="bold">NekoBebop</span>
					</p>

					<p
						className="user-status-text user-chat-job small user-small-text-results-dir page-two-member-title"
						style={{ color: "#adafca" }}
					>
						Marketing Manager
					</p>

					<p
						className="user-status-text user-chat-job small user-small-text-results-dir page-two-member-company"
						style={{ color: "#adafca" }}
					>
						Brandmarks
					</p>
				</div>
			</div>

			<div className="affili-section">
				<p className="desc-section-title flex items-center justify-start gap-[4px]">
					<MdLocationOn size={18} color="rgb(67 120 220)" /> Affiliation
				</p>
				<div className="affili-desc !pl-[15px]">
					<p>
						<span>Institute: </span>The American University of Cairo
					</p>
					<p>
						<span>Years: </span>1992 - 1995
					</p>
					<p>
						<span>Major: </span>Digital Media
					</p>
					<p>
						<span>Faculty: </span>Graphic Design
					</p>
				</div>
			</div>
			<div className="busine-section">
				<p className="desc-section-title flex items-center justify-start gap-[4px]">
					<MdLocationOn size={18} color="rgb(67 120 220)" /> Business
				</p>
				<div className="bussiness-sec-desc">
					<div className="businesses-images-section">
						<img src="/img/avatar/13.jpg" alt="Business" />
						<img src="/img/avatar/13.jpg" alt="Business" />
						<img src="/img/avatar/13.jpg" alt="Business" />
						<div className="more-businesses">
							<span>+13</span>
							<p>MORE</p>
						</div>
					</div>
					<p>
						<span>Industries:</span> Business Administration, Marketing,
						Advertising, +10 more...
					</p>
					<p>
						<span>Skills:</span> Communication, Brand Strategy, Presentation,
						Powerpoint, Adobe, Strategy, +10 more...
					</p>
				</div>
			</div>
			<div className="personal-section-result">
				<p className="desc-section-title flex items-center justify-start gap-[4px]">
					<MdLocationOn size={18} color="rgb(67 120 220)" /> Personal
				</p>
				<div className="perso-sec-desc">
					<p>
						<span>Residence: </span>Cairo - Egypt
					</p>
					<p>
						<span>Nationality: </span>Egyptian
					</p>
					<p>
						<span>Age: </span>36
					</p>
					<p>
						<span>Status: </span>Single
					</p>
					<p>
						<span>Birthday: </span>January 7th 1975
					</p>
					<p>
						<span>Gender: </span>Male
					</p>
				</div>
			</div>
		</div>
	);
};

export default MemberPageOne;
