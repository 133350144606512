import React from 'react'
import "./TrustedBy.css"
import WrapInCenter from '../../WrapInCenter/WrapInCenter'
import { Link } from 'react-router-dom'
import { IoIosCheckmark } from "react-icons/io";


const funfactsData2 = [
    "Last Education of Bachelor Degree",
    "More Than 15 Years Experience",
    "Last Education of Bachelor Degree",
]

const TrustedBy = () => {
    return (
        <section className="trusted-by">
            <div className="cta-banner3 relative overflow-hidden">
                <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 col-xl-5 wow fadeInRight">
                            <div className="home13-ctaimg-1">
                                <div className="block md:flex items-center gap-[40px] mb-10 lg:mb-0">
                                    <div className='flex flex-col items-center'>
                                        <img
                                            width={240}
                                            height={240}
                                            className="mb-[35px]"
                                            src="/img/careers/trusted/1.png"
                                            alt=" image "
                                        />
                                        <img
                                            width={240}
                                            height={240}
                                            className="mb-[35px] md:mb-[0px]"
                                            src="/img/careers/trusted/2.png"
                                            alt=" image "
                                        />
                                    </div>
                                    <div className='flex flex-col items-center'>
                                        <img
                                            width={240}
                                            height={240}
                                            className="mb-[35px]"
                                            src="/img/careers/trusted/3.png"
                                            alt=" image "
                                        />
                                        <img
                                            width={240}
                                            height={240}
                                            src="/img/careers/trusted/4.png"
                                            alt=" image "
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="position-relative mb30-md">
                                <div className="imgbox-about-page position-relative d-none d-xl-block">
                                    <img width={129}
                                        height={129}
                                        className="img-1 spin-right"
                                        src="/images/about/element-1.png"
                                        alt=" image "
                                    />
                                    <img
                                        width={41}
                                        height={11}
                                        className="img-2 bounce-x"
                                        src="/images/about/element-2.png"
                                        alt=" image "
                                    />
                                    <img
                                        width={8}
                                        height={34}
                                        className="img-3 bounce-y"
                                        src="/images/about/element-3.png"
                                        alt=" image "
                                    />
                                    <img
                                        width={57}
                                        height={49}
                                        className="img-4 bounce-y"
                                        src="/images/about/element-4.png"
                                        alt=" image "
                                    />
                                    <img
                                        width={71}
                                        height={71}
                                        className="img-5 spin-right"
                                        src="/images/about/element-5.png"
                                        alt=" image "
                                    />
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-6 col-xl-5 wow fadeInLeft">
                            <div
                                className="about-box-1 pe-xl-4 ps-3 ps-xl-0 wow fadeInLeft"
                                data-wow-delay="300ms"
                            >
                                <h2 className="mb-[15px] text-[30px]">
                                    Truested By Best <br className="d-none d-xl-block" />
                                    Freelancer
                                </h2>
                                <p className="mb-[25px] md:mb-[30px]">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                    do eiusmod <br className="d-none d-xl-block" /> tempor
                                    incididunt.
                                </p>
                                <div className="list-style3 mb-[40px] md:-[30px] ">
                                    <ul>
                                        {funfactsData2.map((elm, i) => (
                                            <li key={i}>
                                                <IoIosCheckmark className="text-white bg-[#ddd]" />
                                                {elm}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <Link to="/service-single" className="button primary w-[250px]">
                                    See More<i className="fal fa-arrow-right-long"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </WrapInCenter>
            </div>
        </section>
    )
}

export default TrustedBy