import React from "react";
import {
  FaTabletAlt,
  FaConciergeBell,
  FaUserPlus,
  FaCaretRight,
} from "react-icons/fa";

const FindYourNeeds = () => {

  return (
    <section className="provide-section responsive-max-width-flokky">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-9">
            <div className="section-header aos flex-col mb-10" data-aos="fade-up">
            <p className="text-[#ff6728] font-bold text-2xl mb-2">Get Work done</p>
              <h2>
                <span>We’re</span> here to help you find your Needs.
              </h2>
              <p className="text-2xl font-semibold">Over 74K listings of Gigs available today for you.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="provide-box">
              <div className="provide-icon">
                <FaTabletAlt size={40} className="text-white"/>
              </div>
              <h3 className="text-4xl">Buy a Service</h3>
              <p className="text-2xl">Explore homy’s 50K+ Services and uncover your ideal needs.</p>
              <a href="service.html" className="btn bg-[#ff6728] text-white
               hover:bg-[#ff6728] flex items-center gap-1 w-fit text-2xl">
                Get Started <FaCaretRight />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="provide-box">
              <div className="provide-icon">
                <FaConciergeBell  size={40} className="text-white"/>
              </div>
              <h3 className="text-4xl">Sell a Service</h3>
              <p className="text-2xl">Explore homy’s 50K+ Services and uncover your ideal needs.</p>
              <a href="add-gigs.html" className="btn bg-[#ff6728] text-white
               hover:bg-[#ff6728] flex items-center gap-1 w-fit text-2xl">
                Add Service <FaCaretRight />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="provide-box">
              <div className="provide-icon">
                <FaUserPlus  size={40} className="text-white" />
              </div>
              <h3 className="text-4xl">Join Us</h3>
              <p className="text-2xl">Explore homy’s 50K+ Services and uncover your ideal needs.</p>
              <a href="add-gigs.html" className="btn bg-[#ff6728] text-white
               hover:bg-[#ff6728] flex items-center gap-1 w-fit text-2xl">
                Get Started <FaCaretRight />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindYourNeeds;
