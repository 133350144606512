import { Modal } from "antd";
import { BsCameraFill } from "react-icons/bs";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid'; // Import uuid library
import { FaTimes } from "react-icons/fa";
import FormTextBox from "../../ui/formTextBox/formTextBox";

const CreateStory = ({isOpened , setIsOpened}) => {
    const [value, setValue] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [thumbnails, setThumbnails] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);

  const handleUpload = (event) => {
    const files = Array.from(event.target.files);
    const updatedFiles = files.map(file => ({
      id:uuidv4(),
      file,
      type: file.type.startsWith("image") ? "image" : "video"
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };
  const handleVideoThumbUpload = (index,e) => {
    const newThumbnails = [...thumbnails];
    newThumbnails[index] = e.target.files[0];
    setThumbnails(newThumbnails);
  };

  const handleRemoveImage = (id) => {
    setUploadedFiles(prevImages => prevImages.filter(image => image.id !== id));
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(uploadedFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setUploadedFiles(items);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const updatedFiles = files.map(file => ({
      id:uuidv4(),
      file,
      type: file.type.startsWith("image") ? "image" : "video"
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    setIsDragOver(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () =>{
    setIsDragOver(false);
  }

  return (
    <>
      <Modal
        title={null}
        open={isOpened}
        onCancel={() => setIsOpened(!isOpened)}
        footer={null}
        width={800}
        zIndex={100000000000}
      >
        <div className="sell-product-container">
          <h2 className="sell-product-title">Create Story</h2>

          <form action="">
            <div className="sell-product-form">
                <FormTextBox
                    label={"Whats going on ?"}
                    name={"status"}
                    value={value}
                    setValue={setValue}
                    />
              <label
                className={`upload-status-img font-[600] flex flex-col items-center justify-center h-[250px] text-[25px] cursor-pointer gap-[10px] rounded-[8px] hover:!border-[#fd6729] hover:!text-[#fd6729]
                    ${isDragOver ? "!text-[#fd6729] !border-[#fd6729]" : ""}
                    `}
                style={{
                    border:"1px dashed #3e3f5ed8",
                    // backgroundColor: "#fbfbfb",
                    transition:"0.5s"
                    }}
                htmlFor="upload-status-img"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <input
                    type="file"
                    id="upload-status-img"
                    style={{ display: "none" }}
                    multiple
                    accept="image/*,video/*"
                    onChange={handleUpload}
                  />
                <BsCameraFill size={35} />
                <p>Drop images or videos here To Upload</p>
              </label>

              { uploadedFiles.length > 0 && (
                <div
                className="progress-stat-bar"
                style={{
                  width: "100%",
                  height: "4px",
                  position: "relative",
                  marginTop:15,
                  marginBottom: 22,
                  paddingRight: 35,
                }}
              >
                <div
                  className="bar"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#ddd",
                  }}
                ></div>
                <div
                  className="colored-bar"
                  style={{
                    width: `50%`,
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    background: "linear-gradient(90deg, #fd6729, orange)",
                  }}
                ></div>

                <p className="absolute top-[-5px] right-0 text-[14px]">50%</p>
                </div>
              ) }

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-1" direction="horizontal">
                  {(provided) => (
                    <div
                      className="image-thumbnails flex flex-wrap gap-[10px]"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {uploadedFiles.map((file, index) => (
                        <Draggable
                          key={file.id}
                          draggableId={file.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="thumbnail relative w-[100px] h-[100px]"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              {file.type === "image" ? (
                                <>
                                    <img
                                    src={URL.createObjectURL(file.file)}
                                    alt="uploaded"
                                    className="w-full h-full object-cover"
                                    />
                                    <button
                                    className="absolute top-[5px] right-[5px] bg-[#00000040] text-white w-[25px] h-[25px] flex items-center justify-center rounded-[50%]"
                                    onClick={() => handleRemoveImage(file.id)}
                                    >
                                        <FaTimes />
                                    </button> 
                                </>
                            ) : (
                                <>
                                    <video
                                    controls
                                    src={URL.createObjectURL(file.file)}
                                    className="w-full h-full"
                                    />
                                    <button
                                    className="absolute top-[5px] right-[5px] bg-[#00000040] text-white w-[25px] h-[25px] flex items-center justify-center rounded-[50%]"
                                    onClick={() => handleRemoveImage(file.id)}
                                    >
                                        <FaTimes />
                                    </button> 
                                </>
                            )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="video-thumbnails flex gap-3">
                { uploadedFiles.map( (file , index) => (
                    file.type === "video" && (
                    <div className="video-thumb relative" key={index}>
                        <label
                            className={`upload-status-video-thumb w-[150px] h-[150px] flex flex-col items-center justify-center cursor-pointer gap-[10px] transition-transform hover:!border-[#fd6729] hover:!text-[#fd6729]`}
                            style={{
                            border: "1px dashed #ddd",
                            backgroundColor: "#fbfbfb"
                            }}
                            htmlFor="upload-status-video-thumb"
                            >
                            <input
                            type="file"
                            id="upload-status-video-thumb"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) => handleVideoThumbUpload(index , e)}
                            />
                            <BsCameraFill size={18} />
                            <p className="text-center">Upload Thumbnail</p>
                        </label>
                        { thumbnails[index] && <img src={URL.createObjectURL(thumbnails[index])} className="absolute top-0 left-0 w-full h-full" alt="" /> }
                    </div>
                    )
                ))}
                </div>


              <div className="sell-product-form-item">
                <button className="button white">Cancel</button>
                <button className="button primary">Post Story</button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CreateStory;
