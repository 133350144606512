import React from 'react';
import { FaStar, FaMeteor, FaHeart, FaShareAlt, FaMapMarkerAlt } from 'react-icons/fa';
import FriendProfileImage from '../../user-profile-image/UserProfileImage';
import img from '../../../assests/about/gigs-01.jpg'
import { MdAdminPanelSettings } from 'react-icons/md';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
const Card = () => {
  return (
    <div className="gigs-grid">
      <div className="gigs-img">
        <a href="https://dreamgigs.dreamstechnologies.com/html/template/service-details.html">
            <img src={img} className="img-fluid" alt="Gigs" />
        </a>
        <div className="card-overlay-badge">
          <a href="https://dreamgigs.dreamstechnologies.com/html/template/service.html"><span className="badge bg-warning"><FaStar /> Featured</span></a>
          <a href="https://dreamgigs.dreamstechnologies.com/html/template/service.html"><span className="badge bg-danger"><FaMeteor /> Hot</span></a>
        </div>
        <div className="fav-selection">
          <a href="javascript:void(0);" className="fav-icon">
            <FaHeart />
          </a>
        </div>
        <div className="user-thumb">
          {/* <a href="https://dreamgigs.dreamstechnologies.com/html/template/user-profile.html">
          <FriendProfileImage/>
          </a> */}
          		<div className="after:content-[''] mt-[-90px] border-solid border-[1px] border-[#ff6728] after:bg-[#1df377] after:w-[9px] after:h-[9px] after:absolute after:top-0 after:-left-2 after:rounded-full relative w-[45px] h-[45px] rounded-full">
			<div className="relative w-full h-full rounded-full p-[1px] bg-white">
				<img
					src="/img/avatar/01.jpg"
					alt="Profile"
					className="w-full h-full rounded-full"
				/>
				<RiVerifiedBadgeFill
					className="absolute -bottom-1 -right-2"
					color={"#36e9f7"}
					size={16}
				/>
				<MdAdminPanelSettings
					className="absolute -top-1 -right-2"
					color={"#d7006a"}
					size={16}
				/>
				{/* <div className="relative w-[16px] h-[16px] -top-[10px] -left-3">
					<img
						src="/img/download.png"
						alt="Padge"
						className="absolute w-full h-full"
					/>
					<p className="absolute text-[10px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10 font-bold">
						12
					</p>
				</div> */}
			</div>
		</div>
        </div>
      </div>
      <div className="gigs-content">
        <div className="gigs-info">
          <a href="https://dreamgigs.dreamstechnologies.com/html/template/service-details.html" className="badge bg-primary-light">
            Programming &amp; Tech
          </a>
          <small>+1</small>
          <p className='flex items-baseline gap-1'><FaMapMarkerAlt /> Newyork</p>
        </div>
        <div className="gigs-title text-start">
          <h3>
            <a href="https://dreamgigs.dreamstechnologies.com/html/template/service-details.html">
              Embedded Android &amp; AOSP customizations
            </a>
          </h3>
        </div>
        <div className="star-rate text-start">
          <span><FaStar /> 5.0 (28 Reviews)</span>
        </div>
        <div className="gigs-card-footer">
          <div className="gigs-share">
            <a href="javascript:void(0);">
              <FaShareAlt />
            </a>
            <span className="badge">Delivery in 1 day</span>
          </div>
          <h5>$780</h5>
        </div>
      </div>
    </div>
  );
}

export default Card;
