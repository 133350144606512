import React from "react";
import "./UserProfilePage.css";
import { Link } from "react-router-dom";
import { MdAdminPanelSettings } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import styles from "./StyleComponents.module.css";

const ProfileHeader = () => {
	return (
		<>
			<div className="profile-header">
				<figure className="profile-header-cover liquid">
					{/* <img src="img/cover/01.jpg" alt="cover-01" /> */}
					<div
						className={`${styles.profileHeaderStyling} bg-[#fbfbfb] gap-2 w-full h-full duration-200 flex items-center justify-center flex-col border-dashed border-[1px] border-[#00dff0] hover:border-[#ff6728] rounded-t-[14px] relative`}
					>
						<div className="absolute top-[35%] max-[1365px]:top-[12.5%] flex items-center justify-center flex-col gap-[6px]">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M19,6 L19,4 L21,4 L21,6 L23,6 L23,8 L21,8 L21,10 L19,10 L19,8 L17,8 L17,6 L19,6 Z M6.93701956,5.8453758 C7.00786802,5.74688188 7.08655595,5.62630624 7.18689462,5.46372136 C7.24312129,5.37261385 7.44826978,5.03326386 7.48180256,4.97841198 C8.31078556,3.62238733 8.91339479,3 10,3 L15,3 L15,5 L10,5 C9.91327186,5 9.64050202,5.28172235 9.18819752,6.02158802 C9.15916322,6.06908141 8.95096113,6.41348258 8.88887147,6.51409025 C8.76591846,6.71331853 8.66374696,6.86987867 8.56061313,7.0132559 C8.1123689,7.63640757 7.66434207,8 7.0000003,8 L4,8 C3.44771525,8 3,8.44771525 3,9 L3,18 C3,18.5522847 3.44771525,19 4,19 L20,19 C20.5522847,19 21,18.5522847 21,18 L21,12 L23,12 L23,18 C23,19.6568542 21.6568542,21 20,21 L4,21 C2.34314575,21 1,19.6568542 1,18 L1,9 C1,7.34314575 2.34314575,6 4,6 L6.81619668,6 C6.84948949,5.96193949 6.89029794,5.91032846 6.93701956,5.8453758 Z M12,18 C9.23857625,18 7,15.7614237 7,13 C7,10.2385763 9.23857625,8 12,8 C14.7614237,8 17,10.2385763 17,13 C17,15.7614237 14.7614237,18 12,18 Z M12,16 C13.6568542,16 15,14.6568542 15,13 C15,11.3431458 13.6568542,10 12,10 C10.3431458,10 9,11.3431458 9,13 C9,14.6568542 10.3431458,16 12,16 Z"></path>
							</svg>
							<p className="text-[18px] font-medium">Add Cover Photo</p>
						</div>
						<input
							type="file"
							name="cover-photo"
							id="cover_photo"
							className="absolute top-0 left-0 z-20 cursor-pointer w-full h-full opacity-0"
						/>
					</div>
				</figure>

				<div className="profile-header-info">
					<div className="user-short-description big">
						<div className="user-Profile-image-container user-short-description-avatar user-avatar big">
							{/* <img
								src="/img/avatar/01.jpg"
								alt="User Profile"
								loading="lazy"
								className="user-Profile-image"
							/>
							<MdAdminPanelSettings
								color={"#d7006a"}
								size={28}
								className="user-profile-admin"
							/>
							<div className="user-profile-page-badge">
								<img src="/img/download.png" alt="badge" />
							</div> */}
							<div
								className={`${styles.profilePhotoStyling} relative w-full h-full z-40 flex items-center justify-center flex-col duration-200 border-dashed border-[1px] border-[#00dff0] hover:border-[#ff6728] rounded-full`}
							>
								<div className="absolute flex items-center justify-center flex-col gap-[6px]">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M19,6 L19,4 L21,4 L21,6 L23,6 L23,8 L21,8 L21,10 L19,10 L19,8 L17,8 L17,6 L19,6 Z M6.93701956,5.8453758 C7.00786802,5.74688188 7.08655595,5.62630624 7.18689462,5.46372136 C7.24312129,5.37261385 7.44826978,5.03326386 7.48180256,4.97841198 C8.31078556,3.62238733 8.91339479,3 10,3 L15,3 L15,5 L10,5 C9.91327186,5 9.64050202,5.28172235 9.18819752,6.02158802 C9.15916322,6.06908141 8.95096113,6.41348258 8.88887147,6.51409025 C8.76591846,6.71331853 8.66374696,6.86987867 8.56061313,7.0132559 C8.1123689,7.63640757 7.66434207,8 7.0000003,8 L4,8 C3.44771525,8 3,8.44771525 3,9 L3,18 C3,18.5522847 3.44771525,19 4,19 L20,19 C20.5522847,19 21,18.5522847 21,18 L21,12 L23,12 L23,18 C23,19.6568542 21.6568542,21 20,21 L4,21 C2.34314575,21 1,19.6568542 1,18 L1,9 C1,7.34314575 2.34314575,6 4,6 L6.81619668,6 C6.84948949,5.96193949 6.89029794,5.91032846 6.93701956,5.8453758 Z M12,18 C9.23857625,18 7,15.7614237 7,13 C7,10.2385763 9.23857625,8 12,8 C14.7614237,8 17,10.2385763 17,13 C17,15.7614237 14.7614237,18 12,18 Z M12,16 C13.6568542,16 15,14.6568542 15,13 C15,11.3431458 13.6568542,10 12,10 C10.3431458,10 9,11.3431458 9,13 C9,14.6568542 10.3431458,16 12,16 Z"></path>
									</svg>
									<p className="text-[15px] font-medium">
										Upload Profile Photo
									</p>
								</div>
								<input
									type="file"
									name="profile-photo"
									id="profile_photo"
									className="absolute top-0 left-0 z-50 cursor-pointer w-full h-full opacity-0"
								/>
							</div>
						</div>

						<p className="user-short-description-title user-profile-header-title ">
							<Link
								to="/user-profile-page"
								className="relative after:content-[''] after:w-[20px] after:h-[20px] after:rounded-full after:bg-[#1df377] after:absolute after:top-0 after:-left-6"
							>
								Marina Valentine
							</Link>
							<RiVerifiedBadgeFill size={24} color={"#36e9f7"} />
						</p>

						<div className="text-center flex items-center justify-center flex-col">
							<p className="user-short-description-text">Marketing Manager</p>
							<p className="user-short-description-text !mt-[2px]">
								Brandmarks
							</p>
						</div>
					</div>

					<div className="profile-header-social-links-wrap">
						<div
							id="profile-header-social-links-slider"
							className="profile-header-social-links profile-user-page"
						>
							<div className="profile-header-social-link">
								<a className="social-link facebook" href="#">
									<svg className="icon-facebook">
										<use xlinkHref="#svg-facebook"></use>
									</svg>
								</a>
							</div>

							<div className="profile-header-social-link">
								<a className="social-link twitter" href="#">
									<svg className="icon-twitter">
										<use xlinkHref="#svg-twitter"></use>
									</svg>
								</a>
							</div>

							<div className="profile-header-social-link">
								<a className="social-link instagram" href="#">
									<svg className="icon-instagram">
										<use xlinkHref="#svg-instagram"></use>
									</svg>
								</a>
							</div>

							<div className="profile-header-social-link">
								<a className="social-link twitch" href="#">
									<svg className="icon-twitch">
										<use xlinkHref="#svg-twitch"></use>
									</svg>
								</a>
							</div>

							<div className="profile-header-social-link">
								<a className="social-link youtube" href="#">
									<svg className="icon-youtube">
										<use xlinkHref="#svg-youtube"></use>
									</svg>
								</a>
							</div>

							<div className="profile-header-social-link">
								<a className="social-link patreon" href="#">
									<svg className="icon-patreon">
										<use xlinkHref="#svg-patreon"></use>
									</svg>
								</a>
							</div>

							<div className="profile-header-social-link">
								<a className="social-link discord" href="#">
									<svg className="icon-discord">
										<use xlinkHref="#svg-discord"></use>
									</svg>
								</a>
							</div>
						</div>
					</div>

					<div className="user-stats">
						<div className="user-stat big">
							<p className="user-stat-title">930</p>

							<p className="user-stat-text">Posts</p>
						</div>

						<div className="user-stat big">
							<p className="user-stat-title">82</p>

							<p className="user-stat-text">Flokkers</p>
						</div>

						<div className="user-stat big">
							<p className="user-stat-title">5.7k</p>

							<p className="user-stat-text">Flokks</p>
						</div>

						<div className="user-stat big">
							<img
								className="user-stat-image"
								src="img/flag/usa.png"
								alt="flag-usa"
							/>

							<p className="user-stat-text">usa</p>
						</div>
					</div>

					<div className="profile-header-info-actions">
						<p className="profile-header-info-action button secondary">
							<span className="hide-text-mobile">Add</span> Friend +
						</p>

						<Link to={"/user-dashboard"} className="z-50">
							<p className="profile-header-info-action button primary">
								<span className="hide-text-mobile">Edit</span> Profile
							</p>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileHeader;
