import { useEffect, useRef, useState } from "react";
import { BiCamera, BiDollar } from "react-icons/bi";
import {
  FaChevronDown,
  FaChevronLeft,
  FaPen,
  FaReply,
  FaStar,
  FaUpload,
} from "react-icons/fa6";
import { PiQuestionMarkFill } from "react-icons/pi";
import LatestReviewsReaction from "./LatestReviewsReaction";
import { RiDeleteBin5Fill, RiVerifiedBadgeFill } from "react-icons/ri";
import { MdAdminPanelSettings } from "react-icons/md";
import { BsFlagFill } from "react-icons/bs";

const LatestReviewsCard = ({ order, withReply, external, images }) => {
  const [isViewMore, setIsViewMore] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);

  const cardTopRef = useRef(null);
  const mainCard = useRef(null);

  useEffect(() => {
    if (isViewMore) {
      setCardHeight(mainCard.current.clientHeight + 30);
    } else {
      setCardHeight(cardTopRef.current.clientHeight + 30);
    }
  }, [isViewMore]);

  return (
    <div
      className="latest-reviews-card py-[15px] px-3 bg-white rounded-xl shadow-sm shadow-gray-800/10 overflow-hidden transition-all duration-300 ease-in-out"
      style={{
        height: `${cardHeight}px`,
      }}
    >
      <div ref={mainCard}>
        <div
          className="flex justify-between items-center gap-4 flex-wrap xl:flex-nowrap"
          ref={cardTopRef}
        >
          <div className="flex items-center flex-1 gap-2 md:gap-3 xl:flex-none">
            <div className="profile-image w-20">
              <div className="profile-image-content">
                <img src="/img/avatar/01.jpg" alt="Profile" />
                <RiVerifiedBadgeFill
                  className="nav-small-verify"
                  color={"#36e9f7"}
                  size={16}
                />
                <MdAdminPanelSettings
                  className="nav-small-admin"
                  color={"#d7006a"}
                  size={16}
                />
                <div className="badge-container-nav">
                  <img
                    src="/img/download.png"
                    alt="Padge"
                    className="!max-w-fit"
                  />
                  <p className="badge-number-nav">12</p>
                </div>
              </div>
            </div>

            <div>
              <h5 className="text-base font-bold text-gray-800 sm:text-lg md:text-xl">
                John Doe - 23 Reviews
              </h5>
              <p className="text-sm text-gray-700 sm:text-base">
                Marketing Manager - Topline Tech
              </p>
            </div>
          </div>

          <div className="w-full flex items-center justify-center gap-2 order-3 xl:!order-none xl:flex-1 xl:w-fit">
            <img
              src="/img/feelings/happy.png"
              alt="happy"
              loading="lazy"
              className="w-16 h-16 object-contain"
            />

            <span className="h-8 w-px bg-gray-200 shrink-0" />

            <div className="flex gap-1">
              <FaStar className="text-yellow-400 w-3 h-3 sm:w-4 sm:h-4" />
              <FaStar className="text-yellow-400 w-3 h-3 sm:w-4 sm:h-4" />
              <FaStar className="text-yellow-400 w-3 h-3 sm:w-4 sm:h-4" />
              <FaStar className="text-yellow-400 w-3 h-3 sm:w-4 sm:h-4" />
              <FaStar className="text-yellow-400 w-3 h-3 sm:w-4 sm:h-4" />
            </div>

            <span className="h-8 w-px bg-gray-200 shrink-0" />

            <div className="flex">
              <BiDollar className="text-[#55d3ee] w-6 h-6" />
              <BiDollar className="text-[#55d3ee] w-6 h-6" />
              <BiDollar className="text-gray-300 w-6 h-6" />
            </div>

            <span className="h-8 w-px bg-gray-200 shrink-0" />

            <p className="text-sm font-semibold text-gray-500 line-clamp-1 sm:text-base">
              11 weeks ago
            </p>
          </div>

          <div className="flex items-baseline gap-3">
            <div className="items-center gap-1 hidden md:flex">
              <h6 className="text-sm font-bold text-gray-700 sm:text-base">
                {external ? "EXTERNAL REVIEW" : "VERIFIED REVIEW"}
              </h6>

              <PiQuestionMarkFill className="text-gray-500" />
            </div>

            <FaUpload className="text-gray-400" />
          </div>

          <button
            className={`flex-shrink-0 flex mb-auto text-[#ff6728] w-8 h-8 rounded-full -m-1
            items-center justify-center transition duration-300 bg-gray-50 hover:bg-gray-200 
            ${isViewMore ? "rotate-90" : ""}`}
            onClick={() => setIsViewMore((prev) => !prev)}
          >
            <FaChevronLeft />
          </button>
        </div>

        <div className="latest-reviews-card-bottom relative flex !gap-6 mt-6 pb-8 flex-col md:pb-2 xl:ps-20 xl:pe-10 lg:flex-row">
          <div className="shrink-0 flex flex-col-reverse items-center gap-4 lg:flex-col lg:w-56 xl:ps-2">
            <div className="w-48 min-h-40 lg:w-56 lg:min-h-48">
              {!images ? (
                <div className="w-full h-full rounded-xl bg-gray-200 flex justify-center items-center flex-col">
                  <BiCamera className="text-gray-500 w-12 h-12" />

                  <p className="text-xl font-bold text-gray-500 text-center">
                    No Photos Added
                  </p>
                </div>
              ) : images.length > 1 ? (
                <div className="grid !grid-cols-2 !mt-0 gap-2 w-full h-full">
                  {images.map((img, index) => (
                    <div
                      className="rounded-lg bg-gray-200 aspect-square"
                      key={index}
                    >
                      <img
                        src={img}
                        alt="review img"
                        loading="lazy"
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  ))}

                  {images.length < 4 &&
                    new Array(4 - images.length)
                      .fill(0)
                      .map((_, i) => (
                        <div
                          className="rounded-lg bg-gray-200 aspect-square"
                          key={i}
                        />
                      ))}
                </div>
              ) : (
                <img
                  src={images[0]}
                  alt="review comment img"
                  loading="lazy"
                  className="w-full h-full rounded-xl object-cover"
                />
              )}
            </div>

            {order && (
              <>
                <span className="h-px w-full bg-gray-200 lg:hidden" />

                <div className="flex items-center gap-3">
                  <div className="w-20 h-20 shrink-0">
                    <img
                      src={order.img}
                      alt="product-img"
                      loading="lazy"
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>

                  <div className="flex flex-col">
                    <h6 className="text-base font-bold text-gray-800">
                      {order.name}
                    </h6>

                    <p
                      className="text-sm text-gray-500 line-clamp-2 mb-1"
                      dangerouslySetInnerHTML={{ __html: order.description }}
                    />

                    <p className="font-bold text-green-600">{order.price}</p>
                  </div>
                </div>

                <span className="h-px w-full bg-gray-200 lg:hidden" />
              </>
            )}
          </div>

          <div className="flex flex-col !gap-6 flex-1">
            <div className="flex flex-col gap-3">
              <h3 className="font-bold text-2xl text-gray-900">
                Amazing website designers!
              </h3>

              <p className="text-base text-gray-700">
                I had a great experience with Topline Tech. They are very
                professional and responsive. They have a great team of website
                designers. I would highly recommend them.
              </p>

              <LatestReviewsReaction />
            </div>

            {withReply && (
              <div className="flex items-start gap-3 ps-6 lg:ps-3">
                <div className="w-20 h-20 hidden lg:block">
                  <img
                    src="/icon/arrow-right.png"
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>

                <div className="flex gap-3">
                  <span className="w-1 h-auto my-2 bg-green-600"></span>

                  <div className="flex flex-col gap-2">
                    <h3 className="font-bold text-xl text-gray-900">
                      Amazing website designers!
                    </h3>

                    <p className="text-sm text-gray-700">
                      I had a great experience with Topline Tech. They are very
                      professional and responsive. They have a great team of
                      website designers. I would highly recommend them.
                    </p>

                    <LatestReviewsReaction small />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="absolute bottom-0 right-0 flex flex-col items-end gap-3">
            <button className="flex w-fit text-gray-500 hover:text-gray-700">
              <BsFlagFill className="text-xl" />
            </button>

            {withReply ? (
              <div className="flex items-center gap-3">
                <button className="flex items-center gap-2 font-bold text-lg w-fit text-gray-500 hover:text-gray-700">
                  <RiDeleteBin5Fill />

                  <span className="leading-none">Delete</span>
                </button>

                <button className="flex items-center gap-2 font-bold text-lg w-fit text-gray-500 hover:text-gray-700">
                  <FaPen />

                  <span className="leading-none">Edit</span>
                </button>
              </div>
            ) : (
              <button className="flex items-center gap-2 font-bold text-lg w-fit text-gray-500 hover:text-gray-700">
                <FaReply />

                <span className="leading-none">Reply</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestReviewsCard;
