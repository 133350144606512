import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../../assests/about/company-logo-01.svg'
import img2 from '../../assests/about/company-logo-02.svg'
import img3 from '../../assests/about/company-logo-05.svg'
import img4 from '../../assests/about/company-logo-06.svg'
const ClientSliderSec = () => {
  return (
    <div className="client-slider-sec responsive-max-width-flokky">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-header aos" data-aos="fade-up">
              <p className='client-slider-sec-title'>Trusted by 300+ Clients around the globe</p>
            </div>
            <Swiper
              spaceBetween={10}
              slidesPerView={4}
              loop={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide>
                <div className="client-logo">
                  <img src={img1} alt="client logo 1" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-logo">
                  <img src={img2} alt="client logo 2" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-logo">
                  <img src={img3} alt="client logo 3" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-logo">
                  <img src={img4} alt="client logo 4" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-logo">
                  <img src={img2} alt="client logo 5" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-logo">
                  <img src={img1} alt="client logo 6" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSliderSec;
