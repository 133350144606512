import React from "react";
import FormSwitch from "../../../ui/form-switch/FormSwitch";

const EmailSettings = () => {
	return (
		<form className="form flex items-start justify-between !flex-row max-[900px]:!flex-col">
			<div className="flex-1 flex items-start justify-start flex-col">
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone liked my posts</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone disliked my posts</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone commented on my posts</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone shared on my posts</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone followed me</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone liked my business</p>
				</div>
			</div>
			<div className="flex-1 flex items-start justify-start flex-col">
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone visited my profile</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone mentioned me </p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone joined my communities</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">
						Someone accepted my friend/follow request
					</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone posted on my timeline</p>
				</div>
				<div className="flex items-center justify-start gap-2 my-4">
					<FormSwitch />
					<p className="font-medium ">Someone send me a message</p>
				</div>
			</div>
		</form>
	);
};

export default EmailSettings;
