// src/Weather.js
import React, { useEffect, useState } from 'react';
import './Weather.css';
import { MdLocationOn } from 'react-icons/md';

const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [forecastData, setForecastData] = useState(null);

  // Function to get the current date and time in the Africa/Cairo timezone
  const getCurrentDate = () => {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('en-GB', {
      timeZone: 'Africa/Cairo',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return formatter.format(date);
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const weatherResponse = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=30.0444&lon=31.2357&units=metric&appid=866cc7ea5ea5c03ae411db6b529bef8e`
        );
        if (!weatherResponse.ok) {
          throw new Error('Network response was not ok');
        }
        const weatherData = await weatherResponse.json();
        setWeatherData(weatherData);

        const forecastResponse = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?lat=30.0444&lon=31.2357&units=metric&appid=866cc7ea5ea5c03ae411db6b529bef8e`
        );
        if (!forecastResponse.ok) {
          throw new Error('Network response was not ok');
        }
        const forecastData = await forecastResponse.json();
        setForecastData(forecastData);
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };

    fetchWeatherData();

    // Update the date every minute to ensure it stays current
    const interval = setInterval(() => {
      setCurrentDate(getCurrentDate());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (!weatherData) {
    return <div>Loading...</div>;
  }

  const { weather, main,name } = weatherData;
  const weatherIcon = weather[0].icon;
  const weatherDescription = weather[0].description;
  const currentTemp = main.temp;

  // Extract forecast for the next 4 days (filtering the forecast to get daily data)
  const forecast = forecastData?.list.filter((entry) => entry.dt_txt.includes("12:00:00")).slice(0, 4);

  // console.log(weatherData)

  return (
    <div className="wow_weather">
      <div className="wow_weather_date">{currentDate}</div>
      <div className="wow_weather_timezone"> <MdLocationOn className='inline' /> Africa/{name}</div>
      <div className="wow_weather_icon">
        {/* <img src={`http://openweathermap.org/img/wn/${weatherIcon}@2x.png`} alt="weather icon" /> */}
        <img src="/img/weatherIcons/sunny.png" alt="weather icon" />
      </div>
      <div className="wow_weather_description">{weatherDescription}</div>
      <div className="wow_weather_current">{Math.floor(currentTemp)}°C</div>
      <div className="wow_weather_forecast">
        {forecast?.map((day, index) => (
          <>
            {index == 0 && 
            <div key={100} className='pt-[30px] text-[18px]'>
              <p className='text-white mb-[19px]'>L</p>
              <p className='text-white'>H</p>
            </div>
            }
            <div key={index} className="fivedays text-center text-[18px] font-[500]">

              <div className='text-[16px] mb-2 text-center text-uppercase'>{new Date(day?.dt * 1000).toLocaleDateString('en-US', { weekday: 'long' }).slice(0,3)}</div>

              <div className='fivedays-temp px-[12px]'>
                <p className='temp-max'><span>{Math.floor(day.main.temp_max)}°</span></p> 
                <p>{Math.floor(day.main.temp_min)}°</p>
              </div>

            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Weather;
