import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { setIsFriendsVisible } from '../../../../app/feature/outletSlice';
import FriendDropDownItem from './FriendDropDownItem';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setSelectedForm } from '../../../../app/feature/userDashboard';

const FriendsDropDown = () => {
    const isFriendsVisible = useSelector(
        (state) => state.outlet.isFriendsVisible
    );
    const dispatch = useDispatch()
    const friendsDropRef = useRef(null)
    const handleClickOutside = (event) => {
        if (friendsDropRef.current && !friendsDropRef.current.contains(event.target)) {
            dispatch(setIsFriendsVisible({ value: false }));
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);
    return (
        <div className="action-list-item-wrap" ref={friendsDropRef}>
            <div className={`action-list-item header-dropdown-trigger ${isFriendsVisible ? "active" : ""
                }`}
                onClick={() => {
                    dispatch(setIsFriendsVisible({ value: !isFriendsVisible }));
                    // dispatch(setIsMessagesVisible({ value: false }));
                    // dispatch(setIsNotificationsVisible({ value: false }));
                    // dispatch(setIsSettingsVisible({ value: false }));
                    // dispatch(setIsCartVisible({ value: false }));
                }}
            >
                <svg className="action-list-item-icon icon-friend">
                    <use xlinkHref="#svg-friend"></use>
                </svg>
            </div>

            <div className="dropdown-box header-dropdown"
                style={{
                    position: "absolute",
                    zIndex: "9999",
                    top: "64px",
                    right: "6px",
                    opacity: `${isFriendsVisible ? "1" : "0"}`,
                    visibility: `${isFriendsVisible ? "visible" : "hidden"}`,
                    transform: `translate(0px, ${isFriendsVisible ? "0px" : "-40px"
                        })`,
                    transition:
                        "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
                }}
            >
                <div className="dropdown-box-header">
                    <p className="dropdown-box-header-title">Friend Requests</p>

                    <div className="dropdown-box-header-actions">
                        <p className="dropdown-box-header-action">Find Friends</p>

                        <p className="dropdown-box-header-action">Settings</p>
                    </div>
                </div>

                <div className="dropdown-box-list no-hover" data-simplebar>

                    <FriendDropDownItem />
                    <FriendDropDownItem />
                    <FriendDropDownItem />

                </div>

                <Link className="dropdown-box-button primary"
                    to="/user-dashboard"
                    onClick={() => {
                        dispatch(setSelectedForm({ value: "Connection Requests" }));
                    }}
                >
                    View all Requests
                </Link>
            </div>
        </div>
    )
}

export default FriendsDropDown