import React, { useEffect } from "react";
import AdsBanner from "../../components/AdsBanner/AdsBanner";
import AccountSettingSidebar from "../../components/user-dashboard/account-setting-sidebar/AccountSettingSidebar";
import ProfileForm from "../../components/user-dashboard/forms/ProfileInfo/ProfileForm";
import { useSelector } from "react-redux";
import SocialLinks from "../../components/user-dashboard/forms/Connectivity/SocialLinks";
import NotificationsSettings from "../../components/user-dashboard/forms/ProfileInfo/NotificationsSettings";
import IndustriesForm from "../../components/user-dashboard/forms/Experince/IndustriesForm";
import DeleteAccount from "../../components/user-dashboard/forms/Account/DeleteAccount";
import ChangePassword from "../../components/user-dashboard/forms/Account/ChangePassword";
import TwoFactorAuthentication from "../../components/user-dashboard/forms/Account/TwoFactorAuthentication";
import PrivacyForm from "../../components/user-dashboard/forms/Account/PrivacyForm";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";
import Affiliations from "../../components/user-dashboard/forms/AffiliationsForm/Affiliations";
import WorklExperienceForm from "../../components/user-dashboard/forms/Experince/WorklExperienceForm";
import EducationForm from "../../components/user-dashboard/forms/Experince/EducationForm";
import SkillsForm from "../../components/user-dashboard/forms/Experince/SkillsForm";
import InterestsForm from "../../components/user-dashboard/forms/Connectivity/InterestsForm";
import MyInformationFrom from "../../components/user-dashboard/forms/Account/MyInformation";
import MyEarnings from "../../components/user-dashboard/forms/Earnings/MyEarnings";
import MyPoints from "../../components/user-dashboard/forms/Earnings/MyPoints";
import WalletCredit from "../../components/user-dashboard/forms/Earnings/WalletCredit";
import BlockedUsers from "../../components/user-dashboard/forms/Account/BlockedUsers";
import InvitationLinks from "../../components/user-dashboard/forms/Connectivity/InvitationLinks";
import MyProducts from "../../components/user-dashboard/forms/Earnings/MyProducts";
import Purchased from "../../components/user-dashboard/forms/Earnings/Purchased";
import ConnectionRequests from "../../components/user-dashboard/forms/Connectivity/ConnectionRequests";
import Notifications from "../../components/user-dashboard/forms/Connectivity/Notifications";
import ManageCommunities from "../../components/user-dashboard/forms/Communities/ManageCommunities";
import JoinedCommunities from "../../components/user-dashboard/forms/Communities/JoinedCommunities";
import Invitations from "../../components/user-dashboard/forms/Communities/Invitations";
import { setSelectedForm } from "../../app/feature/userDashboard";
import { useDispatch } from "react-redux";

const bannersImages = [
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
];

const UserDashboard = () => {
	const dispatch = useDispatch();
	const selectedForm = useSelector((state) => state.userDashboard.selectedForm);

	function getStateFromUrl() {
		const params = new URLSearchParams(window.location.search);
		const state = params.get('state');
		if (state) {
			return JSON.parse(decodeURIComponent(state));
		}
		return null;
	}

	useEffect(() => {
		const state = getStateFromUrl();
		if (state) {
			dispatch(setSelectedForm({ value: state.data }));
		}
	}, [dispatch])
	return (
		<>
			{window.innerWidth > 680 && <AdsBanner bannersImages={bannersImages} />}

			<WrapInCenter noPaddingBottom={window.innerWidth <= 680 ? true : false} noPaddingTop={window.innerWidth <= 680 ? false : true}>
				<div className="grid grid-3-9 relative overflow-hidden">
					<AccountSettingSidebar />
					{/* PROFILE INFO */}
					{selectedForm === "Profile Info" && <ProfileForm />}

					{/* Connectivity */}
					{selectedForm === "Affiliations" && <Affiliations />}
					{selectedForm === "Interests" && <InterestsForm />}
					{selectedForm === "Notifications" && <Notifications />}
					{selectedForm === "Connection Requests" && <ConnectionRequests />}
					{selectedForm === "Social Links" && <SocialLinks />}
					{selectedForm === "Invitation Links" && <InvitationLinks />}

					{/* Experince */}
					{selectedForm === "Work Experience" && <WorklExperienceForm />}
					{selectedForm === "Industries" && <IndustriesForm />}
					{selectedForm === "Education" && <EducationForm />}
					{selectedForm === "Skills" && <SkillsForm />}

					{/* Account */}
					{selectedForm === "My Information" && <MyInformationFrom />}
					{/* {selectedForm === "General Settings" && <GeneralSettings />} */}
					{selectedForm === "Notifications Settings" && <NotificationsSettings />}
					{selectedForm === "Privacy" && <PrivacyForm />}
					{selectedForm === "Change Password" && <ChangePassword />}
					{selectedForm === "Two Factor Authentication" && <TwoFactorAuthentication />}
					{selectedForm === "Delete Account" && <DeleteAccount />}
					{selectedForm === "Blocked Users" && <BlockedUsers />}

					{/* Groups */}
					{selectedForm === "Manage Communtities" && <ManageCommunities />}
					{selectedForm === "Joined Communtities" && <JoinedCommunities />}
					{selectedForm === "Invitations" && <Invitations />}

					{/* Earnings */}
					{selectedForm === "My Earnings" && <MyEarnings />}
					{selectedForm === "My Points" && <MyPoints />}
					{selectedForm === "Wallet & Credit" && <WalletCredit />}
					{selectedForm === "My Products" && <MyProducts />}
					{selectedForm === "Purchased" && <Purchased />}

				</div>
			</WrapInCenter>

			{/* <p className="!block md:!hidden button primary fixed bottom-[150px] -right-[76px] z-50 w-[200px] -rotate-90">Save Changes!</p> */}
		</>
	);
};

export default UserDashboard;
