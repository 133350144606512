import React from "react";
import Pending from "../Status/Pending";
import InputWithStaticData from "../../../ui/InputWithStaticData/InputWithStaticData";
import Rejected from "../Status/Rejected";

const PendingAffiliations = () => {
	return (
		<div className="widget-box mt-4">
			<p className="widget-box-title">Pending Affiliations</p>

			<div className="widget-box-content">
				<form className="form">
					<div className="flex items-center justify-start gap-4">
						<InputWithStaticData
							id={"high_school"}
							label={""}
							name={"high-school"}
							type={"text"}
							value={"High School - XYZ"}
						/>
						<Pending />
					</div>
					<div className="flex items-center justify-start gap-4 mt-5">
						<InputWithStaticData
							id={"business_auc"}
							label={""}
							name={"business-auc"}
							type={"text"}
							value={"Business - American University of Cairo"}
						/>
						<Rejected />
						<p className="text-[16px] font-semibold text-center cursor-pointer">
							View Reason
						</p>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PendingAffiliations;
