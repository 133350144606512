import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { IoLayersOutline, IoReload } from "react-icons/io5";
import { Link } from 'react-router-dom';

import "swiper/css";
import "swiper/css/pagination";
// import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import UserProfileImage from "../../user-profile-image/UserProfileImage"
import { useDispatch } from "react-redux";
import { setIsFriendStoryVisible, setIsUserStoryVisible } from "../../../app/feature/outletSlice";
import CreateStory from "./CreateStory";

const Stories = () => {
  const dispatch = useDispatch()
  const [isOpend, setIsOpened] = useState(false)
  return (
    <>
      <div className="stories">
        <div className="stories-title">
          <h3 className="text-[1.3rem]">Stories</h3>
          <span className="text-[#3e3f5e] cursor-pointer">
            <IoReload />
          </span>
        </div>
        <Swiper
          className="stories-swiper"
          slidesPerView={2}
          spaceBetween={15}
          breakpoints={{
            320: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            // 992: {
            //   slidesPerView: 2,
            //   spaceBetween: 15,
            // },
          }}
        >
          <SwiperSlide>
            <div
              className="story"
              onClick={() => setIsOpened(true)}
              style={{ backgroundImage: "url(/img/cover/03.jpg)" }}
            >
              <a className="add-story" href="#">
                <FaPlus />
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="story friend-story"
              style={{ backgroundImage: "url(/img/cover/04.jpg)" }}
              onClick={() => { dispatch(setIsUserStoryVisible({ value: true })) }}
            >
              <div className="user-status">
                <Link className="user-status-avatar" to="/user-profile-page">
                  <UserProfileImage />
                </Link>
                <p className="user-status-title text-start">
                  <a className="bold !text-[#fff]" href="profile-timeline.html">
                    Marina Valentine{" "}
                  </a>
                </p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="story friend-story"
              style={{ backgroundImage: "url(/img/cover/05.jpg)" }}
              onClick={() => { dispatch(setIsFriendStoryVisible({ value: true })) }}
            >
              <div className="user-status">
                <Link className="user-status-avatar" to="/user-profile-page">
                  <UserProfileImage />
                </Link>
                <p className="user-status-title text-start">
                  <a className="bold !text-[#fff]" href="profile-timeline.html">
                    Marina Valentine{" "}
                  </a>
                </p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className="story friend-story"
              style={{ backgroundImage: "url(/img/cover/03.jpg)" }}
              onClick={() => { dispatch(setIsFriendStoryVisible({ value: true })) }}
            >
              <div className="user-status">
                <Link className="user-status-avatar" to="/user-profile-page">
                  <UserProfileImage />
                </Link>
                <p className="user-status-title text-start">
                  <a className="bold !text-[#fff]" href="profile-timeline.html">
                    Marina Valentine{" "}
                  </a>
                </p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="min-h-[200px]">
            <div>
              <a href="/stories" className="font-[600] text-xl">
                <IoLayersOutline size={25} className="mx-auto" />
                See all
              </a>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>
      <CreateStory isOpened={isOpend} setIsOpened={setIsOpened} />
    </>
  );
};

export default Stories;
