import { useState } from "react";
import "../QuickPost.css";
import { BsCameraVideo } from "react-icons/bs";
import { FaSortDown } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

const QuickPostVideoPreview = ({ videos, setVideos, setPostOptionType,isThumbVisible,setIsThumbVisible }) => {
  const videosArray = Array.from(videos);
  const [img , setImg] = useState('')

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImg(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="video-section">
      <div className="post-option-header">
        <h3 className="post-option-header-title">Upload Video</h3>

        <button className="ml-auto mr-[20px] w-fit text-[#3e3f5e] flex gap-[5px]"
        onClick={ () => setIsThumbVisible( prev => !prev ) }
        >
          Custom Thumbnail <FaSortDown /> 
        </button>

        <button
          className="post-option-cancel-btn"
          onClick={() => {
            setVideos([]);
            setPostOptionType("normal");
          }}
        >
          <MdClose />
        </button>
      </div>

      <div
          className="progress-stat-bar"
          style={{
            width: "100%",
            height: "4px",
            position: "relative",
            marginBottom:22,
            paddingRight:35
          }}
        >
          <div
            className="bar"
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#ddd",
            }}
          ></div>
          <div
            className="colored-bar"
            style={{
              width: `50%`,
              height: "100%",
              position: "absolute",
              top: 0,
              background: "linear-gradient(90deg, #fd6729, orange)",
            }}
          ></div>

          <p className="absolute top-[-5px] right-0 text-[14px]">50%</p>
      </div>

      <div className="video-content">
        {videosArray[0].name}
      </div>

      {
        isThumbVisible && 

        <div className="upload-video-thumbnail">
            <label htmlFor="video-thumb">
              {!img && 'Upload Image' }
            </label>
            <input id="video-thumb" type="file" accept="image/*" onChange={handleInputChange} />
            {img && <img src={img} alt="Thumbnail" />}
        </div>
      }

      
    </div>
  );
};

export default QuickPostVideoPreview;

























{/* <div className="img-preview-container">
        {videosArray.map((video, videoIndex) => (
          <div className="img-preview-item" key={videoIndex}>
            <video controls width="120" height="120">
              <source src={URL.createObjectURL(video)} type={video.type} />
            </video>

            <button
              className="img-preview-close"
              onClick={() => {
                setVideos(
                  videosArray.filter((_, index) => index !== videoIndex)
                );
              }}
            >
              <MdClose />
            </button>
          </div>
        ))}

        <div className="img-preview-upload">
          <label htmlFor="video-upload">
            <input
              type="file"
              id="video-upload"
              accept="video/*"
              multiple
              onChange={(e) => {
                setVideos([...videos, ...e.target.files]);
              }}
            />
            <BsCameraVideo />
          </label>
        </div>
      </div> */}