import "swiper/css";
import "swiper/css/pagination";

import "./AdsBanner.css";
import WrapInCenter from "../WrapInCenter/WrapInCenter";

import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const AdsBanner = ({ bannersImages }) => {
	return (
		<WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
			<Swiper
				spaceBetween={30}
				centeredSlides={true}
				autoplay={{
					delay: 7500,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
				}}
				modules={[Pagination, Autoplay]}
				className={`ads-swiper !h-[100px] md:!h-[150px] !mt-[30px] md:!mt-0 bg-white rounded-[12px] shadow-[0_0_40px_0_rgba(94,92,154,0.06)]`}
			>
				{bannersImages.map((banner, index) => (
					<SwiperSlide key={index} className="">
						<div className="banner-container !w-[970px] !h-[90px]">
							<img src={banner} alt="ads banner" loading="lazy" />
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</WrapInCenter>
	);
};

export default AdsBanner;
