import React, { useEffect, useState } from "react";
import VerifiedAffiliations from "./VerifiedAffiliations";
import PendingAffiliations from "./PendingAffiliations";
import AddAffiliation from "./AddAffiliation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIsAddingAffiliations, setShowContent } from "../../../../app/feature/userDashboard";

const Affiliations = () => {
	const isAdding = useSelector(
		(state) => state.userDashboard.isAddingAffiliations
	);
	const { showContent } = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	// const [isAdding, setIsAdding] = useState(false);
	const [form, setForm] = useState("");

	useEffect(() => {
		dispatch(setIsAddingAffiliations({ value: false }));
	}, [])

	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Connectivity</p>
					<h2 className="section-title">Affiliations</h2>
				</div>
			</div>

			{isAdding && (
				<div className="widget-box mt-[16px]">
					<p className="widget-box-title">Add Affiliation</p>

					<div className="widget-box-content">
						<form className="form">
							<div className="flex items-center justify-center !gap-8 max-[680px]:!flex-col max-[680px]:!gap-4">
								<div
									className="upload-box shadow-sm"
									onClick={() => {
										setForm("Educational");
									}}
								>
									<svg className="upload-box-icon icon-members">
										<use xlinkHref="#svg-members"></use>
									</svg>

									<p className="upload-box-title">Educational Institute</p>
								</div>

								<div
									className="upload-box shadow-sm"
									onClick={() => {
										setForm("Expat");
									}}
								>
									<svg className="upload-box-icon icon-members">
										<use xlinkHref="#svg-members"></use>
									</svg>

									<p className="upload-box-title">Expat Community</p>
								</div>
							</div>
						</form>
						<AddAffiliation form={form} setForm={setForm} />
					</div>
				</div>
			)}

			{!isAdding && (
				<>
					<VerifiedAffiliations />
					<PendingAffiliations />
				</>
			)}


			{/* <div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div> */}
		</div>
	);
};

export default Affiliations;
