import React, { useState } from "react";
import UserProfileImage from "../user-profile-image/UserProfileImage";

const FriendsRecommendation = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState("");

	return (
		<div className="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p className="widget-box-title">
				Friends <span className="highlighted">82</span>
			</p>

			<div className="widget-box-content">
				<div className="user-status-list">
					<div className="user-status request-small">
						<a className="user-status-avatar" href="profile-timeline.html">
							<UserProfileImage />
						</a>

						<div className="ml-1">
							<p className="user-status-title">
								<a className="bold" href="profile-timeline.html">
									Sarah Diamond
								</a>
							</p>

							<p className="user-status-text small !w-[150%] !mt-1">
								Marketing Manager - Brandmarks
							</p>
						</div>

						{/* <div className="action-request-list">
							<div className="action-request accept">
								<svg className="action-request-icon icon-add-friend">
									<use xlinkHref="#svg-add-friend"></use>
								</svg>
							</div>
						</div> */}
					</div>

					<div className="user-status request-small">
						<a className="user-status-avatar" href="profile-timeline.html">
							<UserProfileImage />
						</a>

						<div className="ml-1">
							<p className="user-status-title">
								<a className="bold" href="profile-timeline.html">
									Nick Grissom
								</a>
							</p>

							<p className="user-status-text small !w-[150%] !mt-1">
								Marketing Manager - Brandmarks
							</p>
						</div>

						{/* <div className="action-request-list">
							<div className="action-request accept">
								<svg className="action-request-icon icon-add-friend">
									<use xlinkHref="#svg-add-friend"></use>
								</svg>
							</div>
						</div> */}
					</div>

					<div className="user-status request-small">
						<a className="user-status-avatar" href="profile-timeline.html">
							<UserProfileImage />
						</a>

						<div className="ml-1">
							<p className="user-status-title">
								<a className="bold" href="profile-timeline.html">
									Destroy Dex
								</a>
							</p>

							<p className="user-status-text small !w-[150%] !mt-1">
								Marketing Manager - Brandmarks
							</p>
						</div>

						{/* <div className="action-request-list">
							<div className="action-request accept">
								<svg className="action-request-icon icon-add-friend">
									<use xlinkHref="#svg-add-friend"></use>
								</svg>
							</div>
						</div> */}
					</div>

					<div className="user-status request-small">
						<a className="user-status-avatar" href="profile-timeline.html">
							<UserProfileImage />
						</a>

						<div className="ml-1">
							<p className="user-status-title">
								<a className="bold" href="profile-timeline.html">
									Neko Bebop
								</a>
							</p>

							<p className="user-status-text small !w-[150%] !mt-1">
								Marketing Manager - Brandmarks
							</p>
						</div>

						{/* <div className="action-request-list">
							<div className="action-request decline">
								<svg className="action-request-icon icon-remove-friend">
									<use xlinkHref="#svg-remove-friend"></use>
								</svg>
							</div>
						</div> */}
					</div>

					<div className="user-status request-small">
						<a className="user-status-avatar" href="profile-timeline.html">
							<UserProfileImage />
						</a>

						<div className="ml-1">
							<p className="user-status-title">
								<a className="bold" href="profile-timeline.html">
									The Green Goo
								</a>
							</p>

							<p className="user-status-text small !w-[150%] !mt-1">
								Marketing Manager - Brandmarks
							</p>
						</div>

						{/* <div className="action-request-list">
							<div className="action-request accept">
								<svg className="action-request-icon icon-add-friend">
									<use xlinkHref="#svg-add-friend"></use>
								</svg>
							</div>
						</div> */}
					</div>
				</div>
			</div>

			<a className="widget-box-button button small secondary" href="">
				See all Friends
			</a>
		</div>
	);
};

export default FriendsRecommendation;
