import React, { useState } from "react";
// import FormInput from "../../ui/formInput/FormInput";
import Select from "../../ui/select/Select";
import "../Search.css";
import FormInputwithIcon from "../../ui/formInputWithSearchIcon/FormInputwithIcon";
import SummaryResults from "../SummaryResults/SummaryResults";
import FreelanceCard from "./FreelanceCard/FreelanceCard";

const countries = [
	{ id: 1, value: "Egypt" },
	{ id: 2, value: "United Arab Emirates" },
	{ id: 3, value: "Bahrain" },
	{ id: 4, value: "Saudi" },
];

const careerLevel = [
	{ id: 1, value: "Fresh Graduate" },
	{ id: 2, value: "Junior" },
	{ id: 3, value: "Mid0Level" },
	{ id: 4, value: "Senior" },
];

const initialItemsNumber = [
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
];

const JobsSearch = () => {
	const [isFromHome, setIsFromHome] = useState(false);
	const [isJobsVisible, setIsJobsVisible] = useState(true);
	const [selectedJobsField, setSelectedJobsField] = useState("Career");
	const [numberOfJobs, setNumberOfJobs] = useState([...initialItemsNumber]);
	return (
		<>
			<div
				className={`search-job-component common-for-dir-cat ${
					isJobsVisible
						? "common-for-dir-cat-active"
						: "common-for-dir-cat-close"
				}`}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
					className="title-search-category"
					onClick={() => {
						setIsJobsVisible((prev) => !prev);
					}}
				>
					<div className="search-title">
						<h4>Search Jobs</h4>
						<p>Find what you need quickly and easily</p>
					</div>

					<svg
						class="slider-control-icon icon-small-arrow"
						style={{
							transform: `rotate(${isJobsVisible ? "270" : "90"}deg)`,
							cursor: "pointer",
							marginTop: "-40px",
							fill: `${isJobsVisible ? "#ff6728" : ""}`,
						}}
					>
						<use xlinkHref="#svg-small-arrow"></use>
					</svg>
				</div>

				{isJobsVisible && (
					<div className="jobs-items">
						<p
							className={`${selectedJobsField === "Career" ? "active" : ""}`}
							onClick={() => {
								setSelectedJobsField("Career");
							}}
						>
							Career Jobs
						</p>
						<p
							className={`${selectedJobsField === "Freelance" ? "active" : ""}`}
							onClick={() => {
								setSelectedJobsField("Freelance");
							}}
						>
							Freelance Jobs
						</p>
					</div>
				)}

				{isJobsVisible && (
					<form>
						{/* <FormInput
					label={"Location Distance"}
					type={"number"}
					name={"location-distance"}
					id={"location_distance"}
				/> */}
						<FormInputwithIcon
							name={"keyword-jobs"}
							id={"keyword_jobs"}
							label={"Search Title or Company"}
						/>
						<div
							className="form-row"
							style={{
								display: "flex",
								alignItems: "start",
								justifyContent: "start",
								flexDirection: "column",
								gap: "12px",
								marginTop: "15px",
							}}
						>
							<p style={{ fontSize: "0.95rem", fontWeight: "bold" }}>
								Work From Home Jobs
							</p>
							<div
								className={`form-switch ${isFromHome ? "active" : ""}`}
								onClick={() => {
									setIsFromHome((prev) => !prev);
								}}
							>
								<div className="form-switch-button"></div>
							</div>
						</div>
						<div style={{ marginBottom: "5px" }}></div>
						<div className="search-row">
							<Select defaultValue={"Country"} optionsArray={countries} />
							<Select defaultValue={"City"} optionsArray={countries} />
						</div>
						<div className="search-row">
							<Select defaultValue={"Category"} optionsArray={countries} />
							<Select defaultValue={"Sub-Category"} optionsArray={countries} />
						</div>
						<div className="form-row">
							<Select
								defaultValue={"Career Level"}
								optionsArray={careerLevel}
							/>
						</div>
						<div className="form-row">
							<Select
								defaultValue={"Employment Type"}
								optionsArray={countries}
							/>
						</div>

						<div className="form-row">
							<Select defaultValue={"University"} optionsArray={countries} />
						</div>
						{/* <Select defaultValue={"Employment Type"} optionsArray={countries} />
				<Select defaultValue={"University"} optionsArray={countries} /> */}
						<button
							className="button medium secondary"
							style={{ marginTop: "15px" }}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "5px",
								}}
							>
								<svg class="icon-magnifying-glass">
									<use xlinkHref="#svg-magnifying-glass"></use>
								</svg>
								Search
							</div>
						</button>
					</form>
				)}
			</div>

			<SummaryResults
				numberofItems={numberOfJobs}
				setNumberOfItems={setNumberOfJobs}
				category={"jobs"}
			/>

			<div className="grid grid-4-4-4 centered">
				{selectedJobsField === "Freelance" && (
					<>
						<FreelanceCard />
						<FreelanceCard />
						<FreelanceCard />
						<FreelanceCard />
					</>
				)}
			</div>
		</>
	);
};

export default JobsSearch;
