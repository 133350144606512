import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    phoneNumber: "",
    token: "",
    isAuthenticated: false
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setPhoneNumber(state, action) {
            state.phoneNumber = action.payload.value;
        },
        setToken(state, action) {
            state.token = action.payload.value;
        },
        setIsAuthenticated(state, action) {
            state.isAuthenticated = action.payload.value;
        }
    }
});

export const {
    setPhoneNumber,
    setToken,
    setIsAuthenticated
} = authSlice.actions;

export default authSlice.reducer;