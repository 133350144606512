import React from 'react'
import WrapInCenter from '../../WrapInCenter/WrapInCenter'
import "./BrowseCategories.css"
import { MdArrowRightAlt } from 'react-icons/md'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { FaPencilRuler } from 'react-icons/fa'
import { LiaLaptopCodeSolid } from "react-icons/lia";
import { BsBarChart, BsTranslate } from 'react-icons/bs'
import { CiMicrophoneOn } from "react-icons/ci";
import { LuFileVideo } from 'react-icons/lu';
import { TfiRulerAlt2 } from "react-icons/tfi";


const categories = [
    {
        title: "Developemt & IT",
        icon: (<LiaLaptopCodeSolid className='mb-[22px]' size={45} />)
    },
    {
        title: "Design & Creative",
        icon: (<FaPencilRuler className='mb-[40px]' size={27} />)
    },
    {
        title: "Translate & Writing",
        icon: (<BsTranslate className='mb-[30px]' size={35} />)
    },
    {
        title: "Musics & Audio",
        icon: (<CiMicrophoneOn className='mb-[25px]' size={40} />)
    },
    {
        title: "Video & Animation",
        icon: (<LuFileVideo className='mb-[30px]' size={35} />)
    },
    {
        title: "Engineering & Architecture",
        icon: (<TfiRulerAlt2 className='mb-[28px]' size={35} />)
    },
    {
        title: "Finance & Accounting",
        icon: (<BsBarChart className='mb-[28px]' size={35} />)
    },

]



const BrowseCategories = () => {
    const renderCategoryCard = categories.map((cat, idx) => (
        <SwiperSlide key={idx}>
            <div className="category-card">
                <span className='category-icon'>
                    {cat.icon}
                </span>
                <p>1,835 skills</p>
                <a href='#'>{cat.title}</a>
            </div>
        </SwiperSlide>
    ))
    return (
        <div className='popular-jobs-categories'>
            <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
                <div className="header-career-section relative">
                    <h3>Browse talent by category</h3>
                    <p>Get some Inspirations from 1800+ skills.</p>
                    <a className='font-semibold text-[18px] absolute -top-6 md:top-[12px] right-0' href="#">All Categories <MdArrowRightAlt className='inline ml-[3px]' /></a>
                </div>

                <Swiper
                    slidesPerView={2}
                    spaceBetween={16}
                    loop
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                        991: {
                            slidesPerView: 5,
                            spaceBetween: 16
                        }
                    }}
                    className="category-swiper mt-[30px] md:!overflow-visible"
                >
                    {renderCategoryCard}
                </Swiper>

            </WrapInCenter>
        </div>
    )
}

export default BrowseCategories