import React from "react";
import AdsBanner from "../../../components/AdsBanner/AdsBanner";
import WrapInCenter from "../../../components/WrapInCenter/WrapInCenter";
import SectionHeader from "./SectionHeader";
import '../Affiliations.css'
import GroupForm from "./GroupForm";

const NewGroup = () => {
  const bannersImages = [
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
  ];
  return (
    <>
      <AdsBanner bannersImages={bannersImages} />
      <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
        <SectionHeader/>
        <GroupForm/>
      </WrapInCenter>
    </>
  );
};

export default NewGroup;
