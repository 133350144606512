import React, { useEffect, useRef, useState } from "react";
import UserProfileImage from "../../user-profile-image/UserProfileImage";
import PostCommentForm from "./PostCommentForm";
import { Link } from "react-router-dom";

const Comment = () => {
	const [isCommentMenuOpen, setIsCommentMenuOpen] = useState(false);
	const [isReactsListOpen, setIsReactsListOpen] = useState(false);
	const [isLikeListOpen, setIsLikeListOpen] = useState(false);
	const [isHappyListOpen, setIsHappyListOpen] = useState(false);
	const [isReplyVisible,setIsReplyVisible] = useState(false)

	const commentMenuRef = useRef()
	const reactListRef = useRef()

	useEffect( ()=>{
		document.addEventListener("mousedown" , function(event){
		  if(commentMenuRef.current && !commentMenuRef.current.contains(event.target)){
			setIsCommentMenuOpen(false)
		  }
		  if(reactListRef.current && !reactListRef.current.contains(event.target)){
			setIsReactsListOpen(false)
		  }
		})
	
		return () =>{
		  document.removeEventListener("mousedown" , function(event){
			if(commentMenuRef.current && !commentMenuRef.current.contains(event.target)){
			  setIsCommentMenuOpen(false)
			}
			if(setIsReactsListOpen.current && !setIsReactsListOpen.current.contains(event.target)){
				setIsReactsListOpen(false)
			}
		  })
		}
	  } ,[])

	return (
    <div className="post-comment">
      <a className="user-avatar small no-outline" href="profile-timeline.html">
        {/* <div className="user-avatar-content">
					<div
						className="hexagon-image-30-32"
						data-src="/img/avatar/05.jpg"
					></div>
                    
				</div> */}
        {/* <div className="user-avatar-content post-writer-img">
					<img src="/img/avatar/05.jpg" alt="profile-img" />
					<div className="friends-badge">
						<img src="/img/download.png" alt="badge-background" />
						<p className="friends-badge-number">24</p>
					</div>
				</div> */}
        <UserProfileImage />
      </a>

      <div className="ml-1">
        <p className="post-comment-text">
          <a className="post-comment-text-author" href="profile-timeline.html">
            Neko Bebop
          </a>
          It's always a pleasure to do this streams with you! If we have at
          least half the fun than last time it will be an incredible success!
        </p>
      </div>

      <div className="content-actions">
        <div className="content-action">
          <div className="meta-line">
            <div className="meta-line-list reaction-item-list small">
              <div className="reaction-item gap-[4px]"
              onMouseEnter={() => {
                setIsHappyListOpen(true);
              }}
              onMouseLeave={() => {
                setIsHappyListOpen(false);
              }}
              >
                <img
                  className={`reaction-image reaction-item-dropdown-trigger ${
                    isHappyListOpen ? "active" : ""
                  }`}
                  src="/img/reaction/happy.png"
                  alt="reaction-happy"
                />
                <p className="meta-line-text">4</p>

                <div
                  className={`simple-dropdown padded reaction-item-dropdown !w-[270px] !bottom-[50px] ${
                    isHappyListOpen ? "reacts-list-open" : "reacts-list-close"
                  }`}
                  onMouseEnter={() => {
                    setIsHappyListOpen(true);
                  }}
                  onMouseLeave={() => {
                    setIsHappyListOpen(false);
                  }}
                >
                  <p className="simple-dropdown-text">
                    <img
                      className="reaction"
                      src="/img/reaction/happy.png"
                      alt="reaction-happy"
                    />
                    <p className="reactions-number">5</p>
                    <span className="bold">Happy</span>
                  </p>

				  <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                    <div className="user-status">
                      <Link
                        className="user-status-avatar"
                        to="/user-profile-page"
                      >
                        <UserProfileImage />
                      </Link>

                      <p className="user-status-title">
                        <span className="bold">Neko Bebop </span>
                      </p>

                      <p
                        className="user-status-text small"
                        style={{ color: "#adafca" }}
                      >
                        Marketing Manager - Brandmarks
                      </p>
                    </div>
                  </p>
				  <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                    <div className="user-status">
                      <Link
                        className="user-status-avatar"
                        to="/user-profile-page"
                      >
                        <UserProfileImage />
                      </Link>

                      <p className="user-status-title">
                        <span className="bold">Neko Bebop </span>
                      </p>

                      <p
                        className="user-status-text small"
                        style={{ color: "#adafca" }}
                      >
                        Marketing Manager - Brandmarks
                      </p>
                    </div>
                  </p>
				  <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                    <div className="user-status">
                      <Link
                        className="user-status-avatar"
                        to="/user-profile-page"
                      >
                        <UserProfileImage />
                      </Link>

                      <p className="user-status-title">
                        <span className="bold">Neko Bebop </span>
                      </p>

                      <p
                        className="user-status-text small"
                        style={{ color: "#adafca" }}
                      >
                        Marketing Manager - Brandmarks
                      </p>
                    </div>
                  </p>
                </div>
              </div>

              <div className="reaction-item gap-[4px]"
              onMouseEnter={() => {
                setIsLikeListOpen(true);
              }}
              onMouseLeave={() => {
                setIsLikeListOpen(false);
              }}
              >
                <img
                  className={`reaction-image reaction-item-dropdown-trigger ${
                    isLikeListOpen ? "active" : ""
                  }`}
                  src="/img/reaction/like.png"
                  alt="reaction-like"
                />
                <p className="meta-line-text">4</p>

                <div
                  className={`simple-dropdown padded reaction-item-dropdown !w-[270px] !bottom-[50px] ${
                    isLikeListOpen ? "reacts-list-open" : "reacts-list-close"
                  }`}
                  onMouseEnter={() => {
                    setIsLikeListOpen(true);
                  }}
                  onMouseLeave={() => {
                    setIsLikeListOpen(false);
                  }}
                >
                  <p className="simple-dropdown-text">
                    <img
                      className="reaction"
                      src="/img/reaction/like.png"
                      alt="reaction-like"
                    />
                    <p className="reactions-number">5</p>
                    <span className="bold">Like</span>
                  </p>

                  <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                    <div className="user-status">
                      <Link
                        className="user-status-avatar"
                        to="/user-profile-page"
                      >
                        <UserProfileImage />
                      </Link>

                      <p className="user-status-title">
                        <span className="bold">Neko Bebop </span>
                      </p>

                      <p
                        className="user-status-text small"
                        style={{ color: "#adafca" }}
                      >
                        Marketing Manager - Brandmarks
                      </p>
                    </div>
                  </p>
                  <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                    <div className="user-status">
                      <Link
                        className="user-status-avatar"
                        to="/user-profile-page"
                      >
                        <UserProfileImage />
                      </Link>

                      <p className="user-status-title">
                        <span className="bold">Neko Bebop </span>
                      </p>

                      <p
                        className="user-status-text small"
                        style={{ color: "#adafca" }}
                      >
                        Marketing Manager - Brandmarks
                      </p>
                    </div>
                  </p>
                  <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                    <div className="user-status">
                      <Link
                        className="user-status-avatar"
                        to="/user-profile-page"
                      >
                        <UserProfileImage />
                      </Link>

                      <p className="user-status-title">
                        <span className="bold">Neko Bebop </span>
                      </p>

                      <p
                        className="user-status-text small"
                        style={{ color: "#adafca" }}
                      >
                        Marketing Manager - Brandmarks
                      </p>
                    </div>
                  </p>
                  <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                    <div className="user-status">
                      <Link
                        className="user-status-avatar"
                        to="/user-profile-page"
                      >
                        <UserProfileImage />
                      </Link>

                      <p className="user-status-title">
                        <span className="bold">Neko Bebop </span>
                      </p>

                      <p
                        className="user-status-text small"
                        style={{ color: "#adafca" }}
                      >
                        Marketing Manager - Brandmarks
                      </p>
                    </div>
                  </p>

                </div>
              </div>
            </div>
          </div>

          <div className="meta-line" ref={reactListRef}>
            <p
              className={`meta-line-link light reaction-options-small-dropdown-trigger ${
                isReactsListOpen ? "active" : ""
              }`}
              onClick={() => {
                setIsReactsListOpen((prev) => !prev);
              }}
            >
              React!
            </p>

            <div
              className={`reaction-options reaction-options-small-dropdown top-[80px] !left-[20px] md:top-[45px] md:!left-[145px] ${
                isReactsListOpen
                  ? "comment-react-list-open"
                  : "comment-react-list-close"
              }`}
            >
              <div
                className="reaction-option text-tooltip-tft"
                data-title="Like"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/like.png"
                  alt="reaction-like"
                />
              </div>

              <div
                className="reaction-option text-tooltip-tft"
                data-title="Love"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/love.png"
                  alt="reaction-love"
                />
              </div>

              <div
                className="reaction-option text-tooltip-tft"
                data-title="Dislike"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/dislike.png"
                  alt="reaction-dislike"
                />
              </div>

              <div
                className="reaction-option text-tooltip-tft"
                data-title="Happy"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/happy.png"
                  alt="reaction-happy"
                />
              </div>

              <div
                className="reaction-option text-tooltip-tft"
                data-title="Funny"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/funny.png"
                  alt="reaction-funny"
                />
              </div>

              <div
                className="reaction-option text-tooltip-tft"
                data-title="Wow"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/wow.png"
                  alt="reaction-wow"
                />
              </div>

              <div
                className="reaction-option text-tooltip-tft"
                data-title="Angry"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/angry.png"
                  alt="reaction-angry"
                />
              </div>

              <div
                className="reaction-option text-tooltip-tft"
                data-title="Sad"
              >
                <img
                  className="reaction-option-image"
                  src="/img/reaction/sad.png"
                  alt="reaction-sad"
                />
              </div>
            </div>
          </div>

          <div className="meta-line">
            <p
              className="meta-line-link light"
              onClick={() => setIsReplyVisible((prev) => !prev)}
            >
              Reply
            </p>
          </div>

          <div className="meta-line">
            <p className="meta-line-timestamp">15 minutes ago</p>
          </div>

          <div className="meta-line settings">
            <div className="post-settings-wrap" ref={commentMenuRef}>
              <div
                className={`post-settings post-settings-dropdown-trigger ${
                  isCommentMenuOpen ? "active" : ""
                }`}
                onClick={() => {
                  setIsCommentMenuOpen((prev) => !prev);
                }}
              >
                <svg className="post-settings-icon icon-more-dots">
                  <use xlinkHref="#svg-more-dots"></use>
                </svg>
              </div>

              <div
                className={`simple-dropdown post-settings-dropdown ${
                  isCommentMenuOpen ? "comment-menu-open" : "comment-menu-close"
                }`}
              >
                <p className="simple-dropdown-link">Edit</p>
                <p className="simple-dropdown-link">Delete</p>
                <p className="simple-dropdown-link">Report Post</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isReplyVisible && <PostCommentForm />}
    </div>
  );
};

export default Comment;
