import React from 'react'
import Stories from '../Stories/Stories'
import Weather from '../Weather/Weather'
import HomeSponsore from '../HomeSponsore/HomeSponsore'
import SuggestedPeople from '../SuggestedPeople/SuggestedPeople'

const HomeRightAside = () => {
  return (
    <div className="grid-column min-[1367px]:sticky min-[1367px]:top-[-1310px]">
      <Stories />

      <Weather />

      <HomeSponsore />

      <SuggestedPeople />

      <div className="photo-banner rounded-[8px] overflow-hidden shadow-[0_0_40px_0_rgba(94,92,154,0.06)]">
        <img className="h-[300px]" src="/img/ads/ad-02.jpg" alt="" />
      </div>

    </div>
  )
}

export default HomeRightAside