
import React from "react";
import Select from "../../../components/ui/select/Select";
import FormInput from "../../../components/ui/formInput/FormInput";
import FormSwitch from "../../../components/ui/form-switch/FormSwitch";
import PhotoCoverGroup from "./PhotoCoverGroup";

const GroupForm = () => {
  const categories = [
    { id: 1, value: "Category 1" },
    { id: 2, value: "Category 2" },
    { id: 3, value: "Category 3" },
    { id: 4, value: "Category 4" },
  ];
  const Subcategory = [
    { id: 1, value: "Subcategory 1" },
    { id: 2, value: "Subcategory 2" },
    { id: 3, value: "Subcategory 3" },
    { id: 4, value: "Subcategory 4" },
  ];
  const CommunityType = [
    { id: 1, value: "CommunityType 1" },
    { id: 2, value: "CommunityType 2" },
    { id: 3, value: "CommunityType 3" },
    { id: 4, value: "CommunityType 4" },
  ];
  const CommunityPurpose = [
    { id: 1, value: "CommunityPurpose 1" },
    { id: 2, value: "CommunityPurpose 2" },
    { id: 3, value: "CommunityPurpose 3" },
    { id: 4, value: "CommunityPurpose 4" },
  ];
  return (
    <>
      <PhotoCoverGroup />
      <div
        className={`search-member-component common-for-dir-cat common-for-dir-cat-active MarginTopFormNewGroup`}
      >
        <form>
          <div>
            <FormInput
              type={"text"}
              label={"Community name"}
              name={"Community name"}
              id={"Community-name"}
            />
            <div className="search-row marginMinus mergin-small-screen">
              <Select
                defaultValue={"Community Purpose"}
                optionsArray={CommunityPurpose}
              />
              <Select
                defaultValue={"Community type"}
                optionsArray={CommunityType}
              />
            </div>
          </div>

          <div className="sm:flex items-center gap-4 mt-[-18px]">
            <div className="form-item flex-1 h-[110px]">
              <div className="form-input small full">
                <textarea
                  id="profile-description"
                  name="profile_description"
                  placeholder="About"
                ></textarea>
              </div>
            </div>
          <div className="sm:search-row mt-twopixel flex-1">
            <Select defaultValue={"Category"} optionsArray={categories} />
            <Select defaultValue={"Subcategory"} optionsArray={Subcategory} />
          </div>
          </div>
          <div className="search-row marginMinus block-small-groupForm">
            <div className="CommuintyLinkInput w-[100%]">
              <h3 className=" whitespace-nowrap">https://abncw.com/</h3>
              <FormInput
                type={"text"}
                label={"Community url"}
                name={"Community url"}
                id={"Community url"}
              />
            </div>
            <div className="flex items-center justify-start gap-2 mb-8 w-[100%]">
              <FormSwitch />
              <h3>Approve requests to join</h3>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <button
              className="button medium secondary btnNewGroup"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div>Create Group</div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default GroupForm;
