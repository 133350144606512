import React from "react";
import FormInput from "../../../ui/formInput/FormInput";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";

const DeleteAccount = () => {
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Account</p>
					<h2 className="section-title">Delete Account</h2>
				</div>
			</div>
			<div className="widget-box">
				{/* <p className="widget-box-title">Delete Account</p> */}

				<div className="widget-box-content">
					<form className="form">
						<div>
							<FormInput
								label={"Current Password"}
								type={"password"}
								id={"current_password"}
								name={"current-password"}
							/>
							<button className="button medium secondary">Delete</button>
						</div>
					</form>
				</div>
			</div>

		</div>
	);
};

export default DeleteAccount;
