import React from 'react'
import WrapInCenter from '../../WrapInCenter/WrapInCenter'
import { MdArrowRightAlt } from 'react-icons/md'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';


const businesses = [
    "/img/businesses/01-removebg.png",
    "/img/businesses/02.jpeg",
]


const TopHiring = () => {
    const renderBusinesses = Array(6).fill(businesses).flat().map((val, idx) => (
        <SwiperSlide key={idx}>
            <div className='w-full'>
                <img className='w-[100px] h-[100px] rounded-full shadow-[6px_6px_8px_0px_#404f680d]' style={{ border: "1px solid #e9ecef" }} src={val} alt="" />
            </div>
        </SwiperSlide>
    ))
    return (
        <div className='top-hiring bg-white mt-[25px] py-20'>
            <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
                <div className="header-career-section relative">
                    <h3>Top Hiring Flokker Businesses</h3>
                    <a className='font-semibold text-[18px] absolute -top-6 md:top-[8px] right-0' href="#">All Businesses <MdArrowRightAlt className='inline ml-[3px]' /></a>
                </div>

                <Swiper
                    slidesPerView={2}
                    spaceBetween={16}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 20
                        },
                        991: {
                            slidesPerView: 6,
                            spaceBetween: 16
                        }
                    }}
                    className="top-businesses-swiper mt-[4rem] md:!overflow-visible"
                >
                    {renderBusinesses}
                </Swiper>
            </WrapInCenter>
        </div>
    )
}

export default TopHiring