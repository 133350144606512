import { Modal } from "antd";
// import { BsShareFill } from "react-icons/bs";
import Select from "../../../ui/select/Select";
import { PiBagSimpleFill } from "react-icons/pi";
import { useState } from "react";
import FormInput from "../../../ui/formInput/FormInput";
import { FaTimes } from "react-icons/fa";

const businessPages = [
    { id:1,value: "Business Page 1" },
    { id:2,value: "Business Page 2" },
    { id:3,value: "Business Page 3" },
    { id:4,value: "Business Page 4" },
    { id:5,value: "Business Page 5" },
  ];

const QuickPostShareVacancy = ({isOpened , setIsOpened}) => {
    const [vacancy , setVacancy] = useState("")
    const [value , setValue] = useState("Business Page")
    return (
      <>
        <button className="more-btn" onClick={() => setIsOpened(!isOpened)}>
            <span className="more-btn-img">
                <PiBagSimpleFill size={21} fill="#3f51b5" />
            </span>
    
            <span className="more-btn-text">Share Vacancy</span>
        </button>
  
        <Modal
          title={null}
          open={isOpened}
          onCancel={() => setIsOpened(!isOpened)}
          footer={null}
          width={800}
          zIndex={100000000000}
        >
          <div className="share-vacancy-container">
            <h2 className="sell-product-title">Share Vacancy</h2>
            <form action="">
                <Select defaultValue={value} setValue={setValue} optionsArray={businessPages} />
                {vacancy && 
                  <h2 className="mt-[2rem] w-fit text-white text-[1rem] bg-[#fd6729] py-[8px] px-[20px] rounded-[30px] cursor-pointer"
                  onClick={() => setVacancy("")}>
                    {vacancy} <FaTimes className="inline" />
                  </h2>
                }
                { value !== "Business Page" && (
                    <div className="mt-[2rem]">
                        <h2 className="text-[25px]">Jobs</h2>
                        <div className="grid md:!grid-cols-2 lg:!grid-cols-3 mt-[1rem]">
                            <div className="select-job-card" onClick={() => setVacancy("Vacancy 1")}>
                                <img className="rounded-t-[12px] w-full" src="/img/cover/05.jpg" alt="" />
                                <p className="text-[16px] p-[20px] text-center font-semibold">Job title here</p>
                            </div>
                            <div className="select-job-card" onClick={() => setVacancy("Vacancy 2")}>
                                <img className="rounded-t-[12px] w-full" src="/img/cover/03.jpg" alt="" />
                                <p className="text-[16px] p-[20px] text-center font-semibold">Job title here</p>
                            </div>
                            <div className="select-job-card" onClick={() => setVacancy("Vacancy 3")}>
                                <img className="rounded-t-[12px] w-full" src="/img/cover/04.jpg" alt="" />
                                <p className="text-[16px] p-[20px] text-center font-semibold">Job title here</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex gap-[1rem] mt-12">
                    <button className="button white">Cancel</button>
                    <button className="button primary">Publish Job</button>
                </div>
            </form>
          </div>
        </Modal>
  
        
      </>
    );
  };
  
  export default QuickPostShareVacancy;