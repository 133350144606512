import React, { useState } from 'react'

const UppcommingEvents = () => {
    const [isWidgetOpen, setIsWidgetOpen] = useState("");

  return (
<div className="widget-box">
  <div className="widget-box-settings">
  <div className="post-settings-wrap">
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>

          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link">Widget Settings</p>
          </div>
        </div>
  </div>

  <p className="widget-box-title">Upcoming Events <span className="highlighted">2</span></p>

  <div className="widget-box-content">
    <div className="event-preview-list">
      <div className="event-preview small">
        <figure className="event-preview-cover liquid">
          <img src="img/cover/33.jpg" alt="cover-33" className=' rounded-lg'/>
        </figure>

        <div className="event-preview-info">
          <div className="date-sticker">
            <p className="date-sticker-day">26</p>
            <p className="date-sticker-month">Aug</p>
          </div>

          <p className="event-preview-title popup-event-information-trigger">CosWorld 2019 After Party</p>
          <p className="event-preview-timestamp">11:00 PM</p>
          <p className="event-preview-text">Join us at the CosWorld after party! We'll be eating, drinking and having a great time exchanging experience from the convention.</p>

          <div className="meta-line">
            <div className="communities-card-flokkers-list mt-[-15px]">
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/01.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/02.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/02.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
          </div>
            <p className="meta-line-text mb-2 ml-2">+24 will assist</p>
          </div>
        </div>
      </div>


    </div>
    <div className="event-preview-list mt-3">
      <div className="event-preview small">
        <figure className="event-preview-cover liquid">
          <img src="img/cover/33.jpg" alt="cover-33" className=' rounded-lg'/>
        </figure>

        <div className="event-preview-info">
          <div className="date-sticker">
            <p className="date-sticker-day">26</p>
            <p className="date-sticker-month">Aug</p>
          </div>

          <p className="event-preview-title popup-event-information-trigger">CosWorld 2019 After Party</p>
          <p className="event-preview-timestamp">11:00 PM</p>
          <p className="event-preview-text">Join us at the CosWorld after party! We'll be eating, drinking and having a great time exchanging experience from the convention.</p>

          <div className="meta-line">
            <div className="communities-card-flokkers-list mt-[-15px]">
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/01.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/02.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
            <div className="fldkkerGroupImg">
              <img
                src="/img/avatar/02.jpg"
                alt="fldkker-avatar"
                loading="lazy"
              />
            </div>
          </div>
            <p className="meta-line-text mb-2 ml-2">+24 will assist</p>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

  )
}

export default UppcommingEvents
