import React, { useEffect, useRef, useState } from "react";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";
import QuickPost from "../../components/tech-business-profile/quick-post/QuickPost";
import AdsBanner from "../../components/AdsBanner/AdsBanner";
import "./home-screen.css";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Link } from "react-router-dom";

import BlogPost from "../../components/UserProfilePage/BlogPost";
import PollPost from "../../components/UserProfilePage/PollPost";
import VideoPost from "../../components/UserProfilePage/VideoPost";
import SharedPost from "../../components/UserProfilePage/SharedPost";
import TextPost from "../../components/UserProfilePage/TextPost";
import ImagePostPopup from "../../components/UserProfilePage/ImagePostPopup";
import GifPost from "../../components/UserProfilePage/GifPost";
import BgPost from "../../components/UserProfilePage/BgPost";
import AudioPost from "../../components/UserProfilePage/AudioPost";
import AdPost from "../../components/UserProfilePage/AdPost";
import WelcomeMessage from "../../components/HomeScreen/WelcomeMessage/WelcomeMessage";
import NavigationBar from "../../components/HomeScreen/NavigationBar/NavigationBar";
import YoutubeVideoPost from "../../components/UserProfilePage/YoutubeVideoPost";
import ImagesPost from "../../components/UserProfilePage/ImagesPost";
import ImagePost from "../../components/UserProfilePage/ImagePost";
import ListingPost from "../../components/UserProfilePage/ListingPost";
import ImagesPostPopupGallery from "../../components/UserProfilePage/ImagesPostPopupGallery";
import HomeScreenMobile from "./HomeScreenMobile";
import FriendStory from "../../components/HomeScreen/Stories/FriendStory";
import UserStory from "../../components/HomeScreen/Stories/UserStory";
import HomeRightAside from "../../components/HomeScreen/HomeAsides/HomeRightAside";
import HomeLeftAside from "../../components/HomeScreen/HomeAsides/HomeLeftAside";
import { useSelector } from "react-redux";
import ServicePost from "../../components/UserProfilePage/ServicePost";
import VacancyPost from "../../components/UserProfilePage/VacancyPost";


const bannersImages = [
  "/img/ads/ad.png",
  "/img/ads/ad.png",
  "/img/ads/ad.png",
];


const HomeScreen = () => {
  const isUserStoryVisible = useSelector((state) => state.outlet.isUserStoryVisible);
  const isFriendStoryVisible = useSelector((state) => state.outlet.isFriendStoryVisible);
  const [isImagePopupOpened, setIsImagePopupOpened] = useState(false)
  const [isImagePopupGalleryOpened, setIsImagePopupGalleryOpened] = useState(false);

  const imagePopWrapperRef = useRef(null)
  const imageGalleryPopWrapperRef = useRef(null)

  const screenWidth = window.innerWidth < 1365;

  useEffect(() => {
    document.addEventListener("mousedown", function (e) {
      if (imagePopWrapperRef.current && imagePopWrapperRef.current == e.target) {
        setIsImagePopupOpened(false)
      }
      if (imageGalleryPopWrapperRef.current && imageGalleryPopWrapperRef.current == e.target) {
        setIsImagePopupGalleryOpened(false)
      }
    })

    return () => {
      document.removeEventListener("mousedown", function (e) {
        if (imagePopWrapperRef.current && imagePopWrapperRef.current == e.target) {
          setIsImagePopupOpened(false)
        }
        if (imageGalleryPopWrapperRef.current && imageGalleryPopWrapperRef.current == e.target) {
          setIsImagePopupGalleryOpened(false)
        }
      })
    }
  }, [])

  console.log(isUserStoryVisible)

  return (
    <>

      <div className="homescreen">
        <AdsBanner bannersImages={bannersImages} />

        <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
          {window.innerWidth > 768 ?
            <div className="grid grid-3-6-3">

              <HomeLeftAside />

              <div className="grid-column">
                <QuickPost />

                <WelcomeMessage />

                <NavigationBar />

                <BlogPost />
                <VacancyPost />
                <ServicePost />
                <ListingPost setIsImagePopupGalleryOpened={setIsImagePopupGalleryOpened} />
                <AdPost />
                <VideoPost />
                <ImagePost setIsImagePopupOpened={setIsImagePopupOpened} />
                <AudioPost />
                <AdPost />
                <ImagesPost setIsImagePopupOpened={setIsImagePopupOpened} setIsImagePopupGalleryOpened={setIsImagePopupGalleryOpened} />
                <TextPost />
                <YoutubeVideoPost />
                <GifPost />
                <AdPost />
                <BgPost />
                <SharedPost />
                <PollPost />
                <AdPost />
                <div className="no-more bg-white py-[20px] text-center mb-[1.5rem] text-[18px] font-[600] rounded-[5px]">
                  <p>No More Posts</p>
                </div>
              </div>

              <HomeRightAside />

            </div>
            :
            <HomeScreenMobile
              setIsImagePopupOpened={setIsImagePopupOpened}
              setIsImagePopupGalleryOpened={setIsImagePopupGalleryOpened} />
          }
        </WrapInCenter>

        <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 7500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className={`ads-swiper-2 !h-[200px] !pb-[50px] mt-[30px]`}
          >
            {bannersImages.map((banner, index) => (
              <SwiperSlide key={index} className="bg-white rounded-[12px] shadow-[0_0_40px_0_rgba(94,92,154,0.06)]">
                <div className="banner-container !w-[970px] !h-[90px]">
                  <img src={banner} alt="ads banner" loading="lazy" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <footer className={`${screenWidth ? "" : "isMobileView"} text-center fw-semibold fs-6 login-footer`} n>
            {/* begin::Link */}
            <div className="footer-links-container">
              <Link className="" to="/auth">Terms of use</Link>
              <Link className="" to="/auth">Privacy Policy</Link>
              <Link className="" to="/auth">Contact Us</Link>
              <Link className="" to="/auth">About</Link>
              <Link className="" to="/auth">Blog</Link>
              <Link className="" to="/auth">Events</Link>
            </div>
            <p className="copyrights ">
              Copyright &copy; 2024 FLOKKY | BRANDMARKS
            </p>
            {/* end::Link */}
          </footer>
        </WrapInCenter>
      </div>


      <div className="image-popup-wrapper"
        ref={imagePopWrapperRef}
        style={
          isImagePopupOpened ? {
            opacity: 1,
            visibility: 'visible',
          } : {
            opacity: 0,
            visibility: 'hidden',
          }
        }
      >
        <ImagePostPopup isOpened={isImagePopupOpened} setIsOpened={setIsImagePopupOpened} />
      </div>

      <div className="gallery-popup-wrapper"
        ref={imageGalleryPopWrapperRef}
        style={
          isImagePopupGalleryOpened ? {
            opacity: 1,
            visibility: 'visible',
          } : {
            opacity: 0,
            visibility: 'hidden',
          }
        }
      >
        <ImagesPostPopupGallery isOpened={isImagePopupGalleryOpened} setIsOpened={setIsImagePopupGalleryOpened} />
      </div>

      {isUserStoryVisible && <UserStory />}
      {isFriendStoryVisible && <FriendStory />}

    </>
  );
};

export default HomeScreen;
