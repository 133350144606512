import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

import "./VideoPlayer.css"

const VideoPlayer = () => {
  return (
    <Player
    poster="https://video-react.js.org/assets/poster.png"
    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
    />
  )
}

export default VideoPlayer