import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import img from "../../assests/crea8/01-removebg-preview.png";
import img2 from "../../assests/crea8/02-removebg-preview.png";
import WrapInCenter from "../WrapInCenter/WrapInCenter";

export default function AffiliationsProjects() {
  const [showSwiper, setShowSwiper] = useState(false);
  useEffect(() => {
    setShowSwiper(true);
  }, []);

  return (
    <>
        <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
      <section className="md:pb-90 pb-20">
        <div className="container">
          <div className="row align-items-center wow fadeInUp">
            <div className="col-lg-9">
              <div className="main-title">
                <h2 className="title title-h2-inspiring-crea8">
                  Flokker Affiliation Projects
                </h2>
                <p className="paragraph text-black">
                  Find Latest projects posted by Flookers from <br /> The
                  American University of Cairo
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="text-start text-lg-end mb-4">
                <Link
                  className="ud-btn btn-light-thm bdrs90 d-flex gap-2 items-center md:justify-end justify-start"
                  to="/freelancer-1"
                >
                  View All
                  <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ui-hightest-rated">
                {showSwiper && (
                  <Swiper
                    spaceBetween={30}
                    navigation={{
                      prevEl: ".btn__prev__005",
                      nextEl: ".btn__next__005",
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiperr"
                    loop={true}
                    pagination={{
                      el: ".swiper__pagination__005",
                      clickable: true,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                      },
                      768: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                      992: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                      },
                      1200: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                      },
                    }}
                  >
                    <SwiperSlide className="mb-5">
                      <img src={img} alt="" className=" w-32 h-32" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={img2} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={img} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={img2} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={img} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={img2} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={img} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={img2} alt="" className="w-32 h-32" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={img2} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <img src={img} alt="" className=" w-32 h-32" />
                    </SwiperSlide>{" "}
                  </Swiper>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button className="swiper__btn btn__prev__005 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowLeft />
                </button>
              </div>
              <div className="col-auto">
                <div className="swiper__pagination swiper__pagination__005"></div>
              </div>
              <div className="col-auto">
                <button className="swiper__btn btn__next__005 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      </WrapInCenter>
    </>
  );
}
