import WrapInCenter from "../../../components/WrapInCenter/WrapInCenter";
import "./MainGroup.css";
import { useState } from "react";
import "../../UserProfilePage/UserProfile.css";
import Slider from "../../../components/ui/slider/slider";
import ProfileCompletion from "../../../components/tech-business-profile/timeline-widget/ProfileCompletion";
import AboutMe from "../../../components/UserProfilePage/AboutMe";
import Videos from "../../../components/UserProfilePage/Videos";
import Photos from "../../../components/UserProfilePage/Photos";
import Groups from "../../../components/MainGroupSections/Groups";
import BlogPost from "../../../components/UserProfilePage/BlogPost";
import Skills from "../../../components/UserProfilePage/Skills";
import JobsAndEducation from "../../../components/UserProfilePage/JobsAndEducation";
import GroupHeader from "../../../components/MainGroupSections/GroupHeader";
import Moderator from "../../../components/MainGroupSections/Moderator";
import Adminstrations from "../../../components/MainGroupSections/Adminstrations";
import GroupInfo from "../../../components/MainGroupSections/GroupInfo";
import Discussions from "../../../components/MainGroupSections/Discussions";
import UppcommingEvents from "../../../components/MainGroupSections/UppcommingEvents";
import FilterationMain from "../../../components/MainGroupSections/FilterationMain";
import PostMainGroup from "../../../components/MainGroupSections/PostMainGroup";
import PostWithoutPhoto from "../../../components/MainGroupSections/PostWithoutPhoto";

const userProfileTabItems = [
  {
    name: "Timeline",
    select: "timeline",
    icon: (
      <svg className="section-menu-item-icon icon-timeline">
        <use xlinkHref="#svg-timeline"></use>
      </svg>
    ),
  },
  {
    name: "About",
    select: "about",
    icon: (
      <svg className="section-menu-item-icon icon-profile">
        <use xlinkHref="#svg-profile"></use>
      </svg>
    ),
  },
  {
    name: "Friends",
    select: "friends",
    icon: (
      <svg className="section-menu-item-icon icon-friend">
        <use xlinkHref="#svg-friend"></use>
      </svg>
    ),
  },
  {
    name: "Groups",
    select: "groups",
    icon: (
      <svg className="section-menu-item-icon icon-group">
        <use xlinkHref="#svg-group"></use>
      </svg>
    ),
  },
  {
    name: "Photos",
    select: "photos",
    icon: (
      <svg className="section-menu-item-icon icon-photos">
        <use xlinkHref="#svg-photos"></use>
      </svg>
    ),
  },
  {
    name: "Videos",
    select: "videos",
    icon: (
      <svg className="section-menu-item-icon icon-videos">
        <use xlinkHref="#svg-videos"></use>
      </svg>
    ),
  },
  {
    name: "Badges",
    select: "badges",
    icon: (
      <svg className="section-menu-item-icon icon-badges">
        <use xlinkHref="#svg-badges"></use>
      </svg>
    ),
  },
  {
    name: "Streams",
    select: "streams",
    icon: (
      <svg className="section-menu-item-icon icon-streams">
        <use xlinkHref="#svg-streams"></use>
      </svg>
    ),
  },
  {
    name: "Blog",
    select: "blog",
    icon: (
      <svg className="section-menu-item-icon icon-blog-posts">
        <use xlinkHref="#svg-blog-posts"></use>
      </svg>
    ),
  },
  {
    name: "Forum",
    select: "forum",
    icon: (
      <svg className="section-menu-item-icon icon-forum">
        <use xlinkHref="#svg-forum"></use>
      </svg>
    ),
  },
  {
    name: "Store",
    select: "store",
    icon: (
      <svg className="section-menu-item-icon icon-store">
        <use xlinkHref="#svg-store"></use>
      </svg>
    ),
  },
];
const MainGroup = () => {
  const [activeUserProfileTab, setActiveUserProfileTab] = useState("timeline");

  return (
    <div className="user-profile-page">
      <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
        <GroupHeader />
      </WrapInCenter>
      <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
        <Slider
          activeTab={activeUserProfileTab}
          setActiveTab={setActiveUserProfileTab}
          tabItems={userProfileTabItems}
        />
      </WrapInCenter>
      <WrapInCenter noPaddingBottom={false} noPaddingTop={true}>
        <div className="grid grid-3-6-3 mobile-prefer-content timeline-content">
          {activeUserProfileTab === "timeline" && (
            <>
              <div className="grid-column">
                <GroupInfo />
                <Discussions />
                <UppcommingEvents />
              </div>
              <div className="grid-column">
                <FilterationMain/>
                <PostWithoutPhoto/>
                <PostMainGroup/>
              </div>
              <div className="grid-column">
                <Adminstrations />
                <Moderator />
                <Groups />
                <Photos />
                <Videos />
              </div>
            </>
          )}
          {activeUserProfileTab === "about" && (
            <>
              <div className="grid-column">
                <AboutMe />
              </div>

              <div className="grid-column">
                <Skills />
                <JobsAndEducation />
              </div>

              <div className="grid-column">
                <ProfileCompletion />
              </div>
            </>
          )}
        </div>
      </WrapInCenter>
    </div>
  );
};

export default MainGroup;
