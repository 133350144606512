import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import NavigationAndSidebar from "../../components/NavigationAndSidebar/NavigationAndSidebar";
import { useSelector } from "react-redux";
import { setIsLargeHidden } from "../../app/feature/outletSlice";
import { useDispatch } from "react-redux";
const MainPage = () => {
	const dispatch = useDispatch()
	const isLargeHidden = useSelector((state) => state.outlet.isLargeHidden);
	const isChatsOpen = useSelector((state) => state.outlet.isChatsOpen);
	const isChatOpen = useSelector((state) => state.outlet.isChatOpen);

	const [width, setWidth] = useState(window.innerWidth);
	const [isHidden, setIsHidden] = useState(false);

	const breakpointForGrid = 1365;

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);

		return () => {
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		const threshold = 100;
		setIsHidden(scrollPosition > threshold);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const mainBodyRef = useRef(null)

	useEffect(() => {
		if (!isLargeHidden) {
			const handleClickOutside = (event) => {
				if (mainBodyRef.current && mainBodyRef.current.contains(event.target)) {
					dispatch(setIsLargeHidden({ value: true }));
				}
			};

			document.addEventListener('mousedown', handleClickOutside);

			// Cleanup function
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [isLargeHidden, dispatch]);

	return (
		<>
			<NavigationAndSidebar />
			<div ref={mainBodyRef}
			// className={`content-grid`}
			// style={{
			// 	transform: `translate(${
			// 		width >= breakpointForGrid &&
			// 		isLargeHidden &&
			// 		!isChatsOpen &&
			// 		!isChatOpen
			// 			? `${100 + (width - breakpointForGrid) * 0.5}px`
			// 			: width >= breakpointForGrid && isLargeHidden && isChatsOpen
			// 			? `${100 + (width - breakpointForGrid) * 0.5 - 110}px`
			// 			: width >= breakpointForGrid &&
			// 			  !isLargeHidden &&
			// 			  !isChatsOpen &&
			// 			  !isChatOpen
			// 			? `${100 + (width - breakpointForGrid) * 0.5 + 110}px`
			// 			: "0px"
			// 	}, 0px)`,
			// 	transition: "transform .68s ease-in-out 0s",
			// }}
			>
				<Outlet />
			</div>
		</>
	);
};

export default MainPage;
