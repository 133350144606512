import "./Hero.css";
import bannerImg1 from "../../../assests/career/images/home13-hero-img-1.webp";
import bannerImg3 from "../../../assests/career/images/home13-hero-img-3.webp";
import happyClientImg from "../../../assests/career/images/happy-client.png";
import { FaMedal } from "react-icons/fa";
import WrapInCenter from "../../WrapInCenter/WrapInCenter";
import CountUp from "react-countup";
import HeroForm from "./HeroForm";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useState } from "react";

const role = [
  "Graphics & Design",
  "Digital Marketing",
  "Writing & Translation",
  "Video & Animation",
  "Programming & Tech",
];

const locations = [
  "Egypt",
  "Germany",
  "France",
  "Spain",
  "USA",
]

const compaines = [
  "Compnay 1",
  "Compnay 2",
  "Compnay 3",
  "Compnay 4",
  "Compnay 5",
]


const affiliations = [
  "Affiliation 1",
  "Affiliation 2",
  "Affiliation 3",
  "Affiliation 4",
  "Affiliation 5",
]

const countNumbers = [
  { value: 47000, label: "Search Among 47,000+ Active Jobs" },
  { value: 46000, label: "Find candidates from 46,000+ Skilled Flokkers" },
]

const Hero = () => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="careers-hero-wrapper bg-[#d0f1de]">
      <section className="heroSection">
        <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
          <div className="careerHeroContainer careerContainer">
            <div className="heroContent">
              <span className="careerHeroTag mb-[15px]">Get Started</span>
              <h1 className="careerHeroTitle mb-[25px]">
                Hire the best candidates <br className="hidden md:block" />
                for any job, online.
              </h1>
              <div className="flex items-center flex-wrap gap-[15px]">
                <h3 className="text-[25px] inline">Popular Search</h3>
                <ul className="flex flex-wrap gap-2">
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      managment
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      sales executive
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      engineers
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      accountant
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      brand strategist
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      sales
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      software engineer
                    </a>
                  </li>
                  <li className="inline-flex">
                    <button onClick={() => setShowMore(prev => !prev)}>
                      {showMore ? (
                        <>
                          <IoChevronBack className="inline mr-[2px] -mt-[2px]" /> See Less
                        </>
                      ) : (
                        <>
                          See More <IoChevronForward className="inline ml-[2px] -mt-[2px]" />
                        </>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
              {showMore && (
                <ul className="flex flex-wrap gap-2 mt-[20px]">
                  <li className="mb-[10px] md:mb-0">
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      managment
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      sales executive
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      engineers
                    </a>
                  </li>
                  <li>
                    <a className="bg-[#fff] text-[#adafca] font-medium py-[8px] px-[10px] rounded-3xl active:bg-[#fd6728] active:text-[#fff]" href="#">
                      <FaMagnifyingGlass className="inline mr-[5px]" />
                      accountant
                    </a>
                  </li>
                </ul>
              )}
              <div className="career-advance-search-tab mt-[30px]">
                <HeroForm />
              </div>

              <div className="careerFunFact mt-[30px] animate-up-4">
                <div className="details flex">
                  <p className="mb-0">
                    Search Among <span><CountUp end={countNumbers[0].value} duration={2.75} /></span>+ Active Jobs.
                    Find candidates from <span><CountUp end={countNumbers[1].value} duration={2.75} /></span>+  Skilled Flokkers
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="d-none d-lg-flex homeImg position-relative">
              <div className="">
                <img
                  style={{ height: "300px", width: "300px" }}
                  className="leftimage mb10 img-fluid"
                  src={bannerImg1}
                  alt=" image "
                />
              </div>
              <div className="rightImage ml10">
                <img
                  style={{ height: "510px" }}
                  className="img-3 img-fluid"
                  src={bannerImg3}
                  alt=" banner-img "
                />
                <div className="proofOfQuality iconbox-small1 text-start d-flex wow fadeInRight default-box-shadow4 bounce-x animate-up-1">
                  <span className="icon flaticon-badge vcenter-item">
                    <FaMedal />
                  </span>
                  <div className="details pl20">
                    <h6 className="mb-1">Proof of quality</h6>
                    <p className="text fz13 mb-0">Lorem Ipsum Dolar Amet</p>
                  </div>
                </div>
                <img
                  className="happyClient bounce-x"
                  src={happyClientImg}
                  alt=" image "
                />
              </div>
            </div> */}
          </div>
        </WrapInCenter>
      </section>
    </div >
  );
}



export default Hero;
