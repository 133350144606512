import React from "react";
import InputWithStaticData from "../../../ui/InputWithStaticData/InputWithStaticData";
import Verified from "../Status/Verified";
import { FaPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setIsAddingAffiliations } from "../../../../app/feature/userDashboard";

const VerifiedAffiliations = () => {
	const dispatch = useDispatch();
	return (
		<div className="widget-box">
			<div className="flex items-center justify-between cursor-pointer">
				<p className="widget-box-title">Verified Affiliations</p>
				<p
					className="flex items-center justify-end w-[60%] gap-2 font-semibold text-[16px]"
					onClick={() => {
						dispatch(setIsAddingAffiliations({ value: true }));
					}}
				>
					<FaPlus /> Add Affiliations
				</p>
			</div>

			<div className="widget-box-content">
				<form className="form">
					<div className="flex items-center justify-start gap-4">
						<InputWithStaticData
							id={"primary_affiliation"}
							label={"Primary Affiliation"}
							name={"primary-affiliation"}
							type={"text"}
							value={"Law - Nile University"}
						/>
						<Verified />
					</div>
					<div className="flex items-center justify-start gap-4 mt-5">
						<InputWithStaticData
							id={"other_affiliation"}
							label={"Other Affiliation"}
							name={"other-affiliation"}
							type={"text"}
							value={"Egyptian Expats - Canada"}
						/>
						<Verified />
					</div>
				</form>
			</div>
		</div>
	);
};

export default VerifiedAffiliations;
