import React from 'react'
import "./LatestJobs.css"
import WrapInCenter from '../../WrapInCenter/WrapInCenter'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { MdArrowRightAlt } from 'react-icons/md'
import LatestJobCard from './LatestJobCard';



const LatestJobs = ({ title, subTitle }) => {

    const renderJobCards = Array.from({ length: 5 }).map((_, idx) => (
        <SwiperSlide key={idx}>
            <LatestJobCard />
        </SwiperSlide>
    ))

    return (
        <div className='latest-careers-jobs'>
            <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
                <div className="header-career-section relative">
                    <h3>{title}</h3>
                    <p>{subTitle}</p>
                    <a className='font-semibold text-[18px] absolute top-[8px] right-0' href="#">View All <MdArrowRightAlt className='inline ml-[3px]' /></a>
                </div>
                <div className="job-swiper-wrapper relative">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={16}
                        // navigation={true}
                        // modules={[Navigation]}
                        // navigation={{
                        //     prevEl: '.job-swiper-prev',
                        //     nextEl: '.job-swiper-next',
                        // }}
                        className="job-swiper mt-[30px] md:!overflow-visible"
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            991: {
                                slidesPerView: 3,
                                spaceBetween: 16
                            }
                        }}
                    >
                        {renderJobCards}
                    </Swiper>
                    {/* <div className="latest-swiper-buttons hidden md:block">
                        <button className='job-swiper-prev -left-[25px]' style={{ border: "1px solid #eee" }}>
                            <FaChevronLeft />
                        </button>
                        <button className='job-swiper-next -right-[25px]' style={{ border: "1px solid #eee" }}>
                            <FaChevronRight />
                        </button>
                    </div> */}
                </div>

            </WrapInCenter>
        </div>
    )
}

export default LatestJobs