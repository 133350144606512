import { RiVerifiedBadgeFill } from "react-icons/ri";
const PostMainGroup = () => {
  return (
    <div class="widget-box no-padding">
      <div class="widget-box-status">
        <div class="widget-box-status-content">
          <div class="user-status">
            <a class="user-status-avatar" href="profile-timeline.html">
              <div class="user-avatar small no-border">
                <a className="user-status-avatar" href="profile-timeline.html">
                  <div className="after:content-[''] border-solid border-[1px] border-[#ff6728] after:w-[9px] after:h-[9px] after:absolute after:top-0 after:-left-2 after:rounded-full relative w-[45px] h-[45px] rounded-full">
                    <div className="relative w-full h-full rounded-full p-[1px] bg-white">
                      <img
                        src="/img/avatar/01.jpg"
                        alt="Profile"
                        className="w-full h-full rounded-full"
                      />
                      <div className="relative w-[16px] h-[16px] -top-[10px] -left-3">
                        <img
                          src="/img/download.png"
                          alt="Padge"
                          className="absolute w-full h-full"
                        />
                        <p className="absolute text-[10px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10 font-bold">
                          12
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </a>
            <p class="user-status-title medium flex items-center">
              <a
                class="bold"
                href="profile-timeline.html"
                className="flex color-black"
              >
                <p className="text-[#3e3f5e]">Marina Valentinex</p>
                <RiVerifiedBadgeFill
                  // className="absolute -bottom-1 -right-2"
                  color={"#36e9f7"}
                  size={16}
                />
                {/* <div class="tag-list"> */}
                <a class="tag-item mt-[-2px] ml-1 mr-1" href="newsfeed.html">
                  Pro Member
                </a>
                {/* </div> */}
                <p className="text-[#3e3f5e]">uploaded</p>{" "}
                <span class="bold">23 new photos</span>
              </a>{" "}
            </p>
            <p class="user-status-text small">2 years ago</p>
          </div>
          <p class="widget-box-status-text">
            inspiration :D Hey! I've compiled a list of the best cosplays out
            there for
          </p>
          <div class="picture-collage">
            <div class="picture-collage-row medium">
              <div class="picture-collage-item popup-picture-trigger">
                <div class="photo-preview">
                  <figure class="photo-preview-image liquid">
                    <img
                      src="img/cover/30.jpg"
                      alt="photo-preview-30"
                      className=" rounded-[12px]"
                    />
                  </figure>
                  <div class="photo-preview-info">
                    <div class="reaction-count-list">
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-thumbs-up">
                          <use xlinkHref="#svg-thumbs-up"></use>
                        </svg>
                        <p class="reaction-count-text">2</p>
                      </div>
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-comment">
                          <use xlinkHref="#svg-comment"></use>
                        </svg>
                        <p class="reaction-count-text">5</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="picture-collage-item popup-picture-trigger">
                <div class="photo-preview">
                  <figure class="photo-preview-image liquid">
                    <img
                      src="img/cover/45.jpg"
                      alt="photo-preview-45"
                      className=" rounded-[12px]"
                    />
                  </figure>
                  <div class="photo-preview-info">
                    <div class="reaction-count-list">
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-thumbs-up">
                          <use xlinkHref="#svg-thumbs-up"></use>
                        </svg>
                        <p class="reaction-count-text">2</p>
                      </div>
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-comment">
                          <use xlinkHref="#svg-comment"></use>
                        </svg>
                        <p class="reaction-count-text">5</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="picture-collage-row">
              <div class="picture-collage-item popup-picture-trigger">
                <div class="photo-preview">
                  <figure class="photo-preview-image liquid">
                    <img
                      src="img/cover/33.jpg"
                      alt="photo-preview-33"
                      className=" rounded-[12px]"
                    />
                  </figure>
                  <div class="photo-preview-info">
                    <div class="reaction-count-list">
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-thumbs-up">
                          <use xlinkHref="#svg-thumbs-up"></use>
                        </svg>
                        <p class="reaction-count-text">2</p>
                      </div>
                      <div class="reaction-count negative">
                        <svg className="reaction-count-icon icon-comment">
                          <use xlinkHref="#svg-comment"></use>
                        </svg>
                        <p class="reaction-count-text">5</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="picture-collage-item popup-picture-trigger">
                <div class="photo-preview">
                  <figure class="photo-preview-image liquid">
                    <img
                      src="img/cover/44.jpg"
                      alt="photo-preview-44"
                      className=" rounded-[12px]"
                    />
                  </figure>
                  <div class="photo-preview-info">
                    <div class="reaction-count-list">
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-thumbs-up">
                          <use xlinkHref="#svg-thumbs-up"></use>
                        </svg>
                        <p class="reaction-count-text">2</p>
                      </div>
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-comment">
                          <use xlinkHref="#svg-comment"></use>
                        </svg>
                        <p class="reaction-count-text">5</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="picture-collage-item">
                <a class="picture-collage-item-overlay" href="#">
                  <p class="picture-collage-item-overlay-text">+19</p>
                </a>
                <div class="photo-preview">
                  <figure class="photo-preview-image liquid">
                    <img
                      src="img/cover/37.jpg"
                      alt="photo-preview-37"
                      className=" rounded-[12px]"
                    />
                  </figure>
                  <div class="photo-preview-info">
                    <div class="reaction-count-list">
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-thumbs-up">
                          <use xlink="#svg-thumbs-up"></use>
                        </svg>
                        <p class="reaction-count-text">2</p>
                      </div>
                      <div class="reaction-count negative">
                        <svg class="reaction-count-icon icon-comment">
                          <use xlink="#svg-comment"></use>
                        </svg>
                        <p class="reaction-count-text">5</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-actions">
            <div class="content-action">
              <div class="meta-line">
                <div class="meta-line-list reaction-item-list">
                  <div class="reaction-item">
                    <img
                      class="reaction-image reaction-item-dropdown-trigger"
                      src="img/reaction/wow.png"
                      alt="reaction-wow"
                    />
                  </div>
                  <div class="reaction-item">
                    <img
                      class="reaction-image reaction-item-dropdown-trigger"
                      src="img/reaction/like.png"
                      alt="reaction-like"
                    />
                    {/* <div class="simple-dropdown padded reaction-item-dropdown">
                      <p class="simple-dropdown-text">
                        <img
                          class="reaction"
                          src="img/reaction/like.png"
                          alt="reaction-like"
                        />
                        <span class="bold">Like</span>
                      </p>
                      <p class="simple-dropdown-text">Sandra Strange</p>
                      <p class="simple-dropdown-text">Jane Rodgers</p>
                    </div> */}
                  </div>
                  <div class="reaction-item">
                    <img
                      class="reaction-image reaction-item-dropdown-trigger"
                      src="img/reaction/like.png"
                      alt="reaction-like"
                    />
                  </div>
                </div>
                <p class="meta-line-text">3</p>
              </div>
              {/* <div class="meta-line">
                <div class="meta-line-list user-avatar-list">
                  <div class="user-avatar micro no-stats">
                    <div class="user-avatar-border">
                      <div class="hexagon-22-24"></div>
                    </div>
                    <div class="user-avatar-content">
                      <div
                        class="hexagon-image-18-20"
                        data-src="img/avatar/03.jpg"
                      ></div>
                    </div>
                  </div>
                  <div class="user-avatar micro no-stats">
                    <div class="user-avatar-border">
                      <div class="hexagon-22-24"></div>
                    </div>
                    <div class="user-avatar-content">
                      <div
                        class="hexagon-image-18-20"
                        data-src="img/avatar/15.jpg"
                      ></div>
                    </div>
                  </div>
                  <div class="user-avatar micro no-stats">
                    <div class="user-avatar-border">
                      <div class="hexagon-22-24"></div>
                    </div>
                    <div class="user-avatar-content">
                      <div
                        class="hexagon-image-18-20"
                        data-src="img/avatar/14.jpg"
                      ></div>
                    </div>
                  </div>
                  <div class="user-avatar micro no-stats">
                    <div class="user-avatar-border">
                      <div class="hexagon-22-24"></div>
                    </div>
                    <div class="user-avatar-content">
                      <div
                        class="hexagon-image-18-20"
                        data-src="img/avatar/07.jpg"
                      ></div>
                    </div>
                  </div>
                </div>
                <p class="meta-line-text">4 Participants</p>
              </div> */}
            </div>
            <div class="content-action">
              <div class="meta-line">
                <p class="meta-line-link">3 Comments</p>
              </div>
              <div class="meta-line">
                <p class="meta-line-text">0 Shares</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="post-options">
        <div class="post-option-wrap">
          <div class="post-option reaction-options-dropdown-trigger">
            <svg class="post-option-icon icon-thumbs-up">
              <use xlink="#svg-thumbs-up"></use>
            </svg>
            <p class="post-option-text">React!</p>
          </div>
          <div class="reaction-options reaction-options-dropdown">
            <div class="reaction-option text-tooltip-tft" data-title="Like">
              <img
                class="reaction-option-image"
                src="img/reaction/like.png"
                alt="reaction-like"
              />
            </div>
            <div class="reaction-option text-tooltip-tft" data-title="Love">
              <img
                class="reaction-option-image"
                src="img/reaction/love.png"
                alt="reaction-love"
              />
            </div>
            <div class="reaction-option text-tooltip-tft" data-title="Dislike">
              <img
                class="reaction-option-image"
                src="img/reaction/dislike.png"
                alt="reaction-dislike"
              />
            </div>
            <div class="reaction-option text-tooltip-tft" data-title="Happy">
              <img
                class="reaction-option-image"
                src="img/reaction/happy.png"
                alt="reaction-happy"
              />
            </div>
            <div class="reaction-option text-tooltip-tft" data-title="Funny">
              <img
                class="reaction-option-image"
                src="img/reaction/funny.png"
                alt="reaction-funny"
              />
            </div>
            <div class="reaction-option text-tooltip-tft" data-title="Wow">
              <img
                class="reaction-option-image"
                src="img/reaction/wow.png"
                alt="reaction-wow"
              />
            </div>
            <div class="reaction-option text-tooltip-tft" data-title="Angry">
              <img
                class="reaction-option-image"
                src="img/reaction/angry.png"
                alt="reaction-angry"
              />
            </div>
            <div class="reaction-option text-tooltip-tft" data-title="Sad">
              <img
                class="reaction-option-image"
                src="img/reaction/sad.png"
                alt="reaction-sad"
              />
            </div>
          </div>
        </div>
        <div class="post-option">
          <svg class="post-option-icon icon-comment">
            <use xlink="#svg-comment"></use>
          </svg>
          <p class="post-option-text">Comment</p>
        </div>

        <div class="post-option">
          <svg class="post-option-icon icon-share">
            <use xlink="#svg-share"></use>
          </svg>

          <p class="post-option-text">Share</p>
        </div>
      </div> */}
    </div>
  );
};

export default PostMainGroup;
