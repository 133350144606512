import React, { useState } from "react";
import FormInput from "../../../ui/formInput/FormInput";
import Select from "../../../ui/select/Select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


const InputWithIcon = ({ label, id, name, value, setValue }) => {
	const [focused, setFocused] = useState(false);

	const handleFocus = () => {
		setFocused(true);
	};

	const handleBlur = (e) => {
		if (!e.target.value) {
			setFocused(false);
		}
	};

	return (
		<div className="form-item">
			<div className={`form-input social-input small
				${focused ? "active" : value !== "" && value ? "active" : ""}`}
			>
				<div className={`social-link no-hover ${name}`}>
					{name == "tiktok" ? <FaTiktok className={`icon-${name}`} /> :
						name == "linkedin" ? <FaLinkedin className={`icon-${name}`} /> :
						name == "twitter" ? <FaXTwitter className={`icon-${name}`} /> :
						<svg className={`icon-${name}`}>
							<use xlinkHref={`#svg-${name}`}></use>
						</svg>
					}
				</div>

				<label htmlFor={id}>{label}</label>
				<input
					type="text"
					id={id}
					name={name}
					onFocus={handleFocus}
					onBlur={handleBlur}
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
					}}
				/>
			</div>
		</div>
	)
}

const SocialLinks = () => {
	const { showContent } = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Connectivity</p>
					<h2 className="section-title">Social Links</h2>
				</div>
			</div>
			<div className="widget-box">
				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center justify-between gap-4 !flex-row max-[680px]:!flex-col max-[680px]:!gap-[1rem] mb-[1rem]">
							<InputWithIcon
								label={"Facebook"}
								name={"facebook"}
								id={"facebook"}
							/>
							<InputWithIcon
								label={"Twitter"}
								name={"twitter"}
								id={"twitter"}
							/>
						</div>
						<div className="flex items-center justify-between gap-4 !flex-row max-[680px]:!flex-col max-[680px]:!gap-[1rem] mb-[1rem]">
							<InputWithIcon
								label={"Linkedin"}
								name={"linkedin"}
								id={"linkedin"}
							/>
							<InputWithIcon
								label={"Youtube"}
								name={"youtube"}
								id={"youtube"}
							/>
						</div>
						<div className="flex items-center justify-between gap-4 !flex-row max-[680px]:!flex-col max-[680px]:!gap-[1rem]">
							<InputWithIcon
								label={"Tiktok"}
								name={"tiktok"}
								id={"tiktok"}
							/>
							<InputWithIcon
								label={"Instagram"}
								name={"instagram"}
								id={"instagram"}
							/>
						</div>
					</form>
				</div>
			</div>

			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default SocialLinks;
