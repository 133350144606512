import React from "react";
import FormInput from "../../../components/ui/formInput/FormInput";
import ReCaptchaV2 from "react-google-recaptcha";
import "./forgetPassword.css";

const ForgetPassword = ({ setIsForgetPassword }) => {
	const isMobileView = window.innerWidth <= 380;
	return (
		<div>
			<div className="success">E-mail has been sent successfully</div>
			<div className="fail">We can`t find this email</div>
			<form action="">
				<FormInput
					label="E-mail"
					type="email"
					id="email"
					name="email"
					icon="email"
				/>
				<div
					className="recaptch rc-anchor-normal"
					style={{
						transform: isMobileView ? "scale(0.7)" : "scale(0.85)",
						transformOrigin: "0 0",
					}}
				>
					<ReCaptchaV2
						sitekey="6LeM65opAAAAAI8w0KkzCPLXfwpLSrNiXjY_U5Qh"
						// size={isMobileView ? "compact" : "normal"}
						size={"normal"}
					/>
				</div>

				<div className="action-buttons">
					<button
						className="button medium secondary"
						onClick={(e) => {
							e.preventDefault();
						}}
					>
						Recover
					</button>
					<button
						className="button medium white"
						onClick={(e) => {
							e.preventDefault();
							setIsForgetPassword(false);
						}}
					>
						Login
					</button>
				</div>
			</form>
		</div>
	);
};

export default ForgetPassword;
