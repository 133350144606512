/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import img1 from "../../../assests/about/category1.jpg";
import img2 from "../../../assests/about/category-02.jpg";
import img3 from "../../../assests/about/category-03.jpg";
import img4 from "../../../assests/about/category-04.jpg";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import WrapInCenter from "../../WrapInCenter/WrapInCenter";
export default function Categories() {
  const inspiringProjects = [
    {
      id: 1,
      title: [
        { id: 1, name: "Gaming Coaching" },
        { id: 2, name: "Gaming Sessions" },
        { id: 2, name: "Gameplay Recording" },
        { id: 2, name: "Game Testing" },
        { id: 2, name: "Other Gaming Services" },
      ],
      imageSrc: img1,
      freelancerImageSrc: img1,Categoriy:'Gaming'
    },
    {
      id: 2,
      title: [
        { id: 1, name: "Self Improvement" },
        { id: 2, name: "Online Tutoring" },
        { id: 2, name: "Language Lessons" },
        { id: 2, name: "Career Counseling" },
        { id: 2, name: "Life Coaching" },
      ],
      imageSrc: img2,
      freelancerImageSrc: img2,
      Categoriy:'Self Improvement'
    },
    {
      id: 3,
      title: [
        { id: 1, name: "Fitness Lessons" },
        { id: 2, name: "Workout Plans" },
        { id: 2, name: "Recipe Creation" },
        { id: 2, name: "Meal Plans" },
        { id: 2, name: "Nutrition Coaching" },
      ],
      imageSrc: img3,
      freelancerImageSrc: img3,Categoriy:'Wellness & Fitness'
    },
    {
      id: 4,
      title: [
        { id: 1, name: "Product Photographers" },
        { id: 2, name: "Food Photographers" },
        { id: 2, name: "Lifestyle Photographers" },
        { id: 2, name: "Fashion Photographers" },
        { id: 2, name: "Outdor" },
      ],
      imageSrc: img4,
      freelancerImageSrc: img4,Categoriy:'Lifestyle'
    },
    {
        id: 1,
        title: [
          { id: 1, name: "Gaming Coaching" },
          { id: 2, name: "Gaming Sessions" },
          { id: 2, name: "Gameplay Recording" },
          { id: 2, name: "Game Testing" },
          { id: 2, name: "Other Gaming Services" },
        ],
        imageSrc: img1,
        freelancerImageSrc: img1,Categoriy:'Gaming'
      },
      {
        id: 2,
        title: [
          { id: 1, name: "Self Improvement" },
          { id: 2, name: "Online Tutoring" },
          { id: 2, name: "Language Lessons" },
          { id: 2, name: "Career Counseling" },
          { id: 2, name: "Life Coaching" },
        ],
        imageSrc: img2,
        freelancerImageSrc: img2,
        Categoriy:'Self Improvement'
      },
      {
        id: 3,
        title: [
          { id: 1, name: "Fitness Lessons" },
          { id: 2, name: "Workout Plans" },
          { id: 2, name: "Recipe Creation" },
          { id: 2, name: "Meal Plans" },
          { id: 2, name: "Nutrition Coaching" },
        ],
        imageSrc: img3,
        freelancerImageSrc: img3,Categoriy:'Wellness & Fitness'
      },
      {
        id: 4,
        title: [
          { id: 1, name: "Product Photographers" },
          { id: 2, name: "Food Photographers" },
          { id: 2, name: "Lifestyle Photographers" },
          { id: 2, name: "Fashion Photographers" },
          { id: 2, name: "Outdor" },
        ],
        imageSrc: img4,
        freelancerImageSrc: img4,Categoriy:'Lifestyle'
      },
  ];

  return (
    <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
      
    <section className="md:pb-90 pb-20">
      <div className="container">
        <div
          className="row align-items-center wow fadeInUp"
          data-wow-delay="00ms"
        >
          <div className="col-lg-9">
            <div className="main-title">
              <h2 className="title title-h2-inspiring-crea8">Categories</h2>
              {/* <p className="paragraph">Get some Inspirations from 1800+ skills</p> */}
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
        <div className="row wow fadeInUp" data-wow-delay="300ms">
          {inspiringProjects.map((elm, i) => (
            <div key={i} className="col-sm-6 col-xl-3">
              <div className="listing-style2">
                <div className="list-thumb">
                  <img
                    width={330}
                    height={250}
                    style={{ height: "fit-content" }}
                    className="w-100"
                    src={elm.imageSrc}
                    alt="image"
                  />
                  <p className="category-crea8-img text-nowrap">{elm.Categoriy}</p>
                </div>
                <div className="list-content">
                  <div className="list-meta">
                    <a className="" href="#">
                      <span className="categories-list-crea8">
                      {elm.title.map(((elmChild, i) => (
                          <Link key={i} className=" mb-1 text-lg flex items-center gap-3">{elmChild.name}
                          <FaArrowRight/></Link>
                        )))}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </WrapInCenter>
  );
}
