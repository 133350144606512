import React, { useState } from "react";
import Comment from "./Comment";
import PostCommentForm from "./PostCommentForm";
import CommentReply from "./CommentReply";

const PostComments = ({ children }) => {
  const [isAdditionalVisible, SetIsAdditonalVisible] = useState(false);
  return (
    <div className="post-comment-list">
      {children && <>{children}</>}
      <Comment />
      <CommentReply />
      <Comment />
      <CommentReply />

      <div
        className={`post-comment-list additional-comments ${
          isAdditionalVisible ? "block" : "hidden"
        }`}
      >
        <Comment />
        <Comment />
        <Comment />
        <Comment />
      </div>

      <p
        className="post-comment-heading !text-[1rem]"
        onClick={() => SetIsAdditonalVisible((prev) => !prev)}
      >
        Load More Comments <span className="highlighted">9+</span>
      </p>
    </div>
  );
};

export default PostComments;

{
  /* <div className="post-comment unread reply-2">
	<a
		className="user-avatar small no-outline"
		href="profile-timeline.html"
	>
		<div className="user-avatar-content">
			<div
				className="hexagon-image-30-32"
				data-src="img/avatar/03.jpg"
			></div>
		</div>

		<div className="user-avatar-progress">
			<div className="hexagon-progress-40-44"></div>
		</div>

		<div className="user-avatar-progress-border">
			<div className="hexagon-border-40-44"></div>
		</div>

		<div className="user-avatar-badge">
			<div className="user-avatar-badge-border">
				<div className="hexagon-22-24"></div>
			</div>

			<div className="user-avatar-badge-content">
				<div className="hexagon-dark-16-18"></div>
			</div>

			<p className="user-avatar-badge-text">16</p>
		</div>
	</a>

	<p className="post-comment-text">
		<a className="post-comment-text-author" href="profile-timeline.html">
			Nick Grissom
		</a>
		I wouldn't miss it for anything!! Love both streams!
	</p>

	<div className="content-actions">
		<div className="content-action">
			<div className="meta-line">
				<div className="meta-line-list reaction-item-list small">
					<div className="reaction-item">
						<img
							className="reaction-image reaction-item-dropdown-trigger"
							src="img/reaction/like.png"
							alt="reaction-like"
						/>

						<div className="simple-dropdown padded reaction-item-dropdown">
							<p className="simple-dropdown-text">
								<img
									className="reaction"
									src="img/reaction/like.png"
									alt="reaction-like"
								/>
								<span className="bold">Like</span>
							</p>

							<p className="simple-dropdown-text">Neko Bebop</p>
						</div>
					</div>
				</div>

				<p className="meta-line-text">1</p>
			</div>

			<div className="meta-line">
				<p className="meta-line-link light reaction-options-small-dropdown-trigger">
					React!
				</p>

				<div className="reaction-options small reaction-options-small-dropdown">
					<div
						className="reaction-option text-tooltip-tft"
						data-title="Like"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/like.png"
							alt="reaction-like"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Love"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/love.png"
							alt="reaction-love"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Dislike"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/dislike.png"
							alt="reaction-dislike"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Happy"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/happy.png"
							alt="reaction-happy"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Funny"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/funny.png"
							alt="reaction-funny"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Wow"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/wow.png"
							alt="reaction-wow"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Angry"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/angry.png"
							alt="reaction-angry"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Sad"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/sad.png"
							alt="reaction-sad"
						/>
					</div>
				</div>
			</div>

			<div className="meta-line">
				<p className="meta-line-link light">Reply</p>
			</div>

			<div className="meta-line">
				<p className="meta-line-timestamp">2 minutes ago</p>
			</div>

			<div className="meta-line settings">
				<div className="post-settings-wrap">
					<div className="post-settings post-settings-dropdown-trigger">
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div className="simple-dropdown post-settings-dropdown">
						<p className="simple-dropdown-link">Report Post</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div className="post-comment">
	<a
		className="user-avatar small no-outline"
		href="profile-timeline.html"
	>
		<div className="user-avatar-content">
			<div
				className="hexagon-image-30-32"
				data-src="img/avatar/02.jpg"
			></div>
		</div>

		<div className="user-avatar-progress">
			<div className="hexagon-progress-40-44"></div>
		</div>

		<div className="user-avatar-progress-border">
			<div className="hexagon-border-40-44"></div>
		</div>

		<div className="user-avatar-badge">
			<div className="user-avatar-badge-border">
				<div className="hexagon-22-24"></div>
			</div>

			<div className="user-avatar-badge-content">
				<div className="hexagon-dark-16-18"></div>
			</div>

			<p className="user-avatar-badge-text">19</p>
		</div>
	</a>

	<p className="post-comment-text">
		<a className="post-comment-text-author" href="profile-timeline.html">
			Destroy Dex
		</a>
		YEAHHH!!
		<a href="profile-timeline.html">@MarinaValentine</a> I really enjoyed
		your last stream and it also was really funny! Can't wait!
	</p>

	<div className="content-actions">
		<div className="content-action">
			<div className="meta-line">
				<p className="meta-line-link light reaction-options-small-dropdown-trigger">
					React!
				</p>

				<div className="reaction-options small reaction-options-small-dropdown">
					<div
						className="reaction-option text-tooltip-tft"
						data-title="Like"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/like.png"
							alt="reaction-like"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Love"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/love.png"
							alt="reaction-love"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Dislike"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/dislike.png"
							alt="reaction-dislike"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Happy"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/happy.png"
							alt="reaction-happy"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Funny"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/funny.png"
							alt="reaction-funny"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Wow"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/wow.png"
							alt="reaction-wow"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Angry"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/angry.png"
							alt="reaction-angry"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Sad"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/sad.png"
							alt="reaction-sad"
						/>
					</div>
				</div>
			</div>

			<div className="meta-line">
				<p className="meta-line-link light">Reply</p>
			</div>

			<div className="meta-line">
				<p className="meta-line-timestamp">27 minutes ago</p>
			</div>

			<div className="meta-line settings">
				<div className="post-settings-wrap">
					<div className="post-settings post-settings-dropdown-trigger">
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div className="simple-dropdown post-settings-dropdown">
						<p className="simple-dropdown-link">Report Post</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div className="post-comment">
	<a
		className="user-avatar small no-outline"
		href="profile-timeline.html"
	>
		<div className="user-avatar-content">
			<div
				className="hexagon-image-30-32"
				data-src="img/avatar/07.jpg"
			></div>
		</div>

		<div className="user-avatar-progress">
			<div className="hexagon-progress-40-44"></div>
		</div>

		<div className="user-avatar-progress-border">
			<div className="hexagon-border-40-44"></div>
		</div>

		<div className="user-avatar-badge">
			<div className="user-avatar-badge-border">
				<div className="hexagon-22-24"></div>
			</div>

			<div className="user-avatar-badge-content">
				<div className="hexagon-dark-16-18"></div>
			</div>

			<p className="user-avatar-badge-text">26</p>
		</div>
	</a>

	<p className="post-comment-text">
		<a className="post-comment-text-author" href="profile-timeline.html">
			Sarah Diamond
		</a>
		That sounds awesome Marina! And also thanks a lot for the art sneak
		peek! I went to the GameCon last week and had a great time playing the
		game's open demo.
	</p>

	<div className="content-actions">
		<div className="content-action">
			<div className="meta-line">
				<p className="meta-line-link light reaction-options-small-dropdown-trigger">
					React!
				</p>

				<div className="reaction-options small reaction-options-small-dropdown">
					<div
						className="reaction-option text-tooltip-tft"
						data-title="Like"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/like.png"
							alt="reaction-like"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Love"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/love.png"
							alt="reaction-love"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Dislike"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/dislike.png"
							alt="reaction-dislike"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Happy"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/happy.png"
							alt="reaction-happy"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Funny"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/funny.png"
							alt="reaction-funny"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Wow"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/wow.png"
							alt="reaction-wow"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Angry"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/angry.png"
							alt="reaction-angry"
						/>
					</div>

					<div
						className="reaction-option text-tooltip-tft"
						data-title="Sad"
					>
						<img
							className="reaction-option-image"
							src="img/reaction/sad.png"
							alt="reaction-sad"
						/>
					</div>
				</div>
			</div>

			<div className="meta-line">
				<p className="meta-line-link light">Reply</p>
			</div>

			<div className="meta-line">
				<p className="meta-line-timestamp">39 minutes ago</p>
			</div>

			<div className="meta-line settings">
				<div className="post-settings-wrap">
					<div className="post-settings post-settings-dropdown-trigger">
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div className="simple-dropdown post-settings-dropdown">
						<p className="simple-dropdown-link">Report Post</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> */
}
