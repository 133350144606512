import React, { useState } from "react";
import "../Search.css";
import Select from "../../ui/select/Select";
import FormInputwithIcon from "../../ui/formInputWithSearchIcon/FormInputwithIcon";
import InputSlider from "../../ui/inputSlider/Input-Slider";
import SummaryResults from "../SummaryResults/SummaryResults";
import MarketplaceCard from "./MarketplaceCard/MarketplaceCard";

const countries = [
	{ id: 1, value: "Egypt" },
	{ id: 2, value: "United Arab Emirates" },
	{ id: 3, value: "Bahrain" },
	{ id: 4, value: "Saudi" },
];

const initialItemsNumber = [
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
];

const ClassifiedsSearch = () => {
	const [isMarketVisible, setIsMarketVisible] = useState(true);
	const [numberOfMarkets, setNumberOfMarkets] = useState([
		...initialItemsNumber,
	]);
	const [selectedMarketplaceField, setSelectedMarketplaceField] =
		useState("Member Classifieds");

	return (
		<>
			<div
				className={`search-classified-component common-for-dir-cat ${
					isMarketVisible
						? "common-for-dir-cat-active"
						: "common-for-dir-cat-close"
				}`}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
					className="title-search-category"
					onClick={() => {
						setIsMarketVisible((prev) => !prev);
					}}
				>
					<div className="search-title">
						<h4>Search MarketPlace</h4>
						<p>Find what you need quickly and easily</p>
					</div>

					<svg
						class="slider-control-icon icon-small-arrow"
						style={{
							transform: `rotate(${isMarketVisible ? "270" : "90"}deg)`,
							cursor: "pointer",
							marginTop: "-40px",
							fill: `${isMarketVisible ? "#ff6728" : ""}`,
						}}
					>
						<use xlinkHref="#svg-small-arrow"></use>
					</svg>
				</div>

				{isMarketVisible && (
					<div className="marketPlace-items-container">
						<div className="marketPlace-items">
							<p
								className={`${
									selectedMarketplaceField === "Member Classifieds"
										? "active"
										: ""
								}`}
								onClick={() => {
									setSelectedMarketplaceField("Member Classifieds");
								}}
							>
								Member Classifieds
							</p>
							<p
								className={`${
									selectedMarketplaceField === "Business Products"
										? "active"
										: ""
								}`}
								onClick={() => {
									setSelectedMarketplaceField("Business Products");
								}}
							>
								Business Products
							</p>
							<p
								className={`${
									selectedMarketplaceField === "Real Estate" ? "active" : ""
								}`}
								onClick={() => {
									setSelectedMarketplaceField("Real Estate");
								}}
							>
								Real Estate
							</p>
							<p
								className={`${
									selectedMarketplaceField === "Automotives" ? "active" : ""
								}`}
								onClick={() => {
									setSelectedMarketplaceField("Automotives");
								}}
							>
								Automotives
							</p>
						</div>
					</div>
				)}

				{isMarketVisible && (
					<form>
						<FormInputwithIcon
							name={"keyword-classifieds"}
							id={"keyword_classifieds"}
							label={"Search For Items"}
						/>
						<div style={{ marginBottom: "5px" }}></div>
						<div className="search-row">
							<Select defaultValue={"Category: All"} optionsArray={countries} />
							<Select
								defaultValue={"Sub-Category: All"}
								optionsArray={countries}
							/>
						</div>
						<div className="search-row">
							<Select defaultValue={"Condition"} optionsArray={countries} />
						</div>
						<div className="search-row">
							<Select defaultValue={"Country: All"} optionsArray={countries} />
							<Select defaultValue={"City: All"} optionsArray={countries} />
						</div>
						<div className="search-row">
							<Select
								defaultValue={"University: All"}
								optionsArray={countries}
							/>
						</div>
						<div className="search-row">
							<InputSlider max={300} />
						</div>
						<button
							className="button medium secondary"
							style={{ marginTop: "15px" }}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "5px",
								}}
							>
								<svg class="icon-magnifying-glass">
									<use xlinkHref="#svg-magnifying-glass"></use>
								</svg>
								Search
							</div>
						</button>
					</form>
				)}
			</div>

			<SummaryResults
				numberofItems={numberOfMarkets}
				setNumberOfItems={setNumberOfMarkets}
			/>
			<>
				{(selectedMarketplaceField === "Business Products" ||
					selectedMarketplaceField === "Member Classifieds") && (
					<div className="grid grid-4-4-4 centered">
						<MarketplaceCard />
						<MarketplaceCard />
						<MarketplaceCard />
					</div>
				)}
			</>
		</>
	);
};

export default ClassifiedsSearch;
