import React from 'react'
import EmojiPicker from 'emoji-picker-react';


const EmojiPickerr = ({ onSelect }) => {
  
  return (
    <>
      <EmojiPicker onEmojiClick={onSelect} />
    </>
  )
}

export default EmojiPickerr