import { RiVerifiedBadgeFill } from "react-icons/ri";

const Moderator = () => {
  return (
    <div class="widget-box">
      <p class="widget-box-title">Group Moderators</p>

      <div class="widget-box-content">
        <div class="user-status-list">
          <div class="user-status request-small">
            <a class="user-status-avatar" href="group-timeline.html">
              <div class="user-avatar small no-border">
                <a className="user-status-avatar" href="profile-timeline.html">
                  <div className="after:content-[''] border-solid border-[1px] border-[#ff6728] after:w-[9px] after:h-[9px] after:absolute after:top-0 after:-left-2 after:rounded-full relative w-[45px] h-[45px] rounded-full">
                    <div className="relative w-full h-full rounded-full p-[1px] bg-white">
                      <img
                        src="/img/avatar/01.jpg"
                        alt="Profile"
                        className="w-full h-full rounded-full"
                      />
                      <div className="relative w-[16px] h-[16px] -top-[10px] -left-3">
                        <img
                          src="/img/download.png"
                          alt="Padge"
                          className="absolute w-full h-full"
                        />
                        <p className="absolute text-[10px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10 font-bold">
                          12
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </a>
            <p class="user-status-title flex items-center">
              <a class="bold" href="group-timeline.html">
                Twitch Streamers
              </a>
              <RiVerifiedBadgeFill
                // className="absolute -bottom-1 -right-2"
                color={"#36e9f7"}
                size={16}
              />
            </p>
            <p class="user-status-text small">@Lorem ipsum dolor sit.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moderator;
