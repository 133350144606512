import React, { useEffect, useState } from "react";
import "./SearchPage.css";
import "swiper/css";
import "swiper/css/navigation";
import MembersSearch from "../../components/SearchPageComponents/Members/MembersSearch";
import CommunitiesSearch from "../../components/SearchPageComponents/communities/CommunitiesSearch";
import BusinessesSearch from "../../components/SearchPageComponents/Bussinesses/Bussinesses";
import EventsSearch from "../../components/SearchPageComponents/Events/EventsSearch";
import JobsSearch from "../../components/SearchPageComponents/Jobs/JobsSearch";
import ClassifiedsSearch from "../../components/SearchPageComponents/ClassifiedsSearch/ClassifiedsSearch";
import Slider from "../../components/ui/slider/slider";
import { Swiper, SwiperSlide } from "swiper/react";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import AdsBanner from "../../components/AdsBanner/AdsBanner";

const searchCategories = [
	{
		name: "Members",
		select: "members",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				className="feather feather-users section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
				<circle cx="9" cy="7" r="4"></circle>
				<path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
				<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
			</svg>
		),
	},
	{
		name: "Communities",
		select: "communities",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				className="feather feather-grid section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<rect x="3" y="3" width="7" height="7"></rect>
				<rect x="14" y="3" width="7" height="7"></rect>
				<rect x="14" y="14" width="7" height="7"></rect>
				<rect x="3" y="14" width="7" height="7"></rect>
			</svg>
		),
	},
	{
		name: "Jobs",
		select: "jobs",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				className="feather feather-briefcase section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
				<path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
			</svg>
		),
	},
	{
		name: "Businesses",
		select: "businesses",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				className="feather feather-flag section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
				<line x1="4" y1="22" x2="4" y2="15"></line>
			</svg>
		),
	},
	{
		name: "MarketPlace",
		select: "marketPlace",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				className="feather feather-shopping-cart section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<circle cx="9" cy="21" r="1"></circle>
				<circle cx="20" cy="21" r="1"></circle>
				<path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
			</svg>
		),
	},
	{
		name: "Events",
		select: "events",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="feather feather-crosshair section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<circle cx="12" cy="12" r="10"></circle>
				<line x1="22" y1="12" x2="18" y2="12"></line>
				<line x1="6" y1="12" x2="2" y2="12"></line>
				<line x1="12" y1="6" x2="12" y2="2"></line>
				<line x1="12" y1="22" x2="12" y2="18"></line>
			</svg>
		),
	},
];

const bannersImages = [
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
];

const SearchPage = () => {
	const [searchCategory, setSearchCategory] = useState("members");
	const [width, setWidth] = useState(window.innerWidth);
	const breakpointForSlider = 460;

	useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);
		return () => {
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	return (
		<>
			<AdsBanner bannersImages={bannersImages} />
			<WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
				<div className=".marketPlace-items page-title">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						style={{
							fill: "#e91e63",
							scale: "1.1",
						}}
						className="menu-item-link-icon icon-directory"
					>
						<path d="M1,13 C0.44771525,13 0,12.5522847 0,12 C0,11.4477153 0.44771525,11 1,11 L1.41604369,11 C2.18760031,9.23409522 3.94968096,8 6,8 C7.82440303,8 9.42058775,8.97711876 10.2937745,10.4365766 C10.7845275,10.1580384 11.3764731,10 12,10 C12.6235269,10 13.2154725,10.1580384 13.7062255,10.4365766 C14.5794122,8.97711876 16.175597,8 18,8 C20.050319,8 21.8123997,9.23409522 22.5839563,11 L23,11 C23.5522847,11 24,11.4477153 24,12 C24,12.5522847 23.5522847,13 23,13 C23,15.7614237 20.7614237,18 18,18 C15.2385763,18 13,15.7614237 13,13 C13,12.8312503 13.0083597,12.6644531 13.0246876,12.5 L13,12.5 C13,12.2965729 12.6045695,12 12,12 C11.3954305,12 11,12.2965729 11,12.5 L10.9753124,12.5 C10.9916403,12.6644531 11,12.8312503 11,13 C11,15.7614237 8.76142375,18 6,18 C3.23857625,18 1,15.7614237 1,13 Z M6,16 C7.65685425,16 9,14.6568542 9,13 C9,11.3431458 7.65685425,10 6,10 C4.34314575,10 3,11.3431458 3,13 C3,14.6568542 4.34314575,16 6,16 Z M18,16 C19.6568542,16 21,14.6568542 21,13 C21,11.3431458 19.6568542,10 18,10 C16.3431458,10 15,11.3431458 15,13 C15,14.6568542 16.3431458,16 18,16 Z"></path>
					</svg>
					<div className="directory-title-desc">
						<h3 className="!text-[24px] leading-[1.2]">Directory</h3>
						<p style={{ lineHeight: "1.2", fontSize: "18px" }}>
							Select directory category below
						</p>
					</div>
				</div>
			</WrapInCenter>

			<WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
				<Slider
					setActiveTab={setSearchCategory}
					activeTab={searchCategory}
					tabItems={searchCategories}
				/>
			</WrapInCenter>
			<WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
				<div
					style={{
						marginTop: "21px",
					}}
				>
					{searchCategory === "members" && <MembersSearch />}
					{searchCategory === "communities" && <CommunitiesSearch />}
					{searchCategory === "businesses" && <BusinessesSearch />}
					{searchCategory === "events" && <EventsSearch />}
					{searchCategory === "jobs" && <JobsSearch />}
					{searchCategory === "marketPlace" && <ClassifiedsSearch />}
				</div>
			</WrapInCenter>
		</>
	);
};

export default SearchPage;
