import AdsBanner from "../../components/AdsBanner/AdsBanner";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";
import './Crea8.css'
import Footer from "../../components/Crea8/Footer/Footer";
import ListCrea8 from "../../components/Crea8/ListCrea8";
import Hero1 from "../../components/Crea8/Hero/Hero";
import Testimonial2 from "../../components/Crea8/Testmonia";
import InspiringService11 from "../../components/Crea8/InspiringCrea8";
import NeedSomething5 from "../../components/Crea8/NeedSomeOne";
import HighestRated1 from "../../components/Crea8/FeatureProject";
import TrendingService15 from "../../components/Crea8/Trending";
import AffiliationServices from "../../components/Crea8/AffiliationServices";
import AffiliationsProjects from "../../components/Crea8/AffiliationsProjects";
import PopularServices from "../../components/Crea8/PopularServices";
import FlokkancerServices from "../../components/Crea8/FlokkancerServices";
import CtaBanner16 from "../../components/Crea8/BannerCrea8";
import Categories from "../../components/Crea8/Footer/Categories";
import FindYourNeeds from "../../components/Crea8/FindYourNeeds";
import ClientSliderSec from "../../components/Crea8/ClientSliderSec";
const Crea8 = () => {
  return (
    <div className="crea8">
        <ListCrea8/>
        <Hero1/>
        <PopularServices/>
        <NeedSomething5/>
        <AffiliationServices/>
        <AffiliationsProjects/>
        <FlokkancerServices/>
        <CtaBanner16/>
        <HighestRated1/>
        <FindYourNeeds/>
        <ClientSliderSec/>
        <InspiringService11/>
        <TrendingService15/>
        <Testimonial2/>
        <Categories/>
        <Footer/>
    </div>
  )
}

export default Crea8
