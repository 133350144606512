import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaClock, FaFileContract, FaArrowRight } from "react-icons/fa"; 
import { MdOutlineKeyboardArrowRight,MdOutlineKeyboardArrowLeft } from "react-icons/md";

import FriendProfileImage from "../user-profile-image/UserProfileImage";
import WrapInCenter from "../WrapInCenter/WrapInCenter";

export default function HighestRated1() {
  const [showSwiper, setShowSwiper] = useState(false);
  const [activeTab, setActiveTab] = useState("Writing & Translation");

  useEffect(() => {
    setShowSwiper(true);
  }, []);

  const project1 = [
    {
      id: 1,
      title: "Full-stack Developer to help us to build our",
      img: "/images/team/client-1.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "United States",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "UX Designer",
      price: {
        min: 100,
        max: 150,
      },
      projectType: "Hourly",
      skills: "Adobe Photoshop",
      englishLevel: "Basic level",
      language: "Turkish",
      sort: "best-seller",
    },
    {
      id: 2,
      title: "Swift / SwiftUI Developer for B2B iOS apps",
      img: "/images/team/client-2.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "United Kingdom",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "Web Developers",
      price: {
        min: 200,
        max: 250,
      },
      projectType: "Fixed",
      skills: "Figma",
      englishLevel: "Bilingual",
      language: "English",
      sort: "recommended",
    },
    {
      id: 3,
      title: "English content writer for Fintech",
      img: "/images/team/client-3.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "United Kingdom",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "Illustrators",
      price: {
        min: 300,
        max: 350,
      },
      projectType: "Hourly",
      skills: "Sketch",
      englishLevel: "Fluent",
      language: "Italian",
      sort: "new-arrivals",
    },
    {
      id: 4,
      title: "Full-stack Developer to help us to build our",
      img: "/images/team/client-4.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "Canada",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "Node.js",
      price: {
        min: 400,
        max: 450,
      },
      projectType: "Fixed",
      skills: "Adobe XD",
      englishLevel: "Native",
      language: "Spanish",
      sort: "best-seller",
    },
    {
      id: 5,
      title: "Looking for team members for web agency",
      img: "/images/team/client-5.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "Germany",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "Project Managers",
      price: {
        min: 500,
        max: 550,
      },
      projectType: "Hourly",
      skills: "Balsamiq",
      englishLevel: "Basic level",
      language: "Turkish",
      sort: "recommended",
    },
    {
      id: 6,
      title: "Video animator to bring some illustrations to life",
      img: "/images/team/client-6.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "Turkey",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "UX Designer",
      price: {
        min: 600,
        max: 750,
      },
      projectType: "Fixed",
      skills: "Adobe Photoshop",
      englishLevel: "Bilingual",
      language: "English",
      sort: "new-arrivals",
    },
    {
      id: 7,
      title: "Full-stack Developer to help us to build our",
      img: "/images/team/client-7.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "United States",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "Web Developers",
      price: {
        min: 700,
        max: 850,
      },
      projectType: "Hourly",
      skills: "Figma",
      englishLevel: "Fluent",
      language: "Italian",
      sort: "best-seller",
    },
    {
      id: 8,
      title: "Full-stack Developer to help us to build our",
      img: "/images/team/client-8.png",
      brief:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
      location: "United Kingdom",
      tags: ["Figma", "Sketch", "HTML5"],
      category: "Illustrators",
      price: {
        min: 800,
        max: 950,
      },
      projectType: "Fixed",
      skills: "Sketch",
      englishLevel: "Native",
      language: "Spanish",
      sort: "recommended",
    },
  ];
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
        <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
      <section className="md:pb-90 pb-20">
        <div className="container">
          <div className="row align-items-center wow fadeInUp">
            <div className="col-lg-9">
              <div className="main-title">
                <h2 className="title title-h2-inspiring-crea8">
                  Featured Projects
                </h2>
                <p className="paragraph text-black">
                  Find and bid on gigs that match your skills
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="text-start text-lg-end mb-4">
                <Link
                  className="ud-btn btn-light-thm bdrs90 d-flex gap-2 items-center md:justify-end justify-start
                   font-extrabold text-lg"
                  to="/freelancer-1"
                >
                  All Projects
                  <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
          <div className="row tabs-crea8-futuredproject">
          <div className="col-lg-12">
          <ul className="flex items-center gap-4 flex-wrap ">
                {["Writing & Translation", "All Services", "Graphics & Design", "Data Analysis", "Analytics & Strategy", "Search", "Video & Animation"].map((tab) => (
                  <li
                    key={tab}
                    className={`list-tab-future-crea8 ${activeTab === tab ? "list-tab-future-crea8-active" : ""}`}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>

          </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ui-hightest-rated">
                {showSwiper && (
                  <Swiper
                    spaceBetween={30}
                    navigation={{
                      prevEl: ".btn__prev__002",
                      nextEl: ".btn__next__002",
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiperr"
                    loop={true}
                    pagination={{
                      el: ".swiper__pagination__002",
                      clickable: true,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                      },
                      768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                      },
                      992: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                      },
                    }}
                  >
                    {project1.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="freelancer-style1 bdrs12 bdr1 hover-box-shadow">
                          <div className="d-flex align-items-center gap-3">
                            <div className="thumb w60 position-relative rounded-circle">
                              <FriendProfileImage/>
                            </div>
                            <div>
                              <span className="mb-0  dark-color list-inline-item mb5-sm
                              flex items-baseline text-base gap-1">
                                <FaMapMarkerAlt className=" text-gray-300" /> 
                                {item.location}
                              </span>
                              <span className="mb-0 fz14 dark-color list-inline-item mb5-sm
                              flex items-center text-base gap-1">
                                <FaClock className=" text-gray-300" /> 
                                2 hours ago
                              </span>
                              <span className="mb-0 fz14 dark-color list-inline-item mb5-sm
                              flex items-center text-base gap-1">
                                <FaFileContract className=" text-gray-300" /> 
                                1 Received
                              </span>
                            </div>
                          </div>
                          <div className="details mt-4 text-start">
                            <h5 className="title line-clamp2 text-xl my-3">{item.title}</h5>
                            <p className="text mt10 text-md line-clamp2  my-3">
                              {item.brief
                                ? item.brief
                                : "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."}
                            </p>
                            <div className="skill-tags flex items-center justify-center my-3">
                              {item.tags.map((tag, i) => (
                                <span
                                  key={i}
                                  className={`tag ${i === 1 ? "mx-1" : ""}`}
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                            <h4>
                              <small className="text text-lg text-gray-500 me-3">
                                Hourly Rate
                              </small>{" "}
                              ${item.price.min} - ${item.price.max}
                            </h4>
                            <div className="d-grid mt15">
                              <Link
                                to={`/project-single/${item.id}`}
                                className="ud-btn btn-thm-border bdrs12 default-box-shadow1
                                flex-btn-feature-project mt-3 border-raduis-box-trending-crea8"
                              >
                                Send Proposal
                                <FaArrowRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button className="swiper__btn btn__prev__002 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowLeft />
                </button>
              </div>
              <div className="col-auto">
                <div className="swiper__pagination swiper__pagination__002"></div>
              </div>
              <div className="col-auto">
                <button className="swiper__btn btn__next__002 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowRight  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      </WrapInCenter>
    </>
  );
}
