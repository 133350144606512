import React, { useEffect, useRef, useState } from "react";
import { IoArrowBackCircle, IoArrowForwardCircle, IoEye } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { FiPlusCircle } from "react-icons/fi";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLargeHidden,
  setIsChatOpen,
  setIsChatsOpen,
  setSearchInput,
  setIsCartVisible,
  setIsFriendsVisible,
  setIsMessagesVisible,
  setIsNotificationsVisible,
  setIsSettingsVisible,
  setIsOverlayVisible,
  setIsMobileNavOpen,
  setIsMiniMenuHidden,
} from "../../../app/feature/outletSlice";
import { useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { MdAdminPanelSettings, MdBlock } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { Modal, Tooltip } from "antd";
import { setSelectedForm } from "../../../app/feature/userDashboard";
import FriendsDropDown from "./FriendsDropDown/FriendsDropDown";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLargeHidden = useSelector((state) => state.outlet.isLargeHidden);
  const searchInput = useSelector((state) => state.outlet.searchInput);

  const [isModalOpen, setIsModalOpen] = useState(false)

  const isCartVisible = useSelector(
    (state) => state.outlet.isCartVisible
  );
  const isMessagesVisible = useSelector(
    (state) => state.outlet.isMessagesVisible
  );
  const isNotificationsVisible = useSelector(
    (state) => state.outlet.isNotificationsVisible
  );
  const isSettingsVisible = useSelector(
    (state) => state.outlet.isSettingsVisible
  );
  const isMiniMenuHidden = useSelector(
    (state) => state.outlet.isMiniMenuHidden
  );

  const cartDropRef = useRef(null)
  const notificationsDropRef = useRef(null)
  const messagesDropRef = useRef(null)
  const settingsDropRef = useRef(null)



  const [isLoad, setIsLoad] = useState(true);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const breakPoint = 680;

  const [width, setWidth] = useState(window.innerWidth);
  const [isHidden, setIsHidden] = useState(false);
  const [delayedIcon, setDelayedIcon] = useState(null);

  const toggleSidebar = () => {
    dispatch(setIsMiniMenuHidden({ value: !isMiniMenuHidden }));
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const threshold = 100;
    setIsHidden(scrollPosition > threshold);
  };

  const handleNavigate = (data) => {
    const state = { data: data };
    const encodedState = encodeURIComponent(JSON.stringify(state));
    // const url = `http://localhost:5173/dashboard?state=${encodedState}`;
    const url = `https://marketplace.flokky.app/dashboard?state=${encodedState}`;
    ;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const handlePathChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener("popstate", handlePathChange);

    return () => {
      window.removeEventListener("popstate", handlePathChange);
    };
  }, []);

  useEffect(() => {
    const onPageLoad = () => {
      console.log("Page loaded!");
      setIsLoad(false);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  const handleClickOutside = (event) => {
    if (cartDropRef.current && !cartDropRef.current.contains(event.target)) {
      dispatch(setIsCartVisible({ value: false }));
    }
    if (notificationsDropRef.current && !notificationsDropRef.current.contains(event.target)) {
      dispatch(setIsNotificationsVisible({ value: false }));
    }
    if (messagesDropRef.current && !messagesDropRef.current.contains(event.target)) {
      dispatch(setIsMessagesVisible({ value: false }));
    }
    if (settingsDropRef.current && !settingsDropRef.current.contains(event.target)) {
      dispatch(setIsSettingsVisible({ value: false }));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);


  const [focused, setFocused] = useState(false)

  const inputRef = useRef()

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      setFocused(false);
    }
  };


  return (
    <header className="header">
      <div className="header-actions">
        <div className="header-brand">
          <div className="logo">
            <Link to={"/"} onClick={() => setCurrentPath("/")}>
              <img
                src="/img/flockyFavicon.svg"
                alt=""
                className="icon-logo-flokky small !max-w-fit"
              />
            </Link>
          </div>

          <h1 className="header-brand-text">
            <Link to={"/"} onClick={() => setCurrentPath("/")}>
              <img
                src="/img/FlockyLogoWhite.svg"
                alt="Logo"
                className="flokkyImage"
              />
            </Link>
          </h1>
        </div>
      </div>

      <div className="header-actions">
        <div
          className="sidemenu-trigger navigation-widget-trigger"
          onClick={() => {
            dispatch(setIsLargeHidden({ value: !isLargeHidden }));
            dispatch(setIsChatsOpen({ value: false }));
            dispatch(setIsChatOpen({ value: false }));
          }}
        >
          <svg className="icon-grid">
            <use xlinkHref="#svg-grid"></use>
          </svg>
        </div>

        <div
          className="mobilemenu-trigger navigation-widget-mobile-trigger"
          onClick={() => {
            dispatch(setIsOverlayVisible({ value: true }));
            dispatch(setIsMobileNavOpen({ value: true }));
          }}
        >
          <svg className="icon-grid !fill-[#ffc088]">
            <use xlinkHref="#svg-grid"></use>
          </svg>
        </div>
        {location.pathname.toString().includes("/marketplace") && (
          <div
            className="navigation-widget-mobile-toggle toggle-mini-menu-icon"
            onClick={toggleSidebar}
          >
            {isMiniMenuHidden ? (
              <IoArrowForwardCircle size={23} color="white" />
            ) : (
              <IoArrowBackCircle size={23} color="white" />
            )}
          </div>
        )}

        <nav className="navigation">
          <ul className="menu-main">
            <li className="menu-main-item">
              <a className="menu-main-item-link" href="/">
                Home
              </a>
            </li>

            <li className="menu-main-item">
              <a className="menu-main-item-link" href="#">
                Communities
              </a>
            </li>

            <li className="menu-main-item">
              <a className="menu-main-item-link" href="https://marketplace.flokky.app">
                Marketplace
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="header-actions search-bar"
        style={{ position: "relative" }}
      >
        <div
          className={`interactive-input dark ${searchInput !== "" ? "active" : ""
            }`}
        >
          <input
            type="text"
            id="search-main"
            name="search_main"
            placeholder="Search here for people or groups"
            value={searchInput}
            style={{
              border: "transparent !important",
            }}
            onChange={(e) => {
              dispatch(setSearchInput({ value: e.target.value }));
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 27) {
                dispatch(setSearchInput({ value: "" }));
              }
            }}
          />
          <div className="interactive-input-icon-wrap">
            <svg className="interactive-input-icon icon-magnifying-glass">
              <use xlinkHref="#svg-magnifying-glass"></use>
            </svg>
          </div>

          <div
            className="interactive-input-action"
            onClick={() => dispatch(setSearchInput({ value: "" }))}
          >
            <svg className="interactive-input-action-icon icon-cross-thin">
              <use xlinkHref="#svg-cross-thin"></use>
            </svg>
          </div>
        </div>

        <div
          className="dropdown-box padding-bottom-small header-search-dropdown"
          style={{
            position: "absolute",
            zIndex: 9999,
            top: "57px",
            left: "translate(50%, 50%)",
            maxWidth: "100%",
            opacity: `${searchInput === "" ? "0" : "1"}`,
            visibility: `${searchInput === "" ? "hidden" : "visible"}`,
            transform: `translate(0px, ${searchInput !== "" ? "0px" : "-40px"
              })`,
            transition:
              "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
          }}
        >
          <div className="dropdown-box-category">
            <p className="dropdown-box-category-title">Members</p>
          </div>

          <div className="dropdown-box-list small no-scroll">
            <a className="dropdown-box-list-item" href="profile-timeline.html">
              <div className="user-status notification">
                <div className="user-status-avatar">
                  <div className="user-avatar small no-outline">
                    <div className="user-avatar-content">
                      <div
                        className="hexagon-image-30-32"
                        data-src="img/avatar/05.jpg"
                      ></div>
                    </div>

                    <div className="user-avatar-progress">
                      <div className="hexagon-progress-40-44"></div>
                    </div>

                    <div className="user-avatar-progress-border">
                      <div className="hexagon-border-40-44"></div>
                    </div>

                    <div className="user-avatar-badge">
                      <div className="user-avatar-badge-border">
                        <div className="hexagon-22-24"></div>
                      </div>

                      <div className="user-avatar-badge-content">
                        <div className="hexagon-dark-16-18"></div>
                      </div>

                      <p className="user-avatar-badge-text">12</p>
                    </div>
                  </div>
                </div>

                <p className="user-status-title">
                  <span className="bold">Neko Bebop</span>
                </p>

                <p className="user-status-text">1 friends in common</p>

                <div className="user-status-icon">
                  <svg className="icon-friend">
                    <use xlinkHref="#svg-friend"></use>
                  </svg>
                </div>
              </div>
            </a>

            <a className="dropdown-box-list-item" href="profile-timeline.html">
              <div className="user-status notification">
                <div className="user-status-avatar">
                  <div className="user-avatar small no-outline">
                    <div className="user-avatar-content">
                      <div
                        className="hexagon-image-30-32"
                        data-src="img/avatar/15.jpg"
                      ></div>
                    </div>

                    <div className="user-avatar-progress">
                      <div className="hexagon-progress-40-44"></div>
                    </div>

                    <div className="user-avatar-progress-border">
                      <div className="hexagon-border-40-44"></div>
                    </div>

                    <div className="user-avatar-badge">
                      <div className="user-avatar-badge-border">
                        <div className="hexagon-22-24"></div>
                      </div>

                      <div className="user-avatar-badge-content">
                        <div className="hexagon-dark-16-18"></div>
                      </div>

                      <p className="user-avatar-badge-text">7</p>
                    </div>
                  </div>
                </div>

                <p className="user-status-title">
                  <span className="bold">Tim Rogers</span>
                </p>

                <p className="user-status-text">4 friends in common</p>

                <div className="user-status-icon">
                  <svg className="icon-friend">
                    <use xlinkHref="#svg-friend"></use>
                  </svg>
                </div>
              </div>
            </a>
          </div>

          <div className="dropdown-box-category">
            <p className="dropdown-box-category-title">Groups</p>
          </div>

          <div className="dropdown-box-list small no-scroll">
            <a className="dropdown-box-list-item" href="group-timeline.html">
              <div className="user-status notification">
                <div className="user-status-avatar">
                  <div className="user-avatar small no-border">
                    <div className="user-avatar-content">
                      <div
                        className="hexagon-image-40-44"
                        data-src="img/avatar/24.jpg"
                      ></div>
                    </div>
                  </div>
                </div>

                <p className="user-status-title">
                  <span className="bold">Cosplayers of the World</span>
                </p>

                <p className="user-status-text">139 members</p>

                <div className="user-status-icon">
                  <svg className="icon-group">
                    <use xlinkHref="#svg-group"></use>
                  </svg>
                </div>
              </div>
            </a>
          </div>

          <div className="dropdown-box-category">
            <p className="dropdown-box-category-title">Marketplace</p>
          </div>

          <div className="dropdown-box-list small no-scroll">
            <a
              className="dropdown-box-list-item"
              href="marketplace-product.html"
            >
              <div className="user-status no-padding-top">
                <div className="user-status-avatar">
                  <figure className="picture small round liquid">
                    <img src="img/marketplace/items/07.jpg" alt="item-07" />
                  </figure>
                </div>

                <p className="user-status-title">
                  <span className="bold">Mercenaries White Frame</span>
                </p>

                <p className="user-status-text">By Neko Bebop</p>

                <div className="user-status-icon">
                  <svg className="icon-marketplace">
                    <use xlinkHref="#svg-marketplace"></use>
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="header-mobile !gap-[25px] !w-[272px]">

        {!focused && (
          <>
            <div className="action-list-item-wrap" ref={cartDropRef}>
              <div className={`action-list-item header-dropdown-trigger ${isCartVisible ? "active" : ""}`}
                onClick={() => dispatch(setIsCartVisible({ value: !isCartVisible }))} >
                <FiPlusCircle className="text-white text-[22px]" />
              </div>

              <div
                className={`dropdown-box no-padding-bottom header-dropdown`}
                style={{
                  width: 300,
                  padding: "10px 20px",
                  position: "absolute",
                  zIndex: `${isCartVisible ? "9999" : "-1"}`,
                  top: "64px",
                  right: "20px",
                  opacity: `${isCartVisible ? "1" : "0"}`,
                  visibility: `${isCartVisible ? "visible" : "hidden"}`,
                  transform: `translate(0px, ${isCartVisible ? "0px" : "-40px"})`,
                  transition:
                    "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
                }}
              >
                <ul>
                  <li>
                    <a
                      href="#"
                      className="flex items-center gap-[8px] py-[8px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#9c27b0"
                          d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                        ></path>
                      </svg>
                      Add A Listing
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center gap-[8px] py-[8px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#9c27b0"
                          d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                        ></path>
                      </svg>
                      Create Ad
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center gap-[8px] py-[8px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#9c27b0"
                          d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                        ></path>
                      </svg>
                      Create Job
                    </a>
                  </li>
                  <li>
                    <a href="#"
                      className="flex items-center gap-[8px] py-[8px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#009da0"
                          d="M18,20H6V18H4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V18H18V20M14,2H6A2,2 0 0,0 4,4V12H6V4H14V8H18V12H20V8L14,2M11,16H8V14H11V16M16,16H13V14H16V16M3,14H6V16H3V14M21,16H18V14H21V16Z"
                        ></path>
                      </svg>{" "}
                      Create Blog
                    </a>
                  </li>
                  <li>
                    <a href="#" className="flex items-center gap-[8px] py-[8px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#f25e4e"
                          d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"
                        ></path>
                      </svg>{" "}
                      Create Event
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex items-center gap-[8px] py-[8px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#f79f58"
                          d="M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z"
                        ></path>
                      </svg>{" "}
                      Create Business Profile
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <Link to="/user-dashboard" onClick={() => dispatch(setSelectedForm({ value: "Connection Requests" }))}>
              <svg className="action-list-item-icon icon-friend !fill-white">
                <use xlinkHref="#svg-friend"></use>
              </svg>
            </Link>

            <Link to="/user-dashboard" onClick={() => dispatch(setSelectedForm({ value: "Notifications" }))}
            >
              <svg className="action-list-item-icon icon-notification">
                <use xlinkHref="#svg-notification"></use>
              </svg>
            </Link>

            <Link to={"https://chat.flokky.app/"}>
              <svg className="action-list-item-icon icon-messages">
                <use xlinkHref="#svg-messages"></use>
              </svg>
            </Link>
          </>
        )}

        <div className="mobile-search-bar relative transition-[width] duration-[0.5s] ml-auto" style={{ width: focused ? "100%" : "45px" }} >
          <div className={`interactive-input dark !block !h-[45px]  ${searchInput !== "" ? "active" : ""
            }`}
          >
            <input
              ref={inputRef}
              type="text"
              id="search-main"
              name="search_main"
              style={{ paddingRight: focused ? "50px" : "27px" }}
              placeholder="Search here in chats and media"
              value={searchInput}
              onChange={(e) => {
                dispatch(setSearchInput({ value: e.target.value }));
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                if (e.keyCode === 27) {
                  dispatch(setSearchInput({ value: "" }));
                }
              }}
            />
            <div className="interactive-input-icon-wrap"
              style={{ right: focused ? "0px" : "-7px" }}
            >
              <svg className="interactive-input-icon icon-magnifying-glass">
                <use xlinkHref="#svg-magnifying-glass"></use>
              </svg>
            </div>

            <div
              className="interactive-input-action"
              onClick={() => {
                dispatch(setSearchInput({ value: "" }))
                inputRef?.current?.focus();
              }}
            >
              <svg className="interactive-input-action-icon icon-cross-thin">
                <use xlinkHref="#svg-cross-thin"></use>
              </svg>
            </div>
          </div>

          <div
            className="dropdown-box padding-bottom-small header-search-dropdown"
            style={{
              position: "absolute",
              zIndex: 9999,
              top: "57px",
              left: "translate(50%, 50%)",
              maxWidth: "100%",
              opacity: `${searchInput === "" ? "0" : "1"}`,
              visibility: `${searchInput === "" ? "hidden" : "visible"}`,
              transform: `translate(0px, ${searchInput !== "" ? "0px" : "-40px"
                })`,
              transition:
                "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
            }}
          >
            <div className="dropdown-box-category">
              <p className="dropdown-box-category-title">Members</p>
            </div>

            <div className="dropdown-box-list small no-scroll">
              <a className="dropdown-box-list-item" href="profile-timeline.html">
                <div className="user-status notification">
                  <div className="user-status-avatar">
                    <div className="user-avatar small no-outline">
                      <div className="user-avatar-content">
                        <div
                          className="hexagon-image-30-32"
                          data-src="img/avatar/05.jpg"
                        ></div>
                      </div>

                      <div className="user-avatar-progress">
                        <div className="hexagon-progress-40-44"></div>
                      </div>

                      <div className="user-avatar-progress-border">
                        <div className="hexagon-border-40-44"></div>
                      </div>

                      <div className="user-avatar-badge">
                        <div className="user-avatar-badge-border">
                          <div className="hexagon-22-24"></div>
                        </div>

                        <div className="user-avatar-badge-content">
                          <div className="hexagon-dark-16-18"></div>
                        </div>

                        <p className="user-avatar-badge-text">12</p>
                      </div>
                    </div>
                  </div>

                  <p className="user-status-title">
                    <span className="bold">Neko Bebop</span>
                  </p>

                  <p className="user-status-text">1 friends in common</p>

                  <div className="user-status-icon">
                    <svg className="icon-friend">
                      <use xlinkHref="#svg-friend"></use>
                    </svg>
                  </div>
                </div>
              </a>

              <a className="dropdown-box-list-item" href="profile-timeline.html">
                <div className="user-status notification">
                  <div className="user-status-avatar">
                    <div className="user-avatar small no-outline">
                      <div className="user-avatar-content">
                        <div
                          className="hexagon-image-30-32"
                          data-src="img/avatar/15.jpg"
                        ></div>
                      </div>

                      <div className="user-avatar-progress">
                        <div className="hexagon-progress-40-44"></div>
                      </div>

                      <div className="user-avatar-progress-border">
                        <div className="hexagon-border-40-44"></div>
                      </div>

                      <div className="user-avatar-badge">
                        <div className="user-avatar-badge-border">
                          <div className="hexagon-22-24"></div>
                        </div>

                        <div className="user-avatar-badge-content">
                          <div className="hexagon-dark-16-18"></div>
                        </div>

                        <p className="user-avatar-badge-text">7</p>
                      </div>
                    </div>
                  </div>

                  <p className="user-status-title">
                    <span className="bold">Tim Rogers</span>
                  </p>

                  <p className="user-status-text">4 friends in common</p>

                  <div className="user-status-icon">
                    <svg className="icon-friend">
                      <use xlinkHref="#svg-friend"></use>
                    </svg>
                  </div>
                </div>
              </a>
            </div>

            <div className="dropdown-box-category">
              <p className="dropdown-box-category-title">Groups</p>
            </div>

            <div className="dropdown-box-list small no-scroll">
              <a className="dropdown-box-list-item" href="group-timeline.html">
                <div className="user-status notification">
                  <div className="user-status-avatar">
                    <div className="user-avatar small no-border">
                      <div className="user-avatar-content">
                        <div
                          className="hexagon-image-40-44"
                          data-src="img/avatar/24.jpg"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <p className="user-status-title">
                    <span className="bold">Cosplayers of the World</span>
                  </p>

                  <p className="user-status-text">139 members</p>

                  <div className="user-status-icon">
                    <svg className="icon-group">
                      <use xlinkHref="#svg-group"></use>
                    </svg>
                  </div>
                </div>
              </a>
            </div>

            <div className="dropdown-box-category">
              <p className="dropdown-box-category-title">Marketplace</p>
            </div>

            <div className="dropdown-box-list small no-scroll">
              <a
                className="dropdown-box-list-item"
                href="marketplace-product.html"
              >
                <div className="user-status no-padding-top">
                  <div className="user-status-avatar">
                    <figure className="picture small round liquid">
                      <img src="img/marketplace/items/07.jpg" alt="item-07" />
                    </figure>
                  </div>

                  <p className="user-status-title">
                    <span className="bold">Mercenaries White Frame</span>
                  </p>

                  <p className="user-status-text">By Neko Bebop</p>

                  <div className="user-status-icon">
                    <svg className="icon-marketplace">
                      <use xlinkHref="#svg-marketplace"></use>
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="header-actions">
        <div className="progress-stat">
          <div className="bar-progress-wrap">
            <p className="bar-progress-info">
              Next:{" "}
              <span className="bar-progress-text">
                38<span className="bar-progress-unit">exp</span>
              </span>
            </p>
          </div>

          <div
            id="logged-user-level"
            className="progress-stat-bar"
            style={{ width: "110px", height: "4px", position: "relative" }}
          >
            <canvas
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "70px",
                height: "4px",
                backgroundColor: "#23d2e2",
                zIndex: "1",
              }}
            ></canvas>
            <canvas
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "110px",
                height: "4px",
                backgroundColor: "#4e4ac8",
              }}
            ></canvas>
          </div>
        </div>
      </div>

      <div className="header-actions">

        <div className="action-list dark">
          <div className="action-list-item-wrap text-white text-[20px]">
            <Tooltip placement="bottom" color="#fd6729" title={"Online Users"}>
              <div className="action-list-item gap-[8px] !cursor-default">
                <IoEye /> 5
              </div>
            </Tooltip>

          </div>

          <div className="action-list-item-wrap" ref={window.innerWidth > 680 ? cartDropRef : null}>
            <div className={`action-list-item header-dropdown-trigger ${isCartVisible ? "active" : ""}`}
              onClick={() => {
                dispatch(setIsCartVisible({ value: !isCartVisible }));
              }}
            >
              <FiPlusCircle className="text-white text-[22px]" />
            </div>

            <div className={`dropdown-box no-padding-bottom header-dropdown`}
              style={{
                width: 300,
                padding: "10px 20px",
                position: "absolute",
                zIndex: "9999",
                top: "64px",
                right: "6px",
                opacity: `${isCartVisible ? "1" : "0"}`,
                visibility: `${isCartVisible ? "visible" : "hidden"}`,
                transform: `translate(0px, ${isCartVisible ? "0px" : "-40px"})`,
                transition:
                  "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
              }}
            >
              <ul>
                <li>
                  <a
                    href="#"
                    className="flex items-center gap-[8px] py-[8px]"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9c27b0"
                        d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                      ></path>
                    </svg>
                    Add A Listing
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center gap-[8px] py-[8px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#9c27b0"
                        d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                      ></path>
                    </svg>
                    Create Ad
                  </a>
                </li>
                <li>
                  <a href="#"
                    className="flex items-center gap-[8px] py-[8px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#009da0"
                        d="M18,20H6V18H4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V18H18V20M14,2H6A2,2 0 0,0 4,4V12H6V4H14V8H18V12H20V8L14,2M11,16H8V14H11V16M16,16H13V14H16V16M3,14H6V16H3V14M21,16H18V14H21V16Z"
                      ></path>
                    </svg>{" "}
                    Create Blog
                  </a>
                </li>
                <li>
                  <a href="#" className="flex items-center gap-[8px] py-[8px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#f25e4e"
                        d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"
                      ></path>
                    </svg>{" "}
                    Create Event
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center gap-[8px] py-[8px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#f79f58"
                        d="M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z"
                      ></path>
                    </svg>{" "}
                    Create Business Profile
                  </a>
                </li>
              </ul>
            </div>
            <Modal
              title={null}
              open={isModalOpen}
              onCancel={() => setIsModalOpen(!isModalOpen)}
              footer={null}
              width={800}
              zIndex={100000000000}
            >
              <div className="add-lisitings-container">
                <h2 className="text-2xl font-bold mb-8">Add A Listings</h2>
                <div className="grid !grid-cols-1 md:!grid-cols-2 lg:!grid-cols-3">

                  <div className="upload-box"
                    onClick={() => handleNavigate(1)}
                  >
                    <svg
                      className="text-[#36e9f7]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                        <path
                          fill="currentColor"
                          d="M11 5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2zM5 5h4v4H5zm16 0a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2zm-6 0h4v4h-4zm-6 8a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2zm0 2H5v4h4zm4 2a1 1 0 0 1 1-1h2v-2a1 1 0 1 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 0 1-1-1"
                        />
                      </g>
                    </svg>
                    <p className="upload-box-title">Classifieds</p>
                  </div>

                  <div className="upload-box"
                    onClick={() => handleNavigate(2)}
                  >
                    <svg
                      className="text-[#36e9f7]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.923 15.02q-.154-.59-.6-1.1q-.446-.512-1.135-.766l-6.992-2.62q-.136-.05-.27-.06q-.134-.012-.307-.012H7v-2.34q0-.385.177-.743t.5-.575l4.885-3.479q.223-.159.458-.229t.478-.07t.49.07t.45.229l4.885 3.479q.323.217.5.575T20 8.12v6.898zM14.5 8.441q.162 0 .283-.12t.12-.284t-.12-.282t-.283-.121t-.283.12t-.12.283t.12.283t.283.121m-2 0q.162 0 .283-.12t.12-.284t-.12-.282t-.283-.121t-.283.12t-.12.283t.12.283t.283.121m2 2q.162 0 .283-.12t.12-.284t-.12-.282t-.283-.121t-.283.12t-.12.283t.12.283t.283.121m-2 0q.162 0 .283-.12t.12-.284t-.12-.282t-.283-.121t-.283.12t-.12.283t.12.283t.283.121m1.01 11.23q.198.055.482.048q.283-.007.48-.06L21 19.5q0-.696-.475-1.137q-.475-.44-1.179-.44h-5.158q-.498 0-1.02-.06t-.978-.22l-1.57-.526q-.162-.055-.237-.211t-.025-.315q.05-.14.202-.21t.313-.016l1.433.502q.407.146.893.217q.486.07 1.053.07h1.202q.283 0 .453-.162q.17-.161.17-.455q0-.389-.309-.81q-.308-.421-.716-.565l-6.021-2.21q-.137-.042-.273-.074t-.293-.032h-2.08v6.737zM2.385 19.922q0 .46.308.768q.309.309.769.309h.846q.46 0 .768-.309t.309-.768v-6q0-.46-.309-.768t-.768-.309h-.846q-.46 0-.769.309q-.308.308-.308.768z" />
                    </svg>

                    <p className="upload-box-title">Real Estate</p>

                    {/* <p className="upload-box-text">110x110px size minimum</p> */}
                  </div>

                  <div className="upload-box"
                    onClick={() => handleNavigate(3)}
                  >
                    <svg
                      className="text-[#36e9f7]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                    >
                      <path d="M14.42 7.5L16 11H8l1.42-3.5zM9 12c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1m6 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1m6-7v6c0 5.55-3.84 10.74-9 12c-5.16-1.26-9-6.45-9-12V5l9-4zm-3 7l-2.16-5c-.2-.58-.76-1-1.42-1h-5C8.76 6 8.2 6.42 8 7l-2 5v4c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h6v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1z" />
                    </svg>

                    <p className="upload-box-title">Automotive</p>

                    {/* <p className="upload-box-text">110x110px size minimum</p> */}
                  </div>

                </div>
              </div>
            </Modal>
          </div>

          <FriendsDropDown />

          <div className="action-list-item-wrap" ref={messagesDropRef}>
            <div
              className={`action-list-item header-dropdown-trigger ${isMessagesVisible ? "active" : ""
                }`}

              onClick={() => {
                dispatch(setIsMessagesVisible({ value: !isMessagesVisible }));
              }}
            >
              <Link>
                <svg className="action-list-item-icon icon-messages">
                  <use xlinkHref="#svg-messages"></use>
                </svg>
              </Link>
            </div>

            <div
              className="dropdown-box header-dropdown"
              style={{
                position: "absolute",
                zIndex: "9999",
                top: "64px",
                right: "6px",
                opacity: `${isMessagesVisible ? "1" : "0"}`,
                visibility: `${isMessagesVisible ? "visible" : "hidden"}`,
                transform: `translate(0px, ${isMessagesVisible ? "0px" : "-40px"
                  })`,
                transition:
                  "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
              }}
            >
              <div className="dropdown-box-header">
                <p className="dropdown-box-header-title">Messages</p>

                <div className="dropdown-box-header-actions">
                  <p className="dropdown-box-header-action">Mark all as Read</p>

                  <p className="dropdown-box-header-action">Settings</p>
                </div>
              </div>

              <div className="dropdown-box-list medium" data-simplebar>
                <Link
                  className="dropdown-box-list-item"
                  to="https://chat.flokky.app/"
                >
                  <div className="user-status">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <span className="bold">Bearded Wonder</span>
                    </p>

                    <p className="user-status-text">
                      Great! Then will meet with them at the party...
                    </p>

                    <p className="user-status-timestamp floaty">29 mins ago</p>
                  </div>
                </Link>

                <Link
                  className="dropdown-box-list-item"
                  to="https://chat.flokky.app/"
                >
                  <div className="user-status">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <span className="bold">Neko Bebop</span>
                    </p>

                    <p className="user-status-text">
                      Awesome! I'll see you there!
                    </p>

                    <p className="user-status-timestamp floaty">54 mins ago</p>
                  </div>
                </Link>

                <a
                  className="dropdown-box-list-item"
                  href="https://chat.flokky.app/"
                >
                  <div className="user-status">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <span className="bold">Nick Grissom</span>
                    </p>

                    <p className="user-status-text">
                      Can you stream that new game?
                    </p>

                    <p className="user-status-timestamp floaty">2 hours ago</p>
                  </div>
                </a>

                <a
                  className="dropdown-box-list-item"
                  href="https://chat.flokky.app/"
                >
                  <div className="user-status">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <span className="bold">Sarah Diamond</span>
                    </p>

                    <p className="user-status-text">
                      I'm sending you the latest news of the release...
                    </p>

                    <p className="user-status-timestamp floaty">16 hours ago</p>
                  </div>
                </a>

                <a
                  className="dropdown-box-list-item"
                  href="https://chat.flokky.app/"
                >
                  <div className="user-status">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <span className="bold">James Murdock</span>
                    </p>

                    <p className="user-status-text">
                      Great! Then will meet with them at the party...
                    </p>

                    <p className="user-status-timestamp floaty">7 days ago</p>
                  </div>
                </a>

                <Link
                  className="dropdown-box-list-item"
                  to="https://chat.flokky.app/"
                >
                  <div className="user-status">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <span className="bold">The Green Goo</span>
                    </p>

                    <p className="user-status-text">
                      Can you stream that new game?
                    </p>

                    <p className="user-status-timestamp floaty">10 days ago</p>
                  </div>
                </Link>
              </div>

              <Link className="dropdown-box-button primary" to={"https://chat.flokky.app/"}>
                View all Messages
              </Link>
            </div>
          </div>

          <div className="action-list-item-wrap" ref={notificationsDropRef}>
            <div
              className={`action-list-item header-dropdown-trigger ${isNotificationsVisible ? "active" : ""
                }`}
              onClick={() => {
                dispatch(setIsFriendsVisible({ value: false }));
                dispatch(setIsMessagesVisible({ value: false }));
                dispatch(
                  setIsNotificationsVisible({ value: !isNotificationsVisible })
                );
                dispatch(setIsSettingsVisible({ value: false }));
                dispatch(setIsCartVisible({ value: false }));
              }}
            >
              <svg className="action-list-item-icon icon-notification">
                <use xlinkHref="#svg-notification"></use>
              </svg>
            </div>

            <div
              className="dropdown-box header-dropdown"
              style={{
                position: "absolute",
                zIndex: "9999",
                top: "64px",
                right: "6px",
                opacity: `${isNotificationsVisible ? "1" : "0"}`,
                visibility: `${isNotificationsVisible ? "visible" : "hidden"}`,
                transform: `translate(0px, ${isNotificationsVisible ? "0px" : "-40px"
                  })`,
                transition:
                  "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
              }}
            >
              <div className="dropdown-box-header">
                <p className="dropdown-box-header-title">Notifications</p>

                <div className="dropdown-box-header-actions">
                  <p className="dropdown-box-header-action">Mark all as Read</p>

                  <a href="https://main.flokky.app/user-dashboard" className="dropdown-box-header-action">Settings</a>
                </div>
              </div>

              <div className="dropdown-box-list" data-simplebar>
                <div className="dropdown-box-list-item unread">
                  <div className="user-status notification">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <a className="bold" href="profile-timeline.html">
                        Nick Grissom
                      </a>{" "}
                      posted a comment on your{" "}
                      <a className="highlighted" href="profile-timeline.html">
                        status update
                      </a>
                    </p>

                    <p className="user-status-timestamp">2 minutes ago</p>

                  </div>
                </div>

                <div className="dropdown-box-list-item">
                  <div className="user-status notification">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <a className="bold" href="profile-timeline.html">
                        Sarah Diamond
                      </a>{" "}
                      left a like{" "}
                      <img
                        className="reaction"
                        src="img/reaction/like.png"
                        alt="reaction-like"
                      />{" "}
                      reaction on your{" "}
                      <a className="highlighted" href="profile-timeline.html">
                        status update
                      </a>
                    </p>

                    <p className="user-status-timestamp">17 minutes ago</p>

                  </div>
                </div>

                <div className="dropdown-box-list-item">
                  <div className="user-status notification">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <a className="bold" href="profile-timeline.html">
                        Destroy Dex
                      </a>{" "}
                      posted a comment on your{" "}
                      <a className="highlighted" href="profile-photos.html">
                        photo
                      </a>
                    </p>

                    <p className="user-status-timestamp">31 minutes ago</p>

                  </div>
                </div>

                <div className="dropdown-box-list-item">
                  <div className="user-status notification">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <a className="bold" href="profile-timeline.html">
                        The Green Goo
                      </a>{" "}
                      left a love{" "}
                      <img
                        className="reaction"
                        src="img/reaction/love.png"
                        alt="reaction-love"
                      />{" "}
                      reaction on your{" "}
                      <a className="highlighted" href="profile-timeline.html">
                        status update
                      </a>
                    </p>

                    <p className="user-status-timestamp">2 hours ago</p>

                  </div>
                </div>

                <div className="dropdown-box-list-item">
                  <div className="user-status notification">
                    <div className="user-chat-profile user-status-avatar !top-[5px]">
                      <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                      />
                      <img src="/img/avatar/03.jpg" alt="Profile" />
                      <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                      />
                      <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                      </div>
                    </div>

                    <p className="user-status-title">
                      <a className="bold" href="profile-timeline.html">
                        Neko Bebop
                      </a>{" "}
                      posted a comment on your{" "}
                      <a className="highlighted" href="profile-timeline.html">
                        status update
                      </a>
                    </p>

                    <p className="user-status-timestamp">3 hours ago</p>

                  </div>
                </div>
              </div>

              <Link
                className="dropdown-box-button primary"
                to="/user-dashboard"
                onClick={() => {
                  dispatch(setSelectedForm({ value: "Notifications" }));
                }}
              >
                View all Notifications
              </Link>
            </div>
          </div>
        </div>

        <div className="action-item-wrap" ref={settingsDropRef}>
          <div
            className={`action-item dark header-settings-dropdown-trigger ${isSettingsVisible ? "active" : ""
              }`}
            onClick={() => {
              dispatch(setIsFriendsVisible({ value: false }));
              dispatch(setIsMessagesVisible({ value: false }));
              dispatch(setIsNotificationsVisible({ value: false }));
              dispatch(setIsSettingsVisible({ value: !isSettingsVisible }));
              dispatch(setIsCartVisible({ value: false }));
            }}
          >
            <svg className="action-item-icon icon-settings">
              <use xlinkHref="#svg-settings"></use>
            </svg>
          </div>

          <div
            className="dropdown-navigation header-settings-dropdown"
            style={{
              position: "absolute",
              zIndex: "9999",
              top: "64px",
              right: "22px",
              opacity: `${isSettingsVisible ? "1" : "0"}`,
              visibility: `${isSettingsVisible ? "visible" : "hidden"}`,
              transform: `translate(0px, ${isSettingsVisible ? "0px" : "-40px"
                })`,
              transition:
                "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
            }}
          >
            <div className="dropdown-navigation-header">
              <div className="user-status">
                <div className="user-chat-profile user-status-avatar">
                  <img src="/img/avatar/01.jpg" alt="Profile" />
                </div>

                <p className="user-status-title">
                  <span className="bold">Hi Marina!</span>
                </p>

                <p className="user-status-text small">
                  <Link to="/user-profile-page">@marinavalentine</Link>
                </p>
              </div>
            </div>

            <p className="dropdown-navigation-category">My Profile</p>

            <Link
              className="dropdown-navigation-link"
              to="/user-dashboard"
              onClick={() => {
                dispatch(setSelectedForm({ value: "Profile Info" }));
              }}
            >
              Profile Info
            </Link>

            <Link className="dropdown-navigation-link" to="/affiliations">
              Affiliations
            </Link>

            <p className="dropdown-navigation-category">Account</p>

            <Link
              className="dropdown-navigation-link"
              to="hub-account-info.html"
            >
              Advertising
            </Link>

            <Link
              className="dropdown-navigation-link"
              to="/user-dashboard"
              onClick={() => {
                dispatch(setSelectedForm({ value: "Wallet & Credit" }));
              }}
            >
              Wallet
            </Link>

            <Link
              className="dropdown-navigation-link"
              to="/user-dashboard"
              onClick={() => {
                dispatch(setSelectedForm({ value: "Privacy" }));
              }}
            >
              Privacy
            </Link>

            <p className="dropdown-navigation-category">Groups</p>

            <Link
              className="dropdown-navigation-link"
              to="/user-dashboard"
              onClick={() => {
                dispatch(setSelectedForm({ value: "Manage Communtities" }));
              }}
            >
              Manage Communtities
            </Link>

            <p className="dropdown-navigation-category">Businesses</p>

            <Link
              className="dropdown-navigation-link"
              href="/businesses"
            >
              Manage Businesses
            </Link>

            <p className="dropdown-navigation-button button small secondary">
              Dark mood
            </p>

            <p className="dropdown-navigation-button button small secondary">
              Logout
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
