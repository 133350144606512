import React, { useRef } from "react";
import "../Search.css";
import MemberSearchItems from "./MemberSearchItems";
import EventSearchItems from "./EventSearchItems";
import BusinessesSearchItems from "./BusinessesSearchItems";
import JobsSearchItems from "./JobsSearchItems";

const SummaryResults = ({ numberofItems, setNumberOfItems, category }) => {
	const resultRef = useRef(null);

	const scroll = (direction) => {
		const { current } = resultRef;

		if (direction === "left") {
			current.scrollLeft -= 300;
		} else {
			current.scrollLeft += 300;
		}
	};

	return (
		<div className="small-results">
			<svg
				class="slider-control-icon icon-small-arrow visible"
				onClick={() => {
					scroll("left");
				}}
				style={{
					transform: `rotate(180deg)`,
					cursor: "pointer",
				}}
			>
				<use xlinkHref="#svg-small-arrow"></use>
			</svg>
			<div className="summary-results-container" ref={resultRef}>
				<div
					className={`${
						category === "members"
							? "summary-results"
							: category === "events"
							? "summary-results-events"
							: category === "businesses"
							? "summary-results-businesses"
							: category === "jobs"
							? "summary-results-jobs"
							: ""
					}`}
				>
					<p className="number-of-results">
						<span>234,556</span> Found
					</p>
					<div className="search-keywords">
						{category === "members" && <MemberSearchItems />}
						{category === "events" && <EventSearchItems />}
						{category === "businesses" && <BusinessesSearchItems />}
						{category === "jobs" && <JobsSearchItems />}
					</div>
				</div>
			</div>
			<svg
				class="slider-control-icon icon-small-arrow visible"
				onClick={() => {
					scroll("right");
				}}
				style={{
					cursor: "pointer",
				}}
			>
				<use xlinkHref="#svg-small-arrow"></use>
			</svg>

			<div className="results-view">
				<div className={`number-of-results`}>
					<p
						className={`${numberofItems.length === 32 ? "numberActive" : ""}`}
						onClick={() => {
							const itemsNumber = [
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
							];

							setNumberOfItems([...itemsNumber]);
						}}
					>
						32
					</p>
					<p
						className={`${numberofItems.length === 64 ? "numberActive" : ""}`}
						onClick={() => {
							const itemsNumber = [
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
							];

							setNumberOfItems([...itemsNumber]);
						}}
					>
						64
					</p>
					<p
						className={`${numberofItems.length === 128 ? "numberActive" : ""}`}
						onClick={() => {
							const itemsNumber = [
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								false,
								true,
								false,
								false,
								false,
							];

							setNumberOfItems([...itemsNumber]);
						}}
					>
						128
					</p>
				</div>
				<div
					class="view-action text-tooltip-tft-medium active grid-view-color grid-view-active-color"
					data-title="Small Grid"
				>
					<svg class="view-action-icon icon-small-grid-view">
						<use xlinkHref="#svg-small-grid-view"></use>
					</svg>
				</div>

				<div
					class="view-action text-tooltip-tft-medium grid-view-color"
					data-title="List"
				>
					<svg class="view-action-icon icon-list-grid-view">
						<use xlinkHref="#svg-list-grid-view"></use>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default SummaryResults;
