import React from "react";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import logo from "../../../assests/avatar/logo.svg";
import cover from "../../../assests/avatar/cover.jpg";
import { IoReload } from "react-icons/io5";

const SuggestedCommunities = () => {
  return (
    <div className="widget-box suggested-card">
      <h3 className="widget-box-title">Suggested Communities</h3>
      <span className="widget-relod">
        <IoReload />
      </span>
      <div className="widget-box-content relative">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className={`Suggestedcommunities-swiper !pb-[2rem]`}
        >
          <SwiperSlide className=" bg-transparent affiations-swipper-group">
            <div className="flex flex-col">
              <div className="user-preview">
                <figure className="user-preview-cover liquid">
                  <img
                    src={cover}
                    alt="cover-29"
                    className="rounded-t-[12px]"
                  />
                </figure>
                <div className="user-preview-info">
                  <div className="tag-sticker">
                    <svg className="tag-sticker-icon icon-public">
                      <use xlinkHref="#svg-public"></use>
                    </svg>
                  </div>
                  <div className="user-short-description">
                    <a
                      className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                      href=""
                    >
                      <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                        <img
                          src={logo}
                          alt="Community"
                          className="rounded-full"
                        />
                      </div>
                    </a>
                    <p className="user-short-description-title">
                      <a href="group-timeline.html">Cosplayers of the World</a>
                    </p>
                    <p className="user-short-description-text">
                      All cosplayers welcome!
                    </p>
                  </div>
                  {/* <div className="user-stats">
                              <div className="user-stat">
                                <p className="user-stat-title">139</p>
                                <p className="user-stat-text">members</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">105</p>
                                <p className="user-stat-text">posts</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">7.3k</p>
                                <p className="user-stat-text">visits</p>
                              </div>
                            </div> */}
                  <div className="communities-card-flokkers-list mt-4">
                    <div className="fldkker">
                      <img
                        src="/img/avatar/01.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/02.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/03.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/04.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/05.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/06.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker plus">
                      <p>+19K</p>
                    </div>
                  </div>
                  <div className="user-preview-actions">
                    <p className="button secondary full !flex items-center justify-center gap-2">
                      <svg className="button-icon icon-join-group">
                        <use xlinkHref="#svg-join-group"></use>
                      </svg>
                      Join Group!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className=" bg-transparent affiations-swipper-group">
            <div className="flex flex-col">
              <div className="user-preview">
                <figure className="user-preview-cover liquid">
                  <img
                    src={cover}
                    alt="cover-29"
                    className="rounded-t-[12px]"
                  />
                </figure>
                <div className="user-preview-info">
                  <div className="tag-sticker">
                    <svg className="tag-sticker-icon icon-public">
                      <use xlinkHref="#svg-public"></use>
                    </svg>
                  </div>
                  <div className="user-short-description">
                    <a
                      className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                      href=""
                    >
                      <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                        <img
                          src={logo}
                          alt="Community"
                          className="rounded-full"
                        />
                      </div>
                    </a>
                    <p className="user-short-description-title">
                      <a href="group-timeline.html">Cosplayers of the World</a>
                    </p>
                    <p className="user-short-description-text">
                      All cosplayers welcome!
                    </p>
                  </div>
                  {/* <div className="user-stats">
                              <div className="user-stat">
                                <p className="user-stat-title">139</p>
                                <p className="user-stat-text">members</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">105</p>
                                <p className="user-stat-text">posts</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">7.3k</p>
                                <p className="user-stat-text">visits</p>
                              </div>
                            </div> */}
                  <div className="communities-card-flokkers-list mt-4">
                    <div className="fldkker">
                      <img
                        src="/img/avatar/01.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/02.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/03.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/04.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/05.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/06.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker plus">
                      <p>+19K</p>
                    </div>
                  </div>
                  <div className="user-preview-actions">
                    <p className="button secondary full !flex items-center justify-center gap-2">
                      <svg className="button-icon icon-join-group">
                        <use xlinkHref="#svg-join-group"></use>
                      </svg>
                      Join Group!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className=" bg-transparent affiations-swipper-group">
            <div className="flex flex-col">
              <div className="user-preview">
                <figure className="user-preview-cover liquid">
                  <img
                    src={cover}
                    alt="cover-29"
                    className="rounded-t-[12px]"
                  />
                </figure>
                <div className="user-preview-info">
                  <div className="tag-sticker">
                    <svg className="tag-sticker-icon icon-public">
                      <use xlinkHref="#svg-public"></use>
                    </svg>
                  </div>
                  <div className="user-short-description">
                    <a
                      className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                      href=""
                    >
                      <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                        <img
                          src={logo}
                          alt="Community"
                          className="rounded-full"
                        />
                      </div>
                    </a>
                    <p className="user-short-description-title">
                      <a href="group-timeline.html">Cosplayers of the World</a>
                    </p>
                    <p className="user-short-description-text">
                      All cosplayers welcome!
                    </p>
                  </div>
                  {/* <div className="user-stats">
                              <div className="user-stat">
                                <p className="user-stat-title">139</p>
                                <p className="user-stat-text">members</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">105</p>
                                <p className="user-stat-text">posts</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">7.3k</p>
                                <p className="user-stat-text">visits</p>
                              </div>
                            </div> */}
                  <div className="communities-card-flokkers-list mt-4">
                    <div className="fldkker">
                      <img
                        src="/img/avatar/01.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/02.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/03.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/04.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/05.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/06.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker plus">
                      <p>+19K</p>
                    </div>
                  </div>
                  <div className="user-preview-actions">
                    <p className="button secondary full !flex items-center justify-center gap-2">
                      <svg className="button-icon icon-join-group">
                        <use xlinkHref="#svg-join-group"></use>
                      </svg>
                      Join Group!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className=" bg-transparent affiations-swipper-group">
            <div className="flex flex-col">
              <div className="user-preview">
                <figure className="user-preview-cover liquid">
                  <img
                    src={cover}
                    alt="cover-29"
                    className="rounded-t-[12px]"
                  />
                </figure>
                <div className="user-preview-info">
                  <div className="tag-sticker">
                    <svg className="tag-sticker-icon icon-public">
                      <use xlinkHref="#svg-public"></use>
                    </svg>
                  </div>
                  <div className="user-short-description">
                    <a
                      className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                      href=""
                    >
                      <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                        <img
                          src={logo}
                          alt="Community"
                          className="rounded-full"
                        />
                      </div>
                    </a>
                    <p className="user-short-description-title">
                      <a href="group-timeline.html">Cosplayers of the World</a>
                    </p>
                    <p className="user-short-description-text">
                      All cosplayers welcome!
                    </p>
                  </div>
                  {/* <div className="user-stats">
                              <div className="user-stat">
                                <p className="user-stat-title">139</p>
                                <p className="user-stat-text">members</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">105</p>
                                <p className="user-stat-text">posts</p>
                              </div>
                              <div className="user-stat">
                                <p className="user-stat-title">7.3k</p>
                                <p className="user-stat-text">visits</p>
                              </div>
                            </div> */}
                  <div className="communities-card-flokkers-list mt-4">
                    <div className="fldkker">
                      <img
                        src="/img/avatar/01.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/02.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/03.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/04.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/05.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker">
                      <img
                        src="/img/avatar/06.jpg"
                        alt="fldkker-avatar"
                        loading="lazy"
                      />
                    </div>
                    <div className="fldkker plus">
                      <p>+19K</p>
                    </div>
                  </div>
                  <div className="user-preview-actions">
                    <p className="button secondary full !flex items-center justify-center gap-2">
                      <svg className="button-icon icon-join-group">
                        <use xlinkHref="#svg-join-group"></use>
                      </svg>
                      Join Group!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default SuggestedCommunities;
