import React from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const WalletCredit = () => {
    const { showContent } = useSelector((state) => state.userDashboard)
    const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
                <svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
                    <use xlinkHref="#svg-back-arrow"></use>
                </svg>
            </span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Earnings</p>
                    <h2 className="section-title">Wallet & Credit</h2>
                </div>
            </div>
            <div className="widget-box-content">
                <div className="grid !grid-cols-1 md:!grid-cols-2">
                    <div className="sidebar-box">
                        <p class="sidebar-box-title">Current balance</p>
                        <div class="sidebar-box-items">
                            <p class="price-title big"><span class="currency">LE</span> 0.00</p>

                            <a class="button primary" href="marketplace-checkout.html">Send Money</a>

                        </div>
                    </div>
                    <div className="sidebar-box">
                        <p class="sidebar-box-title">Replenish my balance</p>
                        <div class="sidebar-box-items">
                            <p class="price-title big"><span class="currency">LE</span> 0.00</p>

                            <a class="button primary" href="marketplace-checkout.html">Continue</a>

                        </div>
                    </div>
                </div>
            </div>
            <div className="wallet-transactions mt-[40px]">
                <div className="widget-box">
                    <div className="section-header items-center !mt-[0]">
                        <p className="text-[1.3rem] font-bold">Transaction</p>
                        <button className='button primary w-[100px] md:w-[200px]'>Withdraw</button>
                    </div>
                    <div className="widget-box-content overflow-x-scroll md:!overflow-x-auto">
                        <div className="table table-cart split-rows">
                            <div className="table-header">
                                <div className="table-header-column">
                                    <p className="table-header-title">Type</p>
                                </div>
                                <div className="table-header-column padded-left">
                                    <p className="table-header-title">Decription</p>
                                </div>
                                <div className="table-header-column padded-left">
                                    <p className="table-header-title">Date</p>
                                </div>
                                <div className="table-header-column centered padded-left">
                                    <p className="table-header-title">Amont</p>
                                </div>
                                {/* <div className="table-header-column padded-big-left"></div> */}
                            </div>

                            <div className="table-body same-color-rows">
                                <div className="table-row medium">
                                    <div className="table-column">
                                        <div className="type w-[95px]">
                                            <span className='py-[7px] px-[15px] bg-[#d1e2fb] text-[#0d6efd] font-semibold rounded-md'> Sent</span>
                                        </div>
                                    </div>

                                    <div className="table-column padded-left">
                                        <div className='w-[200px] md:w-auto'>
                                            <p className='text-[0.85rem] font-bold'>
                                                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                                In consequatur officiis unde! Ratione nam laborum,
                                            </p>
                                        </div>
                                        
                                    </div>

                                    <div className="table-column padded-left">
                                        <div className="date w-[150px]">
                                            <p className='text-[0.85rem] font-bold'>2023-11-27 23:11:00</p>
                                        </div>
                                    </div>

                                    <div className="table-column centered padded-left">
                                        <p className="price-title">
                                            <span className="currency">$</span> 12.00
                                        </p>
                                    </div>

                                    {/* <div className="table-column padded-big-left">
                                            <div className="table-action">
                                                <svg className="icon-delete">
                                                    <use xlinkHref="#svg-delete"></use>
                                                </svg>
                                            </div>
                                        </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default WalletCredit