import { Link, useNavigate } from "react-router-dom";
import FormInput from "../../../components/ui/formInput/FormInput";
import FormCheckbox from "../../../components/ui/formCheckbox/FormCheckbox";
import { useState } from "react";
import ForgetPassword from "../forgetPassword/forgetPassword";
// import { login } from "../../../utils/api/Login";
// import { setToken, setIsAuthenticated } from "../../../app/feature/authSlice";
// import { useDispatch } from "react-redux";

const LoginScreen = ({ toggleForm, isMobileView }) => {
	const [isForegtPassword, setIsForgetPassword] = useState(false);
	const [rememberMeIsChecked, setRememberMeIsChecked] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	// const dispatch = useDispatch();
	const navigate = useNavigate();

	// const loginUser = async () => {
	const loginUser = () => {
		// const result = await login(username, password);
		// console.log(result);

		// if (result.status === "success") {
		// 	dispatch(setToken({ value: result.authorisation.token }));
		// 	navigate("/");
		// }
		// dispatch(setIsAuthenticated({ value: true }));
		navigate("/");
	};
	return (
		<>
			<div
				className="form-box login-register-form-element"
				style={
					toggleForm === true && isMobileView === true
						? { display: "none" }
						: toggleForm
						? {
								transform: "translate(50%)",
								opacity: "0",
								visibility: "hidden",
								zIndex: "1",
						  }
						: {
								transform: "translate(0px)",
								opacity: "1",
								visibility: "visible",
								zIndex: "2",
						  }
				}
			>
				<h2 className="form-box-title">
					{isForegtPassword ? "Forget your password?" : "Welcome!"}
				</h2>
				<p className="form-box-details">
					{isForegtPassword
						? ""
						: "Connect, collaborate, support and thrive with your fellow Alumni."}
				</p>

				{!isForegtPassword && (
					<form className="form">
						<FormInput
							label="Username"
							type="text"
							id="login-username"
							name="login_username"
							icon="username"
							value={username}
							setValue={setUsername}
						/>

						<FormInput
							label="Password"
							type="password"
							id="login-password"
							name="login_password"
							icon="password"
							value={password}
							setValue={setPassword}
						/>

						<div className="form-row space-between">
							<div className="form-item">
								<FormCheckbox
									label="Remember Me"
									isChecked={rememberMeIsChecked}
									setIsChecked={setRememberMeIsChecked}
									id={"login-remember"}
									name={"login_remember"}
								/>
							</div>

							<div className="form-item">
								<Link
									className="form-link"
									onClick={() => setIsForgetPassword(true)}
								>
									Forgot Password?
								</Link>
							</div>
						</div>

						<div className="form-row">
							<div className="form-item">
								<button
									className="button medium secondary loginButton"
									onClick={async (e) => {
										e.preventDefault();
										// await loginUser();
										loginUser();
									}}
								>
									Login to your Account!
								</button>
							</div>
						</div>
					</form>
				)}
				{isForegtPassword && (
					<ForgetPassword setIsForgetPassword={setIsForgetPassword} />
				)}
			</div>
		</>
	);
};

export default LoginScreen;
