/* eslint-disable jsx-a11y/anchor-is-valid */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { Navigation } from "swiper/modules";
import { useEffect, useState } from "react";
import {
  MdArrowOutward,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { FaArrowRight, FaStar } from "react-icons/fa";
import ProfileImage from "./ProfileImage";
import WrapInCenter from "../WrapInCenter/WrapInCenter";

export default function TrendingService15() {
  const freelancer1 = [
    {
      id: 1,
      img: "/images/team/fl-1.png",
      name: "Robert Fox",
      profession: "Nursing Assistant",
      rating: 4.9,
      reviews: 595,
      tags: ["Figma", "HTML5", "Sketch"],
      skill: "UX Designer",
      price: 10000,
      location: "united",
      level: "new",
      language: "Turkish",
      sort: "best-seller",
      title: "I will be your Nursing Assistant - UX Designer",
    },
    {
      id: 2,
      img: "/images/team/fl-2.png",
      name: "Jane Doe",
      profession: "Graphic Designer",
      rating: 4.8,
      reviews: 423,
      tags: ["Illustrator", "Photoshop", "InDesign"],
      skill: "Illustrators",
      price: 20000,
      location: "united",
      level: "lavel-1",
      language: "English",
      sort: "recommended",
      title:
        "I will create amazing designs as a Graphic Designer - Illustrators",
    },
    {
      id: 3,
      img: "/images/team/fl-3.png",
      name: "Michael Johnson",
      profession: "Web Developer",
      rating: 4.7,
      reviews: 312,
      tags: ["HTML", "CSS", "JavaScript"],
      skill: "Web Developers",
      price: 30000,
      location: "turkey",
      level: "lavel-2",
      language: "Italian",
      sort: "new-arrivals",
      title: "I will develop web solutions as a Web Developer - Web Developers",
    },
    {
      id: 4,
      img: "/images/team/fl-4.png",
      name: "Emily Williams",
      profession: "Content Writer",
      rating: 4.6,
      reviews: 278,
      tags: ["Blogging", "SEO", "Copywriting"],
      skill: "Project Managers",
      price: 40000,
      location: "germany",
      level: "top",
      language: "Spanish",
      sort: "best-seller",
      title:
        "I will craft compelling content as a Content Writer - Project Managers",
    },
    {
      id: 5,
      img: "/images/team/fl-1.png",
      name: "David Smith",
      profession: "Photographer",
      rating: 4.9,
      reviews: 511,
      tags: ["Portrait", "Landscape", "Editing"],
      skill: "Node.js",
      price: 50000,
      location: "canada",
      level: "new",
      language: "Turkish",
      sort: "recommended",
      title: "I will capture moments beautifully as a Photographer - Node.js",
    },
    {
      id: 6,
      img: "/images/team/fl-2.png",
      name: "Sophia Brown",
      profession: "UI/UX Designer",
      rating: 4.8,
      reviews: 385,
      tags: ["Wireframing", "Prototyping", "User-Centered Design"],
      skill: "UX Designer",
      price: 60000,
      location: "united",
      level: "lavel-1",
      language: "English",
      sort: "new-arrivals",
      title:
        "I will design intuitive user experiences as a UI/UX Designer - UX Designer",
    },
    {
      id: 7,
      img: "/images/team/fl-3.png",
      name: "Daniel Wilson",
      profession: "Video Editor",
      rating: 4.7,
      reviews: 248,
      tags: ["Premiere Pro", "After Effects", "Color Grading"],
      skill: "Web Developers",
      price: 70000,
      location: "united",
      level: "lavel-2",
      language: "Italian",
      sort: "best-seller",
      title:
        "I will create captivating videos as a Video Editor - Web Developers",
    },
    {
      id: 8,
      img: "/images/team/fl-4.png",
      name: "Olivia Taylor",
      profession: "Social Media Manager",
      rating: 4.9,
      reviews: 632,
      tags: ["Facebook", "Instagram", "Twitter"],
      skill: "Node.js",
      price: 80000,
      location: "turkey",
      level: "top",
      language: "Spanish",
      sort: "recommended",
      title:
        "I will manage your social presence as a Social Media Manager - Node.js",
    },
    {
      id: 9,
      img: "/images/team/fl-1.png",
      name: "William Clark",
      profession: "Mobile App Developer",
      rating: 4.6,
      reviews: 314,
      tags: ["iOS", "Android", "React Native"],
      skill: "Illustrators",
      price: 90000,
      location: "germany",
      level: "new",
      language: "Turkish",
      sort: "new-arrivals",
      title:
        "I will build your mobile apps as a Mobile App Developer - Illustrators",
    },
    {
      id: 10,
      img: "/images/team/fl-2.png",
      name: "Ava Turner",
      profession: "Illustrator",
      rating: 4.8,
      reviews: 462,
      tags: ["Digital Art", "Character Design", "Vector Illustration"],
      skill: "Project Managers",
      price: 100000,
      location: "united",
      level: "lavel-1",
      language: "English",
      sort: "best-seller",
      title:
        "I will illustrate your ideas with creativity as an Illustrator - Project Managers",
    },
    {
      id: 11,
      img: "/images/team/fl-3.png",
      name: "James Rodriguez",
      profession: "Copywriter",
      rating: 4.7,
      reviews: 287,
      tags: ["Creative Writing", "Marketing", "Branding"],
      skill: "Web Developers",
      price: 10000,
      location: "united",
      level: "lavel-2",
      language: "Italian",
      sort: "recommended",
      title: "I will craft persuasive copy as a Copywriter - Web Developers",
    },
    {
      id: 12,
      img: "/images/team/fl-4.png",
      name: "Ella Martinez",
      profession: "SEO Specialist",
      rating: 4.9,
      reviews: 523,
      tags: ["Keyword Research", "Link Building", "Analytics"],
      skill: "Node.js",
      price: 20000,
      location: "united",
      level: "top",
      language: "Spanish",
      sort: "new-arrivals",
      title:
        "I will optimize your online presence as an SEO Specialist - Node.js",
    },
  ];
  const [showSwiper, setShowSwiper] = useState(false);
  useEffect(() => {
    setShowSwiper(true);
  }, []);

  return (
    <>
        <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
      <section className="md:pb-90 pb-20">
        <div className="container">
          <div className="row align-items-center wow fadeInUp">
            <div className="col-lg-9">
              <div className="main-title">
                <h2 className="title title-h2-inspiring-crea8">
                  Trending Services
                </h2>
                <p className="paragraph">
                  Most viewed and all-time top-selling services
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="text-start text-lg-end mb-4 mb-lg-2">
                <Link
                  className="ud-btn2 btn-light-thm bdrs90 d-flex gap-2
                   items-center md:justify-end justify-start font-extrabold text-lg"
                  href="/service-1"
                >
                  All Categories
                  <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className=" relative">
                {showSwiper && (
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    freeMode={true}
                    // loop={true}
                    className="mySwiperr"
                    navigation={{
                      prevEl: ".unique-5-pre",
                      nextEl: ".unique-5-next",
                    }}
                    style={{ overflow: "hidden" }}
                    modules={[Navigation]}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      992: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {freelancer1.map((data, i) => (
                      <SwiperSlide key={i}>
                        <div className="freelancer-style1 text-center bdr1 hover-box-shadow mb60 bdrs16 border-raduis-box-trending-crea8">
                          <div className="thumb w90 mb25 mx-auto position-relative rounded-circle">
                            <ProfileImage/>
                          </div>
                          <div className="details">
                            <h5 className="title mb-1">{data.name}</h5>
                            <p className=" name-trending-crea8">{data.skill}</p>
                            <div className="review">
                              <p className="flex justify-center items-center gap-2 my-2">
                                <FaStar className="text-[#e1c03f]"/>
                                <span className="text-black font-bold">
                                  {data.rating}
                                </span>{" "}
                                ({data.review} reviews)
                              </p>
                            </div>
                            <div className="skill-tags d-flex align-items-center justify-content-center gap-3 my-3">
                              <span className="tag">Figma</span>
                              <span className="tag mx10">Sketch</span>
                              <span className="tag">HTML5</span>
                            </div>
                            <hr className="opacity-100 mt-6 mb-6" />
                            <div className="fl-meta d-flex align-items-center justify-content-between">
                              <a className="meta fw500 text-start">
                                Location
                                <br />
                                <span className="fz14 fw400">
                                  {data.location}
                                </span>
                              </a>
                              <a className="meta fw500 text-start">
                                Rate
                                <br />
                                <span className="fz14 fw400">
                                  ${data.hourlyRate} / hr
                                </span>
                              </a>
                              <a className="meta fw500 text-start">
                                Job Success
                                <br />
                                <span className="fz14 fw400">
                                  %{data.jobSuccess}
                                </span>
                              </a>
                            </div>
                            <div className="d-grid mt15">
                              <Link
                                href={`/freelancer-single/${data.id}`}
                                className="ud-btn btn-thm-border bdrs12 default-box-shadow1
                                flex-btn-feature-project mt-3 border-raduis-crea8"
                              >
                                View Profile
                                <MdArrowOutward/>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
                <button
                  type="button"
                  className="prev-btn unique-5-pre center-swiper-trending"
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <button
                  type="button"
                  className="next-btn unique-5-next center-swiper-trending"
                >
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      </WrapInCenter>
    </>
  );
}
