import React from "react";
import UserProfileInMiniHeader from "../../user-profile-image/UserProfileInMiniHeader";

const PhotoCover = ({ isForGroup = false }) => {
	return (
		<div className="grid grid-3-3-3 centered mb-[16px]">
			{!isForGroup && (
				<div className="user-preview small fixed-height">
					<figure className="user-preview-cover liquid">
						<img
							src="img/cover/01.jpg"
							alt="cover-01"
							className="rounded-t-2xl"
						/>
					</figure>

					<div className="user-preview-info">
						<div className="user-short-description small">
							<div className="user-short-description-avatar user-avatar !relative !top-[-58px] !left-[155px] !scale-[1.5]">
								<UserProfileInMiniHeader />
							</div>
						</div>
					</div>
				</div>
			)}

			{isForGroup && (
				<div className="user-preview small fixed-height">
					<figure className="user-preview-cover liquid">
						<img
							src="img/cover/01.jpg"
							alt="cover-01"
							className="rounded-t-2xl"
						/>
					</figure>

					<div className="user-preview-info">
						<div className="user-short-description small">
							<div className="user-short-description-avatar user-avatar !relative !top-[-58px] !left-[155px] !scale-[1.5]">
								<div className="border-solid border-[1px] border-[#ff6728] relative w-[45px] h-[45px] rounded-full">
									<div className="relative w-full h-full rounded-full p-[1px] bg-white">
										<img
											src="/img/avatar/01.jpg"
											alt="Profile"
											className="w-full h-full rounded-full"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="upload-box">
				<svg className="upload-box-icon icon-members">
					<use xlinkHref="#svg-members"></use>
				</svg>

				<p className="upload-box-title">Change Avatar</p>

				<p className="upload-box-text">110x110px size minimum</p>
			</div>

			<div className="upload-box">
				<svg className="upload-box-icon icon-photos">
					<use xlinkHref="#svg-photos"></use>
				</svg>

				<p className="upload-box-title">Change Cover</p>

				<p className="upload-box-text">1184x300px size minimum</p>
			</div>
		</div>
	);
};

export default PhotoCover;
