import React, { useState } from "react";
import { LuScrollText } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { BsMusicNoteBeamed } from "react-icons/bs";


const userProfileTabItems = [
    {
      name: "All Media",
      select: "all",
      icon: (
        <svg
          className="section-menu-item-icon icon-allmedia text-[#adafca]"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M4,2H14V4H4V14H2V4C2,2.89 2.89,2 4,2M8,6H18V8H8V18H6V8C6,6.89 6.89,6 8,6M12,10H20C21.11,10 22,10.89 22,12V20C22,21.11 21.11,22 20,22H12C10.89,22 10,21.11 10,20V12C10,10.89 10.89,10 12,10M14,12V20L20,16L14,12Z"
          ></path>
        </svg>
      ),
    },
    {
      name: "Text",
      select: "text",
      icon: (
        <LuScrollText
          className="section-menu-item-icon icon-text w-[25px] h-[25px] text-[#adafca]"
        />
      ),
    },
    {
      name: "Photos",
      select: "photos",
      icon: (
        <svg className="section-menu-item-icon icon-photos">
          <use xlinkHref="#svg-photos"></use>
        </svg>
      ),
    },
    {
      name: "Audios",
      select: "audio",
      icon: (
        <BsMusicNoteBeamed className="section-menu-item-icon icon-music w-[25px] h-[25px] text-[#adafca]" />
      ),
    },
    {
      name: "Videos",
      select: "videos",
      icon: (
        <svg className="section-menu-item-icon icon-videos">
          <use xlinkHref="#svg-videos"></use>
        </svg>
      ),
    },
    {
      name: "Location",
      select: "location",
      icon: (
        // <MdLocationOn  />
        <FaLocationDot className="section-menu-item-icon icon-location w-[22px] h-[22px] text-[#adafca]" />
      ),
    },
  ];

const NavigationBar = () => {
  const [activeUserProfileTab, setActiveUserProfileTab] = useState("all");

  return (
    <div className="category-slider md:sticky md:top-[70px] z-[99999] bg-transparent !mt-0">
      <nav className="section-navigation search-slider">
        <div id="section-navigation-slider" className="section-menu !w-[100%]">
          {userProfileTabItems.map((category, index) => (
            <a
              className={`section-menu-item posts-tab-item ${
                activeUserProfileTab === category.select ? "active" : ""
              }`}
              style={{
                flex: "1",
                cursor: "pointer",
                width: "115px !important",
              }}
              key={index}
              onClick={() => {
                setActiveUserProfileTab(category.select);
                // window.scrollTo(0, 0);
              }}
            >
              {category.icon}

              <p className="section-menu-item-text">{category.name}</p>
            </a>
          ))}
        </div>
      </nav>
    </div>
  );
};

export default NavigationBar;
