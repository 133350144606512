import React, { useState } from "react";
import Select from "../../ui/select/Select";
import "../Search.css";
import FormInputwithIcon from "../../ui/formInputWithSearchIcon/FormInputwithIcon";
import BusinessesCard from "../../BusinessesComponents/BusinessesCard/BusinessesCard";
import SummaryResults from "../SummaryResults/SummaryResults";
import BusinessProductCard from "./BusinessProductCard/BusinessProductCard";
import ServiceCard from "./ServiceCard/ServiceCard";
import OffersCard from "./OffersCard/OffersCard";

const countries = [
	{ id: 1, value: "Egypt" },
	{ id: 2, value: "United Arab Emirates" },
	{ id: 3, value: "Bahrain" },
	{ id: 4, value: "Saudi" },
];

const initialItemsNumber = [
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
];

const BusinessesSearch = () => {
	const [isBusinessVisible, setIsBusinessVisible] = useState(true);
	const [numberofBusiness, setNumberOfBusiness] = useState([
		...initialItemsNumber,
	]);
	const [selectedBusinessesField, setSelectedBusinessesField] =
		useState("Profiles");

	return (
		<>
			<div
				className={`search-bussinesses-component common-for-dir-cat ${
					isBusinessVisible
						? "common-for-dir-cat-active"
						: "common-for-dir-cat-close"
				}`}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
					className="title-search-category"
					onClick={() => {
						setIsBusinessVisible((prev) => !prev);
					}}
				>
					<div className="search-title">
						<h4>Search Businesses</h4>
						<p>Find what you need quickly and easily</p>
					</div>

					<svg
						class="slider-control-icon icon-small-arrow"
						style={{
							transform: `rotate(${isBusinessVisible ? "270" : "90"}deg)`,
							cursor: "pointer",
							marginTop: "-40px",
							fill: `${isBusinessVisible ? "#ff6728" : ""}`,
						}}
					>
						<use xlinkHref="#svg-small-arrow"></use>
					</svg>
				</div>

				{isBusinessVisible && (
					<div className="offers-items-container">
						<div className="offers-items">
							<p
								className={`${
									selectedBusinessesField === "Profiles" ? "active" : ""
								}`}
								onClick={() => {
									setSelectedBusinessesField("Profiles");
								}}
							>
								Profiles
							</p>
							<p
								className={`${
									selectedBusinessesField === "Products" ? "active" : ""
								}`}
								onClick={() => {
									setSelectedBusinessesField("Products");
								}}
							>
								Products
							</p>
							<p
								className={`${
									selectedBusinessesField === "Services" ? "active" : ""
								}`}
								onClick={() => {
									setSelectedBusinessesField("Services");
								}}
							>
								Services
							</p>
							<p
								className={`${
									selectedBusinessesField === "Offers" ? "active" : ""
								}`}
								onClick={() => {
									setSelectedBusinessesField("Offers");
								}}
							>
								Offers
							</p>
						</div>
					</div>
				)}

				{isBusinessVisible && (
					<form>
						<div>
							<FormInputwithIcon
								name={"keyword-business"}
								id={"keyword_business"}
								label={"Search Title or Company"}
							/>
							<div style={{ marginBottom: "5px" }}></div>
						</div>
						<div className="search-row">
							<Select
								defaultValue={"Business Country: All"}
								optionsArray={countries}
							/>
							<Select
								defaultValue={"Business City: All"}
								optionsArray={countries}
							/>
						</div>
						<div className="search-row">
							<Select
								defaultValue={"Business Industry: All"}
								optionsArray={countries}
							/>
							<Select
								defaultValue={"Sub-Category: All"}
								optionsArray={countries}
							/>
						</div>
						<div>
							<Select
								defaultValue={"University: All"}
								optionsArray={countries}
							/>
						</div>
						<button
							className="button medium secondary"
							style={{
								marginTop: "15px",
							}}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "5px",
								}}
							>
								<svg class="icon-magnifying-glass">
									<use xlinkHref="#svg-magnifying-glass"></use>
								</svg>
								Search
							</div>
						</button>
					</form>
				)}
			</div>
			<SummaryResults
				numberofItems={numberofBusiness}
				setNumberOfItems={setNumberOfBusiness}
				category={"businesses"}
			/>
			<>
				<div className="grid grid-4-4-4 centered">
					{selectedBusinessesField === "Profiles" && (
						<>
							<BusinessesCard
								avatar="/img/businesses/01.jpeg"
								cover="img/cover/04.jpg"
								title="DigiTech Solutions"
								desc="Information Technology"
								stars={4.5}
							/>

							<BusinessesCard
								avatar="/img/businesses/02.jpeg"
								cover="img/cover/16.jpg"
								title="El Nomadics Restaurant, Gift Shops, Souvenirs, T-shirts and..."
								desc="Restaurant"
								stars={3.5}
							/>
							<BusinessesCard
								avatar="/img/businesses/01.jpeg"
								cover="img/cover/04.jpg"
								title="DigiTech Solutions"
								desc="Information Technology"
								stars={4.5}
							/>

							<BusinessesCard
								avatar="/img/businesses/02.jpeg"
								cover="img/cover/16.jpg"
								title="El Nomadics Restaurant, Gift Shops, Souvenirs, T-shirts and..."
								desc="Restaurant"
								stars={3.5}
							/>
						</>
					)}
					{selectedBusinessesField === "Products" && (
						<>
							<BusinessProductCard />
							<BusinessProductCard />
							<BusinessProductCard />
							<BusinessProductCard />
						</>
					)}
					{selectedBusinessesField === "Services" && (
						<>
							<ServiceCard />
							<ServiceCard />
							<ServiceCard />
							<ServiceCard />
						</>
					)}
					{selectedBusinessesField === "Offers" && (
						<>
							<OffersCard />
							<OffersCard />
							<OffersCard />
							<OffersCard />
						</>
					)}
				</div>
			</>
		</>
	);
};

export default BusinessesSearch;
