/* eslint-disable jsx-a11y/img-redundant-alt */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaClock, FaFileContract, FaArrowRight } from "react-icons/fa"; 
import { MdOutlineKeyboardArrowRight,MdOutlineKeyboardArrowLeft } from "react-icons/md";

import img1 from '../../assests/crea8/pro-1.webp';
import img2 from '../../assests/crea8/pro-2.webp';
import img3 from '../../assests/crea8/pro-3.webp';
import img4 from '../../assests/crea8/pro-4.webp';
import WrapInCenter from "../WrapInCenter/WrapInCenter";

export default function HighestRated1() {
  const [showSwiper, setShowSwiper] = useState(false);
  useEffect(() => {
    setShowSwiper(true);
  }, []);

  const inspiringProjects = [
    {
      id: 1,
      title: "Social Media",
      imageSrc: img1,
      freelancerImageSrc: img1,
      author: "8000 services",
    },
    {
      id: 2,
      title: "AI Development",
      imageSrc: img2,
      freelancerImageSrc: img2,
      author: "5000 services",
    },
    {
      id: 3,
      title: "Content Creation",
      imageSrc: img3,
      freelancerImageSrc: img3,
      author: "6000 services",
    },
    {
      id: 4,
      title: "App Design",
      imageSrc: img4,
      freelancerImageSrc: img4,
      author: "7000 services",
    },
    {
        id: 6,
        title: "AI Development",
        imageSrc: img2,
        freelancerImageSrc: img2,
        author: "5000 services",
      },
      {
        id: 7,
        title: "Content Creation",
        imageSrc: img3,
        freelancerImageSrc: img3,
        author: "6000 services",
      },
      {
        id: 8,
        title: "App Design",
        imageSrc: img4,
        freelancerImageSrc: img4,
        author: "7000 services",
      },
  ];

  return (
    <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
      <section className="md:pb-90 pb-20">
        <div className="container">
        <div className="row align-items-center wow fadeInUp" data-wow-delay="00ms">
          <div className="col-lg-9">
            <div className="main-title">
              <h2 className="title title-h2-inspiring-crea8">Explore Popular Services</h2>
              {/* <p className="paragraph">Get some Inspirations from 1800+ skills</p> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="text-start text-lg-end mb-4 mb-lg-2">
              <Link className="ud-btn2 flex gap-2 md:justify-end justify-start items-center
               font-extrabold text-lg" to="/blog-1">
                All Categories <FaArrowRight />
              </Link>
            </div>
          </div>
        </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ui-hightest-rated">
                {showSwiper && (
                  <Swiper
                    spaceBetween={30}
                    navigation={{
                      prevEl: ".btn__prev__008",
                      nextEl: ".btn__next__008",
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiperr"
                    loop={true}
                    pagination={{
                      el: ".swiper__pagination__008",
                      clickable: true,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                      },
                      768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                      },
                      992: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                      },
                    }}
                  >
                    {inspiringProjects.map((elm, index) => (
                      <SwiperSlide key={index}>
                    <div className="listing-style1 bdrs12 default-box-shadow1">
                      <div className="list-thumb">
                        <img
                          width={330}
                          height={250}
                          style={{ height: "fit-content" }}
                          className="w-100"
                          src={elm.imageSrc}
                          alt="image"
                        />
                      </div>
                      <div className="list-content">
                        <div className="list-meta">
                          <Link className="d-flex align-items-center justify-between" to="#">
                            <span>
                              <h5 className="fz14 mb-1">{elm.title}</h5>
                              <p className="fz14 mb-0">{elm.author}</p>
                            </span>
                            <FaArrowRight />
                          </Link>
                        </div>
                      </div>
                    </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button className="swiper__btn btn__prev__008 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowLeft />
                </button>
              </div>
              <div className="col-auto">
                <div className="swiper__pagination swiper__pagination__008"></div>
              </div>
              <div className="col-auto">
                <button className="swiper__btn btn__next__008 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowRight  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WrapInCenter>
  );
}
