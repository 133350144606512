import "./BusinessesPage.css";

import { useState } from "react";
import AdsBanner from "../../components/AdsBanner/AdsBanner";
import Slider from "../../components/ui/slider/slider";
import BusinessesCard from "../../components/BusinessesComponents/BusinessesCard/BusinessesCard";
import CreateBusinessesCard from "../../components/BusinessesComponents/BusinessesCard/CreateBusinessesCard";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";

const bannersImages = [
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
];

const tabItems = [
	{
		name: "My Businesses",
		select: "my-businesses",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="feather feather-users section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
				<circle cx="9" cy="7" r="4"></circle>
				<path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
				<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
			</svg>
		),
	},
	{
		name: "Following",
		select: "following",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="feather feather-grid section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<rect x="3" y="3" width="7" height="7"></rect>
				<rect x="14" y="3" width="7" height="7"></rect>
				<rect x="14" y="14" width="7" height="7"></rect>
				<rect x="3" y="14" width="7" height="7"></rect>
			</svg>
		),
	},
	{
		name: "Affiliated",
		select: "affiliated",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="feather feather-briefcase section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
				<path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
			</svg>
		),
	},
	{
		name: "My Directory",
		select: "my-directory",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="feather feather-flag section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
				<line x1="4" y1="22" x2="4" y2="15"></line>
			</svg>
		),
	},
	{
		name: "Find A Businesses",
		select: "find-businesses",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="feather feather-shopping-cart section-menu-item-icon icon-profile"
				style={{ scale: "1.25" }}
			>
				<circle cx="9" cy="21" r="1"></circle>
				<circle cx="20" cy="21" r="1"></circle>
				<path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
			</svg>
		),
	},
];

const BusinessesPage = () => {
	const [activeTab, setActiveTab] = useState("my-businesses");

	return (
		<section className="businesses-page">
			<AdsBanner bannersImages={bannersImages} />

			<WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
				<div className="page-title">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						style={{
							fill: "#23d2e2",
							scale: "1.1",
						}}
						className="menu-item-link-icon icon-directory"
						stroke="#23d2e2"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
						<line x1="4" y1="22" x2="4" y2="15"></line>
					</svg>

					<div className="directory-title-desc">
						<h3 style={{ lineHeight: "1.2" }}>Businesses</h3>
						<p style={{ lineHeight: "1.2", fontSize: "18px" }}>
							Access your business profile, businesses you follow and more.
						</p>
					</div>
				</div>
			</WrapInCenter>

			<WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
				<Slider
					setActiveTab={setActiveTab}
					activeTab={activeTab}
					tabItems={tabItems}
				/>
			</WrapInCenter>

			<WrapInCenter noPaddingBottom={false} noPaddingTop={true}>
				<div className="businesses-tab-content">
					{activeTab === "my-businesses" ? (
						<>
							<h2 className="tab-title">My Businesses</h2>

							<div className="grid grid-4-4-4 centered mt-0">
								<BusinessesCard
									avatar="/img/businesses/01.jpeg"
									cover="img/cover/04.jpg"
									title="DigiTech Solutions"
									desc="Information Technology"
									cardId={"digitechsolutions"}
									stars={4.5}
								/>

								<BusinessesCard
									avatar="/img/businesses/02.jpeg"
									cover="img/cover/16.jpg"
									title="El Nomadics Restaurant, Gift Shops, Souvenirs, T-shirts and..."
									desc="Restaurant"
									cardId="elnomadics"
									stars={3.5}
								/>

								<CreateBusinessesCard />
							</div>
						</>
					) : (
						activeTab
					)}
				</div>
			</WrapInCenter>
		</section>
	);
};

export default BusinessesPage;
