import React from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
import logo from '../../../../assests/avatar/logo.svg';
import cover from '../../../../assests/avatar/cover.jpg';

import UserProfileImage from "../../../user-profile-image/UserProfileImage"
import { Tooltip } from 'antd';

const Invitations = () => {
    const { showContent } = useSelector((state) => state.userDashboard)
    const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
                <svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
                    <use xlinkHref="#svg-back-arrow"></use>
                </svg>
            </span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Communities</p>
                    <h2 className="section-title">
                        Invitations <span className="highlighted">1</span>
                    </h2>
                </div>
            </div>
            <div className='grid grid-3-3-3 centered-on-mobile'>
                <div className="user-preview">
                    <figure className="user-preview-cover liquid">
                        <img
                            src={cover}
                            alt="cover-29"
                            className="rounded-t-[12px]"
                        />
                    </figure>
                    <div className="user-preview-info">
                        <div className="tag-sticker">
                            <svg className="tag-sticker-icon icon-public">
                                <use xlinkHref="#svg-public"></use>
                            </svg>
                        </div>
                        <div className="user-short-description">
                            <a
                                className="user-short-description-avatar user-avatar medium no-stats rounded-full bg-white p-[6px]"
                                href=""
                            >
                                <div className="bg-white border-solid border-[1px] border-[#ff6728] rounded-full p-[2px]">
                                    <img
                                        src={logo}
                                        alt="Community"
                                        className="rounded-full"
                                    />
                                </div>
                            </a>
                            <p className="user-short-description-title">
                                <a href="group-timeline.html">Cosplayers of the World</a>
                            </p>
                            <p className="user-short-description-text">
                                All cosplayers welcome!
                            </p>
                        </div>
                        <div className="user-stats">
                            <div className="user-stat">
                                <p className="user-stat-title">139</p>
                                <p className="user-stat-text">members</p>
                            </div>
                            <div className="user-stat">
                                <p className="user-stat-title">105</p>
                                <p className="user-stat-text">posts</p>
                            </div>
                            <div className="user-stat">
                                <p className="user-stat-title">7.3k</p>
                                <p className="user-stat-text">visits</p>
                            </div>
                        </div>
                        <div className="user-preview-actions">
                            <Tooltip placement="top" color="#fd6728" title="Ignore Invitaion">
                                <p className="button white white-tertiary !flex items-center justify-center">
                                    <svg className="button-icon icon-leave-group">
                                        <use xlinkHref="#svg-leave-group"></use>
                                    </svg>
                                </p>
                            </Tooltip>
                            <Tooltip placement="top" color="#fd6728" title="Accept Invitaion">
                                <p className="button secondary !flex items-center justify-center">
                                    <svg className="button-icon icon-join-group">
                                        <use xlinkHref="#svg-join-group"></use>
                                    </svg>
                                </p>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="user-preview-footer padded">
                        <div className="user-status">
                            <div className='absolute top-0 left-0'>
                                <UserProfileImage />
                            </div>

                            <p className="user-status-text small !mt-0">
                                {/* <span className="bold">Invitied by</span> */}
                                Invitied by
                            </p>
                            <p className="user-status-title">
                                <a href='/user-profile-page' className="bold">Marina Valentine</a>
                            </p>
                            <p className="user-status-text small !mt-0">
                                Marketing - Brandmarks
                            </p>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Invitations