import React, { useState } from "react";
import Select from "../../../ui/select/Select";
import FormInput from "../../../ui/formInput/FormInput";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const isGraduatedArray = [
	{id:1,value:"No"},
	{id:2,value:"Yes"}
]

const EducationForm = () => {
	const [isGraduated , setIsGraudated] = useState("no")
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Experience</p>
					<h2 className="section-title">Education</h2>
				</div>
			</div>

			<div className="widget-box">
				<p className="widget-box-title">University Level</p>

				<div className="widget-box-content">
					<form className="form">
						<FormInput
							label={"Your University"}
							name={"your-university"}
							id={"your_university"}
							type={"text"}
						/>
						<div className="flex items-center justify-between !gap-8 max-[420px]:!flex-col max-[420px]:!gap-0">
							<Select defaultValue={"Faculty"} optionsArray={[]} />
							<Select defaultValue={"Degree"} optionsArray={[]} />
						</div>
						<div className="flex items-center justify-between !gap-8 max-[420px]:!flex-col max-[420px]:!gap-0">
							<Select defaultValue={"Start Date"} optionsArray={[]} />
							<p className="text-[16px] font-semibold">To</p>
							<Select defaultValue={"End Date"} optionsArray={[]} />
						</div>
						<div className="grid !grid-cols-2 !gap-8 max-[420px]:!grid-cols-1 max-[420px]:!gap-0">
							<Select defaultValue={"Graduated ?"} optionsArray={isGraduatedArray} setValue={setIsGraudated} />
							{isGraduated.toLowerCase() == "no" && <Select defaultValue={"Graduation Year"} optionsArray={[]} />}
						</div>
						<button
							className="button medium secondary"
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							Add University
						</button>
					</form>
				</div>
			</div>

			<div className="widget-box mt-4">
				<p className="widget-box-title">Secondary School Level</p>

				<div className="widget-box-content">
					<form className="form">
						<FormInput
							label={"Secondary School Name"}
							name={"secondary-school-name"}
							id={"secondary_school_name"}
							type={"text"}
						/>
						<div className="flex items-center justify-between !gap-8 max-[420px]:!flex-col max-[420px]:!gap-0">
							<Select defaultValue={"Start Date"} optionsArray={[]} />
							<p className="text-[16px] font-semibold">To</p>
							<Select defaultValue={"End Date"} optionsArray={[]} />
						</div>
						<button
							className="button medium secondary"
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							Add Secondary School
						</button>
					</form>
				</div>
			</div>

			<div className="widget-box mt-4">
				<p className="widget-box-title">Primary School Level</p>

				<div className="widget-box-content">
					<form className="form">
						<FormInput
							label={"Primary School Name"}
							name={"primary-school-name"}
							id={"primary_school_name"}
							type={"text"}
						/>
						<div className="flex items-center justify-between !gap-8 max-[420px]:!flex-col max-[420px]:!gap-0">
							<Select defaultValue={"Start Date"} optionsArray={[]} />
							<p className="text-[16px] font-semibold">To</p>
							<Select defaultValue={"End Date"} optionsArray={[]} />
						</div>
						<button
							className="button medium secondary"
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							Add Primary School
						</button>
					</form>
				</div>
			</div>


			{/* <div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div> */}
		</div>
	);
};

export default EducationForm;
