import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedForm, setShowContent } from "../../../app/feature/userDashboard";

const SideMenuItem = ({ icon, name, desc, items }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { showContent } = useSelector((state) => state.userDashboard)
	const selectedForm = useSelector((state) => state.userDashboard.selectedForm);

	const dispatch = useDispatch();
	const menuRef = useRef(null);

	const handleMenuOpen = (e) => {
		if (menuRef.current && !menuRef.current.contains(e.target) && !showContent) {
			setIsMenuOpen(false);
		}
	}

	const handleScroll = () => {
		const section = document.querySelector('.account-hub-content');
		if (section) {
			window.scrollTo(0, section.offsetTop)
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleMenuOpen)
		return () => {
			document.removeEventListener("mousedown", handleMenuOpen)
		};
	}, [showContent]);
	return (
		<div className="sidebar-menu-item" ref={menuRef}>
			<div
				className="sidebar-menu-header accordion-trigger-linked !h-[115px] md:!h-[135px]"
				onClick={() => {
					setIsMenuOpen((prev) => !prev);
				}}
			>
				{icon}

				<div className="sidebar-menu-header-control-icon">
					{isMenuOpen && (
						<svg className="sidebar-menu-header-control-icon-open icon-minus-small !flex">
							<use xlinkHref="#svg-minus-small"></use>
						</svg>
					)}

					{!isMenuOpen && (
						<svg className="sidebar-menu-header-control-icon-closed icon-plus-small">
							<use xlinkHref="#svg-plus-small"></use>
						</svg>
					)}
				</div>

				<p className="sidebar-menu-header-title">{name}</p>

				<p className="sidebar-menu-header-text">{desc}</p>
			</div>

			<div
				style={{
					overflow: "hidden",
					transition: "all 0.3s ease-in-out 0s",
					height: isMenuOpen ? `${24 + items.length * 34.2}px` : "0",
				}}
			>
				<div className="sidebar-menu-body accordion-content-linked">
					{items.map((item, index) => (
						<p
							key={index + item}
							className={`sidebar-menu-link hover:!text-[#ff6728] ${selectedForm === item ? "text-[#ff6728]" : ""
								}`}
							onClick={() => {
								dispatch(setSelectedForm({ value: item }));
								dispatch(setShowContent(true));
								handleScroll();
							}}
						>
							{item}
						</p>
					))}
				</div>
			</div>
		</div>
	);
};

export default SideMenuItem;
