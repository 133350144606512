import React, { useState } from "react";

const Groups = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState("");

	return (
		<div class="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p class="widget-box-title">
				Groups <span class="highlighted">7</span>
			</p>

			<div class="widget-box-content">
				<div class="filters">
					<p class="filter">Newest</p>

					<p class="filter active">Popular</p>

					<p class="filter">Active</p>
				</div>

				<div class="user-status-list">
					<div class="user-status request-small">
						<a class="user-status-avatar" href="group-timeline.html">
							<div class="user-avatar small no-border">
								<div className="user-avatar-content friend-image-badge">
									<img src="img/avatar/29.jpg" alt="Group Avatar" />
								</div>
							</div>
						</a>

						<p class="user-status-title">
							<a class="bold" href="group-timeline.html">
								Twitch Streamers
							</a>
						</p>

						<p class="user-status-text small">265 members</p>

						<div class="action-request-list">
							<div class="action-request accept">
								<svg class="action-request-icon icon-join-group">
									<use xlinkHref="#svg-join-group"></use>
								</svg>
							</div>
						</div>
					</div>

					<div class="user-status request-small">
						<a class="user-status-avatar" href="group-timeline.html">
							<div class="user-avatar small no-border">
								<div className="user-avatar-content friend-image-badge">
									<img src="img/avatar/24.jpg" alt="Group Avatar" />
								</div>
							</div>
						</a>

						<p class="user-status-title">
							<a class="bold" href="group-timeline.html">
								Cosplayers of the World
							</a>
						</p>

						<p class="user-status-text small">139 members</p>

						<div class="action-request-list">
							<div class="action-request accept">
								<svg class="action-request-icon icon-join-group">
									<use xlinkHref="#svg-join-group"></use>
								</svg>
							</div>
						</div>
					</div>

					<div class="user-status request-small">
						<a class="user-status-avatar" href="group-timeline.html">
							<div class="user-avatar small no-border">
								<div className="user-avatar-content friend-image-badge">
									<img src="img/avatar/25.jpg" alt="Group Avatar" />
								</div>
							</div>
						</a>

						<p class="user-status-title">
							<a class="bold" href="group-timeline.html">
								Stream Designers
							</a>
						</p>

						<p class="user-status-text small">466 members</p>

						<div class="action-request-list">
							<div class="action-request accept">
								<svg class="action-request-icon icon-join-group">
									<use xlinkHref="#svg-join-group"></use>
								</svg>
							</div>
						</div>
					</div>

					<div class="user-status request-small">
						<a class="user-status-avatar" href="group-timeline.html">
							<div class="user-avatar small no-border">
								<div className="user-avatar-content friend-image-badge">
									<img src="img/avatar/28.jpg" alt="Group Avatar" />
								</div>
							</div>
						</a>

						<p class="user-status-title">
							<a class="bold" href="group-timeline.html">
								Street Artists
							</a>
						</p>

						<p class="user-status-text small">951 members</p>

						<div class="action-request-list">
							<div class="action-request decline">
								<svg class="action-request-icon icon-leave-group">
									<use xlinkHref="#svg-leave-group"></use>
								</svg>
							</div>
						</div>
					</div>

					<div class="user-status request-small">
						<a class="user-status-avatar" href="group-timeline.html">
							<div class="user-avatar small no-border">
								<div className="user-avatar-content friend-image-badge">
									<img src="img/avatar/27.jpg" alt="Group Avatar" />
								</div>
							</div>
						</a>

						<p class="user-status-title">
							<a class="bold" href="group-timeline.html">
								Gaming Watchtower
							</a>
						</p>

						<p class="user-status-text small">2.365 members</p>

						<div class="action-request-list">
							<div class="action-request accept">
								<svg class="action-request-icon icon-join-group">
									<use xlinkHref="#svg-join-group"></use>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Groups;
