import React from "react";

const MemberPageTwo = () => {
	return (
		<div className="page-two-member">
			<div
				id="user-preview-stats-slides-01"
				className="user-preview-stats-slides page-two-desc"
			>
				<div className="user-preview-stats-slide tns-item">
					<p className="user-preview-text user-result-about">
						Hello! I'm James Hart, but I go by the name of Destroy Dex on my
						stream channel. Come to check out the latest gaming news!
					</p>
				</div>

				<div className="user-preview-stats-slide tns-item page-two-desc-numbers">
					<div className="user-stats">
						<div className="user-stat">
							<p className="user-stat-title user-stats-title-result">874</p>

							<p className="user-stat-text user-stats-number-result">posts</p>
						</div>

						<div className="user-stat">
							<p className="user-stat-title user-stats-title-result">60</p>

							<p className="user-stat-text user-stats-number-result">
								Connections
							</p>
						</div>

						<div className="user-stat">
							<p className="user-stat-title user-stats-title-result">3.9k</p>

							<p className="user-stat-text user-stats-number-result">Flocks</p>
						</div>
					</div>
				</div>
			</div>
			<div className="page-two-connections">
				<p className="in-common-title">In Common</p>
				<div className="in-common-desc">
					<div className="page-two-connection">
						<p className="connection-title">Connections</p>
						<div className="connection-desc">
							<div className="businesses-card-flokkers-list">
								<div className="fldkker">
									<img
										src="/img/avatar/01.jpg"
										alt="fldkker-avatar"
										loading="lazy"
									/>
								</div>

								<div className="fldkker">
									<img
										src="/img/avatar/02.jpg"
										alt="fldkker-avatar"
										loading="lazy"
									/>
								</div>

								<div className="fldkker plus">
									<p>+19K</p>
								</div>
							</div>
						</div>
					</div>
					<div className="page-two-connection">
						<p className="connection-title">Communities</p>
						<div className="connection-desc">
							<div className="businesses-card-flokkers-list">
								<div className="fldkker">
									<img
										src="/img/avatar/01.jpg"
										alt="fldkker-avatar"
										loading="lazy"
									/>
								</div>

								<div className="fldkker">
									<img
										src="/img/avatar/02.jpg"
										alt="fldkker-avatar"
										loading="lazy"
									/>
								</div>

								<div className="fldkker plus">
									<p>+19K</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MemberPageTwo;
