import React from "react";
import FormInput from "../../../ui/formInput/FormInput";
import Select from "../../../ui/select/Select";
import PhotoCover from "../PhotoCover";
import Verified from "../Status/Verified";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";

const DummyOptions = [
	{ value: "Option One" },
	{ value: "Option Two" },
	{ value: "Option Three" },
];

const ProfileForm = () => {
	const { showContent } = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()

	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">My Profile</p>
					<h2 className="section-title">Profile Info</h2>
				</div>
			</div>
			<PhotoCover />
			<div className="widget-box">
				<p className="widget-box-title">About Info</p>

				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center max-[680px]:items-start justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0">
							<div className="flex-1 flex items-center justify-between gap-2">
								<div className="min-[680px]:flex-1 max-[680px]:w-[100px] max-[400px]:w-[80px]">
									<Select defaultValue={"Code"} optionsArray={[]} />
								</div>
								<div className="flex-2 max-[680px]:w-[140px] max-[400px]:w-[125px]">
									<FormInput
										type={"text"}
										label={"Phone Number"}
										name={"phone-number"}
										id={"phone_number"}
									/>
								</div>
								<Verified />
							</div>
							<div className="flex-1 flex items-center justify-between gap-2">
								<div className="flex-1">
									<FormInput
										type={"email"}
										label={"Email"}
										name={"user-email"}
										id={"user_email"}
										noMT={true}
									/>
								</div>
								<Verified />
							</div>
						</div>
						<div className="flex items-center justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0 mb-2">
							<FormInput
								type={"text"}
								label={"First Name"}
								name={"first-name"}
								id={"first_name"}
							/>
							<FormInput
								type={"text"}
								label={"Last Name"}
								name={"last-name"}
								id={"last_name"}
								noMT={true}
							/>
						</div>
						<div className="flex items-center justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0">
							<div className="form-item flex-1">
								<div className="form-input small full">
									<textarea
										id="profile-description"
										name="profile_description"
										placeholder="About Me"
										rows={4}
									></textarea>
								</div>
							</div>
							<div className="flex items-center justify-center flex-col gap-0 flex-1 max-[680px]:items-start max-[680px]:justify-start max-[680px]:w-[100%]">
								<Select
									defaultValue={"Country of Residence"}
									optionsArray={DummyOptions}
								/>
								<Select defaultValue={"City"} optionsArray={DummyOptions} />
							</div>
						</div>
					</form>
				</div>
			</div>

			<div className="widget-box mt-4">
				<p className="widget-box-title">Personal Info</p>

				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0">
							<Select defaultValue={"Nationality"} optionsArray={[]} />
							<div className="form-item my-[15px]">
								<div className="form-input-decorated">
									<div className="form-input small active">
										<label for="profile-birthday">Birthday</label>
										<input
											type="text"
											id="profile-birthday"
											name="profile_birthday"
											value="August 24th, 1987"
										/>
									</div>

									<svg className="form-input-icon icon-events">
										<use xlinkHref="#svg-events"></use>
									</svg>
								</div>
							</div>
						</div>
						<div className="flex items-center justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0">
							<Select defaultValue={"Gender"} optionsArray={[]} />
							<Select defaultValue={"Status"} optionsArray={[]} />
						</div>
					</form>
				</div>
			</div>

			<div className="widget-box mt-4">
				<p className="widget-box-title">General Settings</p>

				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0">
							<Select defaultValue={"Weather Unit"} optionsArray={[]} />
							<Select defaultValue={"Currency"} optionsArray={[]} />
						</div>
					</form>
				</div>
			</div>


			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default ProfileForm;
