import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaShare } from 'react-icons/fa'
import { FaFaceSmile } from 'react-icons/fa6'
import { Link } from 'react-router-dom';
import UserProfileImage from "../../user-profile-image/UserProfileImage"

import "./FriendStory.css"
import { useDispatch } from 'react-redux';
import { setIsFriendStoryVisible } from '../../../app/feature/outletSlice';

const story = [
    "/img/cover/04.jpg",
    "/img/cover/05.jpg",
    "/img/cover/10.jpg",
    "/img/cover/02.jpg",
    "/img/cover/11.jpg",
  ]


const FriendStory = () => {
    const dispatch = useDispatch()
    const [progress, setProgress] = useState(0);
    const [reactionVisible, setReactionVisible] = useState(false);
    const [activeIndex , setActiveIndex] = useState(0)
    const [isPaused, setIsPaused] = useState(false);

    const reactionListRef = useRef()
    const friendStoryRef = useRef()

    const timerRef = useRef(null); // Use a ref to keep track of the timer

    const handlePrev = () => {
        setActiveIndex(prev => (prev - 1 + story.length) % story.length);
        setProgress(0);
    };

    const handleNext = () => {
        setActiveIndex(prev => (prev + 1) % story.length);
        setProgress(0);

    };

    const handleTimer = useCallback(() => {
        // Clear existing timer if any
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
            setProgress(prev => {
                if (prev < 100) {
                    return prev + 1;
                } else {
                    clearInterval(timerRef.current);
                    return prev;
                }
            });
        }, 50);

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isPaused) {
            handleTimer();
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [isPaused, handleTimer]);

    useEffect(() => {
        if (progress === 100) {
            if (activeIndex === story.length - 1) {
                dispatch(setIsFriendStoryVisible({ value: false }));
                setActiveIndex(0);
            } else {
                handleNext();
            }
        }
    }, [progress]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (friendStoryRef.current && !friendStoryRef.current.contains(e.target)) {
                dispatch(setIsFriendStoryVisible({ value: false }));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch]);

    const handleMouseEnter = () => setIsPaused(true);
    const handleMouseLeave = () => setIsPaused(false);
    
  return (
    <div className="friend-story-container" >
        <div className='friend-story' 
        ref={friendStoryRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            <div class="popup-close-button popup-story-trigger"
            onClick={ () => dispatch(setIsFriendStoryVisible({value:false})) }
            >
                <svg class="popup-close-button-icon icon-cross">
                    <use xlinkHref="#svg-cross"></use>
                </svg>
            </div>
            <div className="story-progress-bar">
                <span className='story-progress' style={{width:`${progress}%`}}></span>
            </div>
            <div className="story-header">
                <div className="user-status">
                    <Link className="user-status-avatar" to="/user-profile-page">
                        <UserProfileImage />
                    </Link>
                    <div className="ml-3">
                        <p className="user-status-title medium mb-1">
                        <a className="bold !text-white" href="/user-profile-page">
                            Marina Valentine{" "}
                        </a>
                        </p>

                        <p className="user-status-text small !mt-[-4px] !text-white">2 days ago</p>
                    </div>
                </div>
                <div className="story-actions">
                    <div className="reactions" ref={reactionListRef}>
                        <p className={`emoji ${reactionVisible ? "active" : ""}`}
                            onClick={ () => setReactionVisible( prev => !prev ) }>
                            <FaFaceSmile size={20} />
                        </p>
                        <div className={`reactions-list ${reactionVisible ? "show" : ""}`}>
                            <div className="reaction-option text-tooltip-tft" data-title="Like">
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/like.png"
                                    alt="reaction-like"
                                />
                            </div>

                            <div className="reaction-option text-tooltip-tft" data-title="Love">
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/love.png"
                                    alt="reaction-love"
                                />
                            </div>

                            <div
                            className="reaction-option text-tooltip-tft"
                            data-title="Dislike"
                            >
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/dislike.png"
                                    alt="reaction-dislike"
                                />
                            </div>

                            <div
                            className="reaction-option text-tooltip-tft"
                            data-title="Happy"
                            >
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/happy.png"
                                    alt="reaction-happy"
                                />
                            </div>

                            <div
                            className="reaction-option text-tooltip-tft"
                            data-title="Funny"
                            >
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/funny.png"
                                    alt="reaction-funny"
                                />
                            </div>

                            <div className="reaction-option text-tooltip-tft" data-title="Wow">
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/wow.png"
                                    alt="reaction-wow"
                                />
                            </div>

                            <div
                            className="reaction-option text-tooltip-tft"
                            data-title="Angry"
                            >
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/angry.png"
                                    alt="reaction-angry"
                                />
                            </div>

                            <div className="reaction-option text-tooltip-tft" data-title="Sad">
                                <img
                                    className="reaction-option-image"
                                    src="/img/reaction/sad.png"
                                    alt="reaction-sad"
                                />
                            </div>

                        </div>
                    </div>
                    <p className="share"><FaShare size={20} /></p>
                </div>
            </div>
            <div className="story-body">
                <button className='story-prevBtn' onClick={handlePrev}><FaChevronLeft size={20} /></button>
                <button className='story-nextBtn' onClick={handleNext}><FaChevronRight size={20} /></button>
                <div className="story-img">
                    <div className="overlay" style={{backgroundImage:`url(${story[activeIndex]})`}}></div>
                    <img src={story[activeIndex]} alt="" />
                </div>
                <div className="story-title"> This is story </div>
            </div>
        </div>
        
    </div>
  )
}

export default FriendStory