import React, { useState } from "react";
// import FormInput from "../../ui/formInput/FormInput";
import Select from "../../ui/select/Select";
import "../Search.css";
import FormInputwithIcon from "../../ui/formInputWithSearchIcon/FormInputwithIcon";
import SummaryResults from "../SummaryResults/SummaryResults";
import EventsCard from "./EventsCard/EventsCard";

const countries = [
	{ id: 1, value: "Egypt" },
	{ id: 2, value: "United Arab Emirates" },
	{ id: 3, value: "Bahrain" },
	{ id: 4, value: "Saudi" },
];

const initialItemsNumber = [
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
];

const EventsSearch = () => {
	const [isEventVisible, setIsEventVisible] = useState(true);
	const [numberOfEvents, setNumberOfEvents] = useState([...initialItemsNumber]);
	return (
		<>
			<div
				className={`search-events-component common-for-dir-cat ${
					isEventVisible
						? "common-for-dir-cat-active"
						: "common-for-dir-cat-close"
				}`}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
					className="title-search-category"
					onClick={() => {
						setIsEventVisible((prev) => !prev);
					}}
				>
					<div className="search-title">
						<h4>Search Events</h4>
						<p>Find what you need quickly and easily</p>
					</div>

					<svg
						class="slider-control-icon icon-small-arrow"
						style={{
							transform: `rotate(${isEventVisible ? "270" : "90"}deg)`,
							cursor: "pointer",
							marginTop: "-40px",
							fill: `${isEventVisible ? "#ff6728" : ""}`,
						}}
					>
						<use xlinkHref="#svg-small-arrow"></use>
					</svg>
				</div>

				{isEventVisible && (
					<form>
						{/* <FormInput
					label={"Keyword"}
					type={"text"}
					name={"key-word-event"}
					id={"key_word_event"}
				/> */}
						<FormInputwithIcon
							name={"keyword-events"}
							id={"keyword_events"}
							label={"Keyword"}
						/>
						<div style={{ marginBottom: "5px" }}></div>
						<div className="search-row">
							<Select defaultValue={"Country"} optionsArray={countries} />
							<Select defaultValue={"City"} optionsArray={countries} />
						</div>
						<button
							className="button medium secondary"
							style={{ marginTop: "15px" }}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "5px",
								}}
							>
								<svg class="icon-magnifying-glass">
									<use xlinkHref="#svg-magnifying-glass"></use>
								</svg>
								Search
							</div>
						</button>
					</form>
				)}
			</div>

			<SummaryResults
				numberofItems={numberOfEvents}
				setNumberOfItems={setNumberOfEvents}
				category={"events"}
			/>

			<div className="grid grid-4-4-4 centered">
				<EventsCard />
				<EventsCard />
				<EventsCard />
				<EventsCard />
			</div>
		</>
	);
};

export default EventsSearch;
