import React, { useState } from "react";
import { Link } from "react-router-dom";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";
import AdsBanner from "../../components/AdsBanner/AdsBanner";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import FriendsStories from "../../components/HomeScreen/Stories/FriendsStories";
import FriendStory from "../../components/HomeScreen/Stories/FriendStory";
import UserStory from "../../components/HomeScreen/Stories/UserStory";
import HomeLeftAside from "../../components/HomeScreen/HomeAsides/HomeLeftAside";
import HomeRightAside from "../../components/HomeScreen/HomeAsides/HomeRightAside";
import { useSelector } from "react-redux";

const bannersImages = [
  "/img/ads/ad.png",
  "/img/ads/ad.png",
  "/img/ads/ad.png",
];


const AllStories = () => {
  const screenWidth = window.innerWidth < 1365;
  const isUserStoryVisible = useSelector((state) => state.outlet.isUserStoryVisible);
  const isFriendStoryVisible = useSelector((state) => state.outlet.isFriendStoryVisible);

  return (
    <>
    <div className="homescreen">
      <AdsBanner bannersImages={bannersImages} />

      <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
        <div className="grid grid-3-6-3">
          <HomeLeftAside />

          <div className="grid-column">
            <FriendsStories />
          </div>

          <HomeRightAside />
        </div>
      </WrapInCenter>

      <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className={`ads-swiper-2 !h-[200px] !pb-[50px] mt-[30px]`}
        >
          {bannersImages.map((banner, index) => (
            <SwiperSlide
              key={index}
              className="bg-white rounded-[12px] shadow-[0_0_40px_0_rgba(94,92,154,0.06)]"
            >
              <div className="banner-container !w-[970px] !h-[90px]">
                <img src={banner} alt="ads banner" loading="lazy" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <footer
          className={`${
            screenWidth ? "" : "isMobileView"
          } text-center fw-semibold fs-6 login-footer`}
          n
        >
          {/* begin::Link */}
          <div className="footer-links-container">
            <Link className="" to="/auth">
              Terms of use
            </Link>
            <Link className="" to="/auth">
              Privacy Policy
            </Link>
            <Link className="" to="/auth">
              Contact Us
            </Link>
            <Link className="" to="/auth">
              About
            </Link>
            <Link className="" to="/auth">
              Blog
            </Link>
            <Link className="" to="/auth">
              Events
            </Link>
          </div>
          <p className="copyrights ">
            Copyright &copy; 2024 FLOKKY | BRANDMARKS
          </p>
          {/* end::Link */}
        </footer>
      </WrapInCenter>
    </div>

    { isUserStoryVisible && <UserStory /> }
    { isFriendStoryVisible && <FriendStory /> }

    </>
  );
};

export default AllStories;
