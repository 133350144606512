import React, { useState, useRef } from "react";
// import FormInput from "../../ui/formInput/FormInput";
import Select from "../../ui/select/Select";
import "../Search.css";
import FormInputwithIcon from "../../ui/formInputWithSearchIcon/FormInputwithIcon";
import MembersCard from "./MembersCard";
import SummaryResults from "../SummaryResults/SummaryResults";

const yearsArray = [
	{ value: 1920 },
	{ value: 1921 },
	{ value: 1922 },
	{ value: 1923 },
	{ value: 1924 },
	{ value: 1925 },
	{ value: 1926 },
	{ value: 1927 },
	{ value: 1928 },
	{ value: 1929 },
	{ value: 1930 },
	{ value: 1931 },
	{ value: 1932 },
	{ value: 1933 },
	{ value: 1934 },
	{ value: 1935 },
	{ value: 1936 },
	{ value: 1937 },
	{ value: 1938 },
	{ value: 1939 },
	{ value: 1940 },
	{ value: 1941 },
	{ value: 1942 },
	{ value: 1943 },
	{ value: 1944 },
	{ value: 1945 },
	{ value: 1946 },
	{ value: 1947 },
	{ value: 1948 },
	{ value: 1949 },
	{ value: 1950 },
	{ value: 1951 },
	{ value: 1952 },
	{ value: 1953 },
	{ value: 1954 },
	{ value: 1955 },
	{ value: 1956 },
	{ value: 1957 },
	{ value: 1958 },
	{ value: 1959 },
	{ value: 1960 },
	{ value: 1961 },
	{ value: 1962 },
	{ value: 1963 },
	{ value: 1964 },
	{ value: 1965 },
	{ value: 1966 },
	{ value: 1967 },
	{ value: 1968 },
	{ value: 1969 },
	{ value: 1970 },
	{ value: 1971 },
	{ value: 1972 },
	{ value: 1973 },
	{ value: 1974 },
	{ value: 1975 },
	{ value: 1976 },
	{ value: 1977 },
	{ value: 1978 },
	{ value: 1979 },
	{ value: 1980 },
	{ value: 1981 },
	{ value: 1982 },
	{ value: 1983 },
	{ value: 1984 },
	{ value: 1985 },
	{ value: 1986 },
	{ value: 1987 },
	{ value: 1988 },
	{ value: 1989 },
	{ value: 1990 },
	{ value: 1991 },
	{ value: 1992 },
	{ value: 1993 },
	{ value: 1994 },
	{ value: 1995 },
	{ value: 1996 },
	{ value: 1997 },
	{ value: 1998 },
	{ value: 1999 },
	{ value: 2000 },
	{ value: 2001 },
	{ value: 2002 },
	{ value: 2003 },
	{ value: 2004 },
	{ value: 2005 },
	{ value: 2006 },
	{ value: 2007 },
	{ value: 2008 },
	{ value: 2009 },
	{ value: 2010 },
	{ value: 2011 },
	{ value: 2012 },
	{ value: 2013 },
	{ value: 2014 },
	{ value: 2015 },
	{ value: 2016 },
	{ value: 2017 },
	{ value: 2018 },
	{ value: 2019 },
	{ value: 2020 },
	{ value: 2021 },
	{ value: 2022 },
	{ value: 2023 },
	{ value: 2024 },
	{ value: 2025 },
	{ value: 2026 },
	{ value: 2027 },
	{ value: 2028 },
	{ value: 2029 },
	{ value: 2030 },
	{ value: 2031 },
	{ value: 2032 },
	{ value: 2033 },
	{ value: 2034 },
	{ value: 2035 },
	{ value: 2036 },
	{ value: 2037 },
	{ value: 2038 },
	{ value: 2039 },
	{ value: 2040 },
	{ value: 2041 },
	{ value: 2042 },
	{ value: 2043 },
	{ value: 2044 },
	{ value: 2045 },
	{ value: 2046 },
	{ value: 2047 },
	{ value: 2048 },
	{ value: 2049 },
	{ value: 2050 },
	{ value: 2051 },
	{ value: 2052 },
	{ value: 2053 },
	{ value: 2054 },
	{ value: 2055 },
	{ value: 2056 },
	{ value: 2057 },
	{ value: 2058 },
	{ value: 2059 },
	{ value: 2060 },
];

const countries = [
	{ id: 1, value: "Egypt" },
	{ id: 2, value: "United Arab Emirates" },
	{ id: 3, value: "Bahrain" },
	{ id: 4, value: "Saudi" },
];

const initialItemsNumber = [
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
];

const MembersSearch = () => {
	const [isMembersVisible, setIsMembersVisible] = useState(true);
	const [numberofMembers, setNumberOfMembers] = useState([
		...initialItemsNumber,
	]);

	return (
		<>
			<div
				className={`search-member-component common-for-dir-cat ${
					isMembersVisible
						? "common-for-dir-cat-active"
						: "common-for-dir-cat-close"
				}`}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						cursor: "pointer",
					}}
					className="title-search-category"
					onClick={() => {
						setIsMembersVisible((prev) => !prev);
					}}
				>
					<div className="search-title">
						<h4>Search Members</h4>
						<p>Find what you need quickly and easily</p>
					</div>

					<svg
						class="slider-control-icon icon-small-arrow"
						style={{
							transform: `rotate(${isMembersVisible ? "270" : "90"}deg)`,
							cursor: "pointer",
							marginTop: "-40px",
							fill: `${isMembersVisible ? "#ff6728" : ""}`,
						}}
					>
						<use xlinkHref="#svg-small-arrow"></use>
					</svg>
				</div>

				{isMembersVisible && (
					<form>
						<div>
							<FormInputwithIcon
								name={"keyword-members"}
								id={"keyword_members"}
								label={"Keyword"}
							/>
							<div style={{ marginBottom: "5px" }}></div>
						</div>
						<div className="search-row">
							<Select
								defaultValue={"Country Of Residence"}
								optionsArray={countries}
							/>
							<Select defaultValue={"City"} optionsArray={countries} />
						</div>
						<div className="search-row">
							<Select
								defaultValue={"University: All"}
								optionsArray={countries}
							/>
							<Select defaultValue={"Nationality"} optionsArray={countries} />
						</div>
						<div className="search-row">
							<Select defaultValue={"Years From"} optionsArray={yearsArray} />
							<Select defaultValue={"Years To"} optionsArray={yearsArray} />
						</div>
						<button
							className="button medium secondary"
							style={{ marginTop: "15px" }}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "5px",
								}}
							>
								<svg class="icon-magnifying-glass">
									<use xlinkHref="#svg-magnifying-glass"></use>
								</svg>
								Search
							</div>
						</button>
					</form>
				)}
			</div>
			<SummaryResults
				numberofItems={numberofMembers}
				setNumberOfItems={setNumberOfMembers}
				category={"members"}
			/>
			<div className="grid grid-4-4-4 centered">
				{numberofMembers.map((member, i) => (
					<MembersCard isFriend={member} key={i + 1} />
				))}
			</div>
		</>
	);
};

export default MembersSearch;
