import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
import MemberPageOne from '../../../SearchPageComponents/Members/MemberPageOne';
import MemberPageTwo from '../../../SearchPageComponents/Members/MemberPageTwo';
import { MdAdminPanelSettings, MdBlock } from 'react-icons/md';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { Tooltip } from 'antd';

const ConnectionRequests = () => {
    const { showContent } = useSelector((state) => state.userDashboard)
    const [page, setPage] = useState(true);
    const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
                <svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
                    <use xlinkHref="#svg-back-arrow"></use>
                </svg>
            </span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Connectivity</p>
                    <h2 className="section-title !text-[1.4rem] md:!text-[1.6rem]">
                        Connection Requests <span className="highlighted">3</span>
                    </h2>
                </div>
                <div className="section-header-actions w-[80%] md:w-auto">
                    <p className="section-header-action">Find Connections</p>
                    <p className="section-header-action ml-[20px] md:ml-[32px]">Settings</p>
                </div>
            </div>

            <div className="grid grid-4-4-4 ">
                <div className="user-preview result">
                    {page === false && <div className="image-border"></div>}
                    {page === true && (
                        <figure className="user-preview-cover liquid">
                            <img
                                src="/img/cover/04.jpg"
                                alt="cover-04"
                                className="user-cover-image-result"
                            />
                        </figure>
                    )}

                    <div className="user-preview-info !px-[10px]">
                        {page === true && (
                            <div className="user-short-description results">
                                <div className="card-image-avatar">
                                    <img src="/img/avatar/03.jpg" alt="user-avatar" />
                                    <MdAdminPanelSettings
                                        color={"#d7006a"}
                                        size={24}
                                        className="admin-user-result-avatar"
                                    />
                                    <RiVerifiedBadgeFill
                                        color={"#36e9f7"}
                                        size={24}
                                        className="verify-user-result-avatar"
                                    />
                                    <div className="badge-container">
                                        <img src="/img/download.png" alt="Padge" />
                                        <p className="badge-number">12</p>
                                    </div>
                                </div>

                                <p className="user-short-description-title user-result-name">
                                    <a href="">NekoBebop</a>
                                </p>

                                <p className="user-short-description-text user-job-result">
                                    <a href="">Marketing Manager</a>
                                </p>
                                <p className="user-short-description-text user-job-result company">
                                    <a href="">Brandmarks</a>
                                </p>
                            </div>
                        )}

                        <div
                            id="user-preview-stats-roster-01"
                            className={`user-preview-stats-roster slider-roster swipe-pages !top-[10px] !right-[15px] ${page === false ? "swipe-pages-two" : ""
                                }`}
                            onClick={() => {
                                setPage((prev) => !prev);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <div
                                className={`slider-roster-item ${page === true ? "active-dot" : ""
                                    }`}
                            ></div>

                            <div
                                className={`slider-roster-item ${page === false ? "active-dot" : ""
                                    }`}
                            ></div>
                        </div>

                        {page === false && <MemberPageOne />}
                        {page === true && <MemberPageTwo />}

                        <div className="user-preview-actions">
                            <Tooltip placement="top" color="#fd6728" title="Accept Connection">
                                <p className="button secondary !flex items-center justify-center">
                                    <svg class="button-icon icon-add-friend">
                                        <use xlinkHref="#svg-add-friend"></use>
                                    </svg>
                                </p>
                            </Tooltip>
                            <Tooltip placement="top" color="#fd6728" title="Ignore Connection">
                                <p className="button white white-tertiary !flex items-center justify-center">
                                    <svg class="button-icon icon-remove-friend">
                                        <use xlinkHref="#svg-remove-friend"></use>
                                    </svg>
                                </p>
                            </Tooltip>
                            <Tooltip placement="top" color="#fd6728" title="Block Connection">
                                <p className="button white white-tertiary !flex items-center justify-center">
                                    <MdBlock class="button-icon icon-remove-friend" />
                                </p>
                            </Tooltip>




                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ConnectionRequests