import React, { useEffect, useState,useRef } from "react";
// import FormInput from "../../ui/formInput/FormInput";
import UserProfileImage from "../../user-profile-image/UserProfileImage";
import { IoSend } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { BsEmojiSmile } from "react-icons/bs";
import { LuSticker } from "react-icons/lu";
import EmojiPickerr from "../EmojiPicker";
import FormTextBox from "../../ui/formTextBox/formTextBox";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import AudioPlayer from "../../ui/AudioPlayer/AudioPlayer";

const PostCommentForm = ({ className }) => {
  const [value, setValue] = useState("");
  const [isAdditionlCommentListOpen, setIsAdditionlCommentListOpen] =
    useState(false);
  const [isStickersOpened, setIsStickersOpened] = useState(false);
  const [isEmojiListOpen, setIsEmojiListOpen] = useState(false);
  const [isGifOpened , setIsGifOpened] = useState(false)
  const [isRecordOpened , setIsRecordOpened] = useState(false)
  const [imagePreview, setImagePreview] = useState(null);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [stickers, setStickers] = useState([]);

  const handleEmojiSelect = (emoji) => {
    setValue((prev) => prev + emoji.emoji);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
	setIsGifOpened(false);
	setIsStickersOpened(false);
	setIsRecordOpened(false);
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
  };

  const [selectedGif, setSelectedGif] = useState(null);
  const [gifs, setGifs] = useState([]);
  const [query, setQuery] = useState("");

  const searchGifs = async (query) => {
    try {
      const response = await fetch(
        `https://api.giphy.com/v1/gifs/search?api_key=2Fe6dvj8EKnZMSPUE8vqnjxvBAXj2oHz&q=${query}&limit=10`
      );
      const data = await response.json();
      setGifs(data.data);
    } catch (error) {
      console.error("Error fetching GIFs:", error);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue); // Update query state with input value
    searchGifs(inputValue); // Call searchGifs with the updated query
  };

  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [voice, setVoice] = useState(false);
  const [recordBlobLink, setRecordBlobLink] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const intervalRef = useRef(null);

  const onStop = () => {
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
    const url = URL.createObjectURL(audioBlob);
    setRecordBlobLink(url);
    audioChunksRef.current = [];
    setIsRunning(false);
    clearInterval(intervalRef.current);
  };

  const startHandle = async () => {
    setElapsedTime(0);
    setIsRunning(true);
    setVoice(true);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = onStop;

    mediaRecorderRef.current.start();

    intervalRef.current = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopHandle = () => {
    mediaRecorderRef.current.stop();
    setIsRunning(false);
    setVoice(false);
    clearInterval(intervalRef.current);
  };

  const clearHandle = () => {
    setIsRunning(false);
    setVoice(false);
    setRecordBlobLink(null);
    setElapsedTime(0);
    clearInterval(intervalRef.current);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchStickers = async () => {
      try {
        const response = await axios.get(
          `https://api.giphy.com/v1/stickers/trending`,
          {
            params: {
              api_key: "2Fe6dvj8EKnZMSPUE8vqnjxvBAXj2oHz",
              limit: 25, // يمكنك تغيير العدد حسب حاجتك
            },
          }
        );
        setStickers(response.data.data);
      } catch (error) {
        console.error("Error fetching stickers:", error);
      }
    };

    fetchStickers();
  }, []);

  return (
    <div className={`post-comment-form ${className ? className : ""}`}>
      <div className="user-avatar small no-outline">
        <UserProfileImage />
      </div>

      <form
        className="form flex items-center gap-[10px]"
        onSubmit={(e) => e.preventDefault()}
      >
        {/* <FormInput
            label={"Your Reply"}
            name={"post-reply"}
            id={"post_reply"}
            type={"text"}
			value={value}
			setValue={setValue}
          /> */}

        <FormTextBox
          label={"Your Reply"}
          name={"post-reply"}
          id={"post_reply"}
          type={"text"}
          value={value}
          setValue={setValue}
        />

        {isAdditionlCommentListOpen && (
          <ul className="grid md:!grid-cols-5 additional-comment-list">
            <li className="relative">
              <span
                className="post-emoji-btn text-[#3e3f5e] hover:text-[#fd6729]"
                onClick={() => setIsEmojiListOpen((prev) => !prev)}
              >
                <BsEmojiSmile className="text-[#3e3f5e]" size={20} />
              </span>

              {isEmojiListOpen && (
                <div className="emojii absolute right-[-40px] top-[40px] z-[99999]">
                  <EmojiPickerr onSelect={handleEmojiSelect} />
                </div>
              )}
            </li>
            <li
            onClick={() => {
              setIsRecordOpened(prev => !prev);
              setIsGifOpened(false);
              setIsStickersOpened(false);
              setImagePreview(null)
              }}
			>
              <span>
                <img
                  className="w-[50px]"
                  src="https://develop.abncw.com/themes/sunshine/img/icons/pub-rec.svg"
                  alt="Record Voice"
                />
              </span>
            </li>
            <li>
              <label className="cursor-pointer" htmlFor="upload-image">
                <img
                  className="w-[50px]"
                  src="https://develop.abncw.com/themes/sunshine/img/icons/pub-img.svg"
                  alt="Upload Images"
                />
              </label>
              <input
                type="file"
                name="upload-image"
                id="upload-image"
                accept="image/*"
                onChange={handleImageUpload}
                hidden={true}
              />
            </li>
            <li 
              onClick={() => {
                setIsGifOpened(prev => !prev);
                setIsStickersOpened(false);
                setImagePreview(null);
                setIsRecordOpened(false);
                }}
			>
              <span>
                <img
                  className="w-[50px]"
                  src="https://develop.abncw.com/themes/sunshine/img/icons/pub-gif.svg"
                  alt="Upload Gifs"
                />
              </span>
            </li>
            <li 
              onClick={() => {
              setIsStickersOpened((prev) => !prev);
              setIsGifOpened(false);
              setImagePreview(null);
              setIsRecordOpened(false);
				}}>
              <span>
                <LuSticker className="text-[#3e3f5e]" size={22} />
              </span>
            </li>
          </ul>
        )}

        <button
          className="w-fit text-[#fd6729] transition duration-[0.3s]"
          style={
            isAdditionlCommentListOpen
              ? { transform: "rotate(-45deg)" }
              : { transform: "rotate(0deg)" }
          }
          onClick={() => {
            setIsAdditionlCommentListOpen((prev) => !prev)
            // setIsRecordOpened(false);
            // setIsGifOpened(false);
            // setIsStickersOpened(false);
            // setImagePreview(null)
          }}
        >
          <FaPlus size={20} />
        </button>
        <button className="w-fit text-[#fd6729]">
          <IoSend size={20} />
        </button>
      </form>

	  {imagePreview && (
        <div className="image-uploaded relative w-full md:w-[400px] lg:w-[300px]">
          <img src={imagePreview} alt="" />
          <button
            className="absolute top-[5px] right-[5px] bg-[#00000040] text-white w-[25px] h-[25px] flex items-center justify-center rounded-[50%]"
            onClick={() => handleRemoveImage()}
          >
            <FaTimes />
          </button>
        </div>
      )}

      {isStickersOpened && (
        <div className="sticker-picker flex gap-2 flex-wrap">
          {stickers.map((sticker) => (
            <img
              key={sticker.id}
              src={sticker.images.fixed_height_small.url}
              alt={`Sticker ${sticker.id}`}
              //   onClick={() => onSelectSticker(sticker)}
              style={{ cursor: "pointer", width: "50px", height: "50px" }}
            />
          ))}
        </div>
      )}

	  { isGifOpened && (
		<div className="gifs-selector">
		{
			selectedGif && (
				<div className="mb-[20px] relative w-[200px]">
					<img
						src={selectedGif?.images.fixed_height.url}
						alt={selectedGif?.title}
					/>

					<button
						className="absolute top-[5px] right-[5px] bg-[#00000040] text-white w-[25px] h-[25px] flex items-center justify-center rounded-[50%]"
						onClick={() => {
						setSelectedGif(null);
						}}
					>
						<FaTimes />
					</button>
				</div>
			)
		}
		<div>
			<input
				type="text"
				value={query}
				onChange={handleInputChange}
				placeholder="Search for GIFs"
				className="mb-[20px]"
			/>
			<div
				className="flex flex-wrap"
				//   style={
				//     gifs.length > 12 ? {height:400 , overflowY:"scroll"} : {}
				//   }
			>
				{gifs?.map((gif) => (
				<img
					key={gif.id}
					src={gif.images.fixed_height.url}
					alt={gif.title}
					onClick={() => setSelectedGif(gif)}
					style={{ cursor: "pointer", width: 115,height:115 }}
				/>
				))}
			</div>
		</div>
		</div>
	  )}

	  { isRecordOpened && (
		<div className="max-w-sm border py-4 px-6 mx-auto">
			<div>
				{recordBlobLink && (
				<button onClick={clearHandle} className="button small white">
					Clear
				</button>
				)}
			</div>
			<div className="">
				<div className="timer text-center mb-[10px] text-[20px]">
				{isRunning && <span>{formatTime(elapsedTime)}</span>}
				</div>
				{!voice ? (
				<button onClick={startHandle} className="button small primary">
					Start
				</button>
				) : (
				<button onClick={stopHandle} className="button small primary">
					Stop
				</button>
				)}
			</div>
			<div>
				{recordBlobLink && 
				// <audio controls src={} className="mt-6" />
				<AudioPlayer src={recordBlobLink} />
				}
			</div>
		</div>
	  )}


    </div>
  );
};

export default PostCommentForm;
