import React from "react";
import Select from "../../../ui/select/Select";
import FormInput from "../../../ui/formInput/FormInput";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const WorklExperienceForm = () => {
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Experience</p>
					<h2 className="section-title">Work Experience</h2>
				</div>
			</div>
			<div className="widget-box">
				{/* <p className="widget-box-title">Work Experience</p> */}

				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center justify-between !gap-8 !flex-row max-[680px]:!flex-col max-[680px]:!gap-0">
							<FormInput
								label={"Job Title"}
								name={"job-title"}
								id={"job_title"}
								type={"text"}
								required={true}
							/>
							<Select defaultValue={"Employment Type"} optionsArray={[]} required={true} />
						</div>
						<div className="flex items-center justify-between !gap-8 !flex-row max-[680px]:!flex-col max-[680px]:!gap-0">
							<Select defaultValue={"Start Date"} optionsArray={[]} required={true} />
							<Select defaultValue={"End Date"} optionsArray={[]} required={true} />
						</div>
						<div className="flex items-center justify-between !gap-8 !flex-row max-[680px]:!flex-col max-[680px]:!gap-0">
							<FormInput
								label={"Company Name"}
								name={"company-name"}
								id={"company_name"}
								type={"text"}
								required={true}
							/>
							<Select defaultValue={"Location"} optionsArray={[]} required={true} />
						</div>
						<Select defaultValue={"Industry"} optionsArray={[]} required={true} />

						<button
							className="button medium secondary mt-2 !hidden md:!block"
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							Add
						</button>
					</form>
				</div>
			</div>


			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default WorklExperienceForm;
