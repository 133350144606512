/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import FriendProfileImage from "../user-profile-image/UserProfileImage";
import img1 from '../../assests/crea8/pro-1.webp'
import img2 from '../../assests/crea8/pro-2.webp'
import img3 from '../../assests/crea8/pro-3.webp'
import img4 from '../../assests/crea8/pro-4.webp'
import WrapInCenter from "../WrapInCenter/WrapInCenter";
export default function InspiringService11() {
    const inspiringProjects = [
        {
          id: 1,
          title: "Caricatures",
          imageSrc: img1,
          freelancerImageSrc: img1,
          author: "Ali Tufan",
        },
        {
          id: 2,
          title: "Product Photography",
          imageSrc: img2,
          freelancerImageSrc: img2,
          author: "Ali Tufan",
        },
        {
          id: 3,
          title: "UI Design",
          imageSrc: img3,
          freelancerImageSrc: img3,
          author: "Ali Tufan",
        },
        {
          id: 4,
          title: "App Design",
          imageSrc: img4,
          freelancerImageSrc: img4,
          author: "Ali Tufan",
        },
      ];

  return (
    <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
    <section className="md:pb-90 pb-20">
      <div className="container">
        <div className="row align-items-center wow fadeInUp" data-wow-delay="00ms">
          <div className="col-lg-9">
            <div className="main-title">
              <h2 className="title title-h2-inspiring-crea8">Inspiring work made on Freeio</h2>
              <p className="paragraph">Get some Inspirations from 1800+ skills</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="text-start text-lg-end mb-4 mb-lg-2">
              <Link className="ud-btn2 flex gap-2 md:justify-end justify-start items-center
              font-extrabold text-lg" to="/blog-1">
                See more <FaArrowRight />
              </Link>
            </div>
          </div>
        </div>
        <div className="row wow fadeInUp" data-wow-delay="300ms">
          {inspiringProjects.map((elm, i) => (
            <div key={i} className="col-sm-6 col-xl-3">
              <div className="listing-style1 bdrs12 default-box-shadow1 border-raduis-box-trending-crea8">
                <div className="list-thumb">
                  <img
                    width={330}
                    height={250}
                    style={{ height: "fit-content" }}
                    className="w-100"
                    src={elm.imageSrc}
                    alt="image"
                  />
                </div>
                <div className="list-content">
                  <div className="list-meta">
                    <a className="d-flex align-items-center" href="#">
                      <span className="position-relative mr-4">
                       <FriendProfileImage/>
                      </span>
                      <span>
                        <h5 className="fz14 mb-1">{elm.title}</h5>
                        <p className="fz14 mb-0">by {elm.author}</p>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
      
    </WrapInCenter>
  );
}
