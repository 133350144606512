import { FaSearch } from "react-icons/fa";
import AdsBanner from "../../AdsBanner/AdsBanner"
import WrapInCenter from "../../WrapInCenter/WrapInCenter";
import img from '../../../assests/about/home20-hero-2.webp'
import img2 from '../../../assests/about/home20-hero-5.webp'
import img3 from '../../../assests/about/home20-hero-6.webp'

const Hero = () => {
  const bannersImages = [
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
  ];
  return (
    <div className="mt-[80px] hero1-crea8 relative">
      <div className=" mt-[-80px] content-hero-1-crea8">
        <div className="images-hero-crea8">
          <div className="left-image-hero-crea8">
            <img src={img} className="img1" alt="" />
            <img src={img2} className="img2" alt="" />
            <img src={img3} className="img3" alt="" />
            <img src={img} className="img4" alt="" />
            <img src={img2} className="img5" alt="" />
            <img src={img3} className="img6" alt="" />
          </div>
        </div>
      <AdsBanner bannersImages={bannersImages}/>
      <WrapInCenter  noPaddingBottom={true} noPaddingTop={false}>
      <div className="flex justify-center flex-col items-center">
        <h1 className="text-6xl">Hire the best Flokkancers for any job!</h1>
        <p className="text-2xl max-w-[720px]">Wrok with talented people at the most affordable price
         to get the most out of your time and cost</p>
         <form className="search-form-hero-crea8">
          <div className="search-crea8-hero">
            <FaSearch/>
            <input type="text" placeholder="What are you looking for?" />
          </div>
          <div className="select-crea8-hero">
            <select name="" id="">
              <option value="" disabled>Choose Category</option>
              <option value=""> Category 1</option>
              <option value=""> Category 2</option>
              <option value=""> Category 3</option>
            </select>
          </div>
          <div className="btn-search-hero-crea8">
            <button>Search</button>
          </div>
         </form>
      </div>
      <div className="popular-search-hero-crea8 popular-search-hero-crea8-flex">
          <p className="popular-search-hero-crea8 text-crea8-hero">Popular Searches</p>
         <ul className="liset-hero-crea8 ml-5">
          <li>Designer</li>
          <li>Developer</li>
          <li>Web</li>
          <li>IOS</li>
          <li>PHP</li>
          <li>Senior</li>
         </ul>
      </div>
      </WrapInCenter>
      </div>
    </div>
  )
}

export default Hero
