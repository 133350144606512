/* eslint-disable jsx-a11y/img-redundant-alt */
import img from '../../assests/crea8/home19-vector-1.webp'
import img2 from '../../assests/crea8/home19-vector-2.webp'
import img3 from '../../assests/crea8/home19-vector-3.webp'
import img4 from '../../assests/crea8/home19-vector-4.webp'
import WrapInCenter from '../WrapInCenter/WrapInCenter';
export default function NeedSomething5() {
     const stepsThree = [
        {
          id: 1,
          imgSrc: img,
          title: "Post a job",
          description:
            "It’s free and easy to post a job. Simply fill in a title, description.description",
        },
        {
          id: 2,
          imgSrc: img2,
          title: "Choose freelancers",
          description:
            "It’s free and easy to post a job. Simply fill in a title, description.",
        },
        {
          id: 3,
          imgSrc: img3,
          title: "Pay safely",
          description:
            "It’s free and easy to post a job. Simply fill in a title, description.",
        },
        {
          id: 4,
          imgSrc: img4,
          title: "We’re here to help",
          description:
            "It’s free and easy to post a job. Simply fill in a title, description.description",
        },
      ];
  return (
    <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
    <section className="our-features md:pb-90 pb-20">
      <div className="container wow fadeInUp">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-title">
              <h2 className='title-h2-inspiring-crea8'>Need something done?</h2>
              <p className="text text-black">
                Most viewed and all-time top-selling services
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {stepsThree.map((elm, i) => (
            <div key={i} className="col-sm-6 col-lg-3">
              <div className="iconbox-style1 bdr1 text-center">
                <div className="thumb">
                  <img
                    width={270}
                    height={181}
                    className="w-100"
                    style={{ height: "fit-content" }}
                    src={elm.imgSrc}
                    alt=" image "
                  />
                </div>
                <div className="details">
                  <h4 className="title-need-some-one mt-4 mb-4 text-xl">{elm.title}</h4>
                  <p className="text-black">{elm.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </WrapInCenter>
  );
}
