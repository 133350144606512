import React from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
import UserProfile from "../../../user-profile-image/UserProfileImage"


const NotificationBox = ({isUnread,type,reactionName}) => {
    return (
        <div className={`notification-box ${isUnread ? "unread" : ""}`}>
            <div className="user-status notification">
                <a className="user-status-avatar" href="profile-timeline.html">
                    <UserProfile />
                </a>
                <p className="user-status-title">
                    <a className="bold" href="profile-timeline.html">Nick Grissom</a> 
                    { type === "comment" && " posted a comment on your " }
                    { type === "reply" && " replied to a comment you made on your " }
                    { type === "share" && " shared your " }
                    { type === "reaction" && <> left a {reactionName} <img className='reaction' src={`/img/reaction/${reactionName}.png`} alt="" /> reaction on your </> }
                    <a className="highlighted" href="profile-timeline.html">status update</a>
                </p>
                <p className="user-status-text small-space">Marketing | Brandmarks | Law - Nile University</p>
                <p className="user-status-timestamp small-space">2 minutes ago</p>
                <div className="user-status-icon">
                    {type == "comment" && <svg className="icon-comment"><use xlinkHref="#svg-comment"></use></svg>}
                    {type == "reply" && <svg className="icon-comment"><use xlinkHref="#svg-comment"></use></svg>}
                    {type == "share" && <svg className="icon-share"><use xlinkHref="#svg-share"></use></svg>}
                    {type == "reaction" && <svg className="icon-thumbs-up"><use xlinkHref="#svg-thumbs-up"></use></svg>}
                </div>
            </div>

            <div className="notification-box-close-button">
                <svg className="notification-box-close-button-icon icon-cross">
                    <use xlinkHref="#svg-cross"></use>
                </svg>
            </div>

            <div className={`mark-${isUnread ? "read" : "unread"}-button`}></div>
        </div>
    )
}


const Notifications = () => {
    const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Connectivity</p>
                    <h2 className="section-title">Notifications</h2>
                </div>
                <div className="section-header-actions">
                    <p className="section-header-action">Mark all as Read</p>
                    <p className="section-header-action">Settings</p>
                </div>
            </div>

            <div className="notification-box-list">
                <NotificationBox type={"comment"} />
                <NotificationBox isUnread={true} type={"reaction"} reactionName={"like"} />
                <NotificationBox type={"reply"} />
                <NotificationBox type={"reaction"} reactionName={"happy"} />
                <NotificationBox type={"share"} />
                <NotificationBox isUnread={true} type={"reaction"} reactionName={"love"} />
            </div>
        </div>
    )
}

export default Notifications