import React, { useState } from "react";

const JobsAndEducation = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState(false);

	return (
		<div className="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p className="widget-box-title">Jobs &amp; Education</p>

			<div className="widget-box-content">
				<div className="timeline-information-list">
					<div className="timeline-information">
						<p className="timeline-information-title">Lead Costume Designer</p>

						<p className="timeline-information-date">2015 - NOW</p>

						<p className="timeline-information-text">
							Lead Costume Designer for the "Amazzo Costumes" agency. I'm in
							charge of a ten person group, overseeing all the proyects and
							talking to potential clients. I also handle some face to face
							interviews for new candidates.
						</p>
					</div>

					<div className="timeline-information">
						<p className="timeline-information-title">Costume Designer</p>

						<p className="timeline-information-date">2013 - 2015</p>

						<p className="timeline-information-text">
							Costume Designer for the "Jenny Taylors" agency. Was in charge of
							working side by side with the best designers in order to complete
							and perfect orders.
						</p>
					</div>

					<div className="timeline-information">
						<p className="timeline-information-title">Designer Intern</p>

						<p className="timeline-information-date">2012 - 2013</p>

						<p className="timeline-information-text">
							Intern for the "Jenny Taylors" agency. Was in charge of the
							communication with the clients and day-to-day chores.
						</p>
					</div>

					<div className="timeline-information">
						<p className="timeline-information-title">
							The Antique College of Design
						</p>

						<p className="timeline-information-date">2007 - 2012</p>

						<p className="timeline-information-text">
							Bachelor of Costume Design in the Antique College. It was a five
							years intensive career, plus a course about Cosplays. Average: A+
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default JobsAndEducation;
