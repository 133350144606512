import React from 'react'

const HomeSponsore = () => {
  return (
    <div className="home-sponsore-card bg-white rounded-[8px] border-[1px] border-solid border-[#fd6729] overflow-hidden shadow-[0_0_40px_0_rgba(94,92,154,0.06)]">
      <a href="#">
      <h3 className='bg-[#fd6729] text-[#fff] p-2'>Sponsored</h3>
      <div className="home-sponsore-img">
        <img className='w-full' src="/img/avatar/29.jpg" alt="" />
      </div>
      <div className="home-sponsore-body p-[10px] pt-[18px]">
        <h3 className='mb-2 text-[18px]'>Indiepro Game Developement</h3>
        <p className='mb-2'>
          {" "}
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam
          repellat perspiciatis totam maiores sed doloremque laudantium sequi
          quae aspernatur saepe eligendi fuga.
        </p>
        <a className='text-[#fd6729]' href="#">abcnw.com</a>
      </div>
      </a>
    </div>
  );
}

export default HomeSponsore