import React from "react";

const JobsSearchItems = () => {
	return (
		<>
			<p>
				Search Title or Company: <span>Brandmarks</span>
			</p>
			<p>
				From Home: <span>Yes</span>
			</p>
			<p>
				Country: <span>Egypt</span>
			</p>
			<p>
				City: <span>Cairo</span>
			</p>
			<p>
				Category: <span>Design</span>
			</p>
			<p>
				Sub-Category: <span>Graphic Design</span>
			</p>
			<p>
				Carrer Level: <span>Senior</span>
			</p>
			<p>
				Employment Type: <span>Full Time</span>
			</p>
			<p>
				University: <span>The American University of Cairo</span>
			</p>
		</>
	);
};

export default JobsSearchItems;
