import React, { useState } from "react";
import { IoIosInformationCircle, IoMdPeople, IoMdPersonAdd } from "react-icons/io";
import { IoFlagSharp } from "react-icons/io5";
import { LuScrollText } from "react-icons/lu";
import { MdGroups, MdPeopleAlt } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const SelectionOptions = [
    {
        icon: (
            // <svg className="upload-box-icon icon-members">
            //     <use xlinkHref="#svg-members"></use>
            // </svg>
            <IoIosInformationCircle className="upload-box-icon w-[30px] h-[30px]" />
        ),
        id: "my-info",
        title: "My Information"
    },
    {
        icon: (
            // <LuScrollText className="upload-box-icon icon-members w-[25px] h-[25px]" />
            <svg
                className="upload-box-icon w-[30px] h-[30px]"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    // fill="#f25e4e"
                    d="M17.8,20C17.4,21.2 16.3,22 15,22H5C3.3,22 2,20.7 2,19V18H5L14.2,18C14.6,19.2 15.7,20 17,20H17.8M19,2C20.7,2 22,3.3 22,5V6H20V5C20,4.4 19.6,4 19,4C18.4,4 18,4.4 18,5V18H17C16.4,18 16,17.6 16,17V16H5V5C5,3.3 6.3,2 8,2H19M8,6V8H15V6H8M8,10V12H14V10H8Z"
                ></path>
            </svg>
        ),
        id: "posts",
        title: "Posts"
    },
    {
        icon: (
            // <svg className="upload-box-icon icon-members">
            //     <use xlinkHref="#svg-members"></use>
            // </svg>
            <IoFlagSharp className="upload-box-icon w-[30px] h-[30px]" />
        ),
        id: "business",
        title: "Businesses"
    },
    {
        icon: (
            // <svg className="upload-box-icon icon-members">
            //     <use xlinkHref="#svg-members"></use>
            // </svg>
            <MdGroups className="upload-box-icon w-[30px] h-[30px]" />
        ),
        id: "community",
        title: "Communities"
    },
    {
        icon: (
            // <svg className="upload-box-icon icon-members">
            //     <use xlinkHref="#svg-add-friend"></use>
            // </svg>
            <IoMdPersonAdd className="upload-box-icon w-[30px] h-[30px]" />
        ),
        id: "connection",
        title: "Connections"
    },
    {
        icon: (
            // <svg className="upload-box-icon w-[30px] h-[30px]">
            //     <use xlinkHref="#svg-group"></use>
            // </svg>
            <MdPeopleAlt className="upload-box-icon w-[30px] h-[30px]" />
        ),
        id: "follower",
        title: "Followers"
    },
]


const MyInformationFrom = () => {
    const [selections, setSelections] = useState([]);
    const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()

    const handleSelection = (selection) => {
        setSelections(prev =>
            prev.includes(selection)
                ? prev.filter(item => item !== selection)
                : [...prev, selection]
        );
    }

    const renderBoxes = SelectionOptions.map(option => (
        <div className="upload-box"
            style={selections.includes(option.id) ?
                {
                    boxShadow: "0 0 40px 0 rgba(94, 92, 154, 0.12)",
                    transform: "translate(0, -4px)",
                    border: "1px solid #fd6728"
                } : {}}
            onClick={() => handleSelection(option.id)} key={option.id}>
            {option.icon}
            <p className="upload-box-title !text-[1.2rem]">{option.title}</p>
        </div>
    ))
    
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Account</p>
                    <h2 className="section-title">My Information</h2>
                </div>
            </div>
            <div className="widget-box">
                {/* <p className="widget-box-title">My Information</p> */}

                <div className="widget-box-content">
                    <div className="grid !grid-cols-2 md:!grid-cols-3">
                        {renderBoxes}
                    </div>
                    <button className="button primary !block mt-4 !w-[250px] ml-auto">Generate File</button>
                </div>
            </div>

        </div>
    );
};

export default MyInformationFrom;
