"use client";
import { Link, useLocation } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaApple, FaGooglePlay } from "react-icons/fa";
import WrapInCenter from "../../WrapInCenter/WrapInCenter";

export default function Footer() {
  const { pathname } = useLocation();
  const about = [
    { id: 1, name: "Careers", path: "/" },
    { id: 2, name: "Press & News", path: "/" },
    { id: 3, name: "Partnerships", path: "/" },
    { id: 4, name: "Privacy Policy", path: "/" },
    { id: 5, name: "Terms of Service", path: "/" },
    { id: 6, name: "Investor Relations", path: "/" },
  ];

  const category = [
    { id: 1, name: "Graphics & Design", path: "/" },
    { id: 2, name: "Digital Marketing", path: "/" },
    { id: 3, name: "Writing & Translation", path: "/" },
    { id: 4, name: "Video & Animation", path: "/" },
    { id: 5, name: "Music & Audio", path: "/" },
    { id: 6, name: "Programming & Tech", path: "/" },
    { id: 7, name: "Data", path: "/" },
    { id: 8, name: "Business", path: "/" },
    { id: 9, name: "Lifestyle", path: "/" },
  ];

  const support = [
    { id: 1, name: "Help & Support", path: "/" },
    { id: 2, name: "Trust & Safety", path: "/" },
    { id: 3, name: "Selling on Freeio", path: "/" },
    { id: 4, name: "Buying on Freeio", path: "/" },
  ];

  const links = [
    { id: 1, name: "Terms of Service", path: "/" },
    { id: 2, name: "Privacy Policy", path: "/" },
    { id: 3, name: "Site Map", path: "/" },
  ];

  return (
    <>
      <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
        <section
          className={`footer-style1 
                ${pathname === "/home-2"
              ? "at-home6 home2-footer-radius"
              : pathname === "/home-4"
                ? "at-home7"
                : pathname === "/home-6"
                  ? "at-home6"
                  : pathname === "/home-10"
                    ? "at-home10"
                    : pathname === "/home-11"
                      ? "at-home11"
                      : ""
            }
                 `}
        >
          {/* footer=header */}
          <div className="container">
            <div className="row bb-white-light pb10 mb60 header-top-footer-crea8">
              <div className="col-md-7">
                <div className="d-block text-center text-md-start justify-content-center justify-content-md-start d-md-flex align-items-center mb-3 mb-md-0">
                  {links.map((item, i) => (
                    <Link
                      key={i}
                      className="link-header-footer"
                      href={item.path}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="col-md-5">
                <div className="social-widget text-center text-md-end">
                  <div className="social-style1">
                    <Link className="text-black me-2 fw500 fz17" href="/">
                      Follow us
                    </Link>
                    <a href="/">
                      <FaFacebookF className="list-inline-item" />
                    </a>
                    <a href="/">
                      <FaTwitter className="list-inline-item" />
                    </a>
                    <a href="/">
                      <FaInstagram className="list-inline-item" />
                    </a>
                    <a href="/">
                      <FaLinkedinIn className="list-inline-item" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div
                  className={`link-style1 mb-4 mb-sm-5 ${pathname === "/home-4"
                      ? "light-style at-home8"
                      : pathname === "/home-11"
                        ? "light-style at-home11"
                        : ""
                    }`}
                >
                  <h5
                    className={`mb15 title-link-footer-crea8 ${pathname !== "/home-4" ? "text-black" : ""
                      }`}
                  >
                    About
                  </h5>
                  <div className="link-list">
                    {about.map((item, i) => (
                      <Link key={i} href={item.path} className=" text-lg text-black">
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className={`link-style1 mb-4 mb-sm-5 ${pathname === "/home-4"
                      ? "light-style at-home8"
                      : pathname === "/home-11"
                        ? "light-style at-home11"
                        : ""
                    }`}
                >
                  <h5
                    className={`mb15 title-link-footer-crea8 ${pathname !== "/home-4" ? "text-black" : ""
                      }`}
                  >
                    Categories
                  </h5>
                  <ul className="ps-0">
                    {category.map((item, i) => (
                      <li key={i} className=" text-lg">
                        <Link href={item.path} className="text-black">{item.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className={`link-style1 mb-4 mb-sm-5 ${pathname === "/home-4"
                      ? "light-style at-home8"
                      : pathname === "/home-11"
                        ? "light-style at-home11"
                        : ""
                    }`}
                >
                  <h5
                    className={`mb15 title-link-footer-crea8 ${pathname !== "/home-4" ? "text-black" : ""
                      }`}
                  >
                    Support
                  </h5>
                  <ul className="ps-0">
                    {support.map((item, i) => (
                      <li key={i} className=" text-lg">
                        <Link href={item.path} className="text-black">{item.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-widget">
                  <div className="footer-widget mb-4 mb-sm-5">
                    <div className="mailchimp-widget">
                      <h5 className="title text-black mb20 title-link-footer-crea8">Subscribe</h5>
                      <div
                        className={`mailchimp-style1 ${pathname === "/home-11" ? " at-home11" : ""
                          }`}
                      >
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Your email address"
                        />
                        <button type="submit">Send</button>
                      </div>
                    </div>
                  </div>
                  <div className="app-widget mb-4 mb-sm-5">
                    <h5 className="title text-black mb20 title-link-footer-crea8 ">Apps</h5>
                    <div className="row mb-4 mb-lg-5">
                      <div className="col-lg-12">
                        <a className="app-list d-flex align-items-center mb10" href="/">
                          <FaApple className="mr-3" />
                          <h6
                            className={`app-title fz15 fw400 mb-0 ${pathname === "/home-11" ? "text-black" : ""
                              }`}
                          >
                            iOS App
                          </h6>
                        </a>
                        <a className="app-list d-flex align-items-center  mt-3" href="/">
                          <FaGooglePlay className="mr-3" />
                          <h6
                            className={`app-title fz15 fw400 mb-0 ${pathname === "/home-11" ? "text-black" : ""
                              }`}
                          >
                            Android App
                          </h6>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container white-bdrt1 py-4">
            <div className="row align-items-center bottom-footer-crea8">
              <div className="col-md-6">
                <div className="text-center text-lg-start text-lg text-black ">
                  <p
                    className={`copyright-text mb-2 mb-md-0  ${pathname === "/home-11" ? "text-black" : "text-white-light"
                      } ff-heading`}
                  >
                    © Freeio. 2024{" "}
                    <Link
                      href="https://themeforest.net/user/ib-themes/portfolio"
                      target="_blank"
                      style={{ color: "inherit" }}
                    >
                      ib-themes
                    </Link>
                    . All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer_bottom_right_btns text-center text-lg-end flex gap-2 justify-end">
                  <select name="" id="" className="select-footer-option-crea8">
                    <option value="">Euro</option>
                    <option value="">option 1</option>
                    <option value="">option 2</option>
                    <option value="">option 3</option>
                  </select>
                  <select name="" id="" className="select-footer-option-crea8">
                    <option value="">English</option>
                    <option value="">option 1</option>
                    <option value="">option 2</option>
                    <option value="">option 3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>

      </WrapInCenter>
    </>
  );
}
