import AdsBanner from "../../components/AdsBanner/AdsBanner";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";
import SectionAffiations from "./SectionAffiations";
import SectionFind_JoinAffiations from "./SectionFind_JoinAffiations";
import SectionJoinnedAffiations from "./SectionJoinnedAffiations";
import SectionffiationsAdmin from "./SectionffiationsAdmin";
import { useNavigate } from "react-router-dom";
import {
	setSelectedForm,
	setIsAddingAffiliations,
} from "../../app/feature/userDashboard";
import { useDispatch } from "react-redux";

const bannersImages = [
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
	"/img/ads/ads.jpeg",
];

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	return (
		<>
			<AdsBanner bannersImages={bannersImages} />
			<WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
				<div className="section-header SectionGropMarginTop">
					<div className="section-header-info">
						<p className="section-pretitle">Community Affiliations</p>
						<h2 className="section-title">My Affiliations</h2>
					</div>
					<div className="create-AddAffiliations">
						<h2
							className="section-title-sec-affiliations"
							onClick={() => {
								navigate("/user-dashboard");
								dispatch(setSelectedForm({ value: "Affiliations" }));
								dispatch(setIsAddingAffiliations({ value: true }));
							}}
						>
							+ Add an affiliations
						</h2>
					</div>
				</div>
				<SectionAffiations />
				<SectionffiationsAdmin />
				<SectionJoinnedAffiations />
				<SectionFind_JoinAffiations />
			</WrapInCenter>
		</>
	);
};

export default Index;
