import React from 'react'
import Hero from '../../components/Careers/Hero/Hero';
import LatestJobs from '../../components/Careers/LatestJobs/LatestJobs';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Autoplay, Pagination } from "swiper/modules";
import Testimonial from '../../components/Careers/Testimony/Testimonial';
import Footer from '../../components/Careers/Footer/Footer';
import UniversityHiring from '../../components/Careers/UniversityHiring/UniversityHiring';
import BrowseCategories from '../../components/Careers/PopularCategories/BrowseCategories';
import TrustedBy from '../../components/Careers/TrustedBy/TrustedBy';
import TopHiring from '../../components/Careers/TopHiring/TopHiring';
import FindTalent from '../../components/Careers/FindTalent/FindTalent';

const bannersImages = [
    "/img/ads/ad.png",
    "/img/ads/ad.png",
    "/img/ads/ad.png",
];

const Careers = () => {

    return (
        <div className='careers-page'>
            <div className="ads-career-wrapper bg-white pt-[70px] pb-[10px] md:pt-[85px] md:pb-[10px]">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 7500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    className={`!h-[100px] md:!h-[150px] !mt-[0px] !pb-[30px]`}
                >
                    {bannersImages.map((banner, index) => (
                        <SwiperSlide key={index} className="">
                            <div className="banner-container !w-[970px] !h-[90px]">
                                <img src={banner} alt="ads banner" loading="lazy" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <Hero />
            <LatestJobs title={"Latest Jobs"} subTitle={"Check out recent jobs posted by Flokkers worldwide."} />
            <UniversityHiring />
            <BrowseCategories />
            <LatestJobs title={"Affiliation Network"} subTitle={"The American University in Cairo"} />
            <TopHiring />
            <TrustedBy />
            <LatestJobs title={"Jobs in Near You"} subTitle={"Cairo, Egypt"} />
            <FindTalent />
            <Testimonial />

            <Footer />
        </div>
    )
}

export default Careers