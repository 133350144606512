import { useState } from "react";

const ListCrea8 = () => {
    const [activeId, setActiveId] = useState(1);
  
    const list = [
      { id: 1, name: "All Categories" },
      { id: 2, name: "Graphics Design" },
      { id: 3, name: "Digital Marketing" },
      { id: 4, name: "Writing Translation" },
      { id: 5, name: "Video Animation" },
      { id: 6, name: "Music Audio" },
      { id: 7, name: "Programming Tech" },
      { id: 8, name: "Business" },
      { id: 9, name: "Lifestyle" },
      { id: 10, name: "Trending" },
    ];
  
    const handleItemClick = (id) => {
      setActiveId(id);
    };
  
    return (
      <div className="responsive-max-width-flokky top-listcrea8">
        <ul className="wrap-list-crea8">
          {list.map((item) => (
            <li
              key={item.id}
              className={`li-list-crea8 ${item.id === activeId ? 'active-li-list-crea8' : ''}`}
              onClick={() => handleItemClick(item.id)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  export default ListCrea8;
  