import React, { useState } from "react";
import Select from "../../ui/select/Select";
import "../Search.css";
import FormInputwithIcon from "../../ui/formInputWithSearchIcon/FormInputwithIcon";
import SummaryResults from "../SummaryResults/SummaryResults";
import CommunityCard from "./CommunityCard/CommunityCard";

const countries = [
	{ id: 1, value: "Egypt" },
	{ id: 2, value: "United Arab Emirates" },
	{ id: 3, value: "Bahrain" },
	{ id: 4, value: "Saudi" },
];

const initialItemsNumber = [
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
];

const CommunitiesSearch = () => {
	const [communitiesField, setCommunitiesField] = useState("Business");
	const [numberOfCommunities, setNumberOfCommunities] = useState([
		...initialItemsNumber,
	]);
	const [isCommunititesVisible, setIsCommunititesVisible] = useState(true);
	return (
		<>
			<div
				className={`search-communitiy-component common-for-dir-cat ${
					isCommunititesVisible
						? "common-for-dir-cat-active"
						: "common-for-dir-cat-close"
				}`}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
					className="title-search-category"
					onClick={() => {
						setIsCommunititesVisible((prev) => !prev);
					}}
				>
					<div className="search-title">
						<h4>Search Communities</h4>
						<p>Find what you need quickly and easily</p>
					</div>

					<svg
						class="slider-control-icon icon-small-arrow"
						style={{
							transform: `rotate(${isCommunititesVisible ? "270" : "90"}deg)`,
							cursor: "pointer",
							marginTop: "-40px",
							fill: `${isCommunititesVisible ? "#ff6728" : ""}`,
						}}
					>
						<use xlinkHref="#svg-small-arrow"></use>
					</svg>
				</div>

				{isCommunititesVisible && (
					<div className="communities-container">
						<div className="communities-items">
							<p
								className={`${communitiesField === "Business" ? "active" : ""}`}
								onClick={() => {
									setCommunitiesField("Business");
								}}
							>
								Business Networking
							</p>
							<p
								className={`${communitiesField === "Social" ? "active" : ""}`}
								onClick={() => {
									setCommunitiesField("Social");
								}}
							>
								Social Networking
							</p>
						</div>
					</div>
				)}

				{isCommunititesVisible && (
					<form>
						<div>
							<FormInputwithIcon
								name={"keyword-communities"}
								id={"keyword_communities"}
								label={"Keyword"}
							/>
							<div style={{ marginBottom: "5px" }}></div>
						</div>
						<div className="search-row">
							<Select
								defaultValue={"University: All"}
								optionsArray={countries}
							/>
						</div>
						<div className="search-row">
							<Select defaultValue={"Category"} optionsArray={countries} />
							<Select defaultValue={"Sub-Category"} optionsArray={countries} />
						</div>
						<button
							className="button medium secondary"
							style={{ marginTop: "15px" }}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: "5px",
								}}
							>
								<svg class="icon-magnifying-glass">
									<use xlinkHref="#svg-magnifying-glass"></use>
								</svg>
								Search
							</div>
						</button>
					</form>
				)}
			</div>

			<SummaryResults
				numberofItems={numberOfCommunities}
				setNumberOfItems={setNumberOfCommunities}
			/>

			<div className="grid grid-4-4-4 centered">
				<CommunityCard />
				<CommunityCard />
				<CommunityCard />
			</div>
		</>
	);
};

export default CommunitiesSearch;
