import React from "react";
import { FaPlus } from "react-icons/fa6";
import { IoLayersOutline, IoReload } from "react-icons/io5";
import { Link } from 'react-router-dom';

import "swiper/css";
import "swiper/css/pagination";
// import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import UserProfileImage from "../../user-profile-image/UserProfileImage"
import { BsLayersFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { setIsFriendStoryVisible, setIsUserStoryVisible } from "../../../app/feature/outletSlice";




const FriendsStories = () => {

  const dispatch = useDispatch()

  return (
    <div className="stories friend-stories">
      <div className="stories-title">
        <h3 className="flex gap-[5px]"> <BsLayersFill /> Friends Stories</h3>
        {/* <span className="text-[#3e3f5e] cursor-pointer">
          <IoReload />
        </span> */}
      </div>
      <Swiper 
      className="FriendsStories-swiper" 
      slidesPerView={4} 
      spaceBetween={15}
      breakpoints={{
        320: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      }}
      >

        <SwiperSlide>
          <div
            className="story friend-story"
            style={{ backgroundImage: "url(/img/cover/04.jpg)" }}
            onClick={() => { dispatch(setIsUserStoryVisible({value:true})) }}
          >
            <div className="user-status">
              <Link className="user-status-avatar" to="/user-profile-page">
                <UserProfileImage />
              </Link>
              <p className="user-status-title text-start">
                <a className="bold !text-[#fff]" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="story friend-story"
            style={{ backgroundImage: "url(/img/cover/05.jpg)" }}
            onClick={() => { dispatch(setIsFriendStoryVisible({value:true})) }}
          >
            <div className="user-status">
              <Link className="user-status-avatar" to="/user-profile-page">
                <UserProfileImage />
              </Link>
              <p className="user-status-title text-start">
                <a className="bold !text-[#fff]" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="story friend-story"
            style={{ backgroundImage: "url(/img/cover/03.jpg)" }}
            onClick={() => { dispatch(setIsFriendStoryVisible({value:true})) }}
          >
            <div className="user-status">
              <Link className="user-status-avatar" to="/user-profile-page">
                <UserProfileImage />
              </Link>
              <p className="user-status-title text-start">
                <a className="bold !text-[#fff]" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="story friend-story"
            style={{ backgroundImage: "url(/img/cover/03.jpg)" }}
            onClick={() => { dispatch(setIsFriendStoryVisible({value:true})) }}
          >
            <div className="user-status">
              <Link className="user-status-avatar" to="/user-profile-page">
                <UserProfileImage />
              </Link>
              <p className="user-status-title text-start">
                <a className="bold !text-[#fff]" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            className="story friend-story"
            style={{ backgroundImage: "url(/img/cover/03.jpg)" }}
            onClick={() => { dispatch(setIsFriendStoryVisible({value:true})) }}
          >
            <div className="user-status">
              <Link className="user-status-avatar" to="/user-profile-page">
                <UserProfileImage />
              </Link>
              <p className="user-status-title text-start">
                <a className="bold !text-[#fff]" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
            </div>
          </div>
        </SwiperSlide>

      </Swiper>
    </div>
  )
}

export default FriendsStories