import styles from "./footer.module.css";
import FooterTerms from "./FooterTerms";
import FooterEnd from "./FooterEnd";
import NavLinks from "./NavLinks";
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";
import WrapInCenter from "../../WrapInCenter/WrapInCenter";
import { FaEnvelope } from "react-icons/fa6";
import { Link } from "react-router-dom";

const about = [
  "Careers",
  "Press & News",
  "Partnerships",
  "Privacy Policy",
  "Terms of Services",
  "Investor Relations",
];
const categories = [
  "Graphics & Design",
  "Digital Marketing",
  "Writing & Translation",
  "Video & Animation",
  "Music & Audio",
  "Programming & Tech",
  "Data",
  "Business",
  "Lifestyle",
];
const support = [
  "Help & Support",
  "Trust & Safety",
  "Selling on Freeio",
  "Buying on Freeio",
];


export default function Footer() {

  const screenWidth = window.innerWidth < 1365;

  return (
    <footer className={styles["career-footer-main"]}>
      <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
        {/* <FooterTerms />
        <div
          className={`row row-cols-1 row-cols-md-4 ${styles["career-footer-main"]}`}
        >
          <NavLinks header="About" data={about} />
          <NavLinks header="Categories" data={categories} />
          <NavLinks header="Support" data={support} />
          <div className={`col d-flex flex-column gap-1`}>
            <div className="row">
              <h5>Subscribe</h5>

              <div className={`${styles["form-container"]}`}>
                <form class="">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email address"
                  />
                  <button>
                    <FaEnvelope fill="#fd6728" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col">
              <h5>Apps</h5>
              <ul>
                <li>
                  <a href="">
                    <span className="me-3">
                      <FaApple className="inline" />
                    </span>
                    IOS App
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="">
                    <span className="me-3">
                      <FaGooglePlay className="inline" size={14} />
                    </span>
                    Android App
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <FooterEnd /> */}

        <footer className={`${screenWidth ? "" : "isMobileView"} text-center fw-semibold fs-6 flex items-center justify-between flex-wrap gap-y-5`} n>
          <div className="footer-links-container flex gap-2.5 flex-wrap">
            <Link className="" to="/auth">Terms of use</Link>
            <Link className="" to="/auth">Privacy Policy</Link>
            <Link className="" to="/auth">Contact Us</Link>
            <Link className="" to="/auth">About</Link>
            <Link className="" to="/auth">Blog</Link>
            <Link className="" to="/auth">Events</Link>
          </div>
          <p className="copyrights">
            Copyright &copy; 2024 FLOKKY | BRANDMARKS
          </p>
        </footer>
      </WrapInCenter>
    </footer>
  );
}
