import React from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";

const BlockedUsers = () => {
    const { showContent } = useSelector((state) => state.userDashboard)
    const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
                <svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
                    <use xlinkHref="#svg-back-arrow"></use>
                </svg>
            </span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Account</p>
                    <h2 className="section-title">Blocked Users</h2>
                </div>
            </div>
            <div className="widget-box">
                {/* <p className="widget-box-title">Blocked Users</p> */}

                <div className="widget-box-content">
                    <h5 className='my-[100px] font-bold text-[25px] text-center'>No members to show</h5>
                </div>
            </div>

        </div>
    )
}

export default BlockedUsers