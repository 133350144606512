import React, { useState } from "react";

const Videos = () => {
	const [isWidgetOpen, setIsWidgetOpen] = useState("");

	return (
		<div className="widget-box">
			<div className="widget-box-settings">
				<div className="post-settings-wrap">
					<div
						onClick={() => {
							setIsWidgetOpen((prev) => !prev);
						}}
						className={`post-settings widget-box-post-settings-dropdown-trigger ${
							isWidgetOpen ? "active" : ""
						}`}
					>
						<svg className="post-settings-icon icon-more-dots">
							<use xlinkHref="#svg-more-dots"></use>
						</svg>
					</div>

					<div
						className={`simple-dropdown widget-box-post-settings-dropdown ${
							isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
						}`}
					>
						<p className="simple-dropdown-link">Widget Settings</p>
					</div>
				</div>
			</div>

			<p className="widget-box-title">
				Videos <span className="highlighted">7</span>
			</p>

			<div className="widget-box-content">
				<div className="video-box-list">
					<div className="video-box small">
						<div className="video-box-cover popup-video-trigger">
							<figure className="video-box-cover-image liquid">
								<img
									src="img/cover/08.jpg"
									alt="cover-08"
									className="video-cover-image"
								/>
							</figure>

							<div className="play-button">
								<svg className="play-button-icon icon-play">
									<use xlinkHref="#svg-play"></use>
								</svg>
							</div>

							<div className="video-box-info">
								<p className="video-box-title">Mochi's Island Story Mode</p>

								<p className="video-box-text">1 hour ago</p>
							</div>
						</div>
					</div>

					<div className="video-box small">
						<div className="video-box-cover popup-video-trigger">
							<figure className="video-box-cover-image liquid">
								<img
									src="img/cover/09.jpg"
									alt="cover-09"
									className="video-cover-image"
								/>
							</figure>

							<div className="play-button">
								<svg className="play-button-icon icon-play">
									<use xlinkHref="#svg-play"></use>
								</svg>
							</div>

							<div className="video-box-info">
								<p className="video-box-title">Sunset Cowboys - Walkthrough</p>

								<p className="video-box-text">3 days ago</p>
							</div>
						</div>
					</div>

					<div className="video-box small">
						<div className="video-box-cover popup-video-trigger">
							<figure className="video-box-cover-image liquid">
								<img
									src="img/cover/05.jpg"
									alt="cover-05"
									className="video-cover-image"
								/>
							</figure>

							<div className="play-button">
								<svg className="play-button-icon icon-play">
									<use xlinkHref="#svg-play"></use>
								</svg>
							</div>

							<div className="video-box-info">
								<p className="video-box-title">
									Quest of the Ogre II: The Revenge USA...
								</p>

								<p className="video-box-text">5 days ago</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Videos;
