import React, { useEffect, useRef, useState } from 'react'
import { MdAdminPanelSettings, MdBlock } from 'react-icons/md';
import { RiVerifiedBadgeFill } from 'react-icons/ri';

const FriendDropDownItem = () => {
    const [isWidgetOpen, setIsWidgetOpen] = useState(false)
    const widgetBoxRef = useRef(null)

    const handleClickOutside = (event) => {
        if (widgetBoxRef.current && !widgetBoxRef.current.contains(event.target)) {
            setIsWidgetOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="dropdown-box-list-item">
            <div className="user-status request">
                <div className="user-chat-profile user-status-avatar">
                    <MdAdminPanelSettings
                        color={"#d7006a"}
                        size={16}
                        className="admin-icon-inside-chat"
                    />
                    <img src="/img/avatar/03.jpg" alt="Profile" />
                    <RiVerifiedBadgeFill
                        color={"#36e9f7"}
                        size={16}
                        className="verify-icon-inside-chat"
                    />
                    <div className="badge-container-chats">
                        <img src="/img/download.png" alt="Padge" />
                        <p className="badge-number-chats">12</p>
                    </div>
                </div>
                <p className="user-status-title">
                    <a className="bold" href="profile-timeline.html">
                        Ginny Danvers
                    </a>
                </p>

                <p className="user-status-text">6 friends in common</p>

                <div className="action-request-list">
                    <div className="action-request accept">
                        <svg className="action-request-icon icon-add-friend">
                            <use xlinkHref="#svg-add-friend"></use>
                        </svg>
                    </div>

                    <div className="widget-box-settings" ref={widgetBoxRef}>
                        <div className="post-settings-wrap">
                            <div
                                onClick={() => {
                                    setIsWidgetOpen((prev) => !prev);
                                }}
                                className={`post-settings ${isWidgetOpen ? "active" : ""}`}
                            >
                                <svg className="post-settings-icon icon-more-dots">
                                    <use xlinkHref="#svg-more-dots"></use>
                                </svg>
                            </div>

                            <div
                                className={`simple-dropdown`}
                                style={{
                                    width: 210,
                                    position: "absolute",
                                    zIndex: "9999",
                                    top: "44px",
                                    right: "0px",
                                    opacity: `${isWidgetOpen ? "1" : "0"}`,
                                    visibility: `${isWidgetOpen ? "visible" : "hidden"}`,
                                    transform: `translate(0px, ${isWidgetOpen ? "0px" : "-40px"
                                        })`,
                                    transition:
                                        "transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s",
                                }}
                            >
                                <p className="simple-dropdown-link">
                                    <p className="action-request ignore with-text">
                                        <svg className="action-request-icon icon-remove-friend">
                                            <use xlinkHref="#svg-remove-friend"></use>
                                        </svg>
                                        <span className="action-request-text">Ignore</span>
                                    </p>

                                </p>
                                <p className="simple-dropdown-link">
                                    <p className="action-request decline with-text">
                                        <MdBlock className="action-request-icon icon-remove-friend" />
                                        <span className="action-request-text">Block</span>
                                    </p>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FriendDropDownItem