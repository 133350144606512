import React from 'react'
import MemberCard from "../../../Marketplace/MemberClassified/MemberCard/MemberCard"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const MyProducts = () => {
    const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
        
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Earnings</p>
                    <h2 className="section-title">My Products</h2>
                </div>
            </div>
            <div className='grid !grid-cols-1 md:!grid-cols-2'>
                <MemberCard />
                <MemberCard />
                <MemberCard />
                <MemberCard />
            </div>
            {/* <div className="widget-box">
                <p className="widget-box-title">My Products</p>

                <div className="widget-box-content">
                </div>
            </div> */}
        </div>
    )
}

export default MyProducts