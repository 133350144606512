import React, { useState } from 'react'
import WrapInCenter from '../../../components/WrapInCenter/WrapInCenter';
import AdsBanner from '../../../components/AdsBanner/AdsBanner';
import { MdAdminPanelSettings } from 'react-icons/md';
import { FaStar } from 'react-icons/fa';
import { Modal, Progress } from 'antd';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { GoDotFill } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { FaCheck, FaClock } from 'react-icons/fa6';
import { IoLocationSharp, IoPricetag } from 'react-icons/io5';
import { TbCalendarDollar } from "react-icons/tb";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { BiSolidCategory } from 'react-icons/bi';
import Footer from '../../../components/Careers/Footer/Footer';


const bannersImages = [
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
    "/img/ads/ads.jpeg",
];

const requirement = [
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, harum nihil! Nihil veniam alias, suscipit dolore ipsam non cumque expedita?",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, harum nihil! Nihil veniam alias, suscipit dolore ipsam non cumque expedita?",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, harum nihil! Nihil veniam alias, suscipit dolore ipsam non cumque expedita?",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, harum nihil! Nihil veniam alias, suscipit dolore ipsam non cumque expedita?",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, harum nihil! Nihil veniam alias, suscipit dolore ipsam non cumque expedita?",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, harum nihil! Nihil veniam alias, suscipit dolore ipsam non cumque expedita?",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, harum nihil! Nihil veniam alias, suscipit dolore ipsam non cumque expedita?",
]


const CareerDetails = () => {
    const [skillToAdd, setSkillToAdd] = useState("")
    const [isOpened, setIsOpened] = useState(false)
    const [isModifyOpened, setIsModifyOpened] = useState(false)

    const [selectedSkills, setSelectedSkills] = useState([
        "HTML", "CSS",
        "JS", "ReactJs",
        "Tailwind", "PHP",
        "Bootstrap", "Asp.Net"
    ])

    const [unSelectedSkills, setUnSelectedSkills] = useState([
        "Python", "C++",
        "UI Design", "Ruby"
    ])


    const handleAddSkill = (skill) => {
        setSkillToAdd(skill)
        setIsOpened(true);
    }


    const handleSelectedSkills = (skill) => {
        // Add new skill to selectedSkills
        setUnSelectedSkills(unSelectedSkills.filter(s => s !== skill))
        setSelectedSkills([...selectedSkills, skill])
    }

    return (
        <div>
            <AdsBanner bannersImages={bannersImages} />
            <WrapInCenter noPaddingBottom={false} noPaddingTop={true}>
                <Header />
                <div className="grid grid-9-3">
                    <div className="content space-y-5">
                        <div className="widget-box">
                            <h2 className='widget-box-title mb-[30px]'>Description</h2>
                            <p className='font-medium leading-[1.2]'>
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                Doloremque possimus quisquam cumque quas nisi vero voluptatibus
                                dolorum fugiat nesciunt saepe atque a, nihil aut error distinctio
                                impedit rerum! Iusto, vitae.Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                Doloremque possimus quisquam cumque quas nisi vero voluptatibus
                                dolorum fugiat nesciunt saepe atque a, nihil aut error distinctio
                                impedit rerum! Iusto, vitae.
                            </p>
                        </div>
                        <div className="widget-box">
                            <h2 className='widget-box-title mb-[30px]'>Requirements</h2>
                            <ul className="w-full flex items-center justify-start flex-wrap gap-4 mt-2">
                                {requirement.map((item, inedx) => (
                                    <li
                                        key={inedx}
                                        className="font-medium leading-[1.2] flex items-center justify-between gap-3"
                                    >
                                        <span className='bg-[#1df37733] p-1.5 rounded-full'>
                                            <FaCheck
                                                color="#1df377"
                                                size={12}
                                                className="cursor-pointer"
                                            // onClick={() => {
                                            //     removeSkill(item);
                                            // }}
                                            />
                                        </span>
                                        {item}{" "}

                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="widget-box">
                            <div className='flex items-center justify-between mb-[40px]'>
                                <h2 className='widget-box-title'>Required Skills</h2>
                                <button className='button primary w-[80px] !h-9 !leading-9'
                                    onClick={() => setIsModifyOpened(!isModifyOpened)}>
                                    Edit Skills
                                </button>
                            </div>
                            <div className="w-full flex items-center justify-start flex-wrap gap-4">
                                {selectedSkills.map((item, inedx) => (
                                    <p
                                        key={inedx}
                                        className="bg-[#fff] text-[#3e3f5e] px-[8px] py-[4px] rounded-lg font-medium text-[16px] border-[1px] border-solid border-[#36e9f7]"
                                    >
                                        {item}
                                    </p>
                                ))}
                            </div>
                            <div className="w-full flex items-center justify-start flex-wrap gap-4 mt-3">
                                {unSelectedSkills.map((item, idx) => (
                                    <p
                                        key={idx}
                                        className="bg-[#adafca] text-[#eee] px-[8px] py-[4px] rounded-lg font-medium text-[16px] border-[1px] border-solid border-[#adafca] cursor-pointer"
                                        onClick={() => handleAddSkill(item)}
                                    >
                                        {item}
                                    </p>
                                ))}
                                <Modal
                                    title={"Add a new skill"}
                                    open={isOpened}
                                    onCancel={() => setIsOpened(!isOpened)}
                                    footer={null}
                                    width={800}
                                    zIndex={100000000000}
                                    centered
                                >
                                    <p className='my-4 font-bold text-xl'>Do you want to add {skillToAdd} ?</p>
                                    <div className='flex items-center justify-center space-x-4'>
                                        <button className="button primary" onClick={() => {
                                            handleSelectedSkills(skillToAdd);
                                            setIsOpened(!isOpened)
                                        }}>
                                            Yes
                                        </button>
                                        <button className="button white" onClick={() => setIsOpened(!isOpened)}>
                                            No
                                        </button>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <aside className='space-y-5'>
                        <div className="widget-box !p-[10px]">
                            <button className='button primary !text-[1.2rem]'>APPLY</button>
                        </div>
                        <div className="widget-box">
                            <span className='absolute top-3 right-3 cursor-pointer'
                                onClick={() => setIsModifyOpened(!isModifyOpened)}>
                                <BsFillPlusCircleFill size={18} fill='#fd6728' />
                            </span>
                            <div className="progress-arc-summary">
                                <div className="progress-arc-wrap">
                                    <div className="progress-arc">
                                        <Progress
                                            type="circle"
                                            percent={86}
                                            size={140}
                                            strokeColor={"#ff6728"}
                                            strokeWidth={8}
                                            showInfo={false}
                                        />
                                    </div>

                                    <div className="progress-arc-info">
                                        <p className="progress-arc-title"> 13/15 </p>
                                    </div>
                                </div>
                                <div className="progress-arc-summary-info">
                                    <p className="progress-arc-summary-title">Matching Skills</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#fff] rounded-xl shadow-[0px_0px_40px_0px_#5e5c9a0f]">
                            <div className="w-[100%] h-[80px] bg-[#fd6729] rounded-t-xl px-[15px]">
                            </div>
                            <div className="owner-profile-img bg-white w-[150px] rounded-full mx-auto -mt-12 p-2.5">
                                <Link to="/user-profile-page" className="relative block">
                                    <img
                                        className="rounded-[50%] !border-[1px] !p-[5px] !border-solid !border-[#fd6729]"
                                        src="/img/avatar/01.jpg"
                                        alt="Profile"
                                    />

                                    <GoDotFill className="absolute !top-[0px] !left-[0px]" size={25} color='#1df377' />

                                    <RiVerifiedBadgeFill
                                        className="nav-small-verify absolute !bottom-[0px] !right-[5px]"
                                        color={"#36e9f7"}
                                        size={25}
                                    />

                                    <MdAdminPanelSettings
                                        className="nav-small-admin !top-[0px] !right-[0px]"
                                        color={"#d7006a"}
                                        size={25}
                                    />

                                    <div className="absolute bottom-[0] left-0 w-[25px] h-[25px]">
                                        <div className="relative">
                                            <div className="absolute w-[25px] h-[25px]">
                                                <img
                                                    className="w-[25px] h-[25px] !border-none"
                                                    src="/img/download.png"
                                                    alt="Padge"
                                                />
                                            </div>
                                            <p className="absolute text-white text-[14px] top-[6px] left-[6px] font-[700]">
                                                12
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="classified-profile-body-info pb-7">
                                <div className="owner-details pt-[1rem] text-center">
                                    <h6 className="owner-details-title text-[20px]">
                                        <Link href="/user-profile-page">
                                            Marina Valentine
                                        </Link>
                                    </h6>

                                    <Link to="/businesses/profile/digitechsolutions" className="block">Marketing Manager</Link>
                                    <Link to="/businesses/profile/digitechsolutions" className="block">Brandmarks</Link>
                                    <Link to="/university" className="block">Nile University</Link>
                                </div>

                            </div>
                        </div>

                    </aside>
                    <Modal title={"Modify skills"}
                        open={isModifyOpened}
                        onCancel={() => setIsModifyOpened(!isModifyOpened)}
                        footer={null}
                        width={800}
                        zIndex={100000000000}
                        centered
                    >
                        <div className='py-2.5'>
                            <div className="w-full flex items-center justify-start flex-wrap gap-4">
                                {selectedSkills.map((item, inedx) => (
                                    <p
                                        key={inedx}
                                        className="bg-[#fff] text-[#3e3f5e] px-[8px] py-[4px] rounded-lg font-medium text-[16px] border-[1px] border-solid border-[#36e9f7]"
                                    >
                                        {item}
                                    </p>
                                ))}
                            </div>
                            <div className="w-full flex items-center justify-start flex-wrap gap-4 mt-3">
                                {unSelectedSkills.map((item, idx) => (
                                    <p
                                        key={idx}
                                        className="bg-[#adafca] text-[#eee] px-[8px] py-[4px] rounded-lg font-medium text-[16px] border-[1px] border-solid border-[#adafca] cursor-pointer"
                                        onClick={() => handleSelectedSkills(item)}
                                    >
                                        {item}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </Modal>
                </div>
            </WrapInCenter>
            <Footer />
        </div>
    )
}

export default CareerDetails


const Header = () => {
    return (
        <div className="profile-header v2 mt-12">
            <figure className="profile-header-cover liquid">
                <img src="/img/careers/images/job-img.jpg" alt="cover-4" />
            </figure>

            <div className="profile-header-info">
                <div className="user-short-description big !pt-[70px]">
                    <Link to="/business" className="profile-header-avatar">
                        <img src="/img/careers/logo/client-1.webp" alt="avatar01" loading="lazy" />
                    </Link>

                    <p className="user-short-description-title !capitalize">
                        Website Designer Required For Directory Theme
                    </p>

                    <div className="!text-[20px] !font-bold mt-1.5 capitalize text-center min-[1365px]:!text-start">
                        <Link to={"/businesses/profile/digitechsolutions"}>
                            Brandsmark
                        </Link>
                    </div>
                </div>

                <div className="user-stats flex-wrap gap-y-4 xl:space-y-0 xl:!top-[unset] xl:!bottom-[20px] xl:!right-0">
                    <div className="user-stat !px-[1rem] !flex !items-center !gap-[5px] after:!top-0">
                        <BiSolidCategory color='brown' size={16} />
                        <div>
                            <p className="user-stat-title !text-[1rem] !font-medium">Web & Design</p>
                        </div>
                    </div>

                    <div className="user-stat !px-[1rem] !flex !items-center !gap-[5px] after:!top-0">
                        <IoLocationSharp size={16} color="#fd6728" />
                        <div>
                            <p className="user-stat-title !text-[1rem] !font-medium">Egypt, Cairo</p>
                        </div>
                    </div>

                    <div className="user-stat !px-[1rem] !flex !items-center !gap-[5px] after:!top-0">
                        <FaClock color='#4caf50' size={13} />
                        <div>
                            <p className="user-stat-title !text-[1rem] !font-medium">20 W</p>
                        </div>
                    </div>

                    <div className="user-stat !px-[1rem] !flex !items-center !gap-[5px] after:!top-0">
                        <TbCalendarDollar color='#3e3f5e' size={17} />
                        <div>
                            <p className="user-stat-title !text-[1rem] !font-bold">14,000 - 15,000 EGP/Month</p>
                        </div>
                    </div>
                </div>

                <div className="tag-sticker">
                    <svg className="tag-sticker-icon icon-public">
                        <use xlinkHref="#svg-public"></use>
                    </svg>
                </div>
            </div>
        </div>
    )
}
