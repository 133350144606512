
const SectionHeader = () => {
    return (
      <div className="section-header SectionGropMarginTop">
        <div className="section-header-info">
          <p className="section-pretitle">Affiliations </p>
          <h2 className="section-title">Create a Networking Group</h2>
        </div>
      </div>
    );
  };
  
  export default SectionHeader;
  