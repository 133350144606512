import React, { useEffect, useRef, useState } from "react";
import PostComments from "./PostComments/PostComments";
import UserProfileImage from "../user-profile-image/UserProfileImage";
import { Link } from "react-router-dom";
import { Modal, } from "antd";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { MdAddLocationAlt, MdOutlineDashboardCustomize, MdOutlinePublic } from "react-icons/md";
import { FaLinkedin, FaStar, FaTelegramPlane, FaUser, FaUsers, FaWhatsapp } from "react-icons/fa";
import { FaFacebookF, FaTag, FaXTwitter } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi";
import { GrFlagFill } from "react-icons/gr";
import { TbTimelineEventText } from "react-icons/tb";
import PostCommentForm from "./PostComments/PostCommentForm";
import { CiHeart, CiStar } from "react-icons/ci";


const postTypeOptions = [
    {
      value: "only-me",
      label: (
        <div className="flex items-center gap-[10px]">
          <RiGitRepositoryPrivateLine /> <span>Only Me</span>
        </div>
      ),
	  svg:<RiGitRepositoryPrivateLine />
    },
    {
      value: "everyone",
      label: (
        <div className="flex items-center gap-[10px]">
          <MdOutlinePublic /> <span>Everyone</span>
        </div>
      ),
	  svg:<MdOutlinePublic />
    },
    {
      value: "people-i-follow",
      label: (
        <div className="flex items-center gap-[10px]">
          <FaUser /> <span>People I Follow</span>
        </div>
      ),
	  svg:<FaUser />
    },
    {
      value: "people-follow-me",
      label: (
        <div className="flex items-center gap-[10px]">
          <FaUsers /> <span>People Follow Me</span>
        </div>
      ),
	  svg:<FaUsers />
    },
  ];


const ListingPost = ({ setIsImagePopupGalleryOpened}) => {
  const [isPostTypeOpen , setIsPostTypeOpen] = useState(false)
	const [postType , setPostType] = useState(postTypeOptions[1])
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
	const [isWowListOpen, setIsWowListOpen] = useState(false);
	const [isHappyListOpen, setIsHappyListOpen] = useState(false);
	const [isLikeListOpen, setIsLikeListOpen] = useState(false);
	const [isReactListOpen, setIsReactListOpen] = useState(false);
	const [isCommentsOpen, setIsCommentsOpen] = useState(false);
	const [isShareOpen , setIsShareOpen] = useState(false)
  const [selectShare , setSelectShare] = useState("")

  const postSettingsRef = useRef()
  const postTypeRef = useRef()


  useEffect( ()=>{
    document.addEventListener("mousedown" , function(event){
      if(postSettingsRef.current && !postSettingsRef.current.contains(event.target)){
        setIsWidgetOpen(false)
      }
      if(postTypeRef.current && !postTypeRef.current.contains(event.target)){
        setIsPostTypeOpen(false)
      }
    })

    return () =>{
      document.removeEventListener("mousedown" , function(event){
        if(postSettingsRef.current && !postSettingsRef.current.contains(event.target)){
          setIsWidgetOpen(false)
        }
        if(postTypeRef.current && !postTypeRef.current.contains(event.target)){
          setIsPostTypeOpen(false)
        }
      })
    }
  } ,[])

  return (
    <div className="widget-box no-padding">
      <div className="widget-box-settings">
        <div className="post-settings-wrap" ref={postSettingsRef}>
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>

          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link">Edit Post</p>

            <p className="simple-dropdown-link">Delete Post</p>

            <p className="simple-dropdown-link">Pin Post</p>

            <p className="simple-dropdown-link">Report Post</p>

            <p className="simple-dropdown-link">Report Author</p>

            <p className="simple-dropdown-link">Disable Comments</p>

            <a className="simple-dropdown-link" href="/post/5" target="_blank">Open in new tab</a>
          </div>
        </div>
      </div>

      <div className="widget-box-status">
        <div className="widget-box-status-content">
          <div className="user-status">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>

            <div className="ml-1">
              <p className="user-status-title medium">
                <a className="bold" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
                add a <span className="bold">Listing</span>
              </p>

              <div className="flex items-center gap-[10px]">
                <div className="post-type-wrapper" ref={postTypeRef}>
                  <button
                    className="w-fit text-[#a6a8c7]"
                    onClick={() => {
                      setIsPostTypeOpen((prev) => !prev);
                    }}
                  >
                    {postType.svg}
                  </button>
                  <div
                    className={`simple-dropdown widget-box-post-types-dropdown ${
                      isPostTypeOpen
                        ? "widget-types-open"
                        : "widget-types-close"
                    }`}
                  >
                    {postTypeOptions.map((option) => (
                      <p
                        className="simple-dropdown-link"
                        onClick={() => {
                          setPostType(option);
                          setIsPostTypeOpen(false);
                        }}
                      >
                        {" "}
                        {option.label}
                      </p>
                    ))}
                  </div>
                </div>
                <p className="user-status-text small !mt-[-4px]">2 days ago</p>
              </div>
            </div>
          </div>


          <div className="listing-container">
            <div className="lisitng-tag flex items-start justify-end gap-[10px] mr-[10px] mt-[24px]">
              <a className="tag-item primary !mt-0 !bg-[#fff1e7] !text-[#ff6900]" href="newsfeed.html">
                Electronics
              </a>
              <p className="text-[14px] py-[5px] px-[15px] rounded-[25px] border border-solid border-[#eee]">
                Used
              </p>
              <p className="special-post-tab bg-[#fd6729] text-white text-[18px] font-bold flex gap-[5px] pt-[10px] py-[8px] px-[20px] rounded-t-[12px] w-fit">
                <MdOutlineDashboardCustomize />
                Lisiting
              </p>
            </div>
            
            <div
              className="picture-collage !mt-[0] mb-[15px] pb-[15px]"
              style={{ borderBottom: "1px solid #ddd" }}
            >
              <div className="picture-collage-row medium">
                <div className="picture-collage-item popup-picture-trigger !w-full !h-[400px]">
                  <div className="photo-preview overflow-hidden">
                    <figure
                      className="photo-preview-image liquid border-[4px] border-solid border-[#fd6729]"
                      style={{
                        background:
                          "url(/img/cover/10.jpg) center center / cover no-repeat",
                      }}
                      onClick={() => setIsImagePopupGalleryOpened((prev) => !prev)}
                    >
                      <img
                        src="/img/cover/10.jpg"
                        alt="photo-preview-10"
                        style={{ display: "none" }}
                      />
                    </figure>
                    <p className="listing-badge">
                      <CiStar /> Featured
                    </p>
                    <div className="fav-selection">
                      <input type="checkbox" name="" id="" />
                      <span>
                        <CiHeart />
                      </span>
                    </div>
                    {/* <div className="photo-preview-info">
                      <div className="reaction-count-list">
                        <div className="reaction-count negative">
                          <svg className="reaction-count-icon icon-thumbs-up">
                            <use xlinkHref="#svg-thumbs-up"></use>
                          </svg>
                          <p className="reaction-count-text">2</p>
                        </div>
                        <div className="reaction-count negative">
                          <svg className="reaction-count-icon icon-comment">
                            <use xlinkHref="#svg-comment"></use>
                          </svg>
                          <p className="reaction-count-text">5</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="picture-collage-row">
                <div
                  class="picture-collage-item popup-picture-trigger "
                  onClick={() => setIsImagePopupGalleryOpened((prev) => !prev)}
                >
                  <div class="photo-preview">
                    <figure
                      class="photo-preview-image liquid"
                      style={{
                        background:
                          "url(/img/cover/16.jpg) center center / cover no-repeat",
                      }}
                    >
                      <img
                        src="/img/cover/16.jpg"
                        alt="photo-preview-16"
                        style={{ display: "none" }}
                      />
                    </figure>

                    {/* <div class="photo-preview-info">
                      <div class="reaction-count-list">
                        <div class="reaction-count negative">
                          <svg class="reaction-count-icon icon-thumbs-up">
                            <use xlinkHref="#svg-thumbs-up"></use>
                          </svg>

                          <p class="reaction-count-text">2</p>
                        </div>

                        <div class="reaction-count negative">
                          <svg class="reaction-count-icon icon-comment">
                            <use xlinkHref="#svg-comment"></use>
                          </svg>

                          <p class="reaction-count-text">5</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div
                  class="picture-collage-item popup-picture-trigger"
                  onClick={() => setIsImagePopupGalleryOpened((prev) => !prev)}
                >
                  <div class="photo-preview">
                    <figure
                      class="photo-preview-image liquid"
                      style={{
                        background:
                          "url(/img/cover/08.jpg) center center / cover no-repeat",
                      }}
                    >
                      <img
                        src="/img/cover/16.jpg"
                        alt="photo-preview-16"
                        style={{ display: "none" }}
                      />
                    </figure>

                    {/* <div class="photo-preview-info">
                      <div class="reaction-count-list">
                        <div class="reaction-count negative">
                          <svg class="reaction-count-icon icon-thumbs-up">
                            <use xlinkHref="#svg-thumbs-up"></use>
                          </svg>

                          <p class="reaction-count-text">2</p>
                        </div>

                        <div class="reaction-count negative">
                          <svg class="reaction-count-icon icon-comment">
                            <use xlinkHref="#svg-comment"></use>
                          </svg>

                          <p class="reaction-count-text">5</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div
                  class="picture-collage-item"
                  onClick={() => setIsImagePopupGalleryOpened((prev) => !prev)}
                >
                  <p class="picture-collage-item-overlay">
                    <p class="picture-collage-item-overlay-text">+22</p>
                  </p>

                  <div class="photo-preview">
                    <figure
                      class="photo-preview-image liquid"
                      style={{
                        background:
                          "url(/img/cover/14.jpg) center center / cover no-repeat",
                      }}
                    >
                      <img
                        src="/img/cover/14.jpg"
                        alt="photo-preview-14"
                        style={{ display: "none" }}
                      />
                    </figure>

                    {/* <div class="photo-preview-info">
                      <div class="reaction-count-list">
                        <div class="reaction-count negative">
                          <svg class="reaction-count-icon icon-thumbs-up">
                            <use xlinkHref="#svg-thumbs-up"></use>
                          </svg>

                          <p class="reaction-count-text">2</p>
                        </div>

                        <div class="reaction-count negative">
                          <svg class="reaction-count-icon icon-comment">
                            <use xlinkHref="#svg-comment"></use>
                          </svg>

                          <p class="reaction-count-text">5</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="listing-heading flex gap-[10px] mb-[15px]">
              <p className="listing-review">
                Reviews: <FaStar className="inline mt-[-2px]" color="gold" /> 5.0
              </p>
              <p className="listing-location ml-auto mr-[1rem]">
                <svg
                  className="inline mt-[-2px]"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_666_21297)">
                    <path
                      d="M6 7.83333C6 8.36376 6.21071 8.87247 6.58579 9.24754C6.96086 9.62261 7.46957 9.83333 8 9.83333C8.53043 9.83333 9.03914 9.62261 9.41421 9.24754C9.78929 8.87247 10 8.36376 10 7.83333C10 7.3029 9.78929 6.79419 9.41421 6.41911C9.03914 6.04404 8.53043 5.83333 8 5.83333C7.46957 5.83333 6.96086 6.04404 6.58579 6.41911C6.21071 6.79419 6 7.3029 6 7.83333Z"
                      stroke="#1D1D1D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.91333 14.82C7.59065 14.7995 7.28653 14.6621 7.05799 14.4333L4.22866 11.6047C3.56456 10.9403 3.08774 10.1122 2.84642 9.20436C2.60511 8.29649 2.60787 7.34099 2.85442 6.43453C3.10098 5.52806 3.58258 4.7028 4.25051 4.04224C4.91843 3.38168 5.74898 2.90926 6.65812 2.67277C7.56726 2.43627 8.52274 2.4441 9.42788 2.69547C10.333 2.94683 11.1557 3.43281 11.8127 4.10423C12.4697 4.77565 12.9377 5.60869 13.1694 6.51908C13.4011 7.42946 13.3882 8.38488 13.132 9.28867"
                      stroke="#1D1D1D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 13.1667L11.3333 14.5L14 11.8333"
                      stroke="#1D1D1D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_666_21297">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Cairo
              </p>
              <p className="listing-price">
                <span className="text-[30px] font-[700] text-[#fd6729]">7,500 EGP</span>
              </p>
            </div>
            <div
              className="lisitng-body pt-[15px]"
              style={{ borderTop: "1px dashed #ddd" }}
            >
              <a
                href="https://marketplace.flokky.app/memberclassifieds/details"
                className="text-[25px] mb-[8px] font-[600] inline-block"
              >
                Ladies Gap Jeans
              </a>
              <p className="">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Pariatur, at ipsam numquam commodi iste reprehenderit a autem
                debitis beatae officia inventore officiis ipsum qui nisi totam
                tenetur similique repudiandae. Sed.
              </p>
            </div>
          </div>

          <div className="content-actions">
            <div className="content-action">
              <div className="meta-line">
                <div className="meta-line-list reaction-item-list">

                  <div className="reaction-item gap-[4px]"
                  onMouseLeave={() => {
                    setIsWowListOpen(false);
                  }}
                  onMouseEnter={() => {
                    setIsWowListOpen(true);
                  }}
                  >
                    <img
                      src="/img/reaction/wow.png"
                      alt="reaction-wow"
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isWowListOpen ? "active" : ""
                      }`}
                      
                    />
                    <p className="meta-line-text">4</p>

                    <div
                      className={`simple-dropdown padded reaction-item-dropdown !w-[270px] ${
                        isWowListOpen ? "reacts-list-open" : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="/img/reaction/wow.png"
                          alt="reaction-wow"
                        />
                        <p className="reactions-number">5</p>
                        <span className="bold">Wow</span>
                      </p>

                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="reaction-item gap-[4px]"
                  onMouseEnter={() => {
                    setIsHappyListOpen(true);
                  }}
                  onMouseLeave={() => {
                    setIsHappyListOpen(false);
                  }}
                  >
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isHappyListOpen ? "active" : ""
                      }`}
                      src="/img/reaction/happy.png"
                      alt="reaction-happy"
                    />
                    <p className="meta-line-text">4</p>

                    <div
                      className={`simple-dropdown padded reaction-item-dropdown !w-[270px] ${
                        isHappyListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                      onMouseEnter={() => {
                        setIsHappyListOpen(true);
                      }}
                      onMouseLeave={() => {
                        setIsHappyListOpen(false);
                      }}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="/img/reaction/happy.png"
                          alt="reaction-happy"
                        />
                        <p className="reactions-number">5</p>
                        <span className="bold">Happy</span>
                      </p>

                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="reaction-item gap-[4px]"
                  onMouseEnter={() => {
                    setIsLikeListOpen(true);
                  }}
                  onMouseLeave={() => {
                    setIsLikeListOpen(false);
                  }}
                  >
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isLikeListOpen ? "active" : ""
                      }`}
                      src="/img/reaction/like.png"
                      alt="reaction-like"
                    />
                    <p className="meta-line-text">4</p>

                    <div
                      className={`simple-dropdown padded reaction-item-dropdown !w-[270px] ${
                        isLikeListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                      onMouseEnter={() => {
                        setIsLikeListOpen(true);
                      }}
                      onMouseLeave={() => {
                        setIsLikeListOpen(false);
                      }}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="/img/reaction/like.png"
                          alt="reaction-like"
                        />
                        <p className="reactions-number">5</p>
                        <span className="bold">Like</span>
                      </p>

                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                      <p className="simple-dropdown-text !mt-[20px] pl-[6px]">
                        <div className="user-status">
                          <Link
                            className="user-status-avatar"
                            to="/user-profile-page"
                          >
                            <UserProfileImage />
                          </Link>

                          <p className="user-status-title">
                            <span className="bold">Neko Bebop </span>
                          </p>

                          <p
                            className="user-status-text small"
                            style={{ color: "#adafca" }}
                          >
                            Marketing Manager - Brandmarks
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-action">
              <div className="meta-line">
                <p className="meta-line-link">5 Comments</p>
              </div>

              <div className="meta-line">
                <p className="meta-line-text">0 Shares</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="post-options">
        <div className="post-option-wrap">
          <div
            className={`post-option reaction-options-dropdown-trigger ${
              isReactListOpen ? "active" : ""
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <svg className="post-option-icon icon-thumbs-up">
              <use xlinkHref="#svg-thumbs-up"></use>
            </svg>

            <p className="post-option-text">React!</p>
          </div>

          <div
            className={`reaction-options reaction-options-dropdown ${
              isReactListOpen ? "emoji-list-open" : "emoji-list-close"
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <div className="reaction-option text-tooltip-tft" data-title="Like">
              <img
                className="reaction-option-image"
                src="/img/reaction/like.png"
                alt="reaction-like"
              />
            </div>

            <div className="reaction-option text-tooltip-tft" data-title="Love">
              <img
                className="reaction-option-image"
                src="/img/reaction/love.png"
                alt="reaction-love"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Dislike"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/dislike.png"
                alt="reaction-dislike"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Happy"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/happy.png"
                alt="reaction-happy"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Funny"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/funny.png"
                alt="reaction-funny"
              />
            </div>

            <div className="reaction-option text-tooltip-tft" data-title="Wow">
              <img
                className="reaction-option-image"
                src="/img/reaction/wow.png"
                alt="reaction-wow"
              />
            </div>

            <div
              className="reaction-option text-tooltip-tft"
              data-title="Angry"
            >
              <img
                className="reaction-option-image"
                src="/img/reaction/angry.png"
                alt="reaction-angry"
              />
            </div>

            <div className="reaction-option text-tooltip-tft" data-title="Sad">
              <img
                className="reaction-option-image"
                src="/img/reaction/sad.png"
                alt="reaction-sad"
              />
            </div>
          </div>
        </div>

        <div
          className="post-option"
          onClick={() => {
            setIsCommentsOpen((prev) => !prev);
          }}
        >
          <svg className="post-option-icon icon-comment">
            <use xlinkHref="#svg-comment"></use>
          </svg>

          <p className="post-option-text">Comment</p>
        </div>

        <div
          className="post-option"
          onClick={() => {
            setIsShareOpen((prev) => !prev);
          }}
        >
          <svg className="post-option-icon icon-share">
            <use xlinkHref="#svg-share"></use>
          </svg>

          <p className="post-option-text">Share</p>
        </div>
      </div>

      <Modal
        title={null}
        open={isShareOpen}
        onCancel={() => setIsShareOpen(!isShareOpen)}
        footer={null}
        width={800}
        zIndex={100000000000}
      >
        <div className="share-post-container">
          <h2 className="share-post-title">Share the post on</h2>

          <div className="share-post-socials">
            <ul className="grid !grid-cols-3 md:!grid-cols-5">
              <li>
                <a href="">
                  <FaXTwitter />
                  Twiiter
                </a>
              </li>
              <li>
                <a href="">
                  <FaFacebookF />
                  Facebook
                </a>
              </li>
              <li>
                <a href="">
                  <FaWhatsapp />
                  Whatsapp
                </a>
              </li>
              <li>
                <a href="">
                  <FaLinkedin />
                  Linkedin
                </a>
              </li>
              <li>
                <a href="">
                  <FaTelegramPlane />
                  Telegram
                </a>
              </li>
            </ul>
          </div>

          <form action="">
            <textarea
              id="share-post-text"
              name="share-post-text"
              placeholder="What's going on? #Hashtag.. @Mention.. Link.."
              rows={5}
            ></textarea>

            <div className="share-on">
              <h3 className="text-[25px] mb-[15px]">Share the post on</h3>

              <div className="share-select-wrapper">
                <div
                  className={`share-select-item ${
                    selectShare == "timeline" ? "active" : ""
                  }`}
                  onClick={() => setSelectShare("timeline")}
                >
                  <span>
                    <TbTimelineEventText size={25} />
                  </span>
                  <h5 className="text-[18px]">My Timeline</h5>
                </div>
                <div
                  className={`share-select-item ${
                    selectShare == "business" ? "active" : ""
                  }`}
                  onClick={() => setSelectShare("business")}
                >
                  <span>
                    <GrFlagFill size={25} />
                  </span>
                  <h5 className="text-[18px]">Bussiness Profile</h5>
                </div>
                <div
                  className={`share-select-item ${
                    selectShare == "community" ? "active" : ""
                  }`}
                  onClick={() => setSelectShare("community")}
                >
                  <span>
                    <HiUserGroup size={25} />
                  </span>
                  <h5 className="text-[18px]">Community</h5>
                </div>
              </div>

              <div className="relative overflow-hidden py-[30px] mb-[30px]">
                <input
                  type="text"
                  placeholder="Enter Business Name"
                  style={
                    selectShare == "business"
                      ? { left: "50%", bottom: "0" }
                      : { left: "50%", bottom: "-50px" }
                  }
                />
                <input
                  type="text"
                  placeholder="Enter Community Name"
                  style={
                    selectShare == "community"
                      ? { left: "50%", bottom: "0" }
                      : { left: "50%", bottom: "-50px" }
                  }
                />
              </div>

              <button className="post-btn button primary">Post</button>
            </div>
          </form>
        </div>
      </Modal>

      <PostComments>{isCommentsOpen && <PostCommentForm />}</PostComments>
    </div>
  );
};

export default ListingPost;
