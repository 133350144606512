import React from "react";

const EventSearchItems = () => {
	return (
		<>
			<p>
				Filters Keyword: <span>React Cairo</span>
			</p>
			<p>
				Country: <span>Egypt</span>
			</p>
			<p>
				City: <span>Cairo</span>
			</p>
		</>
	);
};

export default EventSearchItems;
