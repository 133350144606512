import { FaBuilding, FaEarthAsia, FaMagnifyingGlass } from "react-icons/fa6";
import { IoChevronDownOutline } from "react-icons/io5";
import { BiSolidCategory } from "react-icons/bi";
import { PiBagSimpleFill } from "react-icons/pi";


export default function HeroForm() {

  return (
    <>
      <div className="flex items-center">
        <button className="active tabHeroFormBtn">
          <PiBagSimpleFill className="inline mr-[5px] -mt-[-3px]" />
          Fulltime
        </button>
        <button className="tabHeroFormBtn">
          <PiBagSimpleFill className="inline mr-[5px] -mt-[-3px]" />
          Partime
        </button>
        <button className="tabHeroFormBtn">
          <PiBagSimpleFill className="inline mr-[5px] -mt-[-3px]" />
          Internships
        </button>
      </div>
      <form action="">
        <div className="form-item search">
          <FaMagnifyingGlass className="inline text-[#fd6729] absolute top-[22px] left-[15px]" />
          <input type="text" placeholder="Search for product..." />
        </div>
        <div className="form-item affiliation">
          <FaBuilding className="inline text-[#fd6729] absolute top-[22px] left-[15px]" />
          <label htmlFor="">Affiliation</label>
          <select name="" id="city">
            <option value="" selected>
              Affiliations
            </option>
            <option value="">Affiliation 1</option>
            <option value="">Affiliation 2</option>
            <option value="">Affiliation 3</option>
            <option value="">Affiliation 4</option>
            <option value="">Affiliation 5</option>
          </select>
          <IoChevronDownOutline className="inline text-[#fd6729] absolute bottom-[17px] right-[15px]" />
        </div>
        <div className="form-item location">
          <FaEarthAsia className="inline text-[#fd6729] absolute top-[22px] left-[15px]" />
          <label htmlFor="">Location</label>
          <select name="" id="location">
            <option value="" selected>
              Location
            </option>
            <option value="">Egypt</option>
            <option value="">France</option>
            <option value="">Germany</option>
            <option value="">Spain</option>
            <option value="">USA</option>
          </select>
          <IoChevronDownOutline className="inline text-[#fd6729] absolute bottom-[17px] right-[15px]" />
        </div>
        <div className="form-item category">
          <BiSolidCategory className="inline text-[#fd6729] absolute top-[22px] left-[15px]" />
          <label htmlFor="">Category</label>
          <select name="" id="property">
            <option value="" selected>
              Categories
            </option>
            <option value="">Category 1</option>
            <option value="">Category 2</option>
            <option value="">Category 3</option>
            <option value="">Category 4</option>
            <option value="">Category 5</option>
          </select>
          <IoChevronDownOutline className="inline text-[#fd6729] absolute bottom-[17px] right-[15px]" />
        </div>
        {/* <div className="form-item company">
          <FaBuilding className="inline text-[#fd6729] absolute top-[22px] left-[15px]" />
          <label htmlFor="">Company</label>
          <select name="" id="city">
            <option value="" selected>
              Companies
            </option>
            <option value="">Company 1</option>
            <option value="">Company 2</option>
            <option value="">Company 3</option>
            <option value="">Company 4</option>
            <option value="">Company 5</option>
          </select>
          <IoChevronDownOutline className="inline text-[#fd6729] absolute bottom-[17px] right-[15px]" />
        </div> */}

        <div className="button-submit">
          <input type="button" value="Search" />
        </div>
      </form>
    </>
  );
}
