import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import Card from "./MemberCard/MemberCard";
import WrapInCenter from "../WrapInCenter/WrapInCenter";
export default function FlokkancerServices() {
  const [showSwiper, setShowSwiper] = useState(false);
  useEffect(() => {
    setShowSwiper(true);
  }, []);
  const [activeTab, setActiveTab] = useState("Top Ratings");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
        <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
      
      <section className="md:pb-90 pb-20">
        <div className="container">
          <div className="row align-items-center wow fadeInUp">
            <div className="col-lg-9">
              <div className="main-title">
                <h2 className="title title-h2-inspiring-crea8">
                Flokkancer Services
                </h2>
                <p className="paragraph text-black">
                  Get quality work done by tola
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="text-start text-lg-end mb-4">
                <Link
                  className="ud-btn btn-light-thm bdrs90 d-flex gap-2 items-center md:justify-end justify-start
                   font-extrabold text-lg"
                  to="/freelancer-1"
                >
                  View All
                  <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
          <div className="row tabs-crea8-futuredproject">
          <div className="col-lg-12">
          <ul className="flex items-center gap-4 flex-wrap ">
                {["Top Ratings", "Latest"].map((tab) => (
                  <li
                    key={tab}
                    className={`list-tab-future-crea8 ${activeTab === tab ? "list-tab-future-crea8-active" : ""}`}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </li>
                ))}
              </ul>

          </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ui-hightest-rated">
                {showSwiper && (
                  <Swiper
                    spaceBetween={30}
                    navigation={{
                      prevEl: ".btn__prev__009",
                      nextEl: ".btn__next__009",
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiperr"
                    loop={true}
                    pagination={{
                      el: ".swiper__pagination__009",
                      clickable: true,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                      },
                      768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                      },
                      992: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                      1200: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <Card />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Card />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <Card />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <Card />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <Card />
                    </SwiperSlide>{" "}
                    <SwiperSlide>
                      <Card />
                    </SwiperSlide>{" "}
                  </Swiper>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button className="swiper__btn btn__prev__009 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowLeft />
                </button>
              </div>
              <div className="col-auto">
                <div className="swiper__pagination swiper__pagination__009"></div>
              </div>
              <div className="col-auto">
                <button className="swiper__btn btn__next__009 text-gray-400 text-2xl">
                  <MdOutlineKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      </WrapInCenter>
    </>
  );
}
