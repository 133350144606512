/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from "react-router-dom";
import img from '../../assests/about/about-17.webp'
import { FaArrowRight } from "react-icons/fa";
import WrapInCenter from "../WrapInCenter/WrapInCenter";
export default function CtaBanner16() {
  return (
    <WrapInCenter noPaddingBottom={true} noPaddingTop={false}>
      
    <section className="our-cta position-relative md:pb-90 pb-20">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 col-xl-5">
            <img
              width={549}
              height={576}
              style={{ height: "fit-content" }}
              className="home16-ctaimg-v1 w-100 d-none d-md-block wow fadeInRight"
              src={img}
              alt=" image "
            />
          </div>
          <div className="col-md-6 col-xl-4 offset-xl-2">
            <div className="cta-style3 wow fadeInLeft">
                <p className="text-[#ff6728] font-bold text-2xl mb-2">FLOKKY Crea8</p>
              <h2 className="cta-title text-3xl mb-2">
                A Whole World of Flakkancers <br className="d-none d-xl-block" />
                at your fingertips.
              </h2>
              <p className="cta-text mb-2">
                Work with the largest network of independent professionals and{" "}
                <br className="d-none d-lg-block" />
                get things done—from quick turnarounds.
              </p>
              <div className="flex gap-3 mt-5  flex-wrap">
              <Link href="/project-1" className="ud-btn-crea8-banner mb-2 btn-thm-border bdrs12 onebtn-crea8-banner">
                Find Work <FaArrowRight/>
              </Link>
              <Link
                href="/add-services"
                className="ud-btn-crea8-banner btn-thm-border bdrs12 mb-2 twobtn-crea8-banner"
              >
                Find Talent <FaArrowRight/>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </WrapInCenter>
  );
}
