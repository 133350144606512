import React, { useState } from "react";
// import logoImg from "../../../assests/icons/navbar-logo.png";
import CircleAnimation from "../../../components/circleAnimation/CircleAnimation";
import LoginScreen from "../loginScreen/LoginScreen";
import RegisterScreen from "../registerScreen/RegisterScreen";
import AuthNavbar from "../../../components/ui/authNavbar/AuthNavbar";
import AuthFooter from "../../../components/ui/authFooter/AuthFooter";
import "../authLayout/auth-layout.css";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

const AuthLayout = () => {
	const [toggleForm, setToggleForm] = useState(false);
	// const navigate = useNavigate();
	// const token = useSelector((state) => state.auth.token);

	// if (token !== "") {
	// 	navigate("/");
	// }

	const screenWidth = window.innerWidth < 1365;

	return (
		<>
			<div className="landing">
				<AuthNavbar />
				<div className="landing-decoration"></div>

				{/* <!-- LANDING INFO --> */}
				<div className="landing-content landing-content-css">
					<div className="landing-info">
						<CircleAnimation />
						{/* <!-- TAB SWITCH --> */}
						<div className="tab-switch">
							{/* <!-- TAB SWITCH BUTTON --> */}
							<p
								className={`tab-switch-button login-register-form-trigger ${
									toggleForm ? "" : "active"
								}`}
								onClick={() => setToggleForm(false)}
							>
								Login
							</p>
							{/* <!-- /TAB SWITCH BUTTON --> */}

							{/* <!-- TAB SWITCH BUTTON --> */}
							<p
								className={`tab-switch-button login-register-form-trigger ${
									toggleForm ? "active" : ""
								}`}
								onClick={() => setToggleForm(true)}
							>
								Register
							</p>
							{/* <!-- /TAB SWITCH BUTTON --> */}
						</div>
						{/* <!-- /TAB SWITCH --> */}
					</div>
					{/* <!-- /LANDING INFO --> */}
					<div className="landing-form">
						<LoginScreen toggleForm={toggleForm} isMobileView={screenWidth} />
						<RegisterScreen
							toggleForm={toggleForm}
							isMobileView={screenWidth}
							stateStep={0}
						/>
					</div>
				</div>
				{/* <div className="landing-footer">
				</div> */}
				<AuthFooter
					isMobileView={screenWidth}
					style={{ top: toggleForm ? "-8%" : "-8%" }}
				/>
			</div>
		</>
	);
};

export default AuthLayout;
