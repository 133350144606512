import React, { useEffect, useState } from "react";
import FormInput from "../../../ui/formInput/FormInput";
import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";
const Industries = [
	"Arts",
	"Education",
	"Design",
	"Finance",
	"Real Estate",
	"Retail",
	"Software IT Services",
	"Wellness Fitness",
	"Energy Mining",
	"Consumer Goods",
];

const IndustriesForm = () => {
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	const [selectedIndustries, setSelectedIndustries] = useState([]);
	const [industries, setIndustries] = useState(Industries);
	const [search, setSearch] = useState("");

	useEffect(() => {
		if (search !== "") {
			setIndustries((prev) => {
				const filteredIndustries = prev.filter((item) => item.includes(search));
				return filteredIndustries;
			});
		} else {
			setIndustries(Industries);
		}
	}, [search]);

	const addIndustry = (industry) => {
		setSelectedIndustries((prev) => {
			if (!prev.includes(industry)) {
				return [...selectedIndustries, industry];
			} else {
				return prev;
			}
		});
	};

	const removeIndustry = (industry) => {
		setSelectedIndustries((prev) => {
			const filteredIndustries = prev.filter((item) => item !== industry);
			return filteredIndustries;
		});
	};

	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Experience</p>
					<h2 className="section-title">Industries</h2>
				</div>
			</div>
			<div className="widget-box">
				{/* <p className="widget-box-title">Industries</p> */}

				<div className="widget-box-content">
					<form className="form">
						<div>
							<FormInput
								label={"Search Industries"}
								name={"search-industries"}
								id={"search_industries"}
								type={"text"}
								value={search}
								setValue={setSearch}
							/>
						</div>
						<div className="mt-4">
							<p className="widget-box-title">Selected Industries</p>
							<div className="w-full flex items-center justify-start flex-wrap gap-4 mt-2">
								{selectedIndustries.map((item, inedx) => (
									<p
										key={inedx}
										className="bg-[#ff6728] text-white px-[8px] py-[4px] rounded-lg font-medium text-[16px] flex items-center justify-between gap-2"
									>
										{item}{" "}
										<IoIosCloseCircle
											color="white"
											size={16}
											className="cursor-pointer"
											onClick={() => {
												removeIndustry(item);
											}}
										/>
									</p>
								))}
							</div>
						</div>
						<div className="mt-4">
							<p className="text-[18px] font-semibold">Our Industries</p>
							<div className="w-full p-4 border-solid border-[#23d2e2] border-[1px] rounded-lg flex items-center justify-start flex-wrap gap-4 mt-2">
								{industries.map((item, index) => (
									<p
										key={index}
										onClick={() => {
											addIndustry(item);
										}}
										className="border-solid border-[1px] border-[#23d2e2] duration-200 hover:bg-[#23d2e2] hover:text-white cursor-pointer px-[8px] py-[4px] rounded-lg text-[16px] font-semibold flex items-center justify-between gap-2"
									>
										{item}
									</p>
								))}
							</div>
						</div>
					</form>
				</div>
			</div>


			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default IndustriesForm;
