import "./timeline-widget.css";
import { Progress } from "antd";
import { AiOutlineCheck } from "react-icons/ai";
import { FaPlus } from "react-icons/fa6";

const ProfileCompletion = () => {
	return (
		<div className="widget-box">
			<div className="progress-arc-summary">
				<div className="progress-arc-wrap">
					<div className="progress-arc">
						<Progress
							type="circle"
							percent={59}
							size={140}
							strokeColor={"#ff6728"}
							strokeWidth={8}
							showInfo={false}
						/>
					</div>

					<div className="progress-arc-info">
						<p className="progress-arc-title">59%</p>
					</div>
				</div>

				<div className="progress-arc-summary-info">
					<p className="progress-arc-summary-title">Profile Completion</p>

					<p className="progress-arc-summary-subtitle">Marina Valentine</p>

					<p className="progress-arc-summary-text">
						Complete your profile by filling profile info fields, completing
						quests &amp; unlocking badges
					</p>
				</div>
			</div>

			{/* <div className="achievement-status-list">
        <div className="achievement-status">
          <p className="achievement-status-progress">11/30</p>

          <div className="achievement-status-info">
            <p className="achievement-status-title">Quests</p>

            <p className="achievement-status-text">Completed</p>
          </div>

          <img
            className="achievement-status-image"
            src="/img/badge/completedq-s.png"
            alt="bdage-completedq-s"
          />
        </div>

        <div className="achievement-status">
          <p className="achievement-status-progress">22/46</p>

          <div className="achievement-status-info">
            <p className="achievement-status-title">Badges</p>

            <p className="achievement-status-text">Unlocked</p>
          </div>

          <img
            className="achievement-status-image"
            src="/img/badge/unlocked-badge.png"
            alt="bdage-unlocked-badge"
          />
        </div>
      </div> */}
			<div>
				<ul className="mt-3 flex items-start justify-start flex-col">
					<li className="flex items-center justify-start gap-3 font-medium duration-200 text-[#ff6728] cursor-pointer">
						<FaPlus className="font-normal" color="#adafca" /> Choose your
						Affiliation
					</li>
					<li className="flex items-center justify-start gap-3 font-medium duration-200 text-[#adafca] cursor-pointer line-through">
						<AiOutlineCheck className="font-normal text-[#1df377]" /> Add your
						Name
					</li>
					<li className="flex items-center justify-start gap-3 font-medium duration-200 text-[#adafca] cursor-pointer line-through">
						<AiOutlineCheck className="font-normal text-[#1df377]" /> Add your
						Title
					</li>
					<li className="flex items-center justify-start gap-3 font-medium duration-200 text-[#adafca] cursor-pointer line-through">
						<AiOutlineCheck className="font-normal text-[#1df377]" /> Add your
						Skills
					</li>
					<li className="flex items-center justify-start gap-3 font-medium  duration-200 text-[#adafca] cursor-pointer line-through">
						<AiOutlineCheck className="font-normal text-[#1df377]" /> Add your
						Profile & Cover Photo
					</li>
				</ul>
			</div>
		</div>
	);
};

export default ProfileCompletion;
