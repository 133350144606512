import React from "react";
import FormInput from "../../../ui/formInput/FormInput";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";

const InvitationLinks = () => {
    const { showContent } = useSelector((state) => state.userDashboard)
    const dispatch = useDispatch()
    return (
        <div className={`account-hub-content ${showContent ? 'show' : ''}`}>
            <span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
                <svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
                    <use xlinkHref="#svg-back-arrow"></use>
                </svg>
            </span>
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Connectivity</p>
                    <h2 className="section-title">Invitation Links</h2>
                </div>
            </div>
            <div className="widget-box">
                <div className="widget-box-content">
                    <div className="grid !grid-cols-2 md:!grid-cols-3">
                        <div className="available-links text-center">
                            <h3 className="mb-[1rem]">Available Links</h3>
                            <p>10</p>
                        </div>
                        <div className="generated-links text-center">
                            <h3 className="mb-[1rem]">Generated Links</h3>
                            <p>0</p>
                        </div>
                        <div className="used-links text-center">
                            <h3 className="mb-[1rem]">Used Links</h3>
                            <p>0</p>
                        </div>
                    </div>
                    <button className="button primary !block mt-4 !w-[250px] mx-auto">Generate Link</button>
                    <div className="overflow-x-scroll md:!overflow-x-auto mt-[25px]">
                        <div className="table table-cart split-rows">
                            <div className="table-header">
                                <div className="table-header-column">
                                    <p className="table-header-title">Url</p>
                                </div>
                                <div className="table-header-column padded-left">
                                    <p className="table-header-title">Invited User</p>
                                </div>
                                <div className="table-header-column padded-left">
                                    <p className="table-header-title">Date</p>
                                </div>
                            </div>

                            <div className="table-body same-color-rows">
                                <div className="table-row medium">
                                    <div className="table-column">
                                        <div className="type w-[95px]">
                                            <button className="button primary">Copy</button>
                                        </div>
                                    </div>

                                    <div className="table-column padded-left">
                                        <p className='w-[400px] text-[0.85rem] font-bold'>
                                            {/* <span>isalmesdfsdf</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span> 
                                                <span>, mouasdasdasd</span>  */}
                                        </p>
                                    </div>

                                    <div className="table-column padded-left">
                                        <div className="date w-[150px]">
                                            <p className='text-[0.85rem] font-bold'>2023-11-27 23:11:00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default InvitationLinks;
