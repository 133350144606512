import React from 'react'
import jobImg from "../../../assests/career/images/job-img.jpg"
import businessImage from "../../../assests/career/logo/client-1.webp"
import { Link } from 'react-router-dom'
import { IoLocationSharp, IoStar } from "react-icons/io5";
import { PiBagSimpleFill } from 'react-icons/pi'
import { LuDot } from "react-icons/lu";

import UserProfileImage from "../../user-profile-image/UserProfileImage"


const LatestJobCard = () => {
    const [isFav, setIsFav] = React.useState(false);

    return (
        <div className="job-card">
            <div className="job-card-img">
                <div className={`job-fav ${isFav ? "active" : ""}`} onClick={() => setIsFav(prev => !prev)}>
                    <IoStar />
                </div>
                <Link to={"/career-details"} className="">
                    <img className='w-full h-[150px] object-cover' src={jobImg} alt="" />
                </Link>
                <Link to="/career-details" class="job-apply">Apply</Link>
            </div>
            <div className="job-card-body pt-[15px] pb-[15px] px-[20px] text-start">
                <div className='mb-[30px] relative'>
                    <Link to={"/businesses"} className='flex items-center gap-2'>
                        <span>
                            <img className='job-business-logo' src={businessImage} alt="" />
                        </span>
                        <span className='text-[18px] font-semibold pl-[100px]'>
                            Brandmarks
                        </span>
                    </Link>
                </div>
                <Link to="/career-search" class="list-text mb-2 text-[16px] font-semibold text-[#adafca] block">
                    Web &amp; App Design
                </Link>
                <h5 class="list-title mb-[20px] text-[20px]">
                    <Link to="/career-details" className='text-[#fd6728] hover:text-[#00c7d9]'>Website Designer Required For Directory Theme</Link>
                </h5>
                <ul className="flex items-center flex-wrap mb-[20px] -ml-[3px]">
                    <li className="text-[16px] font-medium flex items-center gap-[2px] !leading-none py-[5px]">
                        {/* <MdLocationOn size={20}  /> */}
                        <IoLocationSharp size={14} color="#3e3f5e" />
                        <Link to="/career-search">luxembourg</Link>
                        <Link to="/career-search">, Grevenmacher</Link>
                    </li>
                    <span className='mx-[8px] py-[5px]'><LuDot size={12} /></span>
                    <li className="text-[16px] font-medium flex items-center gap-[3px] !leading-none py-[5px]">
                        <PiBagSimpleFill size={14} fill="#2196f3" />
                        <Link to="/career-search">Part Time</Link>
                    </li>
                </ul>
                <div className="flex items-center justify-between">
                    <p className="text-[16px] font-medium">
                        Posted: <span className="">20 w</span>
                    </p>
                    <p className="text-[16px] font-bold">
                        14,000 - 15,000 EGP/Month
                    </p>
                </div>
            </div>
            <div className="job-card-footer">
                <div className="user-status">
                    <div className='absolute top-[6px] left-0'>
                        <Link to='/user-profile-page'> <UserProfileImage /> </Link>
                    </div>
                    <p className="user-status-title !text-[1rem]">
                        <Link to='/user-profile-page' className="bold hover:!text-[#00c7d9]">Marina Valentine</Link>
                    </p>
                    <Link to="/businesses/profile/digitechsolutions" className="!text-[0.9rem] font-medium block">
                        Marketing - Brandmarks
                    </Link>
                    <Link to={"/university"} className="!text-[0.9rem] font-medium block">
                        Law - Nile University
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LatestJobCard