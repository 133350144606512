import React from "react";
import { BiChevronDown } from "react-icons/bi";

const CustomTabs = ({ className, tabs, setActiveTab, activeTab, sort }) => {
  return (
    <div
      className={`flex items-center gap-6 overflow-auto rounded-xl shadow-sm shadow-gray-800/10
      scrollbar-hide bg-white p-3 
      ${className ? className : ""}`}
    >
      <div className="flex-1 flex items-center gap-4">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(tab.select)}
            className={`shrink-0 text-xl font-bold w-fit
          ${activeTab === tab.select ? "text-gray-700" : "text-gray-400"}`}
          >
            {tab.name}
          </button>
        ))}
      </div>

      {sort && (
        <div className="flex items-center text-gray-700">
          <select className="w-fit bg-white !border-none p-1 !pe-6 cursor-pointer">
            <option value="newest" className="cursor-pointer">
              Newest
            </option>
            <option value="oldest" className="cursor-pointer">
              Oldest
            </option>
          </select>

          <BiChevronDown className="text-gray-700 text-xl -ms-5 pointer-events-none" />
        </div>
      )}
    </div>
  );
};

export default CustomTabs;
