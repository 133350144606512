import React from "react";
import FormInput from "../../../ui/formInput/FormInput";
import Select from "../../../ui/select/Select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";

const TwoFactorAuth = [{ value: "Enable" }, { value: "Disable" }];

const ChangePassword = () => {
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Account</p>
					<h2 className="section-title">Change Password</h2>
				</div>
			</div>
			<div className="widget-box">
				{/* <p className="widget-box-title">Change Password</p> */}

				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0">
							<FormInput
								type={"password"}
								label={"Current Password"}
								name={"current-password"}
								id={"current_password"}
							/>
							<FormInput
								type={"password"}
								label={"New Password"}
								name={"new-password"}
								id={"new_password"}
								noMT={true}
							/>
						</div>
						<div className="flex items-center justify-between !gap-4 max-[680px]:!flex-col max-[680px]:!gap-0">
							<FormInput
								type={"password"}
								label={"Repeat Password"}
								name={"repeat-password"}
								id={"repeat_password"}
								noMT={true}
							/>
							<Select
								defaultValue={"Two-Factor Authentication"}
								optionsArray={TwoFactorAuth}
							/>
						</div>
					</form>
				</div>
			</div>


			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default ChangePassword;
