import React from "react";

const Pending = () => {
	return (
		<div className="bg-blue-600 text-white font-semibold px-2 py-1 rounded">
			Pending
		</div>
	);
};

export default Pending;
