import React from "react";
import { IoReload } from "react-icons/io5";
import { Link } from 'react-router-dom';

import UserProfileImage from "../../user-profile-image/UserProfileImage"



const SuggestedPeople = () => {
  return (
    <div class="widget-box people-may-like">
      {/* <div class="widget-box-settings">
                  <div class="post-settings-wrap">
                    <div
                      onClick={() => {
                        setIsWidgetMembersOpen((prev) => !prev);
                      }}
                      className={`post-settings widget-box-post-settings-dropdown-trigger ${
                        isWidgetMembersOpen ? "active" : ""
                      }`}
                    >
                      <svg className="post-settings-icon icon-more-dots">
                        <use xlinkHref="#svg-more-dots"></use>
                      </svg>
                    </div>
                    <div
                      className={`simple-dropdown widget-box-post-settings-dropdown ${
                        isWidgetMembersOpen
                          ? "widget-settings-open"
                          : "widget-settings-close"
                      }`}
                    >
                      <p class="simple-dropdown-link">Widget Settings</p>
                    </div>
                  </div>
                </div> */}

      <p class="widget-box-title">Flokkers you may know</p>
      <span className="widget-relod !top-[20px] !right-[28px]">
        <IoReload />
      </span>
      <div class="widget-box-content px-[28px]">
        <div class="user-status-list">
          <div class="user-status request-small !pl-[60px]">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>

            <p className="user-status-title !text-[16px]">
              <span className="bold">Neko Bebop </span>
            </p>

            <p className="user-status-text small" style={{ color: "#adafca" }}>
              Marketing Manager - Brandmarks
            </p>
            <div class="action-request-list">
              <div class="action-request accept">
                <svg class="action-request-icon icon-add-friend">
                  <use xlinkHref="#svg-add-friend"></use>
                </svg>
              </div>
            </div>
          </div>

          <div class="user-status request-small !pl-[60px]">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>

            <p className="user-status-title !text-[16px]">
              <span className="bold">Neko Bebop </span>
            </p>

            <p className="user-status-text small" style={{ color: "#adafca" }}>
              Marketing Manager - Brandmarks
            </p>
            <div class="action-request-list">
              <div class="action-request accept">
                <svg class="action-request-icon icon-add-friend">
                  <use xlinkHref="#svg-add-friend"></use>
                </svg>
              </div>
            </div>
          </div>

          <div class="user-status request-small !pl-[60px]">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>

            <p className="user-status-title !text-[16px]">
              <span className="bold">Neko Bebop </span>
            </p>

            <p className="user-status-text small" style={{ color: "#adafca" }}>
              Marketing Manager - Brandmarks
            </p>
            <div class="action-request-list">
              <div class="action-request accept">
                <svg class="action-request-icon icon-add-friend">
                  <use xlinkHref="#svg-add-friend"></use>
                </svg>
              </div>
            </div>
          </div>

          <div class="user-status request-small !pl-[60px]">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>

            <p className="user-status-title !text-[16px]">
              <span className="bold">Neko Bebop </span>
            </p>

            <p className="user-status-text small" style={{ color: "#adafca" }}>
              Marketing Manager - Brandmarks
            </p>
            <div class="action-request-list">
              <div class="action-request accept">
                <svg class="action-request-icon icon-add-friend">
                  <use xlinkHref="#svg-add-friend"></use>
                </svg>
              </div>
            </div>
          </div>

          <div class="user-status request-small !pl-[60px]">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>

            <p className="user-status-title !text-[16px]">
              <span className="bold">Neko Bebop </span>
            </p>

            <p className="user-status-text small" style={{ color: "#adafca" }}>
              Marketing Manager - Brandmarks
            </p>
            <div class="action-request-list">
              <div class="action-request accept">
                <svg class="action-request-icon icon-add-friend">
                  <use xlinkHref="#svg-add-friend"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestedPeople;
