import React, { useState } from "react";
import UserProfileImage from "../user-profile-image/UserProfileImage";
import { Link } from "react-router-dom";
import PostComments from "./PostComments/PostComments";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { MdOutlinePublic } from "react-icons/md";
import { FaChevronLeft, FaChevronRight, FaUser, FaUsers } from "react-icons/fa";
import PostCommentForm from "./PostComments/PostCommentForm";
import Comment from "./PostComments/Comment";

import "./ImagePopup.css"

const postTypeOptions = [
    {
      value: "only-me",
      label: (
        <div className="flex items-center gap-[10px]">
          <RiGitRepositoryPrivateLine /> <span>Only Me</span>
        </div>
      ),
	  svg:<RiGitRepositoryPrivateLine />
    },
    {
      value: "everyone",
      label: (
        <div className="flex items-center gap-[10px]">
          <MdOutlinePublic /> <span>Everyone</span>
        </div>
      ),
	  svg:<MdOutlinePublic />
    },
    {
      value: "people-i-follow",
      label: (
        <div className="flex items-center gap-[10px]">
          <FaUser /> <span>People I Follow</span>
        </div>
      ),
	  svg:<FaUser />
    },
    {
      value: "people-follow-me",
      label: (
        <div className="flex items-center gap-[10px]">
          <FaUsers /> <span>People Follow Me</span>
        </div>
      ),
	  svg:<FaUsers />
    },
  ];

const ImagesPostPopupGallery = ({ isOpened, setIsOpened }) => {
  const [isPostTypeOpen, setIsPostTypeOpen] = useState(false);
  const [postType, setPostType] = useState(postTypeOptions[1]);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [isWowListOpen, setIsWowListOpen] = useState(false);
  const [isHappyListOpen, setIsHappyListOpen] = useState(false);
  const [isLikeListOpen, setIsLikeListOpen] = useState(false);
  const [isReactListOpen, setIsReactListOpen] = useState(false);
  const [activeIndex , setActiveIndex] = useState(0)
  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % 3)
  }
  const handlePrev = () => {
    setActiveIndex( (activeIndex - 1 + 3) % 3 )
  }

  const imageGallery = [
    <>
    <div class="widget-box no-padding">
    <div
      class="widget-box-scrollable relative"
      style={{ height: "calc( 100% - 131px )" }}
      data-simplebar
    >
      <div class="widget-box-settings">
        <div className="post-settings-wrap">
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>
  
          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen
                ? "widget-settings-open"
                : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link">Edit Post</p>
  
            <p className="simple-dropdown-link">Delete Post</p>
  
            <p className="simple-dropdown-link">Pin Post</p>
  
            <p className="simple-dropdown-link">Report Post</p>
  
            <p className="simple-dropdown-link">Report Author</p>
  
            <p className="simple-dropdown-link">Disable Comments</p>
  
            <p className="simple-dropdown-link">Open in new tab</p>
          </div>
        </div>
      </div>
  
      <div class="widget-box-status">
        <div class="widget-box-status-content">
          <div className="user-status">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>
  
            <div className="ml-1">
              <p className="user-status-title medium">
                <a className="bold" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
  
              <div className="flex items-center gap-[10px]">
                <button
                  className="w-fit text-[#a6a8c7]"
                  onClick={() => {
                    setIsPostTypeOpen((prev) => !prev);
                  }}
                >
                  {postType.svg}
                </button>
                <div
                  className={`simple-dropdown widget-box-post-types-dropdown ${
                    isPostTypeOpen
                      ? "widget-types-open"
                      : "widget-types-close"
                  }`}
                >
                  {postTypeOptions.map((option) => (
                    <p
                      key={option.value}
                      className="simple-dropdown-link"
                      onClick={() => {
                        setPostType(option);
                        setIsPostTypeOpen(false);
                      }}
                    >
                      {" "}
                      {option.label}
                    </p>
                  ))}
                </div>
                <p className="user-status-text small !mt-[0]">2 days ago</p>
              </div>
            </div>
          </div>
  
          <p class="widget-box-status-text">
            Here's a sneak peek of the official box cover art for{" "}
            <a href="#">Machine Wasteland II</a>! Remember that I'll be
            having a stream showing a preview tommorrow at 9PM PCT!
          </p>
  
          <div class="tag-list">
            <a class="tag-item primary" href="newsfeed.html">
              Cover
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Preview
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Art
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Machine
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Wasteland
            </a>
          </div>
  
          <div className="content-actions">
            <div className="content-action">
              <div className="meta-line">
                <div className="meta-line-list reaction-item-list">
                  <div className="reaction-item">
                    <img
                      src="img/reaction/wow.png"
                      alt="reaction-wow"
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isWowListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsWowListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsWowListOpen(true);
                      }}
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isWowListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/wow.png"
                          alt="reaction-wow"
                        />
                        <span className="bold">Wow</span>
                      </p>
  
                      <p className="simple-dropdown-text">Neko Bebop</p>
  
                      <p className="simple-dropdown-text">Nick Grissom</p>
  
                      <p className="simple-dropdown-text">Sarah Diamond</p>
  
                      <p className="simple-dropdown-text">Marcus Jhonson</p>
                    </div>
                  </div>
  
                  <div className="reaction-item">
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isHappyListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsHappyListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsHappyListOpen(true);
                      }}
                      src="img/reaction/happy.png"
                      alt="reaction-happy"
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isHappyListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/happy.png"
                          alt="reaction-happy"
                        />
                        <span className="bold">Happy</span>
                      </p>
  
                      <p className="simple-dropdown-text">Jett Spiegel</p>
  
                      <p className="simple-dropdown-text">Jane Rodgers</p>
                    </div>
                  </div>
  
                  <div className="reaction-item">
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isLikeListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsLikeListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsLikeListOpen(true);
                      }}
                      src="img/reaction/like.png"
                      alt="reaction-like"
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isLikeListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/like.png"
                          alt="reaction-like"
                        />
                        <span className="bold">Like</span>
                      </p>
  
                      <p className="simple-dropdown-text">Neko Bebop</p>
  
                      <p className="simple-dropdown-text">Nick Grissom</p>
  
                      <p className="simple-dropdown-text">Sarah Diamond</p>
  
                      <p className="simple-dropdown-text">Jett Spiegel</p>
  
                      <p className="simple-dropdown-text">Marcus Jhonson</p>
  
                      <p className="simple-dropdown-text">Jane Rodgers</p>
  
                      <p className="simple-dropdown-text">
                        <span className="bold">and 7 more...</span>
                      </p>
                    </div>
                  </div>
                </div>
  
                <p className="meta-line-text">19</p>
              </div>
            </div>
  
            <div className="content-action">
              <div className="meta-line">
                <p className="meta-line-link">5 Comments</p>
              </div>
  
              <div className="meta-line">
                <p className="meta-line-text">0 Shares</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="post-options">
        <div className="post-option-wrap">
          <div
            className={`post-option no-text reaction-options-dropdown-trigger ${
              isReactListOpen ? "active" : ""
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <svg className="post-option-icon icon-thumbs-up">
              <use xlinkHref="#svg-thumbs-up"></use>
            </svg>
          </div>
  
          <div
            className={`reaction-options small reaction-options-dropdown ${
              isReactListOpen ? "emoji-list-open" : "emoji-list-close"
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Like"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/like.png"
                alt="reaction-like"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Love"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/love.png"
                alt="reaction-love"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Dislike"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/dislike.png"
                alt="reaction-dislike"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Happy"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/happy.png"
                alt="reaction-happy"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Funny"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/funny.png"
                alt="reaction-funny"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Wow"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/wow.png"
                alt="reaction-wow"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Angry"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/angry.png"
                alt="reaction-angry"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Sad"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/sad.png"
                alt="reaction-sad"
              />
            </div>
          </div>
        </div>
        <div class="post-option no-text active">
          <svg class="post-option-icon icon-comment">
            <use xlinkHref="#svg-comment"></use>
          </svg>
        </div>
        <div class="post-option no-text">
          <svg class="post-option-icon icon-share">
            <use xlinkHref="#svg-share"></use>
          </svg>
        </div>
      </div>
  
      <div className="post-comment-list">
        <Comment />
        <Comment />
        <Comment />
        <Comment />
        <Comment />
  
        <p className="post-comment-heading">
          Load More Comments <span className="highlighted">9+</span>
        </p>
      </div>
    </div>
  
    <PostCommentForm className={"bordered-top"} />
    </div>
  
    <div class="popup-picture-image-wrap">
      <figure class="popup-picture-image">
        <img src="img/cover/04.jpg" alt="cover-04" />
      </figure>
    </div>
    </>,
    <>
    <div class="widget-box no-padding">
    <div
      class="widget-box-scrollable relative"
      style={{ height: "calc( 100% - 131px )" }}
      data-simplebar
    >
      <div class="widget-box-settings">
        <div className="post-settings-wrap">
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>
  
          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen
                ? "widget-settings-open"
                : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link">Edit Post</p>
  
            <p className="simple-dropdown-link">Delete Post</p>
  
            <p className="simple-dropdown-link">Pin Post</p>
  
            <p className="simple-dropdown-link">Report Post</p>
  
            <p className="simple-dropdown-link">Report Author</p>
  
            <p className="simple-dropdown-link">Disable Comments</p>
  
            <p className="simple-dropdown-link">Open in new tab</p>
          </div>
        </div>
      </div>
  
      <div class="widget-box-status">
        <div class="widget-box-status-content">
          <div className="user-status">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>
  
            <div className="ml-1">
              <p className="user-status-title medium">
                <a className="bold" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
  
              <div className="flex items-center gap-[10px]">
                <button
                  className="w-fit text-[#a6a8c7]"
                  onClick={() => {
                    setIsPostTypeOpen((prev) => !prev);
                  }}
                >
                  {postType.svg}
                </button>
                <div
                  className={`simple-dropdown widget-box-post-types-dropdown ${
                    isPostTypeOpen
                      ? "widget-types-open"
                      : "widget-types-close"
                  }`}
                >
                  {postTypeOptions.map((option) => (
                    <p
                      key={option.value}
                      className="simple-dropdown-link"
                      onClick={() => {
                        setPostType(option);
                        setIsPostTypeOpen(false);
                      }}
                    >
                      {" "}
                      {option.label}
                    </p>
                  ))}
                </div>
                <p className="user-status-text small !mt-[0]">2 days ago</p>
              </div>
            </div>
          </div>
  
          <p class="widget-box-status-text">
            Here's a sneak peek of the official box cover art for{" "}
            <a href="#">Machine Wasteland II</a>! Remember that I'll be
            having a stream showing a preview tommorrow at 9PM PCT!
          </p>
  
          <div class="tag-list">
            <a class="tag-item primary" href="newsfeed.html">
              Cover
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Preview
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Art
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Machine
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Wasteland
            </a>
          </div>
  
          <div className="content-actions">
            <div className="content-action">
              <div className="meta-line">
                <div className="meta-line-list reaction-item-list">
                  <div className="reaction-item">
                    <img
                      src="img/reaction/wow.png"
                      alt="reaction-wow"
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isWowListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsWowListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsWowListOpen(true);
                      }}
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isWowListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/wow.png"
                          alt="reaction-wow"
                        />
                        <span className="bold">Wow</span>
                      </p>
  
                      <p className="simple-dropdown-text">Neko Bebop</p>
  
                      <p className="simple-dropdown-text">Nick Grissom</p>
  
                      <p className="simple-dropdown-text">Sarah Diamond</p>
  
                      <p className="simple-dropdown-text">Marcus Jhonson</p>
                    </div>
                  </div>
  
                  <div className="reaction-item">
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isHappyListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsHappyListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsHappyListOpen(true);
                      }}
                      src="img/reaction/happy.png"
                      alt="reaction-happy"
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isHappyListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/happy.png"
                          alt="reaction-happy"
                        />
                        <span className="bold">Happy</span>
                      </p>
  
                      <p className="simple-dropdown-text">Jett Spiegel</p>
  
                      <p className="simple-dropdown-text">Jane Rodgers</p>
                    </div>
                  </div>
  
                  <div className="reaction-item">
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isLikeListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsLikeListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsLikeListOpen(true);
                      }}
                      src="img/reaction/like.png"
                      alt="reaction-like"
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isLikeListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/like.png"
                          alt="reaction-like"
                        />
                        <span className="bold">Like</span>
                      </p>
  
                      <p className="simple-dropdown-text">Neko Bebop</p>
  
                      <p className="simple-dropdown-text">Nick Grissom</p>
  
                      <p className="simple-dropdown-text">Sarah Diamond</p>
  
                      <p className="simple-dropdown-text">Jett Spiegel</p>
  
                      <p className="simple-dropdown-text">Marcus Jhonson</p>
  
                      <p className="simple-dropdown-text">Jane Rodgers</p>
  
                      <p className="simple-dropdown-text">
                        <span className="bold">and 7 more...</span>
                      </p>
                    </div>
                  </div>
                </div>
  
                <p className="meta-line-text">19</p>
              </div>
            </div>
  
            <div className="content-action">
              <div className="meta-line">
                <p className="meta-line-link">5 Comments</p>
              </div>
  
              <div className="meta-line">
                <p className="meta-line-text">0 Shares</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="post-options">
        <div className="post-option-wrap">
          <div
            className={`post-option no-text reaction-options-dropdown-trigger ${
              isReactListOpen ? "active" : ""
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <svg className="post-option-icon icon-thumbs-up">
              <use xlinkHref="#svg-thumbs-up"></use>
            </svg>
          </div>
  
          <div
            className={`reaction-options small reaction-options-dropdown ${
              isReactListOpen ? "emoji-list-open" : "emoji-list-close"
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Like"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/like.png"
                alt="reaction-like"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Love"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/love.png"
                alt="reaction-love"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Dislike"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/dislike.png"
                alt="reaction-dislike"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Happy"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/happy.png"
                alt="reaction-happy"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Funny"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/funny.png"
                alt="reaction-funny"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Wow"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/wow.png"
                alt="reaction-wow"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Angry"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/angry.png"
                alt="reaction-angry"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Sad"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/sad.png"
                alt="reaction-sad"
              />
            </div>
          </div>
        </div>
        <div class="post-option no-text active">
          <svg class="post-option-icon icon-comment">
            <use xlinkHref="#svg-comment"></use>
          </svg>
        </div>
        <div class="post-option no-text">
          <svg class="post-option-icon icon-share">
            <use xlinkHref="#svg-share"></use>
          </svg>
        </div>
      </div>
  
      <div className="post-comment-list">
        <Comment />
        <Comment />
        <Comment />
        <Comment />
        <Comment />
  
        <p className="post-comment-heading">
          Load More Comments <span className="highlighted">9+</span>
        </p>
      </div>
    </div>
  
    <PostCommentForm className={"bordered-top"} />
    </div>
  
    <div class="popup-picture-image-wrap">
      <figure class="popup-picture-image">
        <img src="img/cover/05.jpg" alt="cover-04" />
      </figure>
    </div>
    </>,
    <>
    <div class="widget-box no-padding">
    <div
      class="widget-box-scrollable relative"
      style={{ height: "calc( 100% - 131px )" }}
      data-simplebar
    >
      <div class="widget-box-settings">
        <div className="post-settings-wrap">
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>
  
          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen
                ? "widget-settings-open"
                : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link">Edit Post</p>
  
            <p className="simple-dropdown-link">Delete Post</p>
  
            <p className="simple-dropdown-link">Pin Post</p>
  
            <p className="simple-dropdown-link">Report Post</p>
  
            <p className="simple-dropdown-link">Report Author</p>
  
            <p className="simple-dropdown-link">Disable Comments</p>
  
            <p className="simple-dropdown-link">Open in new tab</p>
          </div>
        </div>
      </div>
  
      <div class="widget-box-status">
        <div class="widget-box-status-content">
          <div className="user-status">
            <Link className="user-status-avatar" to="/user-profile-page">
              <UserProfileImage />
            </Link>
  
            <div className="ml-1">
              <p className="user-status-title medium">
                <a className="bold" href="profile-timeline.html">
                  Marina Valentine{" "}
                </a>
              </p>
  
              <div className="flex items-center gap-[10px]">
                <button
                  className="w-fit text-[#a6a8c7]"
                  onClick={() => {
                    setIsPostTypeOpen((prev) => !prev);
                  }}
                >
                  {postType.svg}
                </button>
                <div
                  className={`simple-dropdown widget-box-post-types-dropdown ${
                    isPostTypeOpen
                      ? "widget-types-open"
                      : "widget-types-close"
                  }`}
                >
                  {postTypeOptions.map((option) => (
                    <p
                      key={option.value}
                      className="simple-dropdown-link"
                      onClick={() => {
                        setPostType(option);
                        setIsPostTypeOpen(false);
                      }}
                    >
                      {" "}
                      {option.label}
                    </p>
                  ))}
                </div>
                <p className="user-status-text small !mt-[0]">2 days ago</p>
              </div>
            </div>
          </div>
  
          <p class="widget-box-status-text">
            Here's a sneak peek of the official box cover art for{" "}
            <a href="#">Machine Wasteland II</a>! Remember that I'll be
            having a stream showing a preview tommorrow at 9PM PCT!
          </p>
  
          <div class="tag-list">
            <a class="tag-item primary" href="newsfeed.html">
              Cover
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Preview
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Art
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Machine
            </a>
  
            <a class="tag-item primary" href="newsfeed.html">
              Wasteland
            </a>
          </div>
  
          <div className="content-actions">
            <div className="content-action">
              <div className="meta-line">
                <div className="meta-line-list reaction-item-list">
                  <div className="reaction-item">
                    <img
                      src="img/reaction/wow.png"
                      alt="reaction-wow"
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isWowListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsWowListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsWowListOpen(true);
                      }}
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isWowListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/wow.png"
                          alt="reaction-wow"
                        />
                        <span className="bold">Wow</span>
                      </p>
  
                      <p className="simple-dropdown-text">Neko Bebop</p>
  
                      <p className="simple-dropdown-text">Nick Grissom</p>
  
                      <p className="simple-dropdown-text">Sarah Diamond</p>
  
                      <p className="simple-dropdown-text">Marcus Jhonson</p>
                    </div>
                  </div>
  
                  <div className="reaction-item">
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isHappyListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsHappyListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsHappyListOpen(true);
                      }}
                      src="img/reaction/happy.png"
                      alt="reaction-happy"
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isHappyListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/happy.png"
                          alt="reaction-happy"
                        />
                        <span className="bold">Happy</span>
                      </p>
  
                      <p className="simple-dropdown-text">Jett Spiegel</p>
  
                      <p className="simple-dropdown-text">Jane Rodgers</p>
                    </div>
                  </div>
  
                  <div className="reaction-item">
                    <img
                      className={`reaction-image reaction-item-dropdown-trigger ${
                        isLikeListOpen ? "active" : ""
                      }`}
                      onMouseLeave={() => {
                        setIsLikeListOpen(false);
                      }}
                      onMouseEnter={() => {
                        setIsLikeListOpen(true);
                      }}
                      src="img/reaction/like.png"
                      alt="reaction-like"
                    />
  
                    <div
                      className={`simple-dropdown padded reaction-item-dropdown ${
                        isLikeListOpen
                          ? "reacts-list-open"
                          : "reacts-list-close"
                      }`}
                    >
                      <p className="simple-dropdown-text">
                        <img
                          className="reaction"
                          src="img/reaction/like.png"
                          alt="reaction-like"
                        />
                        <span className="bold">Like</span>
                      </p>
  
                      <p className="simple-dropdown-text">Neko Bebop</p>
  
                      <p className="simple-dropdown-text">Nick Grissom</p>
  
                      <p className="simple-dropdown-text">Sarah Diamond</p>
  
                      <p className="simple-dropdown-text">Jett Spiegel</p>
  
                      <p className="simple-dropdown-text">Marcus Jhonson</p>
  
                      <p className="simple-dropdown-text">Jane Rodgers</p>
  
                      <p className="simple-dropdown-text">
                        <span className="bold">and 7 more...</span>
                      </p>
                    </div>
                  </div>
                </div>
  
                <p className="meta-line-text">19</p>
              </div>
            </div>
  
            <div className="content-action">
              <div className="meta-line">
                <p className="meta-line-link">5 Comments</p>
              </div>
  
              <div className="meta-line">
                <p className="meta-line-text">0 Shares</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="post-options">
        <div className="post-option-wrap">
          <div
            className={`post-option no-text reaction-options-dropdown-trigger ${
              isReactListOpen ? "active" : ""
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <svg className="post-option-icon icon-thumbs-up">
              <use xlinkHref="#svg-thumbs-up"></use>
            </svg>
          </div>
  
          <div
            className={`reaction-options small reaction-options-dropdown ${
              isReactListOpen ? "emoji-list-open" : "emoji-list-close"
            }`}
            onMouseEnter={() => setIsReactListOpen(true)}
            onMouseLeave={() => setIsReactListOpen(false)}
          >
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Like"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/like.png"
                alt="reaction-like"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Love"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/love.png"
                alt="reaction-love"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Dislike"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/dislike.png"
                alt="reaction-dislike"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Happy"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/happy.png"
                alt="reaction-happy"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Funny"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/funny.png"
                alt="reaction-funny"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Wow"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/wow.png"
                alt="reaction-wow"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Angry"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/angry.png"
                alt="reaction-angry"
              />
            </div>
  
            <div
              className="reaction-option text-tooltip-tft"
              data-title="Sad"
            >
              <img
                className="reaction-option-image"
                src="img/reaction/sad.png"
                alt="reaction-sad"
              />
            </div>
          </div>
        </div>
        <div class="post-option no-text active">
          <svg class="post-option-icon icon-comment">
            <use xlinkHref="#svg-comment"></use>
          </svg>
        </div>
        <div class="post-option no-text">
          <svg class="post-option-icon icon-share">
            <use xlinkHref="#svg-share"></use>
          </svg>
        </div>
      </div>
  
      <div className="post-comment-list">
        <Comment />
        <Comment />
        <Comment />
        <Comment />
        <Comment />
  
        <p className="post-comment-heading">
          Load More Comments <span className="highlighted">9+</span>
        </p>
      </div>
    </div>
  
    <PostCommentForm className={"bordered-top"} />
    </div>
  
    <div class="popup-picture-image-wrap">
      <figure class="popup-picture-image">
        <img src="img/cover/03.jpg" alt="cover-04" />
      </figure>
    </div>
    </>,
  ]

  return (

    <>
    <div
      class="popup-picture"
      style={
        isOpened
          ? {
              position: "fixed",
              top: "74px",
              left: "50%",
              zIndex: 100001,
              opacity: 1,
              visibility: "visible",
              transform: "translate(-50%, 0px)",
              transition:
                "transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s",
              // marginLeft: "-607.5px",
            }
          : {
              position: "fixed",
              top: "74px",
              left: "50%",
              zIndex: 100001,
              opacity: 0,
              visibility: "hidden",
              transform: "translate(-50%, -40px)",
              transition:
                "transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s",
            }
      }
    >
      <div
        class="popup-close-button popup-picture-trigger"
        onClick={() => setIsOpened((prev) => !prev)}
      >
        <svg class="popup-close-button-icon icon-cross">
          <use xlinkHref="#svg-cross"></use>
        </svg>
      </div>

      {imageGallery[activeIndex]}

    </div>
    <div className="popup-gallery-buttons">
        <button className="button-prev text-[#fff] absolute top-[50%] left-[-8px] md:left-[5px] translate-y-[-50%] w-fit text-[50px]" onClick={handlePrev}><FaChevronLeft /></button>
        <button className="button-next text-[#fff] absolute top-[50%] right-[-8px] md:right-[5px] translate-y-[-50%] w-fit text-[50px]" onClick={handleNext}><FaChevronRight /></button>
    </div>
    </>
  );
};

export default ImagesPostPopupGallery