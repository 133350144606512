import React from "react";

const Verified = () => {
	return (
		<div className="bg-green-600 text-white font-semibold px-2 py-1 rounded">
			Verified
		</div>
	);
};

export default Verified;
