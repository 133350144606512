import React from "react";
import Select from "../../../ui/select/Select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";

const PrivacyForm = () => {
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Account</p>
					<h2 className="section-title">Privacy</h2>
				</div>
			</div>
			<div className="widget-box">
				{/* <p className="widget-box-title">Privacy</p> */}

				<div className="widget-box-content">
					<form className="form">
						<div className="flex items-center justify-between !gap-4 max-[1000px]:!flex-col max-[1000px]:!gap-0">
							<Select defaultValue={"Who can follow me ?"} optionsArray={[]} />
							<Select defaultValue={"Who can message me ?"} optionsArray={[]} />
						</div>
						<div className="flex items-center justify-between !gap-4 max-[1000px]:!flex-col max-[1000px]:!gap-0">
							<Select
								defaultValue={"Who can see my connections ?"}
								optionsArray={[]}
							/>
							<Select
								defaultValue={"Who can post on my timeline ?"}
								optionsArray={[]}
							/>
						</div>
						<div className="flex items-center justify-between !gap-4 max-[1000px]:!flex-col max-[1000px]:!gap-0">
							<Select
								defaultValue={"Who can see my birthday?"}
								optionsArray={[]}
							/>
							<div
								className="form-item form-item-css"
								style={{
									marginTop: "0 !important",
									marginBottom: "0 !important",
								}}
							>
								<Select
									defaultValue={"Confirm request when someone follows you ?"}
									optionsArray={[]}
								/>
							</div>
						</div>
						<div className="flex items-center justify-between !gap-4 max-[1000px]:!flex-col max-[1000px]:!gap-0">
							<Select
								defaultValue={
									"Send users a notification when i visit their profile?"
								}
								optionsArray={[]}
							/>
							<Select defaultValue={"Show my activities ?"} optionsArray={[]} />
						</div>
						<div className="flex items-center justify-between !gap-4 max-[1000px]:!flex-col max-[1000px]:!gap-0">
							<Select defaultValue={"Status"} optionsArray={[]} />
							<Select
								defaultValue={"Share my location with public?"}
								optionsArray={[]}
							/>
						</div>
						<div className="flex items-center justify-between gap-4">
							<Select
								defaultValue={
									"Allow search engines to index my profile and posts?"
								}
								optionsArray={[]}
							/>
						</div>
					</form>
				</div>
			</div>


			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default PrivacyForm;
