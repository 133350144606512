import React, { useState } from "react";

const Discussions = () => {
  const [isWidgetOpen, setIsWidgetOpen] = useState("");

  return (
    <div className="widget-box">
      <div className="widget-box-settings">
        <div className="post-settings-wrap">
          <div
            onClick={() => {
              setIsWidgetOpen((prev) => !prev);
            }}
            className={`post-settings widget-box-post-settings-dropdown-trigger ${
              isWidgetOpen ? "active" : ""
            }`}
          >
            <svg className="post-settings-icon icon-more-dots">
              <use xlinkHref="#svg-more-dots"></use>
            </svg>
          </div>

          <div
            className={`simple-dropdown widget-box-post-settings-dropdown ${
              isWidgetOpen ? "widget-settings-open" : "widget-settings-close"
            }`}
          >
            <p className="simple-dropdown-link">Widget Settings</p>
          </div>
        </div>
      </div>

      <p className="widget-box-title">
        Discussions <span className="highlighted">16</span>
      </p>

      <div className="widget-box-content">
        <div className="filters">
          <p className="filter">Newest</p>
          <p className="filter active">Popular</p>
        </div>

        <div className="post-preview-line-list">
          <div className="post-preview-line">
            <p className="post-preview-line-title">
              <a href="forums-discussion.html">
                In your opinion, who has the best cosplay?
              </a>
            </p>
            <div className="post-preview-line-meta">
              <a
                className="user-avatar micro no-border"
                href="profile-timeline.html"
              >
                <div className="user-avatar-content">
                  <div
                    className="hexagon-image-18-20"
                    data-src="img/avatar/05.jpg"
                  ></div>
                </div>
              </a>
              <p className="post-preview-line-author">
                <a href="profile-timeline.html">By Neko Bebop</a>
              </p>
              <p className="post-preview-line-timestamp">2 weeks ago</p>
            </div>
          </div>

          <div className="post-preview-line">
            <p className="post-preview-line-title">
              <a href="forums-discussion.html">
                Preparing everything for CosWorld 2019, show your works here!
              </a>
            </p>
            <div className="post-preview-line-meta">
              <a
                className="user-avatar micro no-border"
                href="profile-timeline.html"
              >
                <div className="user-avatar-content">
                  <div
                    className="hexagon-image-18-20"
                    data-src="img/avatar/03.jpg"
                  ></div>
                </div>
              </a>
              <p className="post-preview-line-author">
                <a href="profile-timeline.html">By Nick Grissom</a>
              </p>
              <p className="post-preview-line-timestamp">13 hours ago</p>
            </div>
          </div>

          <div className="post-preview-line">
            <p className="post-preview-line-title">
              <a href="forums-discussion.html">
                Official Topic! Let's organize the global meetups for all
                continents!
              </a>
            </p>
            <div className="post-preview-line-meta">
              <a
                className="user-avatar micro no-border"
                href="profile-timeline.html"
              >
                <div className="user-avatar-content">
                  <div
                    className="hexagon-image-18-20"
                    data-src="img/avatar/02.jpg"
                  ></div>
                </div>
              </a>
              <p className="post-preview-line-author">
                <a href="profile-timeline.html">By Destroy Dex</a>
              </p>
              <p className="post-preview-line-timestamp">5 days ago</p>
            </div>
          </div>

          <div className="post-preview-line">
            <p className="post-preview-line-title">
              <a href="forums-discussion.html">
                Share your cosplay tips here! Tutorials, DIY's, paints and more
              </a>
            </p>
            <div className="post-preview-line-meta">
              <a
                className="user-avatar micro no-border"
                href="profile-timeline.html"
              >
                <div className="user-avatar-content">
                  <div
                    className="hexagon-image-18-20"
                    data-src="img/avatar/05.jpg"
                  ></div>
                </div>
              </a>
              <p className="post-preview-line-author">
                <a href="profile-timeline.html">By Neko Bebop</a>
              </p>
              <p className="post-preview-line-timestamp">20 hours ago</p>
            </div>
          </div>

          <div className="post-preview-line">
            <p className="post-preview-line-title">
              <a href="forums-discussion.html">
                Check out all the pictures I took at the CosplayWonder 2018
              </a>
            </p>
            <div className="post-preview-line-meta">
              <a
                className="user-avatar micro no-border"
                href="profile-timeline.html"
              >
                <div className="user-avatar-content">
                  <div
                    className="hexagon-image-18-20"
                    data-src="img/avatar/07.jpg"
                  ></div>
                </div>
              </a>
              <p className="post-preview-line-author">
                <a href="profile-timeline.html">By Sarah Diamond</a>
              </p>
              <p className="post-preview-line-timestamp">19 days ago</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discussions;
