import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import WrapInCenter from "../../components/WrapInCenter/WrapInCenter";
import AdsBanner from "../../components/AdsBanner/AdsBanner";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


import BlogPost from "../../components/UserProfilePage/BlogPost";
import PollPost from "../../components/UserProfilePage/PollPost";
import VideoPost from "../../components/UserProfilePage/VideoPost";
import SharedPost from "../../components/UserProfilePage/SharedPost";
import TextPost from "../../components/UserProfilePage/TextPost";
import ImagePostPopup from "../../components/UserProfilePage/ImagePostPopup";
import GifPost from "../../components/UserProfilePage/GifPost";
import BgPost from "../../components/UserProfilePage/BgPost";
import AudioPost from "../../components/UserProfilePage/AudioPost";
import AdPost from "../../components/UserProfilePage/AdPost";
import NavigationBar from "../../components/HomeScreen/NavigationBar/NavigationBar";
import YoutubeVideoPost from "../../components/UserProfilePage/YoutubeVideoPost";
import ImagesPost from "../../components/UserProfilePage/ImagesPost";
import ImagePost from "../../components/UserProfilePage/ImagePost";
import ListingPost from "../../components/UserProfilePage/ListingPost";
import ImagesPostPopupGallery from "../../components/UserProfilePage/ImagesPostPopupGallery";
import UserStory from "../../components/HomeScreen/Stories/UserStory";
import FriendStory from "../../components/HomeScreen/Stories/FriendStory";
import HomeLeftAside from "../../components/HomeScreen/HomeAsides/HomeLeftAside";
import HomeRightAside from "../../components/HomeScreen/HomeAsides/HomeRightAside";
import { useSelector } from "react-redux";

const bannersImages = [
  "/img/ads/ad.png",
  "/img/ads/ad.png",
  "/img/ads/ad.png",
];


const SinglePost = () => {
  const isUserStoryVisible = useSelector((state) => state.outlet.isUserStoryVisible);
  const isFriendStoryVisible = useSelector((state) => state.outlet.isFriendStoryVisible);
  const [isImagePopupOpened , setIsImagePopupOpened] = useState(false)
  const [isImagePopupGalleryOpened, setIsImagePopupGalleryOpened] = useState(false);
  
  const imagePopWrapperRef = useRef(null)
  const imageGalleryPopWrapperRef = useRef(null)

	const screenWidth = window.innerWidth < 1365;

  useEffect( ()=>{
    document.addEventListener("mousedown" , function(e){
      if(imagePopWrapperRef.current && imagePopWrapperRef.current == e.target){
        setIsImagePopupOpened(false)
      }
      if(imageGalleryPopWrapperRef.current && imageGalleryPopWrapperRef.current == e.target){
        setIsImagePopupGalleryOpened(false)
      }
    })

    return () =>{
      document.removeEventListener("mousedown" , function(e){
        if(imagePopWrapperRef.current && imagePopWrapperRef.current == e.target){
          setIsImagePopupOpened(false)
        }
        if(imageGalleryPopWrapperRef.current && imageGalleryPopWrapperRef.current == e.target){
          setIsImagePopupGalleryOpened(false)
        }
      })
    }
  } ,[])

  return (
    <>
    
    <div className="homescreen">
      <AdsBanner bannersImages={bannersImages} />

      <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
      <div className="grid grid-3-6-3">
            <HomeLeftAside />

            <div className="grid-column">
              {/* <ImagesPost setIsImagePopupOpened={setIsImagePopupOpened} setIsImagePopupGalleryOpened={setIsImagePopupGalleryOpened} /> */}
              <PollPost />
            </div>

            <HomeRightAside />
          </div>
      </WrapInCenter>

      <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}	
          autoplay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[ Pagination,Autoplay]}
          className={`ads-swiper-2 !h-[200px] !pb-[50px] mt-[30px]`}
        >
          {bannersImages.map((banner, index) => (
            <SwiperSlide key={index} className="bg-white rounded-[12px] shadow-[0_0_40px_0_rgba(94,92,154,0.06)]">
              <div className="banner-container !w-[970px] !h-[90px]">
                <img src={banner} alt="ads banner" loading="lazy" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <footer className={`${ screenWidth ? "" : "isMobileView"} text-center fw-semibold fs-6 login-footer`}n>
          {/* begin::Link */}
          <div className="footer-links-container">
            <Link className="" to="/auth">Terms of use</Link>
            <Link className="" to="/auth">Privacy Policy</Link>
            <Link className="" to="/auth">Contact Us</Link>
            <Link className="" to="/auth">About</Link>
            <Link className="" to="/auth">Blog</Link>
            <Link className="" to="/auth">Events</Link>
          </div>
          <p className="copyrights ">
            Copyright &copy; 2024 FLOKKY | BRANDMARKS
          </p>
          {/* end::Link */}
        </footer>
      </WrapInCenter>
    </div>

    
    <div className="image-popup-wrapper" 
      ref={imagePopWrapperRef}
      style={
        isImagePopupOpened ? {
        opacity: 1,
        visibility: 'visible',
      } : {
        opacity: 0,
        visibility: 'hidden',
      }
    }
    >
      <ImagePostPopup isOpened={isImagePopupOpened} setIsOpened={setIsImagePopupOpened} />
    </div>

    <div className="gallery-popup-wrapper"
      ref={imageGalleryPopWrapperRef}
      style={
        isImagePopupGalleryOpened ? {
        opacity: 1,
        visibility: 'visible',
      } : {
        opacity: 0,
        visibility: 'hidden',
      }
    }
    >
      <ImagesPostPopupGallery isOpened={isImagePopupGalleryOpened} setIsOpened={setIsImagePopupGalleryOpened} />
    </div>
    
    { isUserStoryVisible && <UserStory /> }
    { isFriendStoryVisible && <FriendStory /> }

    </>
  );
};

export default SinglePost;
