import React, { useState } from 'react'

import Stories from '../../components/HomeScreen/Stories/Stories';
import SuggestedCommunities from '../../components/HomeScreen/SuggestedCommunities/SuggestedCommunities';
import QuickPost from '../../components/tech-business-profile/quick-post/QuickPost';
import NavigationBar from '../../components/HomeScreen/NavigationBar/NavigationBar';
import BlogPost from '../../components/UserProfilePage/BlogPost';
import ListingPost from '../../components/UserProfilePage/ListingPost';
import VideoPost from '../../components/UserProfilePage/VideoPost';
import AdPost from '../../components/UserProfilePage/AdPost';
import ImagePost from '../../components/UserProfilePage/ImagePost';
import AudioPost from '../../components/UserProfilePage/AudioPost';
import SuggestedBusiness from '../../components/HomeScreen/SuggestedBusiness/SuggestedBusiness';
import ImagesPost from '../../components/UserProfilePage/ImagesPost';
import TextPost from '../../components/UserProfilePage/TextPost';
import SuggestedPeople from '../../components/HomeScreen/SuggestedPeople/SuggestedPeople';
import YoutubeVideoPost from '../../components/UserProfilePage/YoutubeVideoPost';
import GifPost from '../../components/UserProfilePage/GifPost';
import BgPost from '../../components/UserProfilePage/BgPost';
import SharedPost from '../../components/UserProfilePage/SharedPost';
import PollPost from '../../components/UserProfilePage/PollPost';
import InviteFriends from '../../components/HomeScreen/InviteFriends/InviteFriends';
import Weather from '../../components/HomeScreen/Weather/Weather';
import HomeSponsore from '../../components/HomeScreen/HomeSponsore/HomeSponsore';



const HomeScreenMobile = ({setIsImagePopupOpened,setIsImagePopupGalleryOpened}) => {
    // const [isImagePopupOpened , setIsImagePopupOpened] = useState(false)
    // const [isImagePopupGalleryOpened, setIsImagePopupGalleryOpened] = useState(false);
    return (
        <div className="grid">
            <Stories />
            <SuggestedCommunities />
            <QuickPost />
            <div className="photo-banner rounded-[8px] overflow-hidden shadow-[0_0_40px_0_rgba(94,92,154,0.06)]">
                <img className="w-full" src="/img/ads/ad-02.jpg" alt="" />
            </div>
            <NavigationBar />
            <BlogPost />
            <ListingPost setIsImagePopupGalleryOpened={setIsImagePopupGalleryOpened} />
            <VideoPost />
            <AdPost />
            <ImagePost setIsImagePopupOpened={setIsImagePopupOpened} />
            <AudioPost />
            <SuggestedBusiness />
            <ImagesPost setIsImagePopupOpened={setIsImagePopupOpened} setIsImagePopupGalleryOpened={setIsImagePopupGalleryOpened} />
            <TextPost />
            <SuggestedPeople />
            <YoutubeVideoPost />
            <GifPost />
            <BgPost />
            <AdPost />
            <SharedPost />
            <PollPost />
            <InviteFriends />
            <Weather />
            <TextPost />
            <SharedPost />
            <HomeSponsore />
            <div className="no-more bg-white py-[20px] text-center mb-[1.5rem] text-[18px] font-[600] rounded-[5px]">
                <p>No More Posts</p>
            </div>
        </div>
    )
}

export default HomeScreenMobile