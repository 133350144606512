import React, { useState } from "react";

const FormSwitch = () => {
	const [isActive, setIsActive] = useState(false);
	return (
		<div
			className={`form-switch ${isActive ? "active" : ""}`}
			onClick={() => {
				setIsActive((prev) => !prev);
			}}
		>
			<div className="form-switch-button"></div>
		</div>
	);
};

export default FormSwitch;
