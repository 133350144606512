import { useState } from "react";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import "./Testimonial.css";
import WrapInCenter from "../../WrapInCenter/WrapInCenter";
import UserProfileImage from "../../user-profile-image/UserProfileImage"
import { Link } from "react-router-dom";


export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <section className="careerTestimonialSection mb-[110px]">
      <WrapInCenter noPaddingBottom={true} noPaddingTop={true}>
        <div className="header-career-section">
          <h3>What our students have to say</h3>
          <p>Discover your perfect program in our courses.</p>
        </div>

        <div className="flex justify-center mt-16">
          <ImQuotesRight size={50} className="inline text-[#5bbb7b] ml-2" />
        </div>

        <div className="mt-[50px] max-w-[650px] font-bold text-[20px] md:text-[30px] text-center mx-auto">
          "Our family was traveling via bullet train between cities in Japan with
          our luggage - the location for this hotel made that so easy. Agoda
          price was fantastic"
        </div>
        <div className="testimonial-tabs-wrapper grid lg:!grid-cols-3 !mt-[50px]">
          <div className={`testimonial-tab ${activeTab === 1 ? "active" : ""}`} onClick={() => setActiveTab(1)}>
            <div className="user-status">
              <div className='absolute top-[6px] left-0'>
                <Link to='/user-profile-page'> <UserProfileImage /> </Link>
              </div>
              <p className="user-status-title !text-[1rem]">
                <Link to='/user-profile-page' className="bold">Marina Valentine</Link>
              </p>
              <Link to="/businesses" className="!text-[0.9rem] font-medium block">
                Marketing - Brandmarks
              </Link>
              <Link to={"/university"} className="!text-[0.9rem] font-medium block">
                Law - Nile University
              </Link>
            </div>
          </div>
          <div className={`testimonial-tab ${activeTab === 2 ? "active" : ""}`} onClick={() => setActiveTab(2)}>
            <div className="user-status">
              <div className='absolute top-[6px] left-0'>
                <Link to='/user-profile-page'> <UserProfileImage /> </Link>
              </div>
              <p className="user-status-title !text-[1rem]">
                <Link to='/user-profile-page' className="bold">Marina Valentine</Link>
              </p>
              <Link to="/businesses" className="!text-[0.9rem] font-medium block">
                Marketing - Brandmarks
              </Link>
              <Link to={"/university"} className="!text-[0.9rem] font-medium block">
                Law - Nile University
              </Link>
            </div>
          </div>
          <div className={`testimonial-tab ${activeTab === 3 ? "active" : ""}`} onClick={() => setActiveTab(3)}>
            <div className="user-status">
              <div className='absolute top-[6px] left-0'>
                <Link to='/user-profile-page'> <UserProfileImage /> </Link>
              </div>
              <p className="user-status-title !text-[1rem]">
                <Link to='/user-profile-page' className="bold">Marina Valentine</Link>
              </p>
              <Link to="/businesses" className="!text-[0.9rem] font-medium block">
                Marketing - Brandmarks
              </Link>
              <Link to={"/university"} className="!text-[0.9rem] font-medium block">
                Law - Nile University
              </Link>
            </div>
          </div>
        </div>
      </WrapInCenter>
    </section>
  );
}
