import React, { useState } from "react";
import FormSwitch from "../../../ui/form-switch/FormSwitch";
import NotificationForm from "./NotificationForm";
import EmailSettings from "./EmailSettings";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowContent } from "../../../../app/feature/userDashboard";

const NotificationsSettings = () => {
	const [selectedForm, setSelectedForm] = useState("Notifications");
	const {showContent} = useSelector((state) => state.userDashboard)
	const dispatch = useDispatch()
	return (
		<div className={`account-hub-content ${showContent ? 'show' : ''}`}>
			<span className="block md:hidden" onClick={() => dispatch(setShowContent(!showContent))}>
				<svg class="fill-[#3e3f5e] w-[20px] h-[20px]">
					<use xlinkHref="#svg-back-arrow"></use>
				</svg>
			</span>
			<div className="section-header">
				<div className="section-header-info">
					<p className="section-pretitle">Account</p>
					<h2 className="section-title">Notifications Settings</h2>
				</div>
			</div>
			<div className="widget-box">
				<div className="flex items-center justify-start gap-4">
					<p
						className={`widget-box-title duration-200 hover:text-[#ff6728] cursor-pointer ${
							selectedForm === "Notifications" ? "text-[#ff6728]" : ""
						}`}
						onClick={() => {
							setSelectedForm("Notifications");
						}}
					>
						Notifications Settings
					</p>
					<p
						className={`widget-box-title duration-200 hover:text-[#ff6728] cursor-pointer ${
							selectedForm === "Email" ? "text-[#ff6728]" : ""
						}`}
						onClick={() => {
							setSelectedForm("Email");
						}}
					>
						Email Notification
					</p>
				</div>

				{selectedForm === "Notifications" && (
					<p className="widget-box-title mt-8 text-[18px]">Notify me when</p>
				)}
				{selectedForm === "Email" && (
					<p className="widget-box-title mt-8 text-[18px]">Email me when</p>
				)}
				<div className="widget-box-content">
					{selectedForm === "Notifications" && <NotificationForm />}
					{selectedForm === "Email" && <EmailSettings />}
				</div>
			</div>


			<div className="user-preview-actions !flex md:!hidden mt-[40px]">
				<button className="button primary">Save Changes</button>
			</div>
		</div>
	);
};

export default NotificationsSettings;
